import React from 'react';
import Modal from 'react-bootstrap/Modal';
import avatar from '../../../assets/img/avatars-lg.png';

type Props = {
    companyList: string[];
    show: boolean;
    setShow: (key: boolean) => void;
};

const CompanyNameList: React.FC<Props> = ({ companyList, show, setShow }) => {
    return (
        <>
            <Modal show={show} onHide={() => setShow(false)} centered>
                <div className="lt-modal-content">
                    <Modal.Header className="lt-modal-header">
                        <Modal.Title className="text-center fw-700 w-100 fs-20">Companies</Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            title="Close"
                            aria-label="Close"
                            onClick={() => setShow(false)}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="lt-modal-body">
                        <div className="d-flex align-items-center my-4">
                            <div className="ms-3">
                                {companyList.length > 0 ?
                                    companyList.map((item, key) => {
                                        return <p className="fs-18" key={key}> {key+1}. {item}</p>;
                                    }) : "No company found"}
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
};

export default CompanyNameList;
