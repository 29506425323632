import React, { useState, useMemo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { FeedbackColor } from './utils/colors';
import { months } from './utils/month';
import { GetFeedbackCountType, seriesType } from '../../../types/dashboard';
import { dashboardService } from '../../../lib/api/dashboard';
import YearsDropdown from '../../../components/dropdown';
import PageLoader from '../../../components/loader';
import { percentage } from './utils/percentage';

type Props = {
    selectedYear: number;
};

const PlatformFeedback: React.FC<Props> = ({ selectedYear }) => {
    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<GetFeedbackCountType>();
    const [dropdownYear, setDropdownYear] = useState<number>(selectedYear);
    const [seriesArray, setSeriesArray] = useState<seriesType[]>([]);
    const [feedbackTotal, setFeedbackTotal] = useState<number>(0);

    useMemo(() => {
        setDropdownYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await dashboardService.getFeedbackCount(dropdownYear).then((res) => {
                    const value = res.resultObject;
                    setData(value);
                    setFeedbackTotal(
                        value.excellent.reduce(reducer) +
                            value.good.reduce(reducer) +
                            value.neutral.reduce(reducer) +
                            value.sad.reduce(reducer) +
                            value.angry.reduce(reducer)
                    );

                    setSeriesArray([
                        {
                            name: 'Excellent',
                            data: value.excellent,
                        },
                        {
                            name: 'Good',
                            data: value.good,
                        },
                        {
                            name: 'Average',
                            data: value.neutral,
                        },
                        {
                            name: 'Bad',
                            data: value.sad,
                        },
                        {
                            name: 'Awful',
                            data: value.angry,
                        },
                    ]);
                });
            } catch (error) {
                console.log('GetFeedbackCount Services', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dropdownYear]);

    const options: ApexOptions = {
        chart: {
            height: 420,
            stacked: true,
            toolbar: {
                show: true,
            },
            zoom: {
                enabled: true,
            },
        },
        colors: FeedbackColor,
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                columnWidth: '35%',
                barHeight: '70%',
                distributed: false,
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: months,
        },
        legend: {
            position: 'bottom',
        },
    };

    return (
        <div className="col-lg-12 col-xl-4">
            <div className="card border p-4 mb-4">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        {' '}
                        <div className="d-flex mb-3">
                            <div className="card-title fs-16 fw-600">
                                Platform feedback{' '}
                                <i className="bi bi-info-circle lt-tooltip">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">
                                        Total number of feedback given by companies in a year or month
                                    </span>
                                </i>
                            </div>
                            <YearsDropdown setDropdownYear={setDropdownYear} />
                        </div>
                        <div className="position-relative border-top pt-3 text-center">
                            <ReactApexChart options={options} series={seriesArray} type="bar" height={420} />

                            <div
                                className="btn btn-outline-primary text-decoration-none"
                                data-bs-toggle="modal"
                                data-bs-target="#DetailedFeedback"
                            >
                                Detailed feedback
                            </div>
                            <div
                                className="modal fade"
                                id="DetailedFeedback"
                                role="dialog"
                                aria-labelledby="DetailedFeedbackLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content  lt-modal-content">
                                        <div className="modal-header lt-modal-header">
                                            <h5 className="modal-title w-100 fs-18 fw-700" id="InviteFriendLabel">
                                                {dropdownYear} Detailed feedback
                                            </h5>
                                            <button
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                title="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body p-0">
                                            <div className="table-responsive">
                                                <table className="table table-border mb-0 text-nowrap feedback-popup text-start">
                                                    <thead>
                                                        <tr>
                                                            <th>Feedback</th>
                                                            <th>Employers</th>
                                                            <th>Year of {dropdownYear}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Excellent</td>
                                                            <td>{data?.excellent.reduce(reducer)}</td>
                                                            <td>
                                                                {data?.excellent.reduce(reducer)}{' '}
                                                                <span className="lt-text-primary fs-12">
                                                                    (
                                                                    {percentage(
                                                                        data?.excellent.reduce(reducer),
                                                                        feedbackTotal
                                                                    )}
                                                                    %)
                                                                </span>{' '}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Good</td>
                                                            <td>{data?.good.reduce(reducer)}</td>
                                                            <td>
                                                                {data?.good.reduce(reducer)}{' '}
                                                                <span className="lt-text-primary fs-12">
                                                                    (
                                                                    {percentage(
                                                                        data?.good.reduce(reducer),
                                                                        feedbackTotal
                                                                    )}
                                                                    %)
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Average</td>
                                                            <td>{data?.neutral.reduce(reducer)}</td>
                                                            <td>
                                                                {data?.neutral.reduce(reducer)}{' '}
                                                                <span className="lt-text-primary fs-12">
                                                                    (
                                                                    {percentage(
                                                                        data?.neutral.reduce(reducer),
                                                                        feedbackTotal
                                                                    )}
                                                                    %)
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bad</td>
                                                            <td>{data?.sad.reduce(reducer)}</td>
                                                            <td>
                                                                {data?.sad.reduce(reducer)}{' '}
                                                                <span className="lt-text-primary fs-12">
                                                                    (
                                                                    {percentage(
                                                                        data?.sad.reduce(reducer),
                                                                        feedbackTotal
                                                                    )}
                                                                    %)
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Awful</td>
                                                            <td>{data?.angry.reduce(reducer)}</td>
                                                            <td>
                                                                {data?.angry.reduce(reducer)}{' '}
                                                                <span className="lt-text-primary fs-12">
                                                                    (
                                                                    {percentage(
                                                                        data?.angry.reduce(reducer),
                                                                        feedbackTotal
                                                                    )}
                                                                    %)
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default PlatformFeedback;

const reducer = (previousValue: number, currentValue: number) => {
    return previousValue + currentValue;
};
