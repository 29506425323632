import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

import Error404 from '../../components/errors/404';
import PageLoader from '../../components/loader';
import Profile from '../../components/profile';
import Suspend from '../../components/suspend-modal/suspend';
import { candidateService } from '../../lib/api/candidate';
import { suspensionService } from '../../lib/api/suspension';
import { getHighestEducationText } from '../../lib/utils/dictionary';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import { CandidateProfileType } from '../../types/candidate';

type Props = RouteComponentProps<{ id: string }>;

const CandidateView: React.FC<Props> = ({ match }) => {
    const { id } = match.params;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CandidateProfileType>();
    const [currentId, setCurrentId] = useState('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);

    const unsuspend = useCallback(async () => {
        try {
            setLoading(true);
            await suspensionService.unSuspendByCandidate(id);
            setRerenderKey(Date.now());
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [setRerenderKey, rerenderKey, setCurrentId]);

    const suspendProfile = useCallback(async () => {
        Swal.fire({
            title: Message.unsuspendCandidate,
            icon: WarningIcon,
            showCancelButton: true,
            confirmButtonColor: '#0096ff',
            cancelButtonColor: '#d83b01',
            confirmButtonText: 'Unsuspend',
            showConfirmButton: true,
            showCloseButton: false,
        }).then((result: any) => {
            if (result.isConfirmed) {
                unsuspend();
                Swal.fire({
                    icon: SuccessIcon,
                    title: SweetAlertTitle.Success,
                    text: Message.unsuspendCandSuccess,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
            }
        });
    }, []);

    const getCompanyProfile = useCallback(async () => {
        if (!id) return;

        try {
            setLoading(true);
            const { resultObject } = await candidateService.getId(id);
            setData(resultObject);
        } catch (error: any) {
            console.log('candidate services getId error', error.response);
        } finally {
            setLoading(false);
        }
    }, [rerenderKey]);

    useEffect(() => {
        getCompanyProfile();
    }, [rerenderKey, getCompanyProfile]);

    const suspendCandidate = (item: any) => {
        setShowAdminModal(true);
        setCurrentId(item);
    };

    return loading ? (
        <PageLoader />
    ) : data ? (
        <>
            <Profile
                id={data.id}
                avatar={data.avatarUrl}
                banner={data.bannerUrl}
                name={`${data.firstName} ${data.lastName}`}
                activity={data.country}
                location={`${data.cityDistrict}, ${data.state}`}
                address={data.state}
                aboutMe={data.aboutMe}
                reviewsCount={data.reviewsCount}
                rating={data.rating.value}
                contractCompliance={data.contractComplianceQuantity}
                contractViolation={data.contractViolationQuantity}
                phone={data.phone}
                email={data.email}
                reviewList={data.candidateReviews.map((item) => ({
                    id: item.id,
                    avatar: item.companyAvatarUrl,
                    name: `${item.companyName}`,
                    text: item.reviewText,
                    date: item.date,
                    contractComplianceState: item.contractComplianceState,
                    rating: item.stars.value,
                    creatorId: item.companyId,
                    offerId: item.offerId,
                }))}
                education={{
                    highestEducation:
                        typeof data.highestEducation === 'number' ? getHighestEducationText(data.highestEducation) : '',
                    institutionName: data.institutionName || '',
                    graduationYear: data.graduationYear,
                }}
                resume={data.resumeFileUrl ? data.resumeFileUrl : 'no Resume'}
                candidate={id}
                website={data.website}
                suspendStatus={data.suspendStatus}
                suspendProfile={suspendProfile}
                suspendCandidate={suspendCandidate}
                jobTitle={data.jobTitle}
                suspendReasonOtherText={data.suspendReasonOtherText}
                suspendReasons={data.suspendReasons}
                userCountryCode={data.userCountryCode}
                aadhar={data.aadhar}
            />

            <Suspend
                candidate={true}
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={id}
                setRerenderKey={setRerenderKey}
            />
        </>
    ) : (
        <Error404 />
    );
};

export default CandidateView;
