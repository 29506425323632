import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import PageLoader from '../loader';
import { CategoryType, mapSuspension } from '../../containers/company-list-old/utils/suspension';
import { suspensionService } from '../../lib/api/suspension';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import { spaceRegexp } from '../../lib/utils/validation';
import { SuspendStatusEnum } from '../../types/suspension';

type Props = {
    setShowAdminModal: (value: boolean) => void;
    showAdminModal: boolean;
    id: string;
    setRerenderKey: (key: number) => void;
    candidate?: boolean;
    reportByuser?: boolean;
    reportedId?: string;
    reportBycandidate?: boolean;
};

type SelectedReasonsType = {
    category: string;
    reason: string;
};

const Suspend: React.FC<Props> = ({
    setShowAdminModal,
    showAdminModal,
    reportedId,
    id,
    setRerenderKey,
    candidate,
    reportByuser,
    reportBycandidate,
}) => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [selectedReasons, setSelectedReasons] = useState<SelectedReasonsType[]>([]);
    const [textReason, setTextReason] = useState('');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

    const submitForm = useCallback(async () => {
        const reasons = selectedReasons.reduce(
            (reasons: string[], item) => (item.reason ? [...reasons, item.reason] : reasons),
            []
        );
        try {
            setLoadingSubmit(true);
            if (reportByuser) {
                await suspensionService.suspendByCandidateReport({
                    candidateReportFromCompanyId: reportedId !== undefined ? reportedId : '',
                    suspensionReasonList: reasons,
                    otherText: textReason,
                });
                setRerenderKey(Date.now());
            }
            if (reportBycandidate) {
                await suspensionService.suspendByCompanyReport({
                    companyReportFromCandidateId: reportedId !== undefined ? reportedId : '',
                    suspensionReasonList: reasons,
                    otherText: textReason,
                });
                setRerenderKey(Date.now());
            }
            if (candidate) {
                await suspensionService.suspendByCandidate({
                    candidateId: id,
                    suspensionReasonList: reasons,
                    otherText: textReason,
                });
            } else {
                await suspensionService.suspendByAdmin({
                    companyId: id,
                    suspendStatus: SuspendStatusEnum.suspended,
                    suspensionReasonList: reasons,
                    otherText: textReason,
                });
            }
            setRerenderKey(Date.now());
            setNextButtonDisabled(true);
        } catch (err: any) {
            console.log('suspensionService suspend error', err.response);
        } finally {
            setLoadingSubmit(false);
            setSelectedReasons([]);
            setTextReason('');
        }
    }, [textReason, selectedReasons, id, setRerenderKey]);

    const selectedReasonsswal = () => {
        setShowAdminModal(false);
        Swal.fire({
            title: candidate ? Message.suspendCandidate : Message.suspendCompany,
            text: candidate ? Message.suspendCandidateText : Message.suspendCompText,
            icon: WarningIcon,
            showCancelButton: true,
            confirmButtonColor: '#0096ff',
            cancelButtonColor: '#d83b01',
            confirmButtonText: 'Suspend',
            showConfirmButton: true,
            showCloseButton: false,
        }).then((result: any) => {
            if (result.isConfirmed) {
                submitForm();
                Swal.fire({
                    icon: SuccessIcon,
                    title: SweetAlertTitle.Success,
                    text: candidate ? Message.suspendCandSussess : Message.suspendCompSussess,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                setShowAdminModal(true);
                setNextButtonDisabled(true);
            }
        });
    };

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                const {
                    resultObject: { items },
                } = await suspensionService.getReasonList();
                const categories = mapSuspension(items);
                setCategories(categories);
                setSelectedReasons(initialSelectedReasons(categories));
            } catch (err: any) {
                console.log('suspensionService getList error', err.response);
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    return (
        <>
            <Modal
                show={showAdminModal}
                onHide={() => {
                    setShowAdminModal(false);
                    setSelectedReasons([]);
                    setTextReason('');
                }}
                centered
            >
                <div className="lt-modal-content">
                    <Modal.Header className="lt-modal-header">
                        <Modal.Title className="text-center fw-700 w-100 fs-20">
                            Suspend This {candidate ? 'Candidate' : 'Company'}{' '}
                        </Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            title="Close"
                            onClick={() => {
                                setShowAdminModal(false);
                                setSelectedReasons([]);
                                setTextReason('');
                                setNextButtonDisabled(true);
                            }}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="lt-modal-body">
                        {loading ? (
                            <PageLoader />
                        ) : (
                            <>
                                <p className="fs-14 fw-400 my-4">
                                    Choose a reason for suspending this {candidate ? 'Candidate' : 'Company'}:
                                </p>
                                <div className="accordion accordion-flush" id="ReportReasons">
                                    {categories.map((category, i) => (
                                        <div key={category.id} className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button
                                                    className="accordion-button collapsed fw-600 px-0"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#Reason${i}`}
                                                    aria-expanded="false"
                                                    aria-controls={`Reason${i}`}
                                                >
                                                    {category.label}
                                                </button>
                                            </h2>
                                            <div
                                                id={`Reason${i}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby="flush-headingOne"
                                                data-bs-parent="#ReportReasons"
                                            >
                                                <div className="accordion-body">
                                                    {category.reasons.map((reason: any) => (
                                                        <div key={reason.id} className="form-check mb-3">
                                                            <input
                                                                className="form-check-input align-self-center"
                                                                type="radio"
                                                                name={category.id}
                                                                id={reason.id}
                                                                // checked={
                                                                //     selectedReasons.find(
                                                                //         (item) => item.category === category.id
                                                                //     )?.reason === reason.id
                                                                // }
                                                                onChange={() => {}}
                                                                onClick={() => {
                                                                    setNextButtonDisabled(false);
                                                                    const filteredSelectedReasons =
                                                                        selectedReasons.filter(
                                                                            (item) => item.category !== category.id
                                                                        );
                                                                    const selectedCategory = selectedReasons.find(
                                                                        (item) => item.category === category.id
                                                                    );
                                                                    setSelectedReasons([
                                                                        ...filteredSelectedReasons,
                                                                        {
                                                                            category: category.id,
                                                                            reason:
                                                                                selectedCategory?.reason === reason.id
                                                                                    ? ''
                                                                                    : reason.id,
                                                                        },
                                                                    ]);
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label fw-400 fs-14"
                                                                htmlFor={reason.id}
                                                            >
                                                                {reason.label}
                                                            </label>
                                                            <p className="fs-12">{reason.text}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingSix">
                                            <button
                                                className="accordion-button collapsed fw-600 px-0"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#Reason6"
                                                aria-expanded="false"
                                                aria-controls="Reason6"
                                            >
                                                Other
                                            </button>
                                        </h2>
                                        <div
                                            id="Reason6"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingSix"
                                            data-bs-parent="#ReportReasons"
                                        >
                                            <div className="accordion-body px-0 pt-0">
                                                <textarea
                                                    value={textReason}
                                                    onChange={({ target }) =>
                                                        setTextReason(target.value.replace(spaceRegexp, ''))
                                                    }
                                                    maxLength={250}
                                                    className="form-control fs-14 other-reason mb-2"
                                                />
                                                {textReason ? (
                                                    <div className="text-end">{textReason.length}/250</div>
                                                ) : (
                                                    <div className="text-end">0/250</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal.Body>

                    <Modal.Footer className="lt-modal-footer">
                        <div className="d-flex w-100 m-0 pt-3">
                            <div className="col pe-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary w-100"
                                    onClick={() => {
                                        setShowAdminModal(false);
                                        setSelectedReasons([]);
                                        setTextReason('');
                                        setNextButtonDisabled(true);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    onClick={selectedReasonsswal}
                                    // disabled={!selectedReasons.find((item) => item.reason.length) && !textReason}
                                    disabled={nextButtonDisabled && !textReason}
                                    type="button"
                                    className="btn btn-primary w-100"
                                >
                                    {loadingSubmit && <span className="spinner-border spinner-border-sm" />}
                                    Next
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};

export default Suspend;

const initialSelectedReasons = (categories: CategoryType[]) =>
    categories.map((item) => ({ category: item.id, reason: '' }));
