import { SweetAlertIcon } from "sweetalert2";

export const Message = {
    // Candidate
    unsuspendCandidate: 'Are you sure to restore this candidate?',
    unsuspendCandSuccess: 'Candidate restored successfully.',
    unsuspendCandText: 'You can suspend them again in candidate lists',

    suspendCandidate: 'Are you sure to suspend this candidate?',
    suspendCandSussess: 'Candidate suspended successfully',
    suspendCandidateText:'You can unsuspend them again in candidate lists',

    discardCandidate:'Are you sure to discard this report?',
    discardSuccessfull:'Discard successfull!',
    discardCandidateRevert:"You won't be able to revert this!",

    ContactValidation : 'Contact number should be of 10 digits',
    
    // Company
    unsuspendCompany : 'Are you sure to restore this company?',
    unsuspendSuccess: 'Company restored successfully.',
    unsuspendText: 'You can suspend them again in Company Lists, All Company',

    suspendCompany: 'Are you sure to suspend this company?',
    suspendCompSussess: 'Company suspended successfully',
    suspendCompText: 'You can unsuspend them again in Company Lists, All Company',

    deleteEmployee: 'Delete Employee?',
    deleteEmployeeText: "Are you sure? You won't be able to undo this.",
    deleteEmployeeSuccess: 'Employee has been deleted.',

    fileDelete: 'Your file has been deleted.',

    CompanyContactNumber : 'Company Contact number should be of 10 digits',

    // Employee
    employeeUpdate:'Employee details have been updated',
    addEmployee: 'Employee has been added',
    invitationSuccess: 'Invitation resend successfully',
    invitationError:'Invitation resend error',
    InvitationSent : 'Invitation sent successfully.',
    InvitationsSent : 'Invitations sent successfully.',


    // Bulk Invites
    HeadersMismatch : 'Excel headers do not match with the provided template.',
    
    PostalCodeValidation : 'Postal code should not exceed 10 digits',
    TooManyExcelRows : 'Too many rows in the Excel file. Please upload a file with up to 50 rows.',
    HeadersWithNoData : 'Headers are present, but there is no data below them. Please upload a file with data.',
    EmptyExcel : 'Empty Excel file uploaded. Please upload a file with data.',
    UnableToReadExcel : 'Unable to read the uploaded file. Please make sure it is a valid Excel file.',
    NoFileSelected : 'No file selected. Please upload a valid Excel file.',
    SomethingWentWrong : 'Something went wrong.',
    SomethingWentWrongTryAgain : 'Something went wrong. Please try again',
    UpdatedStatus : 'Updated status successfully',
    UpdatedSalesOwner : 'Updated sales owner successfully',
    DocumentUploaded :'Document uploaded succesfully.',
    AttachmentSize5mb : 'Attachment size must be smaller than 5mb',
}

export const SuccessIcon: SweetAlertIcon = 'success';
export const ErrorIcon: SweetAlertIcon = 'error';
export const InfoIcon: SweetAlertIcon = 'info';
export const WarningIcon: SweetAlertIcon = 'warning';
export const QuestionIcon: SweetAlertIcon = 'question';

export const SweetAlertTitle = {
    Profile : 'Profile',
    Empty : '',
    CompanyProfile : 'Company Profile',
    Warning : 'Warning',
    Sorry : 'Sorry',
    Status : 'Status',
    Error : 'Error',
    Success : 'success',
    Alert : 'Alert',
    Oops : 'Oops...',
}
