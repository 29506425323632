import React, { useState, useCallback, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Files from 'react-files';
import { object as objectYup, mixed as mixedYup } from 'yup';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { ReactComponent as IconTrash } from '../../../assets/icons/trash.svg';
import Swal from 'sweetalert2';
import excelIcon from '../../../assets/img/excel-icon.svg';
import { SortTypeEnum } from '../../../types';
import { ExcelApiFormDataType, ExcelResponseType } from '../../../types/excelUpload';
import { ReactFilesError, ReactFilesFile } from '../../../types/files';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { excelUploadDataService } from '../../../lib/api/excelUpload';
import { CompanyProfileType } from '../../../types/company';
import DataAsExcel from '../../../components/download/dataAsExcel';
import DownloadExcelResponse from '../../../components/excel-invites/modal/downloadExcelResponse';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserType } from '../../../types/auth';
import imgNoResult from '../../../assets/icons/offer-no-result.svg';
import RoundTimer from '../../../components/round-timer';
import { OpenAPIService } from '../../../lib/api/openApi';
import PageLoader from '../../../components/loader';
import { ErrorIcon, Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../../lib/utils/messages';

type Props = {
    setShowCandidateUploadContent: (key: boolean) => void;
};

const initialValues = {
    excelDataFileUrl: null,
    companyId: '',
};

interface SearchQueries {
    [key: string]: string;
}

const CandidateExcelBulkUpload: React.FC<Props> = ({ setShowCandidateUploadContent }) => {
    const [loading, setLoading] = useState(false);
    const [dummyData, setDummyData] = useState([
        {
            FirstName: '',
            MiddleName: '',
            LastName: '',
            UserEmail: '',
            ContactNumber: '',
            CompanyName: '',
            CompanyWebsite: '',
            CompanyEmail: '',
            CompanyContact: '',
            Country: '',
            PostalCode: '',
        },
    ]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [headerContent, setHeaderContent] = useState<any[][]>([]);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(['', '', '']);
    const [selectedContent, setSelectedContent] = useState<any[][]>([]);
    const [disableSelectOption, setDisableSelectOption] = useState(true);
    const [showDataPreview, setShowDataPreview] = useState(false);
    const [resetInputField, setResetInputField] = useState(0);
    const [excelDataAsBase64String, setExcelDataAsBase64String] = useState<string>('');
    const [excelData, setExcelData] = useState<{ file?: File; error?: string } | null>(null);
    const [openProcessedDataModal, setOpenProcessedDataModal] = useState(false);
    const [isLead, setIsLead] = useState(false);
    const [allCompanyList, setAllCompanyList] = useState<CompanyProfileType[]>([]);
    const [disableBtn, setDisableBtn] = useState(false);
    const [passRecords, setPassRecords] = useState(0);
    const [failedRecords, setFailedRecords] = useState(0);
    const [duplicateRecords, setDuplicateRecords] = useState(0);
    const [totalOutPutData, setTotalOutPutData] = useState<ExcelResponseType[]>([]);
    const [selectedOptionLabels, setSelectedOptionLabels] = useState<string[]>(['']);
    const [excelArrayData, setExcelArrayData] = useState<ExcelApiFormDataType[] | []>([]);
    const [excelFileData, setExcelFileData] = useState<ReactFilesFile[]>();
    const [offerXSelected, setOfferXSelected] = useState(false);
    const [screenXSelected, setScreenXSelected] = useState(false);
    const [appError, setAppError] = useState('');
    // const [rowNumber, setRowNumber] = useState(1);
    const ExcelDataHeadersLabel = [
        'First Name',
        'Middle Name',
        'Last Name',
        'User Email',
        'Contact Number',
        'Company Name',
        'Company Website',
        'Company Email',
        'Company Contact',
        'Country',
        'Postal Code',
    ];
    const [sortingOrder, setSortingOrder] = useState<{ [key: string]: string }>({});

    const { user } = useSelector((state: RootState) => state.user);

    const hasUser = user as UserType;

    const [searchQueries, setSearchQueries] = useState<SearchQueries>({});
    const [filteredData, setFilteredData] = useState<any[][]>([]);

    const updateSearchQuery = (columnName: string, query: string) => {
        const updatedQueries = { ...searchQueries, [columnName]: query };
        setSearchQueries(updatedQueries);

        let newFilteredData = [...selectedContent];

        ExcelDataHeadersLabel.forEach((header, index) => {
            const searchQuery = updatedQueries[header]?.toLowerCase();

            if (searchQuery) {
                newFilteredData = newFilteredData.filter((row) => {
                    const cellValue = (row[index] || '').toString().toLowerCase();
                    return cellValue.includes(searchQuery);
                });
            }
        });

        if (Object.values(updatedQueries).every((query) => query === '')) {
            setFilteredData([...selectedContent]);
        } else {
            setFilteredData(newFilteredData);
        }
    };

    const getCountriesCodeAndPhoneCode = async (name: string) => {
        const data = await OpenAPIService.fetchCountriesCodeAndPhoneCode(name);

        return data;
    };

    const filterData = () => {
        if (Object.values(searchQueries).every((query) => query === '')) {
            setFilteredData([...selectedContent]);
        } else {
            const newFilteredData = selectedContent.filter((row) => {
                return ExcelDataHeadersLabel.every((header, index) => {
                    const query = searchQueries[header] || '';
                    const cellValue = row[index];
                    if (cellValue !== null && cellValue !== undefined) {
                        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
                    }
                    return false; // Handle undefined or null values
                });
            });

            setFilteredData(newFilteredData);
        }
    };

    const handleSorting = (columnName: string) => {
        const order = sortingOrder[columnName] === 'asc' ? 'desc' : 'asc';
        setSortingOrder({ [columnName]: order });

        const sortedData = [...filteredData].sort((a, b) => {
            const index = ExcelDataHeadersLabel.indexOf(columnName);
            const valueA = (a[index] || '').toString().toLowerCase();
            const valueB = (b[index] || '').toString().toLowerCase();

            if (order === 'asc') {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        });

        setFilteredData(sortedData);
    };

    const areAllHeadersPresent = (cell: string): boolean => {
        const sanitizedRow = typeof cell === 'string' ? cell.replace(/\s/g, '').toLowerCase() : cell;
        const sanitizedHeaders = ExcelDataHeadersLabel.map(header => header.replace(/\s/g, '').toLowerCase());
        return sanitizedHeaders.includes(sanitizedRow);
    }

    const handleFileInputChange = (files: ReactFilesFile[]) => {
        if (files[0]) {
            const file = files[0];
            setDisableSelectOption(true);

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target?.result;
                let headersPresent = false;
                if (data) {
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    const nonEmptyRows = sheetData.filter((row) =>
                    row.some((cell) => cell !== null && cell !== '')
                    );
                    if (nonEmptyRows.length !== 0) {
                        for (const row of nonEmptyRows[0]) {
                            headersPresent = areAllHeadersPresent(row);
                            if(!headersPresent){
                                setExcelData(null);
                                resetState();
                                setResetInputField(resetInputField + 1);
                                setShowDataPreview(false);
                                setLoading(false);
                                Swal.fire({
                                    title: SweetAlertTitle.Sorry,
                                    icon: WarningIcon,
                                    text: Message.HeadersMismatch,
                                });
                                return;
                            }
                        }
                    }
                    if (nonEmptyRows.length !== 0) {
                        
                        if (nonEmptyRows.length > 1 && nonEmptyRows.length <= 50) {
                            const extractedHeaders = nonEmptyRows[0].map((header: any) => String(header));
                            const headerContent = nonEmptyRows.slice(1);

                            const objectKeys = Object.keys(dummyData[0]).map((key) => key.toLowerCase());

                            const allNamesPresent = extractedHeaders.every((name) =>
                                objectKeys.includes(name.toLowerCase().trim())
                            );

                            if (allNamesPresent && extractedHeaders.length === objectKeys.length) {
                                setHeaders(extractedHeaders);
                                setHeaderContent(headerContent);
                                // setFilteredData(headerContent);
                                setDisableSelectOption(false);

                                const initialSelectedHeaders = ExcelDataHeadersLabel.map(
                                    (label) =>
                                        extractedHeaders.find(
                                            (header) =>
                                                header.replaceAll(' ', '').toLowerCase() ===
                                                label.replaceAll(' ', '').toLowerCase()
                                        ) || ''
                                );
                                setSelectedHeaders(initialSelectedHeaders);
                                setSelectedOptionLabels(initialSelectedHeaders);

                                const columnIndexMap: { [key: string]: number } = {};
                                extractedHeaders.forEach((header, columnIndex) => {
                                    columnIndexMap[header] = columnIndex;
                                });
                                const selectedContent = headerContent.map((row) =>
                                    initialSelectedHeaders.map((header) => row[columnIndexMap[header]])
                                );

                                const dataArray = headerContent.map(async (row) => {
                                    const rowData: any = {};

                                    try {
                                        for (const [columnIndex, header] of extractedHeaders.entries()) {
                                            if (columnIndex !== -1) {
                                                const content = row[columnIndex];
                                                if (header.toLowerCase() !== 'middlename' && !content) {
                                                    Swal.fire({
                                                        title: SweetAlertTitle.Sorry,
                                                        icon: WarningIcon,
                                                        text: `${header} should have content`,
                                                    });
                                                    setExcelData(null);
                                                    resetState();
                                                    setResetInputField(resetInputField + 1);
                                                    setShowDataPreview(false);
                                                    throw new Error(`${header} should have content`);
                                                } else {
                                                    if (header.toLowerCase() === 'country') {
                                                        const countryDetails = await getCountriesCodeAndPhoneCode(
                                                            row[columnIndex]
                                                        );

                                                        if (countryDetails) {
                                                            rowData.CompanyCountryCode = String(countryDetails.id);
                                                            rowData.CompanySTDCode = countryDetails.phonecode;
                                                            rowData.companyCountryName = countryDetails.name;
                                                            rowData.ownerSTDCode = countryDetails.phonecode;
                                                        } else {
                                                            setExcelData(null);
                                                            resetState();
                                                            setResetInputField(resetInputField + 1);
                                                            setShowDataPreview(false);
                                                            setLoading(false);

                                                            throw new Error('Not a valid country/STD code');
                                                        }
                                                    } else if (header.toLowerCase() === 'middlename') {
                                                        rowData[header] = row[columnIndex] || '';
                                                    } else if (header.toLowerCase() === 'companycontact') {
                                                        if (
                                                            String(row[columnIndex]).length > 10 ||
                                                            String(row[columnIndex]).length < 10
                                                        ) {
                                                            Swal.fire({
                                                                title: SweetAlertTitle.Sorry,
                                                                icon: WarningIcon,
                                                                text: Message.CompanyContactNumber,
                                                            });
                                                            setExcelData(null);
                                                            resetState();
                                                            setResetInputField(resetInputField + 1);
                                                            setShowDataPreview(false);
                                                            setLoading(false);
                                                            throw new Error(
                                                                'Company Contact number should be of 10 digits'
                                                            );
                                                        } else {
                                                            rowData[header] = String(row[columnIndex]) || '';
                                                            // setLoading(false);
                                                        }
                                                    } else if (header.toLowerCase() === 'contactnumber') {
                                                        if (
                                                            String(row[columnIndex]).length > 10 ||
                                                            String(row[columnIndex]).length < 10
                                                        ) {
                                                            Swal.fire({
                                                                title: SweetAlertTitle.Sorry,
                                                                icon: WarningIcon,
                                                                text: Message.ContactValidation,
                                                            });
                                                            setExcelData(null);
                                                            resetState();
                                                            setResetInputField(resetInputField + 1);
                                                            setShowDataPreview(false);
                                                            setLoading(false);
                                                            throw new Error(Message.ContactValidation);
                                                        } else {
                                                            rowData[header] = String(row[columnIndex]) || '';
                                                            // setLoading(false);
                                                        }
                                                    } else if (
                                                        header === 'companystatecode' ||
                                                        header === 'companycitycode'
                                                    ) {
                                                        rowData[header] = null;
                                                        // setLoading(false);
                                                    } else if (header.toLowerCase() === 'postalcode') {
                                                        if (String(row[columnIndex]).length > 10) {
                                                            Swal.fire({
                                                                title: SweetAlertTitle.Sorry,
                                                                icon: WarningIcon,
                                                                text: Message.PostalCodeValidation,
                                                            });
                                                            setExcelData(null);
                                                            resetState();
                                                            setResetInputField(resetInputField + 1);
                                                            setShowDataPreview(false);
                                                            setLoading(false);
                                                            throw new Error(Message.PostalCodeValidation);
                                                        } else {
                                                            rowData[header] = String(row[columnIndex]) || '';
                                                            // setLoading(false);
                                                        }
                                                    } else {
                                                        rowData[header] = row[columnIndex];
                                                        // setLoading(false);
                                                    }
                                                }
                                            } else {
                                                Swal.fire({
                                                    title: SweetAlertTitle.Sorry,
                                                    icon: WarningIcon,
                                                    text: `${header} is missing in the provided data`,
                                                });
                                                setExcelData(null);
                                                resetState();
                                                setResetInputField(resetInputField + 1);
                                                setShowDataPreview(false);
                                                setLoading(false);
                                                throw new Error(`${header} is missing in the provided data`);
                                            }
                                        }
                                        // setLoading(false);
                                        return rowData;
                                    } catch (error) {
                                        setLoading(false);
                                        throw error;
                                    }
                                });

                                Promise.all(dataArray)
                                    .then((resolvedDataArray) => {
                                        if (resolvedDataArray) {
                                            setExcelArrayData(resolvedDataArray || []);
                                            setSelectedContent(selectedContent);
                                            setExcelData({
                                                file,
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        console.error('Error resolving promises:', error);
                                    });
                            } else {
                                setExcelData(null);
                                resetState();
                                setResetInputField(resetInputField + 1);
                                setShowDataPreview(false);
                                setLoading(false);
                                Swal.fire({
                                    title: SweetAlertTitle.Sorry,
                                    icon: WarningIcon,
                                    text: Message.HeadersMismatch,
                                });
                            }
                        } else if (nonEmptyRows.length > 50) {
                            setExcelData(null);
                            resetState();
                            setResetInputField(resetInputField + 1);
                            setShowDataPreview(false);
                            setLoading(false);
                            Swal.fire({
                                title: SweetAlertTitle.Sorry,
                                icon: WarningIcon,
                                text: Message.TooManyExcelRows,
                            });
                        } else {
                            setExcelData(null);
                            resetState();
                            setResetInputField(resetInputField + 1);
                            setShowDataPreview(false);
                            setLoading(false);
                            Swal.fire({
                                title: SweetAlertTitle.Sorry,
                                icon: WarningIcon,
                                text: Message.HeadersWithNoData,
                            });
                        }
                    } else {
                        setExcelData(null);
                        resetState();
                        setResetInputField(resetInputField + 1);
                        setLoading(false);
                        Swal.fire({
                            title: SweetAlertTitle.Sorry,
                            icon: WarningIcon,
                            text: Message.EmptyExcel,
                        });
                    }
                } else {
                    setExcelData(null);
                    resetState();
                    setResetInputField(resetInputField + 1);
                    setLoading(false);
                    Swal.fire({
                        title: SweetAlertTitle.Sorry,
                        icon: WarningIcon,
                        text: Message.UnableToReadExcel,
                    });
                }
            };

            reader.readAsBinaryString(file);
            // setLoading(false);
            setShowDataPreview(true);
        } else {
            setExcelData(null);
            resetState();
            setResetInputField(resetInputField + 1);
            setLoading(false);
            Swal.fire({
                title: SweetAlertTitle.Sorry,
                icon: WarningIcon,
                text: Message.NoFileSelected,
            });
        }
    };

    const exportTableToExcel = async () => {
        if (selectedContent) {
            const data = [ExcelDataHeadersLabel, ...selectedContent];

            const ws = XLSX.utils.aoa_to_sheet(data);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            const excelBase64Data = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });

            setExcelDataAsBase64String(excelBase64Data);
        }
    };

    const initialErrors = useInitialErrors(initialValues, getValidationSchema());

    const resetState = () => {
        setHeaders([]);
        setHeaderContent([]);
        setSelectedHeaders(['', '', '']);
        setSelectedContent([]);
        setDisableSelectOption(true);
        setExcelData(null);
        setShowDataPreview(false);
        setSelectedOptionLabels(['']);
    };

    const submitForm = useCallback(
        async (values: any) => {
            if (offerXSelected === true) {
                if (Array.isArray(excelArrayData) && excelArrayData.length !== 0) {
                    const requestData: ExcelApiFormDataType[] = excelArrayData;

                    try {
                        setDisableBtn(true);

                        const {
                            isSuccessful,
                            resultObject: { duplicateRecords, companyLeadsExcelTotalOutputModel, failRecords, passRecords },
                        } = await excelUploadDataService.setCompanyExcelUploadData({ companyRequestModel: requestData });

                        setTotalOutPutData(companyLeadsExcelTotalOutputModel);
                        setDuplicateRecords(duplicateRecords);
                        setFailedRecords(failRecords);
                        setPassRecords(passRecords);

                        if (isSuccessful) {
                            Swal.fire({
                                icon: SuccessIcon,
                                title: SweetAlertTitle.Status,
                                text: Message.InvitationsSent,
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setOpenProcessedDataModal(true);
                                    setDisableBtn(false);
                                }
                            });
                        } else {
                            setDisableBtn(false);
                        }
                        setDisableBtn(false);
                    } catch (err) {
                        setDisableBtn(false);
                        Swal.fire({
                            icon: ErrorIcon,
                            title: SweetAlertTitle.Error,
                            text: Message.SomethingWentWrong,
                            allowOutsideClick: false,
                        });
                    }
                }
            }
            else if (screenXSelected === true) {
                if (Array.isArray(excelArrayData) && excelArrayData.length !== 0) {
                    const requestData: ExcelApiFormDataType[] = excelArrayData;
                    try {
                        setDisableBtn(true);

                        const {
                            statusCode,
                            result: { duplicateRecords, companyLeadsExcelTotalOutputModel, failRecords, passRecords },
                        } = await excelUploadDataService.setScreenXCompanyExcelUploadData({ companyRequestModel: requestData });

                        setTotalOutPutData(companyLeadsExcelTotalOutputModel);
                        setDuplicateRecords(duplicateRecords);
                        setFailedRecords(failRecords);
                        setPassRecords(passRecords);

                        if (statusCode === 0) {
                            Swal.fire({
                                icon: SuccessIcon,
                                title: SweetAlertTitle.Status,
                                text: Message.InvitationsSent,
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setOpenProcessedDataModal(true);
                                    setDisableBtn(false);
                                }
                            });
                        } else {
                            setDisableBtn(false);
                        }
                        setDisableBtn(false);
                    } catch (err) {
                        setDisableBtn(false);
                        Swal.fire({
                            icon: ErrorIcon,
                            title: SweetAlertTitle.Error,
                            text: Message.SomethingWentWrong,
                            allowOutsideClick: false,
                        });
                    }
                }
            }
            else {
                setAppError('Please select the application.');
            }
        },
        [excelArrayData, offerXSelected, screenXSelected]
    );

    useEffect(() => {
        if (selectedContent) {
            exportTableToExcel();
            setFilteredData([...selectedContent]);
        }
    }, [selectedContent]);

    useEffect(() => {
        if (excelFileData) {
            handleFileInputChange(excelFileData);
        }
    }, [excelFileData]);

    return (
        <div className="">
            {/* <div className="row my-4">
                <span className="mt-2 ps-4 pe-2 text-secondary">
                    <span
                        onClick={() => {
                            setShowCandidateUploadContent(false);
                        }}
                        className="cursor-pointer"
                    >
                        <i className="bi bi-chevron-left lt-text-secondary-alt me-0"></i>
                    </span>
                    <span className="ms-2 fs-20 fw-600">Candidate data upload</span>
                </span>
            </div> */}
            {/* <div className="d-flex align-items-center justify-content-center">
                <div className="mb-3">
                    <div className="alert lt-bg-faq-light-alt alert-dismissible fade show mb-0" role="alert">
                        <span className="lt-text-dark-blue">
                            <i className="bi bi-exclamation-triangle"></i>{' '}
                            <span className="fs-12 fw-600">Attention:</span>{' '}
                            <span className="fs-12 ">
                                Kindly use the Bulk Invitations feature to extend invitations to your current employees
                                for enrollment in the OfferX system. Upon their registration, you can access their
                                records in the Onboarded tab to write Offboarding reviews. Additionally, make sure to
                                invite your existing employees using their personal email IDs.
                            </span>
                        </span>
                        <button
                            type="button"
                            className="btn-close fs-14 lt-text-dark-blue"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => sessionStorage.setItem('nameCheckAlert', '1')}
                        ></button>
                    </div>
                </div>
            </div> */}
            <div className="container-fluid">
                <Formik
                    onSubmit={submitForm}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={getValidationSchema()}
                    initialErrors={initialErrors}
                >
                    {(formikProps: FormikProps<any>) => {
                        const { handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm, values } =
                            formikProps;

                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="row d-flex mt-4">
                                    <div className="col-md-12">
                                        <div className="d-flex">
                                            <div className="form-check me-3">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    onChange={() => {
                                                        setOfferXSelected(true);
                                                        setScreenXSelected(false);
                                                        setAppError('');
                                                    }
                                                    }
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    OfferX
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    onChange={(ev) => {
                                                        setScreenXSelected(true);
                                                        setOfferXSelected(false);
                                                        setAppError('');
                                                    }
                                                    }
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                    ScreenX
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-danger fs-12 mb-3">
                                            {appError}
                                        </div>
                                    </div>
                                    <div className="col-md-3 lt-bg-gray-10 p-3 border-radius-5">
                                        <div className="d-flex">
                                            <div className="me-2 mt-1">
                                                <img src={excelIcon} alt="Sample excel sheet" />
                                            </div>
                                            <div>
                                                <div>Sample Excel Sheet</div>
                                                <p className="text-secondary fs-10 mb-2">
                                                    Download the attached sample and use it as a starting point for your
                                                    own file.
                                                </p>
                                                <DataAsExcel
                                                    data={dummyData}
                                                    id="sampleData"
                                                    text="Download"
                                                    FileName="CompanyDataUpload"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 bulk-upload-drop">
                                        <Field name="excelDataFileUrl">
                                            {(fieldProps: FieldProps) => {
                                                const { field } = fieldProps;

                                                return (
                                                    <div>
                                                        <Files
                                                            className={
                                                                !showDataPreview
                                                                    ? 'files-dropzone'
                                                                    : 'files-dropzone disable-dropzone'
                                                            }
                                                            key={resetInputField}
                                                            onChange={(files: ReactFilesFile[]) => {
                                                                setLoading(true);
                                                                setExcelFileData(files);
                                                                setResetInputField(resetInputField);
                                                                setSelectedContent([]);
                                                                setHeaderContent([]);
                                                                setSelectedOptionLabels([]);
                                                                if (files[0]) {
                                                                    const file = files[0];
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, file);
                                                                }
                                                            }}
                                                            onError={(error: ReactFilesError) => {
                                                                if (error.code === 1) {
                                                                }
                                                            }}
                                                            accepts={['.xls', '.xlsx']}
                                                            clickable={showDataPreview ? false : true}
                                                        >
                                                            <div className="drag-drop-box">
                                                                <div className="text-center">
                                                                    <i className="bi bi-upload fs-28" />
                                                                    <p>Drop your file to upload or browse</p>
                                                                    <div className="fs-12 lt-text-disabled mt-2">
                                                                        Supports only XLS & XLSX
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Files>
                                                        <small className="text-danger fs-12 mt-1">
                                                            {excelData?.error}
                                                        </small>
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </div>

                                    {excelData?.file && showDataPreview && (
                                        <div className="col-md-3">
                                            <div className="uploaded-div">
                                                <div className="fs-14 mb-2">Uploaded file:</div>
                                                <div className="d-flex align-items-center uploaded-border">
                                                    <img
                                                        src={excelIcon}
                                                        width={20}
                                                        className="me-2"
                                                        alt="Uploaded file"
                                                    />
                                                    <div className="fs-12">{excelData?.file?.name}</div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (excelData?.file) {
                                                                setFieldValue('excelDataFileUrl', '');
                                                                // setExcelData(null);
                                                                resetState();
                                                                setResetInputField(resetInputField + 1);
                                                                setExcelArrayData([]);
                                                                setLoading(false);
                                                            }
                                                        }}
                                                        title="Delete"
                                                        className="ms-2 lh-1"
                                                    >
                                                        <IconTrash />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* {headers.length > 0 && !showDataPreview ? (
                                        <PreviewExcelData
                                            showDataPreview={!showDataPreview}
                                            setShowDataPreview={setShowDataPreview}
                                            excelDataHeadersLabel={ExcelDataHeadersLabel}
                                            disableSelectOption={disableSelectOption}
                                            headerContent={headerContent}
                                            headers={headers}
                                            selectedHeaders={selectedHeaders}
                                            setSelectedHeaders={setSelectedHeaders}
                                            selectedOptionLabels={selectedOptionLabels}
                                            setSelectedOptionLabels={setSelectedOptionLabels}
                                            setSelectedContent={setSelectedContent}
                                        />
                                    ) : ( */}

                                <>
                                    {' '}
                                    {disableBtn && <RoundTimer duration={180} />}
                                    <div className="container-fluid mt-2 ps--0">
                                        <div className="row">
                                            <div className="col-md-12 ps-0">
                                                <div className="table-responsive bulk-companies">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                {ExcelDataHeadersLabel.map((header, index) => (
                                                                    <>
                                                                        <th key={index}>
                                                                                {header && (
                                                                                <div className="mb-2"
                                                                                        onClick={() =>
                                                                                            handleSorting(header)
                                                                                        }
                                                                                        style={{
                                                                                            cursor: 'pointer',
                                                                                            marginLeft: '5px',
                                                                                        }}
                                                                                    >
                                                                                    <div key={header}>
                                                                                    {header}{' '}
                                                                                    <span>
                                                                                        {sortingOrder[header] ===
                                                                                            'asc' ? (
                                                                                            <i className="bi bi-chevron-up"></i>
                                                                                        ) : (
                                                                                            <i className="bi bi-chevron-down"></i>
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder={`Search ${header}`}
                                                                                value={searchQueries[header] || ''}
                                                                                onChange={(e) =>
                                                                                    updateSearchQuery(
                                                                                        header,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </th>
                                                                    </>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        {showDataPreview && selectedContent.length > 0 ? (
                                                            <tbody>
                                                                {filteredData.length > 0 ? (
                                                                    filteredData.map((row, rowIndex) => (
                                                                        <tr key={rowIndex}>
                                                                            {row.map((cell, cellIndex) => (
                                                                                <td key={cellIndex}>{cell}</td>
                                                                            ))}
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={ExcelDataHeadersLabel.length}>
                                                                            <div className="offer-container">
                                                                                <img
                                                                                    src={imgNoResult}
                                                                                    alt=""
                                                                                    className="offer-no-result-img"
                                                                                />
                                                                                <div className="mt-2">No results</div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        ) : !loading ? (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={ExcelDataHeadersLabel.length}>
                                                                        <div className="offer-container">
                                                                            <img
                                                                                src={imgNoResult}
                                                                                alt=""
                                                                                className="offer-no-result-img"
                                                                            />
                                                                            <div className="mt-2">No results</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={ExcelDataHeadersLabel.length}>
                                                                        <div className="offer-container">
                                                                            <PageLoader />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showDataPreview && selectedContent.length > 0 && (
                                        <div className="text-end me-3">
                                            <button
                                                type="submit"
                                                disabled={disableBtn}
                                                className="my-3 btn btn-primary"
                                            >
                                                {disableBtn && (
                                                    <span className="microsoft-icon">
                                                        <span className="spinner-border spinner-border-sm" />
                                                    </span>
                                                )}
                                                Process
                                            </button>

                                            {/* <button
                                                        type="button"
                                                        onClick={() => {
                                                            exportTableToExcel2();
                                                        }}
                                                    >
                                                        Download
                                                    </button> */}
                                        </div>
                                    )}
                                </>

                                {openProcessedDataModal && (
                                    <DownloadExcelResponse
                                        resetState={resetState}
                                        setFieldValue={setFieldValue}
                                        openProcessedDataModal={openProcessedDataModal}
                                        setOpenProcessedDataModal={setOpenProcessedDataModal}
                                        totalOutPutData={totalOutPutData}
                                        duplicateRecords={duplicateRecords}
                                        failedRecords={failedRecords}
                                        passRecords={passRecords}
                                        setIsLead={setIsLead}
                                        setShowCandidateUploadContent={setShowCandidateUploadContent}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CandidateExcelBulkUpload;

const getValidationSchema = () =>
    objectYup().shape({
        excelDataFileUrl: mixedYup().required('Required field.'),
    });

async function getFileFromUrl(base64Object: string) {
    const name = 'excelUploadData.xlsx';

    function convertToByteArray(base64: string): Uint8Array {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }

    const file = new File([convertToByteArray(base64Object)], name, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const reactFile = file as ReactFilesFile;
    reactFile.preview = {
        type: 'file',
        url: URL.createObjectURL(file),
    };
    reactFile.id = name;
    reactFile.extension = 'xlsx';

    return reactFile;
}
