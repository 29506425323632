import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from '../redux';
import Routes from './routes/routes';
import { appVersion } from '../config/constants';

// Css deps
import 'normalize.css/normalize.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../assets/styles/bootstrap.min.css';
import '../assets/styles/colors.css';
import '../assets/styles/admin.css';
import '../assets/styles/styles.css';
import '../assets/styles/app/app.sass';

const App = () => {
    useEffect(() => {
        console.log(`Version: ${appVersion}`);
    }, []);

    return (
        <Provider store={store}>
            <Routes />
        </Provider>
    );
};

export default App;
