import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    settings: null,
};
const consentWorkflowSlice = createSlice({
    name: 'consentWorkFlow',
    initialState,
    reducers: {
        setConsentWorkFlowSettings(state, action: PayloadAction<any>) {
            state.settings = action.payload;
        }
    },
});

export const getConsentWorkflowSettings = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await fetch('/consent-workflow-settings.json');
            const data = await response.json();
            await dispatch(consentWorkflowSlice.actions.setConsentWorkFlowSettings(data));
        } catch (err) {
            console.log('error from consentWorkflowSlice', err);
        }
    };
};

export default consentWorkflowSlice.reducer;
export const { setConsentWorkFlowSettings } = consentWorkflowSlice.actions;