import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../components/table';
import avatar from '../../assets/img/company-lg-icon.png';
import PageLoader from '../../components/loader';
import { companyService } from '../../lib/api/company';
import { SuspendStatusEnumColor } from '../../types/suspension';

const HRMSEmployerVerification = () => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const setStatusMessage = (status: number) => {
        switch (status) {
            case 0:
                return 'Inactive';

            case 1:
                return 'Active';

            default:
                return 'Waiting For Upload';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'companyName',
                title: 'Company Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="offer-table-reoffered me-2">
                                <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                            </div>
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.companyName}>
                                    {item.companyName}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'hrmsJoinStatus',
                title: 'Status',
                // hideSort: true,
                width: '128px',
                // inputFilter: (value: any) => {
                //     return getJoinStatusText(value);
                // },
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis text-capitalize" title={setStatusMessage(item.hrmsJoinStatus)}>
                            <span style={{ color: `${getStatusColor(item.hrmsJoinStatus)}` }}>
                                {setStatusMessage(item.hrmsJoinStatus)}
                            </span>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'adminName',
                title: 'Admin Name',
                render: (item: any) => (
                    <>
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.adminName}>
                                {item.adminName}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'companyEmail',
                title: 'Company Email',
                render: (item: any) => (
                    <>
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.companyEmail}>
                                {item.companyEmail}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'adminEmail',
                title: 'Admin Email',
                render: (item: any) => (
                    <>
                        <div className="text-crop" style={{ top: '6px' }}>
                            <div className="big-text text-capitalize" title={item.adminEmail}>
                                {item.adminEmail}
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'registrationDate',
                title: 'Registered Date',
                width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.registrationDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'companyPhone',
                title: 'Company Contact Number',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.companyPhone}>
                        {item?.companyPhone ? <>{item?.companyPhone}</> : 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'adminPhone',
                title: 'Admin Contact Number',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.ownerPhoneNumber}>
                        {item?.adminPhone ? <>{item?.adminPhone}</> : 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'companyWebsite',
                title: 'Company Website',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.companyWebsite}>
                        {item?.companyWebsite ? <>{item?.companyWebsite}</> : 'NA'}
                    </div>
                ),
            },
        ],
        []
    );

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);

            const { resultObject } = await companyService.getAllHRMSList();

            setData(resultObject);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="company-page-contener employer-table">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Table columns={columns} data={data} />
                    </>
                )}
            </div>
        </>
    );
};

export default HRMSEmployerVerification;

const getStatusColor = (value: SuspendStatusEnumColor) => {
    switch (value) {
        case SuspendStatusEnumColor.active:
            return '#D83B01';
        case SuspendStatusEnumColor.suspended:
            return '#107C10';
        default:
            return '';
    }
};
