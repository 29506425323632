import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { employeeService } from '../../lib/api/employee';
import { EmployeeJoinStatusEnum, EmployeeType } from '../../types/employee';
import { getJoinStatusText, getPermissionText } from '../../lib/utils/dictionary';
// import Table from '../../components/table/simple';
import CreateEmployee from './modals/create';
import { ManagerPermissionEnum } from '../../types/auth';
import PageLoader from '../../components/loader';
import imgNoResult from '../../assets/icons/offer-no-result.svg';
import Swal from 'sweetalert2';
import { ErrorIcon, Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import Table from '../../components/table';

const Employees = () => {
    const [data, setData] = useState<EmployeeType[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [loadingResend, setLoadingResend] = useState('');
    const [activeEmployee, setActiveEmployee] = useState<EmployeeType | null>(null);

    async function resend(id: string) {
        try {
            setLoadingResend(id);
            await employeeService.resend(id).then((res) => {
                Swal.fire({
                    icon: SuccessIcon,
                    text: Message.invitationSuccess,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
        } catch (err) {
            console.log('error employeeService getList', err);
            Swal.fire({
                icon: ErrorIcon,
                title: SweetAlertTitle.Oops,
                text: Message.invitationError,
                showConfirmButton: false,
                timer: 2500,
            });
            toast.error('Invitation resend error');
        } finally {
            setLoadingResend('');
        }
    }

    const columns = useMemo(
        () => [
            {
                dataIndex: 'fullName',
                title: 'User Name',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.fullName}>
                        {item.fullName}
                    </div>
                ),
            },
            {
                dataIndex: 'email',
                title: 'Email',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.email}>
                        {item.email}
                    </div>
                ),
            },
            {
                dataIndex: 'role',
                title: 'Role',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.role}>
                        {item.role}
                    </div>
                ),
            },
            {
                dataIndex: 'dateAdded',
                title: 'Date Added',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.dateAdded).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'joinStatus',
                title: 'Status',
                inputFilter: (value: any) => {
                    return getJoinStatusText(value);
                },
                render: (item: any) => (
                    <span style={{ color: `${getStatusColor(item.joinStatus)}` }}>
                        {getJoinStatusText(item.joinStatus)}
                    </span>
                ),
            },
            {
                dataIndex: '',
                title: 'Actions',
                render: (item: any) => (
                    <div className="d-flex justify-content-center">
                        {item.joinStatus === EmployeeJoinStatusEnum.pending && (
                            <span
                                onClick={() => resend(item.id)}
                                className={`lt-action-btn ${loadingResend ? 'table-btn-disabled' : ''}`}
                                title="Resend"
                            >
                                {loadingResend === item.id ? (
                                    <span className="spinner-border spinner-border-sm lt-text-primary-alt ms-1" />
                                ) : (
                                    <i className="bi bi-arrow-clockwise" />
                                )}
                            </span>
                        )}
                        {(item.joinStatus === EmployeeJoinStatusEnum.pending ||
                            item.joinStatus === EmployeeJoinStatusEnum.joined) && (
                            <>
                                <span
                                    onClick={() => setActiveEmployee(item)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddEmployee"
                                    className="lt-action-btn"
                                    title="Edit"
                                >
                                    <i className="bi bi-pencil" />
                                </span>
                                <span
                                    onClick={() => handleDelete(item)}
                                    className="lt-action-btn lt-del"
                                    title="Delete"
                                >
                                    <i className="bi bi-trash" />
                                </span>
                            </>
                        )}
                    </div>
                ),
            },
        ],
        [loadingResend]
    );

    const getList = useCallback(async () => {
        try {
            !loading && setLoading(true);
            const {
                resultObject: { items },
            } = await employeeService.getList();
            const mapItems = items.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.middleName ? item.middleName : ''} ${item.lastName}`,
                role: item.permissionTypeList
                    .map((permissionItem: ManagerPermissionEnum) => getPermissionText(permissionItem))
                    .join(', '),
            }));
            setData(mapItems);
        } catch (err) {
            console.log('error employeeService getList', err);
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingResend]);

    async function handleDelete(activeEmployee: any) {
        if (!activeEmployee) return;
        try {
            Swal.fire({
                title: Message.deleteEmployee,
                text: Message.deleteEmployeeText,
                icon: WarningIcon,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Delete!',
                showConfirmButton: true,
                showCloseButton: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await employeeService.delete(activeEmployee.id);
                    await Swal.fire(SweetAlertTitle.Success, Message.deleteEmployeeSuccess, SuccessIcon);
                    setLoading(false);
                    setLoadingResend(activeEmployee.id);
                }
            });
        } catch (err) {
            console.log('error employeeService delete', err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="d-flex align-items-center ox-dashboard-title">
                <div className="w-100">
                    <h1>Manage Employees</h1>
                </div>
                <div className="flex-shrink-1 text-nowrap">
                    <button
                        onClick={() => setActiveEmployee(null)}
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#AddEmployee"
                    >
                        Invite New Employee
                    </button>
                </div>
            </div>

            {loading ? (
                <PageLoader />
            ) : (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-0 lt-usermanagement-table mt-4">
                                {!!data?.length ? (
                                    <Table columns={columns} data={data} />
                                ) : (
                                    <div className="offer-container">
                                        <img src={imgNoResult} alt="" className="offer-no-result-img" />
                                        <div className="mt-2">You have no employees</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            <CreateEmployee activeEmployee={activeEmployee} getList={getList} />
        </>
    );
};

export default Employees;

const getStatusColor = (value: EmployeeJoinStatusEnum) => {
    switch (value) {
        case EmployeeJoinStatusEnum.pending:
            return '#AE8701';
        case EmployeeJoinStatusEnum.joined:
            return '#107C10';
        case EmployeeJoinStatusEnum.deleted:
            return '#D83B01';
        default:
            return '';
    }
};
