import React, { useEffect, useState } from 'react';
import PageLoader from '../../../components/loader';
import { dashboardService } from '../../../lib/api/dashboard';
import { getColorStatus } from '../../../lib/utils/cards-get-color-status';
import { GetTotalUserOfferCountType } from '../../../types/dashboard';
import { candidateCountType } from '../../../types/candidate';
import { candidateService } from '../../../lib/api/candidate';
import { companyService } from '../../../lib/api/company';
import { CompanyCountType } from '../../../types/company';

type Props = {
    selectedYear: number;
};

const OffersWidget: React.FC<Props> = ({ selectedYear }) => {
    const [data, setData] = useState<GetTotalUserOfferCountType>();
    const [countsData, setCountsData] = useState<candidateCountType | null>(null);
    const [companycountsData, setcompanyCountsData] = useState<CompanyCountType | null>(null);

    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const { resultObject } = await dashboardService.getTotalUserOfferCount(selectedYear);
                setData(resultObject);

                const { resultObject: data } = await candidateService.getCount();
                setCountsData(data);

                const { resultObject: companydata } = await companyService.getCount();
                setcompanyCountsData(companydata);
            } catch (error) {
                console.log('GetTotalUserOfferCount services', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [selectedYear]);

    return (
        <div className="row mt-4 row-deck">
            {loading ? (
                <PageLoader />
            ) : (
                <>
                <div className="col-xl-3 col-lg-6 col-md-6">
                {
                    /* <div className="card p-4 mb-4 lt-shape-card grayscale">
                <div className='block-element'><span>Coming Soon</span></div>
                <div className="fw-600 fs-14">
                    <span className="lt-dashboard-text">Total Revenue</span> <i className="bi bi-info-circle lt-tooltip"><span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">Total revenue generated since OfferX launch</span></i> <span className="float-end fs-32 lt-dashboard-icon"><i className="lt-dashboard-text bi bi-currency-dollar" /></span>
                </div>
                <div className="fw-400 fs-32 lt-text-link-primary">
                    $ 0
                </div>
                <div>
                    <span className="lt-text-success fw-600"><i className="bi bi-caret-up-fill" /> 0%</span> than last month
                </div>
            </div> */
                    <div className="card p-4 mb-4 lt-shape-card">
                        <div className="fw-600 fs-14">
                            <div className="lt-dashboard-text">
                                Total companies
                                <i className="bi bi-info-circle lt-tooltip ms-1">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Total companies in offerx as on today
                                    </span>
                                </i>
                            </div>
                            <div className="fs-32 lt-dashboard-icon">
                                <i className="lt-dashboard-text bi bi-building me-0" />
                            </div>
                        </div>
                        <div className="fw-400 fs-32 lt-text-link-primary">
                            {' '}
                            {companycountsData?.totalCompaniesCount}
                        </div>
                        <div>
                            <span
                                className={
                                    companycountsData !== null &&
                                    getColorStatus(companycountsData.totalCompaniesGrowth)
                                        ? 'lt-text-success fw-600'
                                        : 'lt-text-error fw-600'
                                }
                            >
                                <i
                                    className={
                                        companycountsData !== null &&
                                        getColorStatus(companycountsData.totalCompaniesGrowth)
                                            ? 'bi bi-caret-up-fill'
                                            : 'bi bi-caret-down-fill'
                                    }
                                />{' '}
                                {companycountsData?.totalCompaniesGrowth}%
                            </span>
                            <span className="ms-1"> than last month</span>
                        </div>
                    </div>
                }
            </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                {/* <div className="card p-4 mb-4 lt-shape-card grayscale">
                <div className='block-element'><span>Coming Soon</span></div>
                <div className="fw-600 fs-14">
                    <span className="lt-dashboard-text">Total Revenue</span> <i className="bi bi-info-circle lt-tooltip"><span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">Total revenue generated since OfferX launch</span></i> <span className="float-end fs-32 lt-dashboard-icon"><i className="lt-dashboard-text bi bi-currency-dollar" /></span>
                </div>
                <div className="fw-400 fs-32 lt-text-link-primary">
                    $ 0
                </div>
                <div>
                    <span className="lt-text-success fw-600"><i className="bi bi-caret-up-fill" /> 0%</span> than last month
                </div>
</div> */
<div className="card p-4 mb-4 lt-shape-card">
                    <div className="fw-600 fs-14">
                        <span className="lt-dashboard-text">Total candidates</span>
                        <i className="bi bi-info-circle lt-tooltip ms-1">
                            <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">Total candidates in offerx as on today</span>
                        </i>
                        <span className="float-end fs-32 lt-dashboard-icon">
                            <i className="lt-dashboard-text bi bi-people" />
                        </span>
                    </div>
                    <div className="fw-400 fs-32 lt-text-link-primary">{countsData?.totalCandidatesCount}</div>
                    <div>
                        <span className={countsData !== null && getColorStatus(countsData.totalCandidatesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                            <i className={countsData !== null && getColorStatus(countsData.totalCandidatesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData?.totalCandidatesGrowth}%
                        </span>
                        <span className='ms-1'> than last month</span>
                    </div>
                </div>}
            </div>
            
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="card p-4 mb-4 lt-shape-card">
                            <div className="fw-600 fs-14">
                                <span className="lt-dashboard-text">Total users</span>{' '}
                                <i className="bi bi-info-circle lt-tooltip">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Total number of users signed up since OfferX launch
                                    </span>
                                </i>{' '}
                                <span className="float-end fs-32 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-people" />
                                </span>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{data?.totalUsers || 0}</div>
                            <div>
                                <span
                                    className={
                                        getColorStatus(Number(data?.userPercentage))
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(Number(data?.userPercentage))
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />{' '}
                                    {Math.round(Number(data?.userPercentage)) || 0}%
                                </span>{' '}
                                <span className='ms-1'> than last month</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="card p-4 mb-4 lt-shape-card">
                            <div className="fw-600 fs-14">
                                <span className="lt-dashboard-text">Total offers</span>{' '}
                                <i className="bi bi-info-circle lt-tooltip">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Total number of offers released since OfferX launch
                                    </span>
                                </i>{' '}
                                <span className="float-end fs-32 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-envelope"></i>
                                </span>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{data?.totalOffers || 0}</div>
                            <div>
                                <span
                                    className={
                                        getColorStatus(Number(data?.offerPercentage))
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(Number(data?.offerPercentage))
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />{' '}
                                    {Math.round(Number(data?.offerPercentage)) || 0}%
                                </span>{' '}
                                <span className='ms-1'> than last month</span>
                            </div>
                        </div>
                    </div>
                    
                </>
            )}
        </div>
    );
};

export default OffersWidget;
