import React from 'react';
import siteLogo from '../../assets/img/offerx-logo.png';

const Header = () => {
    return (
        <div className="offerx-admin-header">
            <button
                aria-label="Toggle navigation"
                className="navbar-toggler border-0"
                data-bs-target="#offcanvasNavbar"
                data-bs-toggle="offcanvas"
                type="button"
            >
                <i className="bi bi-list" />
            </button>
            <div className="fs-20 fw-700 offerx-logo-link px-0">
                <img src={siteLogo} alt="OfferX Logo" className="site-logo" />
                <span className="fs-12 fw-400"> | Admin Panel</span>
            </div>
        </div>
    );
};

export default Header;
