import React from 'react';
import { getYearsList } from './utils/years';

type Props = {
    setSelectedYear: (key: number) => void
};

const DateRange:React.FC<Props> = ({ setSelectedYear }) => {
    const YearsItems = getYearsList();
    
    return (
        <div className="row mb-4">
            <div className="col-lg-12 col-xl-12 d-flex">
                <label className="date-range fs-14 fw-700">Date Range:</label>
                <select onChange={(Event) => setSelectedYear(Number(Event.target.value))} className="form-select input-border-default">
                    {YearsItems.reverse().map((list, index) => {
                        return <option key={list} value={list}>Year of {list}</option>
                    })}
                </select>
            </div>
        </div>
    )
}

export default DateRange