import React, { useMemo, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CandidateListDataType } from '../../../types/policyDocument';
import Table from '../../../components/table';
import { policyDocumentService } from '../../../lib/api/policyDocument';
import PageLoader from '../../../components/loader';
import Base64DownloadPdf from '../Base64/base64DownloadPdf';

type Props = {
    candidateList: CandidateListDataType[];
    showCandidateListModal: boolean;
    policyDocId: string;
    setShowCandidateListModal: (key: boolean) => void;
};

const CandidateListModal: React.FC<Props> = ({
    policyDocId,
    candidateList,
    showCandidateListModal,
    setShowCandidateListModal,
}) => {
    const [data, setData] = useState<CandidateListDataType[]>();

    useEffect(() => {
        if (policyDocId) {
            const getData = async (policyDocID: string, countryID?: string) => {
                try {
                    const {
                        resultObject: { candidateList },
                    } = await policyDocumentService.getCandidateanDocumentCount({
                        policyDocumentId: policyDocID,
                        countryId: '',
                    });

                    setData(candidateList);
                } catch (err) {
                    console.log(err, 'Error');
                }
            };
            getData(policyDocId);
        } else {
            const getData = async (policyDocID: string, countryID: string) => {
                try {
                    const {
                        resultObject: { candidateList },
                    } = await policyDocumentService.getCandidateanDocumentCount({
                        policyDocumentId: policyDocID,
                        countryId: countryID,
                    });

                    setData(candidateList);
                } catch (err) {
                    console.log(err, 'Error');
                }
            };
            getData(candidateList[0].policyDocumentId, candidateList[0].countryId.toString());
        }
    }, [policyDocId, candidateList]);

    const policyDocumentStatus = (status: string) => {
        switch (status) {
            case '0':
                return 'Pending';

            case '1':
                return 'Accepted';

            default:
                return 'NA';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'countryName',
                title: 'Country',
                render: (item: any) => <div>{item?.countryName || 'NA'}</div>,
            },
            {
                dataIndex: 'candidateName',
                title: 'Candidate Name',
                render: (item: any) => <div className='text-crop' title={item?.candidateName || 'NA'}><div className='big-text'>{item?.candidateName || 'NA'}</div></div>,
            },
            {
                dataIndex: 'status',
                title: 'Status',
                render: (item: any) => <div>{policyDocumentStatus(item?.status?.toString())}</div>,
            },
            {
                dataIndex: '',
                title: 'Action',
                render: (item: any) => (
                    <>
                        {item?.policyDocumentId && <Base64DownloadPdf id={item?.policyDocumentId} />}
                    </>
                ),
            },
        ],
        []
    );

    return (
        <div>
            {' '}
            <Modal
                show={showCandidateListModal}
                className="modal fade admin-candidate-list-modal"
                id="CandidateList"
                backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="CandidateListLabel"
                aria-hidden="true"
                size="lg"
                centered
            >
                <div className="modal-content">
                    <Modal.Header className="modal-header align-items-start border-0">
                        <div className="flex-column">
                            <h1 className="modal-title w-100 fs-16" id="CandidateListLabel">
                                Candidate List
                            </h1>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShowCandidateListModal(false)}
                        ></button>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {data ? (
                            <Table columns={columns} data={data} />
                        ) : candidateList ? (
                            <Table columns={columns} data={candidateList} />
                        ) : (
                            <PageLoader />
                        )} */}
                        {data ? <div className='candlist-table'><Table columns={columns} data={data} /></div> : <PageLoader />}
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};

export default CandidateListModal;
