import React, { useEffect, useMemo, useRef, useState } from 'react';

type Props = {
    id?: string;
    setFieldValue?: (fieldName: string, fieldvalue: CountryDataType[]) => void;
    countryFieldValue?: string;
    setFieldTouched: (key: string) => void;
    resetMultiSelectDropdown?: boolean;
};

type Country = {
    id: number;
    name: string;
    iso2: string;
};

type CountryDataType = {
    countryId: string;
    countryName: string;
    countryCode: string;
};

const MultiSelectDropdown: React.FC<Props> = ({
    id,
    setFieldValue,
    resetMultiSelectDropdown,
    countryFieldValue,
    setFieldTouched,
}) => {
    const [selectedCountryList, setSelectedCountryList] = useState<CountryDataType[]>([]);
    const [filterText, setFilterText] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [countriesData, setCountriesData] = useState<Country[]>();
    const [filteredCountries, setFilteredCountries] = useState<Country[]>();
    const [dropdownPlacement, setDropdownPlacement] = useState<'down' | 'up'>('down');
    const [selectedCountryIndexes, setSelectedCountryIndexes] = useState<number[]>([]);

    const filterInputRef = useRef<HTMLInputElement>(null);
    const countryButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const filterCountries = () => {

        if (countriesData) {
            let filterList = countriesData.filter(
                (countries) =>
                    countries.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
                    countries.id.toString().startsWith(filterText)
            );
            setFilteredCountries(filterList);
        }
    }

    useEffect(() => {
        filterCountries();
    }, [filterText]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryButtonRef.current &&
                !countryButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const getAllCountriesData = async () => {
            try {
                var headers = new Headers();
                headers.append('X-CSCAPI-KEY', 'VFhERlVLdHBrUjBMR1ZEV0FIREh0QjlrMmtJOFZFMHRuRjdzcHRMUg==');

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                };

                const response = await fetch('https://api.countrystatecity.in/v1/countries', requestOptions);
                const result = await response.json();
                setCountriesData(result);
                setFilteredCountries(result);
            } catch (error) {
                console.log(error, 'data error');
            }
        };
        getAllCountriesData();
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryButtonRef.current &&
                !countryButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        const handleDropdownPosition = () => {
            if (openDropdown && countryButtonRef.current && dropdownRef.current) {
                const dropdownHeight = dropdownRef.current.offsetHeight;
                const triggerRect = countryButtonRef.current.getBoundingClientRect();
                const taskbarHeight = window.innerHeight - window.screen.height;

                if (triggerRect.bottom + dropdownHeight > window.innerHeight - taskbarHeight) {
                    setDropdownPlacement('up');
                } else {
                    setDropdownPlacement('down');
                }
            }
        };

        handleDropdownPosition();

        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('scroll', handleDropdownPosition);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('scroll', handleDropdownPosition);
        };
    }, [openDropdown]);

    const selectedCountries = useMemo(() => {
        return countriesData && selectedCountryIndexes.map((index) => countriesData[index - 1]?.name);
    }, [countriesData, selectedCountryIndexes]);

    useEffect(() => {
        if (selectedCountryList) {
            setFieldValue?.('countryList', selectedCountryList);
        }

        if (resetMultiSelectDropdown) {
            setFieldValue?.('countryList', []);
            setSelectedCountryIndexes([]);
        }
    }, [selectedCountryList, setFieldValue, resetMultiSelectDropdown, setFieldTouched]);

    return (
        <div className="position-relative">
            <>
                <button
                    type="button"
                    ref={countryButtonRef}
                    id="country"
                    className="form-select cursor-pointer text-start text-ellipsis"
                    onClick={() => {
                        setOpenDropdown(!openDropdown);
                        setFilterText('');
                        setTimeout(() => {
                            filterInputRef.current?.focus();
                        }, 50);
                    }}
                    title={selectedCountries && selectedCountries!.length > 0 ? selectedCountries!.join(', ') : ''}
                >
                    {/* {countryName.length > 0 ? countryName.join(', ') : 'Select Country'} */}
                    {selectedCountries && selectedCountries.length > 0
                        ? selectedCountries.slice(0, 2).join(', ') + (selectedCountries.length > 2 ? '...' : '')
                        : 'Select Country'}
                </button>
                {openDropdown && (
                    <div ref={dropdownRef} className={`country-dropdown ${dropdownPlacement} w-100`}>
                        <div className="sticky-search">
                            <input
                                type="search"
                                ref={filterInputRef}
                                className="form-control cursor-pointer country-search"
                                placeholder="Search Country"
                                value={filterText}
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                }}
                            />
                        </div>
                        <div>
                            {filteredCountries &&
                                filteredCountries.map((country, index) => (
                                    <li
                                        // onBlur={() => {
                                        //     setOpenDropdown(false);
                                        // }}
                                        className="dropdown-item cursor-pointer fs-14"
                                        onClick={() => {
                                            const countryId = String(country.id);
                                            const selectedCountryIndex = selectedCountryIndexes.indexOf(
                                                Number(countryId)
                                            );

                                            if (selectedCountryIndex === -1) {
                                                setSelectedCountryIndexes([
                                                    ...selectedCountryIndexes,
                                                    Number(countryId),
                                                ]);
                                                setSelectedCountryList((prevCountryList) => [
                                                    ...prevCountryList,
                                                    {
                                                        countryId,
                                                        countryName: country.name,
                                                        countryCode: country.iso2
                                                    },
                                                ]);
                                            } else {
                                                setSelectedCountryIndexes(
                                                    selectedCountryIndexes.filter((id) => id !== Number(countryId))
                                                );
                                                setSelectedCountryList((prevCountryList) =>
                                                    prevCountryList.filter((c) => c.countryId !== countryId)
                                                );
                                                setFieldTouched('countryList');
                                            }
                                        }}
                                        key={country.id}
                                        value={country.id}
                                    >
                                        <input
                                            checked={selectedCountryIndexes.includes(country.id)}
                                            type="checkbox"
                                            readOnly
                                        />{' '}
                                        {country.name}
                                    </li>
                                ))}
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default MultiSelectDropdown;
