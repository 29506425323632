import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { CandidateGlobalRequestType, ReportFromCandidateType } from '../../types/candidate';
import avatar from '../../assets/img/avatars.png';
import { SuspensionDecisionEnum } from '../../types/suspension';
import { candidateService } from '../../lib/api/candidate';
import { suspensionService } from '../../lib/api/suspension';
import DatePickerComponent from '../../components/date-picker';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import { getSuspensionDecisionText } from '../../lib/utils/dictionary';
import ReportDetails from './modals/report-details';
import { routes } from '../routes/routes-names';
import { generateLink } from '../../lib/utils/generate-link';
import Suspend from '../../components/suspend-modal/suspend';
import statesData from '../countries states cities/states.json';
import citiesData from '../countries states cities/cities.json';
import StateCityCodeValue from '../../components/profile/stateCityCodeValue';
import Table from '../../components/table';

interface State {
    StateId: string;
    StateName: string;
    CountryId: string;
}

interface City {
    CitiesId: string;
    CitiesName: string;
    CitiesStateId: string;
}

const ReportedByUser = () => {
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [range, setRange] = useState<[Date, Date] | null>(null);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ReportFromCandidateType[]>([]);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showReportModal, setShowReportModal] = useState(false);
    const [currentId, setCurrentId] = useState('');
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [candidateList, setCandidateList] = useState<ReportFromCandidateType>();

    // const getStateName = (item: ReportFromCandidateType) => {
    //     if (isNaN(parseFloat(item?.candidateState!))) {
    //         const stateIdByName = statesData.find(
    //             (state: State) => state?.StateName.toLowerCase() === item?.candidateState!.toLowerCase()
    //         );
    //         const stateNameById = statesData.find((state: State) => state.StateId === stateIdByName?.StateId);

    //         return stateNameById?.StateName!;
    //     } else if (item?.candidateState! === 'Other') {
    //         return 'Others';
    //     } else {
    //         const stateNameById = statesData.find((state: State) => state.StateId === item?.candidateState!);
    //         return stateNameById?.StateName!;
    //     }
    // };

    // const getCityName = (item: ReportFromCandidateType) => {
    //     if (isNaN(parseFloat(item?.candidateCityDistrict!))) {
    //         const cityIdByName = citiesData.find(
    //             (city: City) => city.CitiesName.toLowerCase() === item?.candidateCityDistrict!.toLowerCase()
    //         );
    //         const cityNameById = citiesData.find((city: City) => city.CitiesId === cityIdByName?.CitiesId);

    //         return cityNameById?.CitiesName!;
    //     } else if (item?.candidateCityDistrict! === 'Other') {
    //         return 'Others';
    //     } else {
    //         const cityNameById = citiesData.find((city: City) => city.CitiesId === item?.candidateCityDistrict!);
    //         return cityNameById?.CitiesName!;
    //     }
    // };

    const getCandidateList = useCallback(async () => {
        try {
            setLoading(true);
            sessionStorage.clear();
            const { resultObject: data } = await candidateService.getCount();
            const params: CandidateGlobalRequestType = {
                Start: 1,
                Limit: data.reportedByCompaniesCandidatesCount,
            };
            const {
                resultObject: { items, count },
            } = await candidateService.getReportFromCompanyList(params);

            const mapItems = items.map((item) => ({
                ...item,
                // location: `${item.candidateState}, ${item.candidateCityDistrict}`,
                // location: `${getStateName(item)}, ${getCityName(item)}`,
                candidateSuspendReason: [...item.candidateSuspendReason, item.suspendReasonOtherText],
            }));
            setData(mapItems);
            setTotalCount(count);
        } catch (error) {
            console.log('error candidateService', error);
        } finally {
            setLoading(false);
        }
    }, [rerenderKey]);

    useEffect(() => {
        (async () => {
            await getCandidateList();
        })();
    }, [getCandidateList, rerenderKey]);

    const updateFlag = useCallback(
        async (id: string, value: boolean) => {
            try {
                setLoadingFlag(true);
                await candidateService.updateFlag({ candidateReportFromCompanyId: id, flagValue: value });
                Swal.fire({
                    icon: SuccessIcon,
                    title: `${value ? 'Flag' : 'Unflag'} Your work has been saved`,
                    showConfirmButton: false,
                    timer: 1500,
                });
                setRerenderKey(Date.now());
            } catch (error) {
                console.log('candidateService updateFlag', error);
            } finally {
                setLoadingFlag(false);
            }
        },
        [setRerenderKey]
    );

    const rejectCandidate = useCallback(
        async (id: string) => {
            try {
                Swal.fire({
                    title: Message.discardCandidate,
                    text: Message.discardCandidateRevert,
                    icon: WarningIcon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    showConfirmButton: true,
                    showCloseButton: false,
                }).then(async (result: any) => {
                    if (result.isConfirmed) {
                        await suspensionService.candidateRejectByReport(id).then((res) => {
                            if (res.isSuccessful === true) {
                                Swal.fire(SweetAlertTitle.Success, Message.discardSuccessfull, SuccessIcon);
                            }
                        });
                    }
                });
            } catch (error) {
            } finally {
                setLoadingFlag(false);
            }
        },
        [setRerenderKey]
    );

    const discardByCandidate = useCallback(
        async (id: any) => {
            try {
                Swal.fire({
                    title: Message.discardCandidate,
                    text: Message.discardCandidateRevert,
                    icon: WarningIcon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    showConfirmButton: true,
                    showCloseButton: false,
                }).then(async function (result) {
                    if (result.isConfirmed) {
                        await suspensionService.rejectByCandidateReport(id);
                        setRerenderKey(Date.now());
                        Swal.fire({
                            title: SweetAlertTitle.Success,
                            text: Message.discardSuccessfull,
                            icon: SuccessIcon,
                        });
                        setShowReportModal(false);
                    }
                });
            } catch (err: any) {
                console.log('suspensionService rejectByCandidateReport error', err.response);
            } finally {
            }
        },
        [setRerenderKey]
    );

    const getSuspensionDecisionText = (status: SuspensionDecisionEnum) => {
        switch (status) {
            case 0:
                return 'Active';

            case 1:
                return 'Rejected';

            case 2:
                return 'Approved';

            default:
                return 'unknown';
        }
    };

    const columns = useMemo(
        () => [
            // {
            //     dataIndex: 'avatarUrl',
            //     title: '',
            //     render: (item: any) => (
            //         <img src={item.candidateAvatarUrl || avatar} alt="" className="avatar avatar--xs" />
            //     ),
            // },
            {
                dataIndex: 'candidateFullName',
                title: 'Candidate Name',
                render: (item: any) => (
                    <div className="text-ellipsis">
                        <div className="offer-table-reoffered me-2">
                            <img src={item.candidateAvatarUrl || avatar} alt="" className="avatar avatar--xs" />
                        </div>
                        <Link
                            to={generateLink(routes.candidateView, {
                                id: item.candidateId,
                                suspendStatus: item.suspendStatus,
                            })}
                            className="offer-table-link text-capitalize"
                        >
                            {item.candidateFullName}
                        </Link>
                    </div>
                ),
            },
            {
                dataIndex: 'candidateState',
                title: 'Location',
                render: (item: any) => (
                    <div
                        className="text-ellipsis text-capitalize"
                        title={item?.candidateCityDistrict && item.candidateState + item?.candidateCityDistrict}
                    >
                        <StateCityCodeValue
                            id={String(Math.random()) + 1}
                            countryValue={item?.candidateCountry}
                            cityValue={item?.candidateCityDistrict}
                            stateValue={item?.candidateState}
                        />
                    </div>
                ),
            },
            {
                dataIndex: 'reportedDate',
                title: 'Reported Date',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.reportedDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'reportedByCompanyName',
                title: 'Reported By',
                render: (item: any) => (
                    <div className="offer-table-link text-ellipsis" title={item.reportedByCompanyName}>
                        {item.reportedByCompanyName}
                    </div>
                ),
            },
            {
                dataIndex: 'candidateSuspendReasonText',
                title: 'Reason',
                inputFilter: (value: any) => {
                    return value;
                },
                render: (item: any) => (
                    <div
                        className="text-ellipsis"
                        title={item.candidateSuspendReason.join(', ').replace(/, ([^,]*)$/, '  $1')}
                    >
                        {item.candidateSuspendReason.join(', ').replace(/, ([^,]*)$/, '  $1')}{' '}
                        {item.candidateSuspendReasonText}
                    </div>
                ),
            },
            {
                dataIndex: 'suspensionDecision',
                title: 'Status',
                // inputFilter: (value: any) => {
                //     return getSuspensionDecisionText(value);
                // },
                render: (item: any) => (
                    <span style={{ color: `${getStatusColor(item.suspensionDecision)}` }}>
                        {getSuspensionDecisionText(item.suspensionDecision)}
                    </span>
                ),
            },
            {
                dataIndex: '',
                title: 'Actions',
                render: (item: any) => (
                    <div className="hover-icons text-center">
                        <button
                            type="button"
                            title="View report details"
                            className="lt-text-primary"
                            onClick={() => (setShowReportModal(true), setCurrentId(item.id), setCandidateList(item))}
                        >
                            <i className="bi bi-eye" />
                        </button>
                        <button
                            type="button"
                            title={item.isFlagged ? 'Unflag' : 'Flag'}
                            className="lt-text-primary lt-flag-icon"
                            onClick={() => updateFlag(item.id, !item.isFlagged)}
                        >
                            {loadingFlag === item.id ? (
                                <span className="spinner-border spinner-border-sm lt-text-primary-alt ms-1" />
                            ) : (
                                <i className={`bi flag-select ${item.isFlagged ? 'bi-flag-fill' : 'bi-flag'}`} />
                            )}
                        </button>
                        <button
                            type="button"
                            title="Discard"
                            disabled={
                                item.suspensionDecision === SuspensionDecisionEnum.approved ||
                                item.suspensionDecision === SuspensionDecisionEnum.rejected
                            }
                            onClick={() => discardByCandidate(item.id)}
                        >
                            <i className="bi bi-trash" />
                        </button>
                    </div>
                ),
            },
        ],
        [loadingFlag, updateFlag, setRerenderKey]
    );

    useMemo(() => {
        if (range) {
            const dateFilter = data.filter((item) => {
                const reportedDate = new Date(item.reportedDate as Date).setHours(0, 0, 0, 0);
                return reportedDate >= range[0].setHours(0, 0, 0, 0) && reportedDate <= range[1].setHours(0, 0, 0, 0);
            });
            setData(dateFilter);
        } else {
            setData(data);
        }
    }, [range, loadingFlag, loading]);

    const reset = useCallback(() => {
        setRange(null);
        setData(data);
    }, [range]);

    return (
        <>
            <div className="d-flex align-items-center mb-4">
                <div className="label me-1">
                    <h1 className="fw-700 fs-14 m-0">Date range:</h1>
                </div>
                <div className="ms-2">
                    <DatePickerComponent
                        key={range?.toString()}
                        startDate={range && range[0]}
                        endDate={range && range[1]}
                        placeholder="Select date range"
                        rangePicker
                        onChange={(date) => {
                            setRange(date as [Date, Date] | null);
                        }}
                    />
                </div>
                {range && (
                    <div className="ms-2">
                        <button type="button" onClick={reset} className="btn-link">
                            Reset
                        </button>
                    </div>
                )}
            </div>
            <Table columns={columns} data={data} />
            <ReportDetails
                setRerenderKey={setRerenderKey}
                showReportModal={showReportModal}
                setShowAdminModal={setShowAdminModal}
                setShowReportModal={setShowReportModal}
                data={data.find((item) => item.id === currentId)}
            />
            <Suspend
                reportByuser
                reportedId={candidateList?.id}
                candidate={true}
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={candidateList?.candidateId || ''}
                setRerenderKey={setRerenderKey}
            />
        </>
    );
};

export default ReportedByUser;

const getStatusColor = (value: SuspensionDecisionEnum) => {
    switch (value) {
        case SuspensionDecisionEnum.active:
            return '#107C10';
        case SuspensionDecisionEnum.approved:
            return '#107C10';
        case SuspensionDecisionEnum.rejected:
            return '#D83B01';
        default:
            return '';
    }
};
