import { axiosInstance } from './index';
import { candidateRejectResponseType, CandidateReportRequestType, CandidateReportType, CompanyReportType, ReportRequestType, SuspensionResponseType } from '../../types/suspension';

export const suspensionService = {
    async getReasonList() {
        const { data } = await axiosInstance.get('Suspension/GetAllSuspensionReasonsList');
        return data as SuspensionResponseType;
    },

    async suspendByAdmin(data: ReportRequestType) {
        await axiosInstance.post('CompanySuspension/SuspendByAdmin', data);
    },

    async unSuspendByAdmin(id: string) {
        await axiosInstance.post(`CompanySuspension/${id}/UnsuspendByAdmin`);
    },

    async rejectByReport(data: { companyReportFromCandidateId: string }) {
        await axiosInstance.post(`CompanySuspension/RejectByReport`, data);
    },

    async suspendByReport(data: { companyReportFromCandidateId: string }) {
        await axiosInstance.post(`CompanySuspension/SuspendByReport`, data);
    },

    async suspendByCandidate(data: CandidateReportRequestType) {
        await axiosInstance.post('CandidateSuspension/SuspendByAdmin', data);
    },

    async unSuspendByCandidate(id: string) {
        await axiosInstance.post(`CandidateSuspension/${id}/UnsuspendByAdmin`)
    },

    async candidateRejectByReport(id: string) {
        const { data } = await axiosInstance.post(`CandidateSuspension/${id}/RejectByReport`)
        return data as candidateRejectResponseType;
    },

    async suspendByCandidateReport(data : CandidateReportType ) {
        await axiosInstance.post(`CandidateSuspension/SuspendByReport`, data);
    },

    async suspendByCompanyReport(data : CompanyReportType ) {
        await axiosInstance.post(`CompanySuspension/SuspendByReport`, data);
    },
    
    async rejectByCandidateReport(candidateReportFromCompanyId: string ) {
        await axiosInstance.post(`CandidateSuspension/${candidateReportFromCompanyId}/RejectByReport`);
    },
};
