import React, { useMemo, useState, useEffect } from 'react';
import { CandidateListDataType, CompanyListDataType, CountryListDataType } from '../../../types/policyDocument';
import Table from '../../../components/table';
import { Modal } from 'react-bootstrap';
import CompanyListModal from './companyListModal';
import CandidateListModal from './candidateListModal';
import { policyDocumentService } from '../../../lib/api/policyDocument';
import PageLoader from '../../../components/loader';

type Props = {
    countryList: CountryListDataType[];
    policyDocId: string;
    showCountryListModal: boolean;
    setShowCountryListModal: (key: boolean) => void;
    showCandidateListModal: boolean;
    setShowCandidateListModal: (key: boolean) => void;
    showCompanyListModal: boolean;
    setShowCompanyListModal: (key: boolean) => void;
};

const CountryListModal: React.FC<Props> = ({
    setShowCompanyListModal,
    showCompanyListModal,
    policyDocId,
    showCandidateListModal,
    setShowCandidateListModal,
    countryList,
    showCountryListModal,
    setShowCountryListModal,
}) => {
    const [totalCompanyListData, setTotalCompanyListData] = useState<CompanyListDataType[]>([]);
    const [totalCandidateListData, setTotalCandidateListData] = useState<CandidateListDataType[]>([]);

    const columns = useMemo(
        () => [
            {
                dataIndex: 'countryName',
                title: 'Country',
                isInputFilterVisible: false,
                render: (item: any) => <>{item?.countryName || 'NA'}</>,
            },
            {
                dataIndex: '',
                title: 'Total Companies',
                render: (item: any) => (
                    <>
                        <div
                            className="text-center d-flex"
                            style={{ width: '200px' }}
                            onClick={() => {
                                setTotalCompanyListData(item);
                                setShowCompanyListModal(true);
                                setShowCountryListModal(false);
                            }}
                        >
                            <span className="nav-link cursor-pointer">[{item?.countryCompanyCount!}]</span>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: '',
                title: 'Total Candidates',
                render: (item: any) => (
                    <>
                        <div
                            className="text-center d-flex"
                            style={{ width: '200px' }}
                            onClick={() => {
                                setTotalCandidateListData(item);
                                setShowCandidateListModal(true);
                                setShowCountryListModal(false);
                            }}
                        >
                            <span className="nav-link cursor-pointer">[{item?.countryCandidateCount!}]</span>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <div>
            <Modal
                show={showCountryListModal}
                className="modal fade"
                id="CountryList"
                backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="CountryListLabel"
                aria-hidden="true"
                size="lg"
                centered
            >
                <div className="modal-content">
                    <Modal.Header className="modal-header align-items-start border-0">
                        <div className="flex-column">
                            <h1 className="modal-title w-100 fs-16" id="CountryListLabel">
                                Country List
                            </h1>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShowCountryListModal(false)}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        {countryList ? <Table columns={columns} data={countryList} /> : <PageLoader />}
                    </Modal.Body>
                </div>
            </Modal>
            {showCompanyListModal && (
                <CompanyListModal
                    companyList={totalCompanyListData}
                    policyDocId={policyDocId}
                    showCompanyListModal={showCompanyListModal}
                    setShowCompanyListModal={setShowCompanyListModal}
                />
            )}

            {showCandidateListModal && (
                <CandidateListModal
                    policyDocId={policyDocId}
                    candidateList={totalCandidateListData}
                    showCandidateListModal={showCandidateListModal}
                    setShowCandidateListModal={setShowCandidateListModal}
                />
            )}
        </div>
    );
};

export default CountryListModal;
