import { axiosInstance } from './index';
import { EmployeeSalesOwnerFormType, EmployeeVerificationFormType, ScreenXEmployeeSalesOwnerFormType, ScreenXEmployeeVerificationFormType } from '../../types/employeeVerification';

export const employeeVerificationService = {
    async update(data: EmployeeVerificationFormType & { id: string }) {
        await axiosInstance.put('Company/UpdateCompanyVerificationStatus', data);
    },

    async updateScreenXCompanyStatus(data: ScreenXEmployeeVerificationFormType) {
        await axiosInstance.post('Company/UpdateCompanyStatusScreenX', data);
    },
};

export const employeeSalesOwnerService = {
    async update(data: EmployeeSalesOwnerFormType & { id: string }) {
        await axiosInstance.put('Company/UpdateCompanyReferral', data);
    },

    async updateScreenX(data: ScreenXEmployeeSalesOwnerFormType) {
        await axiosInstance.post('Company/UpdateSalesOwnerScreenX', data);
    },
};
