import { ExcelApiUploadDataKey, SalesOwnerNamesApiResponseData, ScreenXExcelApiResponseData } from './../../types/excelUpload';
import { axiosInstance } from './index';
import { ExcelApiResponseData } from '../../types/excelUpload';

export const excelUploadDataService = {
    async setCompanyExcelUploadData(params: ExcelApiUploadDataKey) {
        const { data } = await axiosInstance.post(`CompanyLeads/AddCompanyLeads`, params);
        return data as ExcelApiResponseData;
        // await axiosInstance.post(`CompanyLeads/AddCompanyLeads`, params);
    },

    async getSalesOwnerNamesData() {
        const { data } = await axiosInstance.get(`CompanyLeads/GetSalesOwnersList`);
        return data as SalesOwnerNamesApiResponseData;
    },

    async setScreenXCompanyExcelUploadData(params: ExcelApiUploadDataKey) {
        params.companyRequestModel.forEach((x)=>{
            x.salesOwnerEmail = localStorage.getItem("AdminEmail") || '';
            x.salesOwnerName = localStorage.getItem("AdminName") || '';
        })
        const { data } = await axiosInstance.post(`Company/InviteCompanyScreenX`, params);
        return data as ScreenXExcelApiResponseData;
        // await axiosInstance.post(`CompanyLeads/AddCompanyLeads`, params);
    },
};
