import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ReportFromCandidateType } from '../../../types/candidate';
import avatar from '../../../assets/img/avatars-lg.png';
import { generateLink } from '../../../lib/utils/generate-link';
import { routes } from '../../routes/routes-names';
import { suspensionService } from '../../../lib/api/suspension';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../../lib/utils/messages';
import { SuspendStatusEnum, SuspensionDecisionEnum } from '../../../types/suspension';
import { DiscardEnum } from '../../../types/review';

type Props = {
    showReportModal: boolean;
    setShowReportModal: (value: boolean) => void;
    data?: ReportFromCandidateType;
    setRerenderKey: (key: number) => void;
    setShowAdminModal: (value: boolean) => void;
};

const ReportDetails: React.FC<Props> = ({
    showReportModal,
    setShowReportModal,
    data,
    setRerenderKey,
    setShowAdminModal,
}) => {
    const suspendByCandidate = useCallback(
        async (id: any) => {
            try {
                Swal.fire({
                    title: Message.suspendCandidate,
                    text: Message.suspendCandidateText,
                    icon: WarningIcon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    showConfirmButton: true,
                    showCloseButton: false,
                }).then(async function (result) {
                    if (result.isConfirmed) {
                        await suspensionService.suspendByCandidateReport(id);
                        setRerenderKey(Date.now());
                        Swal.fire({
                            title: SweetAlertTitle.Success,
                            text: Message.suspendCandSussess,
                            icon: SuccessIcon,
                        });
                    }
                });
            } catch (err: any) {
                console.log('suspensionService suspendByCandidateReport error', err.response);
            } finally {
            }
        },
        [setRerenderKey]
    );

    const discardByCandidate = useCallback(
        async (id: any) => {
            try {
                Swal.fire({
                    title: Message.discardCandidate,
                    text: Message.discardCandidateRevert,
                    icon: WarningIcon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    showConfirmButton: true,
                    showCloseButton: false,
                }).then(async function (result) {
                    if (result.isConfirmed) {
                        await suspensionService.rejectByCandidateReport(id);
                        setRerenderKey(Date.now());
                        Swal.fire({
                            title: SweetAlertTitle.Success,
                            text: Message.discardSuccessfull,
                            icon: SuccessIcon,
                        });
                        setShowReportModal(false);
                    }
                });
            } catch (err: any) {
                console.log('suspensionService rejectByCandidateReport error', err.response);
            } finally {
            }
        },
        [setRerenderKey]
    );

    return (
        <>
            <Modal show={showReportModal} onHide={() => setShowReportModal(false)} centered>
                <div className="lt-modal-content">
                    <Modal.Header className="lt-modal-header">
                        <Modal.Title className="text-center fw-700 w-100 fs-20">Report details</Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            title="Close"
                            aria-label="Close"
                            onClick={() => setShowReportModal(false)}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="lt-modal-body">
                        <div className="d-flex align-items-center my-4">
                            <img
                                src={data?.candidateAvatarUrl || avatar}
                                alt={'Candidate Profile Image'}
                                className="avatar avatar-lg"
                            />
                            <div className="ms-3">
                                <h5 className="fs-18 fw-700"> {data?.candidateFullName}</h5>
                                <p className="fs-14 fw-400 text-capitalize">
                                    {data?.candidateCityDistrict}, {data?.candidateState.toString().toLowerCase()}
                                </p>
                                <p className="fs-14 fw-400 text-capitalize">
                                    {data?.candidateJobTitle.toString().toLowerCase()}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="col-lg-3 col-sm-4 col-12 fs-14 fw-600">Reported by</div>
                            <div className="col fs-14 fw-400">{data?.reportedByCompanyName}</div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="col-lg-3 col-sm-4 col-12 fs-14 fw-600">Reported Date</div>
                            <div className="col fs-14 fw-400">
                                {new Date(String(data?.reportedDate)).toLocaleString('en-in', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                })}
                            </div>
                        </div>
                        <div className="d-flex mb-3">
                            <div className="col-lg-3 col-sm-4 col-12 fs-14 fw-600">Reason</div>
                            <div className="col fs-14 fw-400">
                                {/* {data?.candidateSuspendReason.length ? (
                                    <p className='text-justify'>{data?.candidateSuspendReason.join(', ').replace(/, ([^,]*)$/, '  $1')}</p>) : (<div>NA</div>)} */}

                                <ol className="ps-3 mb-0">
                                    {data?.candidateSuspendReason
                                        .filter((x) => x !== null)
                                        .map((number, index) => (
                                            <li key={index}>{number}</li>
                                        ))}
                                </ol>
                                {data?.candidateSuspendReasonText && (
                                    <>
                                        <span className="col-12 fw-600">Other text: </span>{' '}
                                        {data?.candidateSuspendReasonText}
                                    </>
                                )}
                            </div>
                        </div>
                        {data?.suspensionDecision === SuspensionDecisionEnum.approved ||
                        data?.candidateSuspensionStatus === SuspendStatusEnum.suspended ? (
                            <div className="d-flex mb-3">
                                <div className="col-lg-3 col-sm-4 col-12 fs-14 fw-600">Suspend reason</div>
                                <div className="col fs-14 fw-400">
                                    <p className="text-justify">This candidate has been suspended</p>
                                </div>
                            </div>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer className="lt-modal-footer">
                        {}
                        <div className="d-flex w-100 m-0 pt-3">
                            <div className="col pe-2">
                                {/* <Link to={generateLink(routes.candidateView, { id: String(data?.candidateId) })} className="btn btn-outline-primary fs-18 btn-lg mb-3 w-100 mx-0">
                                    View Candidate's Profile
                                </Link> */}
                                <button
                                    type="button"
                                    disabled={data?.disCardStatus !== DiscardEnum.Active}
                                    className="btn btn-outline-primary w-100"
                                    onClick={() => discardByCandidate(data?.id)}
                                >
                                    {/* <i className="bi bi-trash" /> */}
                                    Discard this report
                                </button>
                            </div>
                            <div className="col ps-2">
                                <button
                                    type="button"
                                    style={{ pointerEvents: 'auto' }}
                                    title={
                                        data?.suspensionDecision === SuspensionDecisionEnum.approved ||
                                        data?.candidateSuspensionStatus === SuspendStatusEnum.suspended
                                            ? 'This candidate has been suspended'
                                            : ''
                                    }
                                    disabled={
                                        data?.suspensionDecision === SuspensionDecisionEnum.approved ||
                                        data?.disCardStatus !== DiscardEnum.Active ||
                                        data?.candidateSuspensionStatus === SuspendStatusEnum.suspended
                                    }
                                    className="btn btn-primary w-100"
                                    onClick={() => (setShowReportModal(false), setShowAdminModal(true))}
                                >
                                    Suspend this candidate
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};

export default ReportDetails;
