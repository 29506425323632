import { HighestEducationEnum, ManagerPermissionEnum } from '../../types/auth';
import { EmployeeJoinStatusEnum } from '../../types/employee';
import { OfferStateEnum } from '../../types/offer';
import { InviteStatusEnum } from '../../types/invitations';
import { SuspensionDecisionEnum } from '../../types/suspension';
import { consentWorkFlowDomains } from '../constants/constants';

export const getHighestEducationText = (value: HighestEducationEnum) => {
    switch (value) {
        case HighestEducationEnum.NohighSchoolDiplomaOrGed:
            return 'No high school diploma or GED';
        case HighestEducationEnum.HighSchoolDiplomaOrGed:
            return 'High school diploma or GED';
        case HighestEducationEnum.Vocational_TradeDegree_MilitaryTraining:
            return 'Vocational / Trade degree / Military training';
        case HighestEducationEnum.SomeCollege_NotEnrolledNow:
            return 'Some college - not enrolled now';
        case HighestEducationEnum.CurrentlyEnrolledInCollege:
            return 'Currently enrolled in college';
        case HighestEducationEnum.BachelorsOrAssociateDegree:
            return 'Bachelors or Associate degree';
        case HighestEducationEnum.GraduateWorkOrGraduateDegree:
            return 'Graduate work or Graduate degree';
        default:
            return 'unknown';
    }
};

export const getPermissionText = (value: ManagerPermissionEnum) => {
    switch (value) {
        case ManagerPermissionEnum.superAdmin:
            return 'Super Admin';
        case ManagerPermissionEnum.business:
            return 'Business';
        case ManagerPermissionEnum.management:
            return 'Management';
        case ManagerPermissionEnum.developer:
            return 'Developer';
        default:
            return 'unknown';
    }
};

export const getJoinStatusText = (value: EmployeeJoinStatusEnum) => {
    switch (value) {
        case EmployeeJoinStatusEnum.pending:
            return 'Pending';
        case EmployeeJoinStatusEnum.joined:
            return 'Joined';
        case EmployeeJoinStatusEnum.deleted:
            return 'Deleted';
        default:
            return 'unknown';
    }
};

export const getInviteStatusText = (value: InviteStatusEnum) => {
    switch (value) {
        case InviteStatusEnum.invited:
            return 'Invited';
        case InviteStatusEnum.joined:
            return 'Joined';
        case InviteStatusEnum.deleted:
            return 'Deleted';
        default:
            return 'unknown';
    }
};

export const getOfferStateText = (value: OfferStateEnum) => {
    switch (value) {
        case OfferStateEnum.pending:
            return 'pending';
        case OfferStateEnum.accepted:
            return 'accepted';
        case OfferStateEnum.rejected:
            return 'rejected';
        case OfferStateEnum.retracted:
            return 'retracted';
        case OfferStateEnum.expired:
            return 'expired';
        default:
            return 'unknown';
    }
};

export const getSuspensionDecisionText = (value: SuspensionDecisionEnum) => {
    switch (value) {
        case SuspensionDecisionEnum.active:
            return 'Active';
        case SuspensionDecisionEnum.rejected:
            return 'Rejected';
        case SuspensionDecisionEnum.approved:
            return 'Approved';
        default:
            return 'unknown';
    }
};

export function isConsentFeatureEnabled(consentWorkFlowSettings: any): boolean {
    // Get the current domain
    const domain = window.location.hostname;
    let featureEnabled = false;

    if (domain === consentWorkFlowDomains[0]) {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.dev;
    } else if (domain === consentWorkFlowDomains[1]) {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.prod;
    } else if (domain === consentWorkFlowDomains[2]) {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.test;
    } else if (domain === consentWorkFlowDomains[3]) {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.dev;
    } else if (domain === consentWorkFlowDomains[4]) {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.uat;
    } else {
        featureEnabled = consentWorkFlowSettings?.settings?.isFeatureEnabled?.dev;
    }
    return featureEnabled;
}
