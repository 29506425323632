import React from 'react';
import { Line } from 'react-chartjs-2';

interface LineChartProps {
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor?: string;
            borderColor?: string;
            borderWidth?: number;
        }[];
    };
    options?: {
        responsive?: boolean;
        maintainAspectRatio?: boolean;
        scales?: {
            yAxes?: {
                ticks?: {
                    beginAtZero?: boolean;
                };
            };
        };
    };
    chartStyle?: React.CSSProperties;
}

const LineChart = ({ data }: LineChartProps) => {
    const defaultOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    const mergedOptions = { ...defaultOptions };

    const chartStyle = {
        width: '500px',
        height: '400px',
        margin: 'auto',
    };

    return (
        <div className="line-chart" style={chartStyle}>
            <Line
                data={data}
                options={mergedOptions}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                placeholder={undefined}
            />
        </div>
    );
};

export default LineChart;
