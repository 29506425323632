import { useMemo, useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import avatar from '../../../assets/img/company-lg-icon.png';
import { CompanyProfileType, ScreenXCompanyProfileType, ScreenXSalesOwnerProfileType } from '../../../types/company';
// import { SuspendStatusEnum } from '../../../types/suspension';
import {
    EmployeeSalesOwnerFormType,
    EmployeeVerificationFormType,
    ScreenXEmployeeSalesOwnerFormType,
    ScreenXEmployeeVerificationFormType,
} from '../../../types/employeeVerification';
// import { employeeService } from '../../../lib/api/employee';
import { employeeSalesOwnerService, employeeVerificationService } from '../../../lib/api/employeeVerification';
import Swal from 'sweetalert2';
import PageLoader from '../../../components/loader';
import PdfViewer from '../../../components/pdf-viewer';
// import DocxViewer from '../../../components/docx-viewer';
import { getFileExtension } from '../../../lib/utils/file-extension';
// import { FALSE } from 'sass';
import { Image } from 'react-bootstrap';
import countriesData from '../../countries states cities/countries.json';
import StateCityCodeValue from '../../../components/profile/stateCityCodeValue';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { UserType } from '../../../types/auth';
import { SalesOwnerResponseType } from '../../../types/excelUpload';
import { excelUploadDataService } from '../../../lib/api/excelUpload';
import { Message, SuccessIcon, SweetAlertTitle } from '../../../lib/utils/messages';

type Props = {
    show: boolean;
    setShow: (key: boolean) => void;
    data: CompanyProfileType | ScreenXCompanyProfileType | undefined;
    getList: () => void;
    applicationName: string;
    salesOwnerData?: ScreenXSalesOwnerProfileType[];
};

interface Country {
    CountryId: string;
    CountryName: string;
    CountryPhoneCode: string;
}

const ActionModal: React.FC<Props> = ({ show, setShow, data, getList, applicationName, salesOwnerData }) => {
    const [disable, setDisable] = useState<boolean>(false);
    const [comment, setComment] = useState(data && data?.comments);
    const [commentValidation, setCommentValidation] = useState(false);
    const [salesOwnerValidation, setSalesOwnerValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showdocumentMessage, setShowdocumentMessage] = useState('');
    const [salesOwnerValue, setSalesOwnerValue] = useState<EmployeeSalesOwnerFormType>({
        companyId: '',
        referral: '',
    });
    const [buttonClicked, setButtonClicked] = useState<string>();
    const [toggle, setToggle] = useState(false);
    const [companyCountryPhoneCode, setCompanyCountryPhoneCode] = useState(data?.country! || '91');
    const [salesOwnerNameData, setSalesOwnerNameData] = useState<SalesOwnerResponseType[]>();

    const { user } = useSelector((state: RootState) => state.user);

    const hasUser = user as UserType;

    const [statusValue, setStatusValue] = useState<EmployeeVerificationFormType>({
        companyId: data?.id || '',
        creatorId: data?.creatorId || '',
        suspendStatus: 5,
        comments: data?.comments || comment || '',
    });
    const [showSalesOwnerDropdown, setShowSalesOwnerDropdown] = useState(false);

    const salesOwnerId = ['W0', 'W15293', 'W15284', 'W15269', 'W15270', 'W15178'];

    const disableConditionFunction = () => {
        if (
            // data?.suspendStatus === 2 ||
            data?.suspendStatus === 4 ||
            data?.suspendStatus === 0 ||
            data?.suspendStatus === 1
            // data?.suspendStatus === 5
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const setSalesOwnerName = (id: string | undefined) => {
        switch (id) {
            case 'W15178':
                return 'Nikitha';

            case 'W15270':
                return 'Teja';

            case 'W15269':
                return 'Eshwar';

            case 'W15284':
                return 'Sai Kiran';

            case 'W15332':
                return 'Rajiv Atluri';

            case 'W15325':
                return 'Shirini';

            case 'W15293':
                return 'Rohith';

            case 'W0':
                return 'Others';

            default:
                return 'NA';
        }
    };

    const statusFunction = () => {
        if (data?.suspendStatus === 0) {
            setShowdocumentMessage('Company is Approved');
        } else if (data?.suspendStatus === 1) {
            setShowdocumentMessage('Company has been suspended');
        } else if (data?.suspendStatus === 2) {
            setShowdocumentMessage('Document not uploaded');
        } else if (data?.suspendStatus === 3) {
            setShowdocumentMessage('Document uploaded');
        } else if (data?.suspendStatus === 4) {
            setShowdocumentMessage('Company has been rejected');
        } else if (data?.suspendStatus === 5) {
            setShowdocumentMessage('Need more evidence');
        } else if (data?.suspendStatus === 6) {
            setShowdocumentMessage('Partially approved');
        }
    };

    const resetValues = () => {
        setLoading(false);
        setShow(false);
        setSalesOwnerValue({
            companyId: '',
            referral: '',
        });
        setSalesOwnerValidation(false);
        setCommentValidation(false);
        setToggle(false);
    };

    const handleSubmit = async () => {
        try {
            if (applicationName === 'OfferX') {
                // if (salesOwnerValue.referral !== '' && statusValue.comments !== '') {
                if (buttonClicked === 'suspendStatus' && statusValue.comments !== '') {
                    setLoading(true);

                    await employeeVerificationService.update({ ...statusValue, id: statusValue?.companyId || '' });
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.UpdatedStatus,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (buttonClicked === 'salesOwner' && salesOwnerValue.referral !== '') {
                    setLoading(true);

                    await employeeSalesOwnerService.update({
                        ...salesOwnerValue,
                        id: salesOwnerValue?.companyId || '',
                    });
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.UpdatedSalesOwner,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            } else if (applicationName === 'ScreenX') {
                // if (salesOwnerValue.referral !== '' && statusValue.comments !== '') {
                if (buttonClicked === 'suspendStatus' && statusValue.comments !== '') {
                    setLoading(true);
                    const val: ScreenXEmployeeVerificationFormType = {
                        companyId: statusValue.companyId,
                        suspendStatus: statusValue.suspendStatus,
                        comments: statusValue.comments,
                        updatedByEmail: localStorage.getItem('AdminEmail') || '',
                        email: data?.creatorEmail || '',
                        companyName: data?.name || '',
                    };
                    await employeeVerificationService.updateScreenXCompanyStatus(val);
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.UpdatedStatus,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (buttonClicked === 'salesOwner' && salesOwnerValue.referral !== '') {
                    setLoading(true);
                    const val1: ScreenXEmployeeSalesOwnerFormType = {
                        companyId: salesOwnerValue.companyId,
                        referral: salesOwnerValue.referral,
                        updatedByEmail: localStorage.getItem('AdminEmail') || '',
                    };
                    await employeeSalesOwnerService.updateScreenX(val1);
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.UpdatedSalesOwner,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            }

            await getList();
            resetValues();
        } catch (err) {
            console.log('error employeeService create', err);
            resetValues();
        }
    };

    const salesOwnerShowing = () => {
        return showSalesOwnerDropdown ? (
            <></>
        ) : (
            <>
                {/* <div className="col">
                    <div>
                        <div className="fs-16 fw-600">Sales Owner</div>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <div className="fs-12">{setSalesOwnerName(data?.salesOwner) || 'NA'}</div>
                    </div>
                </div> */}
                <div className="my-3">
                    <div className="fs-14 fw-600">Sales Owner:</div>
                </div>
                <div className="d-flex align-items-center">
                    <i className="bi bi-person-badge lt-text-primary-alt me-2" />
                    <div className="fs-12">
                        {applicationName === 'OfferX'
                            ? setSalesOwnerName(data?.salesOwner) || 'NA'
                            : data?.salesOwner || 'NA'}
                    </div>
                </div>
            </>
        );
    };

    const showActionFooter = (salesOwner: string) => {
        if (salesOwnerNameData) {
            const result = salesOwnerNameData.filter((item) => {
                return item?.salesOwnerId === salesOwner;
            });

            if (result.length >= 1) {
                return true;
            }
        }
    };

    useEffect(() => {
        disableConditionFunction();
        statusFunction();
        if (applicationName === 'OfferX') {
            if (
                data?.salesOwner === null ||
                data?.salesOwner === '' ||
                data?.salesOwner === ' ' ||
                !salesOwnerId.includes(data?.salesOwner!)
            ) {
                setShowSalesOwnerDropdown(true);
            } else {
                setShowSalesOwnerDropdown(false);
            }
        } else {
            if (
                data?.salesOwner === null ||
                data?.salesOwner === '' ||
                data?.salesOwner === ' ' ||
                data?.salesOwner === 'null'
            ) {
                setShowSalesOwnerDropdown(true);
            } else {
                setShowSalesOwnerDropdown(false);
            }
        }
    }, [data, getList]);

    useEffect(() => {
        (async () => {
            const { resultObject: data } = await excelUploadDataService.getSalesOwnerNamesData();

            setSalesOwnerNameData(data);
        })();
    }, [show]);

    useEffect(() => {
        setComment(data?.comments);
    }, [show]);

    // useEffect(() => {
    //     if (isNaN(parseFloat(data?.country!))) {
    //         if (data?.country! === 'India') {
    //             setCompanyCountryPhoneCode('91');
    //         } else {
    //             const country = countriesData.find(
    //                 (country: Country) => country.CountryName.toLowerCase() === data?.country?.toLowerCase()
    //             );
    //             setCompanyCountryPhoneCode(country?.CountryPhoneCode!);
    //         }
    //     } else {
    //         const country = countriesData.find((country: Country) => country.CountryId === data?.country!);
    //         setCompanyCountryPhoneCode(country?.CountryPhoneCode!);
    //     }
    // }, [data?.country]);

    return (
        <div>
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setShow(false);
                    setSalesOwnerValue({
                        companyId: '',
                        referral: '',
                    });
                    setToggle(false);
                }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="lt-modal-content">
                    {loading ? (
                        <PageLoader text="Updating Status" />
                    ) : (
                        <>
                            <Modal.Header className="lt-modal-header employer-verification">
                                <Modal.Title className="modal-title w-100 mb-4 fs-16">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img
                                            src={data?.avatarUrl || avatar}
                                            alt=""
                                            className="avatar avatar--sm me-3"
                                        />
                                        <div className="text-start">
                                            <div className="fs-16 fw-700 text-capitalize">{data?.name}</div>
                                            <div>
                                                {showdocumentMessage ? (
                                                    <div
                                                        className={`${
                                                            data?.suspendStatus == 3 || data?.suspendStatus == 5
                                                                ? 'lt-text-primary-alt'
                                                                : data?.suspendStatus == 0
                                                                ? 'text-success'
                                                                : data?.suspendStatus == 2
                                                                ? 'lt-bg-orange'
                                                                : data?.suspendStatus == 6
                                                                ? 'lt-text-chocolate'
                                                                : 'text-danger'
                                                        } fs-14`}
                                                    >
                                                        {showdocumentMessage}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    title="Close"
                                    onClick={() => {
                                        setCommentValidation(false);
                                        setSalesOwnerValidation(false);
                                        setShow(false);
                                        setComment('');
                                        setSalesOwnerValue({
                                            companyId: '',
                                            referral: '',
                                        });
                                        setToggle(false);
                                    }}
                                ></button>
                            </Modal.Header>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (
                                        buttonClicked === 'suspendStatus' &&
                                        (comment === '' || comment === null || comment === undefined)
                                    ) {
                                        setCommentValidation(true);
                                    } else if (buttonClicked === 'salesOwner' && salesOwnerValue.referral === '') {
                                        setSalesOwnerValidation(true);
                                    } else {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Modal.Body className="lt-modal-body">
                                    {loading ? (
                                        <PageLoader text="Updating Status" />
                                    ) : (
                                        <>
                                            {
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-8 d-lg-flex ">
                                                            <div className="flex-column">
                                                                <div className="fs-16 fw-600 mb-2">
                                                                    Uploaded Document:
                                                                </div>
                                                                <div className="action-modal">
                                                                    <p className="fs-14 mx-2 my-1">
                                                                        {data?.fileUrl === '' ? (
                                                                            <></>
                                                                        ) : getFileExtension(data?.fileUrl || '') ===
                                                                          'pdf' ? (
                                                                            <PdfViewer url={data?.fileUrl || ''} />
                                                                        ) : (
                                                                            // eslint-disable-next-line jsx-a11y/img-redundant-alt

                                                                            <div className="action-img">
                                                                                <Image
                                                                                    className="img-fluid"
                                                                                    src={data?.fileUrl || ''}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                {data?.fileUrl === '' || data?.fileUrl === null ? (
                                                                    <div className="text-center">
                                                                        <div>
                                                                            <i className="bi bi-file-earmark-excel fs-52 mb-3"></i>
                                                                        </div>
                                                                        <div>Documents not uploaded</div>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="fs-16 fw-600 mb-3">Company Details:</div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">CIN</div>
                                                                <div className="fs-12">{data?.cinNumber || 'NA'}</div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">GST Number</div>
                                                                <div className="fs-12">
                                                                    {
                                                                        (applicationName === 'OfferX'
                                                                            ? data?.gstNumber?.value
                                                                            : data?.gstNumber ||
                                                                              'NA') as React.ReactNode
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">
                                                                    Company Name As In GST Return
                                                                </div>
                                                                <div className="fs-12">{data?.name || 'NA'}</div>
                                                            </div>
                                                            <div className="my-3">
                                                                <div className="fs-14 fw-600">Contact Details:</div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt lt-text-primary-alt me-2"></i>
                                                                {applicationName === 'OfferX' ? (
                                                                    <>
                                                                        <div
                                                                            className="fs-12 text-capitalize text-ellipsis"
                                                                            title={
                                                                                data?.companyAddress
                                                                                    ?.replaceAll('%', ', ')
                                                                                    .toLocaleLowerCase() +
                                                                                    ', ' +
                                                                                    data?.state?.toLocaleLowerCase() +
                                                                                    ', ' +
                                                                                    data?.cityDistrict?.toLocaleLowerCase() +
                                                                                    ', ' +
                                                                                    data?.postalCode || 'NA'
                                                                            }
                                                                        >
                                                                            {data?.companyAddress &&
                                                                                data?.companyAddress
                                                                                    ?.replaceAll('%', ', ')
                                                                                    .toLocaleLowerCase() + ', '}

                                                                            {data && (
                                                                                <StateCityCodeValue
                                                                                    id={String(Math.random()) + 1}
                                                                                    countryValue={data?.country}
                                                                                    cityValue={data?.cityDistrict}
                                                                                    stateValue={data?.state}
                                                                                />
                                                                            )}
                                                                            {data?.postalCode || 'NA'}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div
                                                                            className="fs-12 text-capitalize text-ellipsis"
                                                                            title={
                                                                                (data?.cityDistrict === 'null' ||
                                                                                data?.cityDistrict === null ||
                                                                                data?.cityDistrict === ''
                                                                                    ? ''
                                                                                    : data?.cityDistrict.toLocaleLowerCase() +
                                                                                      ', ') +
                                                                                (data?.state === 'null' ||
                                                                                data?.state === null ||
                                                                                data?.state === ''
                                                                                    ? ''
                                                                                    : data?.state.toLocaleLowerCase() +
                                                                                      ', ') +
                                                                                (data?.country === 'null' ||
                                                                                data?.country === null ||
                                                                                data?.country === ''
                                                                                    ? ''
                                                                                    : data?.country.toLocaleLowerCase() +
                                                                                      ', ') +
                                                                                (data?.postalCode === 'null' ||
                                                                                data?.postalCode === null ||
                                                                                data?.postalCode === ''
                                                                                    ? ''
                                                                                    : data?.postalCode)
                                                                            }
                                                                        >
                                                                            {(data?.cityDistrict === 'null' ||
                                                                            data?.cityDistrict === null ||
                                                                            data?.cityDistrict === ''
                                                                                ? ''
                                                                                : data?.cityDistrict.toLocaleLowerCase() +
                                                                                  ', ') +
                                                                                (data?.state === 'null' ||
                                                                                data?.state === null ||
                                                                                data?.state === ''
                                                                                    ? ''
                                                                                    : data?.state.toLocaleLowerCase() +
                                                                                      ', ') +
                                                                                (data?.country === 'null' ||
                                                                                data?.country === null ||
                                                                                data?.country === ''
                                                                                    ? ''
                                                                                    : data?.country.toLocaleLowerCase()) ||
                                                                                'NA'}

                                                                            <br />
                                                                            {data?.postalCode === 'null' ||
                                                                            data?.postalCode === null ||
                                                                            data?.postalCode === ''
                                                                                ? ''
                                                                                : data?.postalCode}
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-telephone lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12">
                                                                    {applicationName === 'OfferX' ? (
                                                                        <>
                                                                            {data?.phone ? (
                                                                                <>
                                                                                    {/* +{companyCountryPhoneCode} {data?.phone} */}
                                                                                    {data?.compCountryCode &&
                                                                                        '+' +
                                                                                            data?.compCountryCode}{' '}
                                                                                    {data?.phone}
                                                                                </>
                                                                            ) : (
                                                                                'NA'
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>{data?.phone ? <>{data?.phone}</> : 'NA'}</>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="my-3">
                                                                <div className="fs-14 fw-600">
                                                                    Company Email and Website:
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-envelope lt-text-primary-alt me-2"></i>
                                                                <div
                                                                    className="fs-12 text-ellipsis"
                                                                    title={data?.email || 'NA'}
                                                                >
                                                                    {data?.email || 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <i className="bi bi-globe2 lt-text-primary-alt me-2"></i>
                                                                <div
                                                                    className="fs-12 text-ellipsis"
                                                                    title={data?.website || 'NA'}
                                                                >
                                                                    {data?.website || 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="my-3">
                                                                <div className="fs-14 fw-600">User Details:</div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-envelope lt-text-primary-alt me-2"></i>
                                                                <div
                                                                    className="fs-12 text-ellipsis"
                                                                    title={data?.creatorEmail}
                                                                >
                                                                    {data?.creatorEmail || 'NA'}
                                                                </div>
                                                            </div>

                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-phone lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12">
                                                                    {applicationName === 'OfferX' ? (
                                                                        <>
                                                                            {' '}
                                                                            {/* +{companyCountryPhoneCode} {data?.ownerPhoneNumber} */}
                                                                            {data?.ownerCountryCode &&
                                                                                '+' + data?.ownerCountryCode}{' '}
                                                                            {data?.ownerPhoneNumber}
                                                                        </>
                                                                    ) : (
                                                                        <>{data?.ownerPhoneNumber}</>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {salesOwnerShowing()}
                                                            {/* {data?.suspendStatus !== SuspendStatusEnum.active &&
                                                            data?.suspendStatus !== SuspendStatusEnum.rejected ? (
                                                                data?.salesOwner === null ||
                                                                data?.salesOwner === '' ||
                                                                data?.salesOwner === ' ' ? (
                                                                    <>
                                                                        <div className="my-3">
                                                                            <div className="fs-14 fw-600">
                                                                                Select:
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <select
                                                                                    className="form-select cursor-pointer"
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value === '') {
                                                                                            setSalesOwnerValidation(
                                                                                                true
                                                                                            );
                                                                                            setSalesOwnerValue({
                                                                                                companyId: data?.id,
                                                                                                referral:
                                                                                                    e.target.value ||
                                                                                                    data?.salesOwner,
                                                                                            });
                                                                                        } else {
                                                                                            setSalesOwnerValue({
                                                                                                companyId: data?.id,
                                                                                                referral:
                                                                                                    e.target.value ||
                                                                                                    data?.salesOwner,
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <option value={''}>Select</option>
                                                                                    
                                                                                    <option value={'W0'}>Others</option>
                                                                                </select>
                                                                                {salesOwnerValue.referral === '' &&
                                                                                salesOwnerValidation ? (
                                                                                    <p className="text-danger fs-12">
                                                                                        Please select a sales owner.
                                                                                    </p>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )
                                                            ) : (
                                                                <></>
                                                            )} */}
                                                            {/* <div className="row">
                                                                <input type="checkbox" name="" id="" /> Check me
                                                            </div> */}
                                                        </div>

                                                        {/* Toggle switch starts */}
                                                        <div
                                                            className={
                                                                showActionFooter(hasUser?.id || '')
                                                                    ? 'd-none'
                                                                    : 'd-block'
                                                            }
                                                        >
                                                            <div className="border-bottom mt-3"></div>

                                                            {showSalesOwnerDropdown ? (
                                                                <div className="col-md-12 mt-3">
                                                                    <div className="d-flex justify-content-between toggle-switch">
                                                                        <div className="fs-16 fw-600 mb-2">
                                                                            Update Sales Owner
                                                                        </div>
                                                                        <div>
                                                                            <input
                                                                                type="checkbox"
                                                                                id="toggle-btn"
                                                                                onClick={() => {
                                                                                    setToggle(!toggle);
                                                                                }}
                                                                            />
                                                                            <label
                                                                                className="toggle-btn-label"
                                                                                htmlFor="toggle-btn"
                                                                            ></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            {toggle ? (
                                                                <>
                                                                    <div className="d-flex justify-content-between mt-3">
                                                                        <select
                                                                            className="form-select cursor-pointer w-auto"
                                                                            onChange={(e) => {
                                                                                if (e.target.value === '') {
                                                                                    setSalesOwnerValidation(true);
                                                                                    setSalesOwnerValue({
                                                                                        companyId: data?.id,
                                                                                        referral: e.target.value,
                                                                                    });
                                                                                } else {
                                                                                    setSalesOwnerValue({
                                                                                        companyId: data?.id,
                                                                                        referral: e.target.value,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <option value={''}>Select</option>
                                                                            {salesOwnerData ? (
                                                                                salesOwnerData.map(
                                                                                    (
                                                                                        item: ScreenXSalesOwnerProfileType
                                                                                    ) => {
                                                                                        return (
                                                                                            <option
                                                                                                value={
                                                                                                    item?.walleroEmpId
                                                                                                }
                                                                                            >
                                                                                                {item?.name}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )
                                                                            ) : (
                                                                                <>
                                                                                    <option value={'W15178'}>
                                                                                        Nikitha
                                                                                    </option>
                                                                                    <option value={'W15269'}>
                                                                                        Eshwar
                                                                                    </option>
                                                                                    <option value={'W15284'}>
                                                                                        Sai Kiran
                                                                                    </option>
                                                                                    <option value={'W15325'}>
                                                                                        Shirini
                                                                                    </option>
                                                                                    <option value={'W15293'}>
                                                                                        Rohith
                                                                                    </option>
                                                                                    <option value={'W15332'}>
                                                                                        Rajiv
                                                                                    </option>
                                                                                    <option value={'W0'}>Others</option>
                                                                                </>
                                                                            )}
                                                                        </select>
                                                                        <button
                                                                            type="submit"
                                                                            onClick={() => {
                                                                                if (salesOwnerValue.referral === '') {
                                                                                    setSalesOwnerValidation(true);
                                                                                } else {
                                                                                    setButtonClicked('salesOwner');
                                                                                    // handleSubmit();
                                                                                }
                                                                            }}
                                                                            className="btn btn-primary"
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                        {/* Toggle switch ends */}
                                                                    </div>

                                                                    {salesOwnerValue.referral === '' &&
                                                                    salesOwnerValidation ? (
                                                                        <p className="text-danger fs-12">
                                                                            Please select a sales owner.
                                                                        </p>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-md-12 mt-3">
                                                                        <div className="fs-16 fw-600 mb-2">
                                                                            Comments
                                                                            <span className="lt-text-error">*</span>
                                                                        </div>
                                                                        {disable ? (
                                                                            <div className="row">
                                                                                <div className="col-8">
                                                                                    <p className="fs-14">
                                                                                        {data?.comments || 'NA'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                    <textarea
                                                                                        value={comment}
                                                                                        key={Number(show)}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.value === '') {
                                                                                                setCommentValidation(
                                                                                                    true
                                                                                                );
                                                                                                setComment(
                                                                                                    e.target.value
                                                                                                );
                                                                                            } else {
                                                                                                setComment(
                                                                                                    e.target.value
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        className="form-control mb-2"
                                                                                        placeholder="Your comments will be shared to client over email"
                                                                                        maxLength={250}
                                                                                    />
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <div className="col-md-9 text-danger fs-12">
                                                                                            {(comment === '' ||
                                                                                                comment === null ||
                                                                                                comment ===
                                                                                                    undefined) &&
                                                                                            commentValidation ? (
                                                                                                <p className="float-left">
                                                                                                    Comment is required.
                                                                                                </p>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="col-md-3 fs-12">
                                                                                            <p className="float-right">
                                                                                                {comment?.length || 0} /
                                                                                                250
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    {disable ? (
                                                                        // showSalesOwnerDropdown &&
                                                                        data?.suspendStatus !== 0 &&
                                                                        data?.suspendStatus !== 1 &&
                                                                        // showSalesOwnerDropdown &&
                                                                        data?.suspendStatus !== 4 ? (
                                                                            <div className="d-lg-flex m-0 mt-3 justify-content-end">
                                                                                <button
                                                                                    // disabled={disable}
                                                                                    type="submit"
                                                                                    className="btn btn-outline-brown me-3 m-w-100"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 6,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Partially Approve
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ) : data?.suspendStatus === 2 &&
                                                                      data?.companyInviteType === 0 ? (
                                                                        <>
                                                                            <div className="d-lg-flex m-0 mt-3 justify-content-end">
                                                                                <button
                                                                                    type="submit"
                                                                                    disabled={disable}
                                                                                    className="btn btn-outline-brown me-0 m-w-100"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 6,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Partially Approve
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="d-lg-flex m-0 mt-3 justify-content-end">
                                                                                <button
                                                                                    type="submit"
                                                                                    disabled={
                                                                                        data?.suspendStatus === 6
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    className="btn btn-outline-brown me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 6,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {data?.suspendStatus === 6
                                                                                        ? 'Partially Approved'
                                                                                        : 'Partially Approve'}
                                                                                </button>
                                                                                <button
                                                                                    type="submit"
                                                                                    disabled={disable}
                                                                                    className="btn btn-outline-danger me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 4,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Reject
                                                                                </button>
                                                                                {/* {data?.companyInviteType !== 1 && ( */}
                                                                                <button
                                                                                    type="submit"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 5,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                    disabled={
                                                                                        data?.suspendStatus === 5
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    className="btn btn-outline-primary me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                                                >
                                                                                    Need More Evidence
                                                                                </button>
                                                                                {/* )} */}
                                                                                <button
                                                                                    type="submit"
                                                                                    onClick={() => {
                                                                                        setStatusValue({
                                                                                            companyId: data?.id || '',
                                                                                            creatorId:
                                                                                                data?.creatorId || '',
                                                                                            suspendStatus: 0,
                                                                                            comments: comment || '',
                                                                                        });
                                                                                        setButtonClicked(
                                                                                            'suspendStatus'
                                                                                        );
                                                                                    }}
                                                                                    disabled={disable}
                                                                                    className="btn btn-outline-success m-w-100"
                                                                                >
                                                                                    Approve
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )}
                                </Modal.Body>
                                {/* <Modal.Footer className="lt-modal-footer">
                                    {disable ? (
                                        showSalesOwnerDropdown &&
                                        data?.suspendStatus !== 0 &&
                                        showSalesOwnerDropdown &&
                                        data?.suspendStatus !== 4 ? (
                                            <div className="d-lg-flex m-0 mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-warning me-3 m-w-100"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 6,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                >
                                                    Partially Approved
                                                </button>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    ) : data?.suspendStatus === 2 ? (
                                        <>
                                            <div className="d-lg-flex m-0 mt-3">
                                                <button
                                                    type="submit"
                                                    disabled={disable}
                                                    className="btn btn-outline-warning me-0 m-w-100"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 6,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                >
                                                    Partially Approved
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-lg-flex m-0 mt-3">
                                                <button
                                                    type="submit"
                                                    disabled={disable}
                                                    className="btn btn-outline-warning me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 6,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                >
                                                    Partially Approved
                                                </button>
                                                <button
                                                    type="submit"
                                                    disabled={disable}
                                                    className="btn btn-outline-danger me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 4,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                >
                                                    Reject
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 5,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                    disabled={disable}
                                                    className="btn btn-outline-primary me-3 m-w-100 mb-lg-0 mb-md-0 mb-3"
                                                >
                                                    Need more information
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 0,
                                                            comments: comment || '',
                                                        });
                                                        setButtonClicked('suspendStatus');
                                                    }}
                                                    disabled={disable}
                                                    className="btn btn-outline-success m-w-100"
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </Modal.Footer> */}
                            </form>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ActionModal;
