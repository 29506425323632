export const urlPrefix = '/';

export const routes = {
    main: `${urlPrefix}`,
    dashboard: `${urlPrefix}dashboard`,
    companyList: `${urlPrefix}companies/company-list`,
    hrmsCompanyList: `${urlPrefix}companies/hrms-company-list`,
    // companyListNew: `${urlPrefix}company-list-new`,
    reportedCompanyList: `${urlPrefix}companies/reported-companies`,
    companyRegistration: `${urlPrefix}companies/company-registration`,
    companyVerification: `${urlPrefix}companies/company-verification`,
    userManagement: `${urlPrefix}user-management`,
    CompanyView: `${urlPrefix}company-list/:id`,
    candidateList: `${urlPrefix}candidates/candidate-list`,
    reportedCandidateList: `${urlPrefix}candidates/reported-candidates`,
    candidateView: `${urlPrefix}candidate-list/:id`,
    policy: `${urlPrefix}policy`,
    affiliateUser: `${urlPrefix}affiliate/users`,
    affiliateCompanies: `${urlPrefix}affiliate/companies`,
};
