import React, { useState, useEffect } from 'react';
import { companyService } from '../../lib/api/company';
import { CompanyCountType } from '../../types/company';
import PageLoader from '../../components/loader';
import { getColorStatus } from '../../lib/utils/cards-get-color-status';

type Props = {
    rerenderKey: number;
    applicationName: string;
};

const Statistics: React.FC<Props> = ({ rerenderKey, applicationName }) => {
    const [countsData, setCountsData] = useState<CompanyCountType | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                if(applicationName === "OfferX")
                {
                    setLoading(true);
                    const { resultObject: data } = await companyService.getCount();
                    setCountsData(data);
                }
                else{
                    setLoading(true);
                    const {
                        result: { companies, count },
                    }  = await companyService.getScreenXCompanyList();
                    const activeCompanyCount = companies.filter((value) => value.isActive === true).length;
                    const val: CompanyCountType ={
                        totalCompaniesCount: companies.length,
                        totalCompaniesGrowth:0,
                        activeCompaniesCount: activeCompanyCount,
                        activeCompaniesGrowth:0,
                        suspendedCompaniesCount:0,
                        suspendedCompaniesGrowth:0,
                        reportedByCandidatesCompaniesCount:0,
                        reportedByCandidatesCompaniesGrowth:0
                    }
                    setCountsData(val);
                }
            } catch (error) {
                console.log('Company count error', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [rerenderKey]);

    return (
        <>
            {countsData ? (
                <div className="row mt-4">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Total companies
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Total companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary"> {countsData.totalCompaniesCount}</div>
                            {/* <div>
                                <span className={getColorStatus(countsData.totalCompaniesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.totalCompaniesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.totalCompaniesGrowth}%
                                </span>
                                <span className='ms-1'> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Active companies
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Active companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{countsData.activeCompaniesCount}</div>
                            {/* <div>
                                <span className={getColorStatus(countsData.activeCompaniesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.activeCompaniesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} />{countsData.activeCompaniesGrowth}%
                                </span>
                                <span className='ms-1'> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    {applicationName === "OfferX" &&
                        <>
                            <div className="col-lg-3 col-xl-3">
                                <div className="card lt-shape-card p-3 mb-4">
                                    <div className="fw-600 fs-14">
                                        <div className="lt-dashboard-text">
                                            Suspended companies
                                            <i className="bi bi-info-circle lt-tooltip ms-1">
                                                <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                                    Suspended companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                                </span>
                                            </i>
                                        </div>
                                        <div className="fs-28 lt-dashboard-icon">
                                            <i className="lt-dashboard-text bi bi-building me-0" />
                                        </div>
                                    </div>
                                    <div className="fw-400 fs-32 lt-text-link-primary">
                                        {countsData.suspendedCompaniesCount}
                                    </div>
                                    {/* <div>
                                <span className={getColorStatus(countsData.suspendedCompaniesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.suspendedCompaniesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.suspendedCompaniesGrowth}%
                                </span>
                                <span className='ms-1'> than last month</span>
                            </div> */}
                                </div>
                            </div>
                        </>
                    }
                    {applicationName === "OfferX" &&
                        <>
                            <div className="col-lg-3 col-xl-3">
                                <div className="card lt-shape-card p-3 mb-4">
                                    <div className="fw-600 fs-14">
                                        <div className="lt-dashboard-text">
                                            Reported by candidates
                                            <i className="bi bi-info-circle lt-tooltip ms-1">
                                                <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">
                                                    Reported by candidates in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                                </span>
                                            </i>
                                        </div>
                                        <div className="fs-28 lt-dashboard-icon">
                                            <i className="lt-dashboard-text bi bi-people me-0" />
                                        </div>
                                    </div>
                                    <div className="fw-400 fs-32 lt-text-link-primary">
                                        {countsData.reportedByCandidatesCompaniesCount}
                                    </div>
                                    {/* <div>
                                <span className={getColorStatus(countsData.reportedByCandidatesCompaniesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.reportedByCandidatesCompaniesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.reportedByCandidatesCompaniesGrowth}%
                                </span>
                                <span className='ms-1'> than last month</span>
                            </div> */}
                                </div>
                            </div>
                        </>
                    }
                </div>
            ) : null}
        </>
    );
};

export default Statistics;
