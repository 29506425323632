import { useMemo, useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import avatar from '../../../assets/img/company-lg-icon.png';
import { CompanyProfileType } from '../../../types/company';
import { SuspendStatusEnum } from '../../../types/suspension';
import { EmployeeVerificationFormType } from '../../../types/employeeVerification';
import { employeeService } from '../../../lib/api/employee';
import { employeeVerificationService } from '../../../lib/api/employeeVerification';
import Swal from 'sweetalert2';
import PageLoader from '../../../components/loader';
import PdfViewer from '../../../components/pdf-viewer';
import DocxViewer from '../../../components/docx-viewer';
import { getFileExtension } from '../../../lib/utils/file-extension';
import { FALSE } from 'sass';
import { Image } from 'react-bootstrap';
import countriesData from '../../countries states cities/countries.json';
import { Message, SuccessIcon, SweetAlertTitle } from '../../../lib/utils/messages';

type Props = {
    show: boolean;
    setShow: (key: boolean) => void;
    data: CompanyProfileType | undefined;
    getList: () => void;
};

interface Country {
    CountryId: string;
    CountryName: string;
    CountryPhoneCode: string;
}

const ActionModal: React.FC<Props> = ({ show, setShow, data, getList }) => {
    // const getVerificationData = useMemo(() => console.log(data, 'comoedata'), [data]);
    const [disable, setDisable] = useState<boolean>(false);
    const [comment, setComment] = useState(data && data?.comments);
    const [commentValidation, setCommentValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showdocumentMessage, setShowdocumentMessage] = useState('');
    const [statusValue, setStatusValue] = useState<EmployeeVerificationFormType>({
        companyId: data?.id || '',
        creatorId: data?.creatorId || '',
        suspendStatus: 5,
        comments: data?.comments || comment || '',
    });
    const [companyCountryPhoneCode, setCompanyCountryPhoneCode] = useState(data?.country || '91');


    const disableConditionFunction = () => {
        if (
            data?.suspendStatus === 2 ||
            data?.suspendStatus === 4 ||
            data?.suspendStatus === 0 ||
            data?.suspendStatus === 1 ||
            data?.suspendStatus === 5
        ) {
            setDisable(true);
        } else {
            setDisable(false);
        }
    };

    const statusFunction = () => {
        if (data?.suspendStatus === 0) {
            setShowdocumentMessage('Company is Approved');
        } else if (data?.suspendStatus === 1) {
            setShowdocumentMessage('Company has been suspended');
        } else if (data?.suspendStatus === 2) {
            setShowdocumentMessage('Document not uploaded');
        } else if (data?.suspendStatus === 3) {
            setShowdocumentMessage('Document uploaded');
        } else if (data?.suspendStatus === 4) {
            setShowdocumentMessage('Company has been rejected');
        } else if (data?.suspendStatus === 5) {
            setShowdocumentMessage('Need more evidence');
        } else if (data?.suspendStatus === 6) {
            setShowdocumentMessage('Partially approved');
        }
    };

    const handleSubmit = async () => {
        try {
            if (true) {
                setLoading(true);

                await employeeVerificationService.update({ ...statusValue, id: statusValue?.companyId || '' });
                Swal.fire({
                    icon: SuccessIcon,
                    title: SweetAlertTitle.Success,
                    text: Message.UpdatedStatus,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            await getList();
            setLoading(false);
            setShow(false);
        } catch (err) {
            console.log('error employeeService create', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        disableConditionFunction();
        statusFunction();
    }, [data, getList]);

    useEffect(() => {
        setComment(data?.comments);
    }, [show]);

    useEffect(() => {
        if (isNaN(parseFloat(data?.country!))) {
            if (data?.country! === 'India') {
                setCompanyCountryPhoneCode('91');
            } else {
                const country = countriesData.find(
                    (country: Country) => country.CountryName.toLowerCase() === data?.country?.toLowerCase()
                );
                setCompanyCountryPhoneCode(country?.CountryPhoneCode!);
            }
        } else {
            const country = countriesData.find((country: Country) => country.CountryId === data?.country!);
            setCompanyCountryPhoneCode(country?.CountryPhoneCode!);
        }
    }, [data?.country]);

    return (
        <div>
            <Modal
                size="lg"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className="lt-modal-content">
                    {loading ? (
                        <PageLoader text="Updating Status" />
                    ) : (
                        <>
                            <Modal.Header className="lt-modal-header employer-verification">
                                <Modal.Title className="modal-title w-100 mb-4 fs-16">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <img
                                            src={data?.avatarUrl || avatar}
                                            alt=""
                                            className="avatar avatar--sm me-3"
                                        />
                                        <div className="text-start">
                                            <div className="fs-16 fw-700 text-capitalize">{data?.name}</div>
                                            <div>
                                                {showdocumentMessage ? (
                                                    <div
                                                        className={`${
                                                            data?.suspendStatus == 3 || data?.suspendStatus == 5
                                                                ? 'lt-text-primary-alt'
                                                                : data?.suspendStatus == 0
                                                                ? 'text-success'
                                                                : data?.suspendStatus == 2
                                                                ? 'lt-bg-orange'
                                                                : data?.suspendStatus == 6
                                                                ? 'lt-text-chocolate'
                                                                : 'text-danger'
                                                        } fs-14`}
                                                    >
                                                        {showdocumentMessage}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Title>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    title="Close"
                                    onClick={() => {
                                        setCommentValidation(false);
                                        setShow(false);
                                        setComment('');
                                    }}
                                ></button>
                            </Modal.Header>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    if (comment === '') {
                                        setCommentValidation(true);
                                    } else {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Modal.Body className="lt-modal-body">
                                    {loading ? (
                                        <PageLoader text="Updating Status" />
                                    ) : (
                                        <>
                                            {
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="fs-16 fw-600 mb-2">Uploaded Document:</div>
                                                            {data?.fileUrl === '' ? (
                                                                <div className="mt-5 text-center">
                                                                    <div>
                                                                        <i className="bi bi-file-earmark-excel fs-52 mb-3"></i>
                                                                    </div>
                                                                    <div>Documents not uploaded</div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <div className="action-modal">
                                                                <p className="fs-14 mx-2 my-1">
                                                                    {data?.fileUrl === '' ? (
                                                                        <></>
                                                                    ) : getFileExtension(data?.fileUrl || '') ===
                                                                      'pdf' ? (
                                                                        <PdfViewer url={data?.fileUrl || ''} />
                                                                    ) : (
                                                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt

                                                                        <div className="action-img">
                                                                            <Image
                                                                                className="img-fluid"
                                                                                src={data?.fileUrl || ''}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="fs-16 fw-600 mb-3">Company Details:</div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">CIN</div>
                                                                <div className="fs-12">{data?.cinNumber || 'NA'}</div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">GST Number</div>
                                                                <div className="fs-12">
                                                                    {data?.gstNumber.value || 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="mb-3">
                                                                <div className="fs-14 fw-600">
                                                                    Company Name As In GST Return
                                                                </div>
                                                                <div className="fs-12">{data?.name || 'NA'}</div>
                                                            </div>
                                                            <div className="my-3">
                                                                <div className="fs-14 fw-600">Contact Details:</div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-geo-alt lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12 text-capitalize">
                                                                    {data?.companyAddress?.toLocaleLowerCase() +
                                                                        ', ' +
                                                                        data?.state?.toLocaleLowerCase() +
                                                                        ', ' +
                                                                        data?.cityDistrict?.toLocaleLowerCase() +
                                                                        ', ' +
                                                                        data?.postalCode || 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-telephone lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12">
                                                                    {data?.phone ? (
                                                                        <>
                                                                            +{companyCountryPhoneCode} {data?.phone}
                                                                        </>
                                                                    ) : (
                                                                        'NA'
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <i className="bi bi-envelope lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12">{data?.email || 'NA'}</div>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <i className="bi bi-globe2 lt-text-primary-alt me-2"></i>
                                                                <div className="fs-12">{data?.website || 'NA'}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <div className="fs-16 fw-600 mb-2">
                                                                Comments<span className="lt-text-error">*</span>
                                                            </div>
                                                            {disable ? (
                                                                <p className="fs-14">{data?.comments || 'NA'}</p>
                                                            ) : (
                                                                <>
                                                                    <textarea
                                                                        value={comment}
                                                                        key={Number(show)}
                                                                        onChange={(e) => {
                                                                            if (e.target.value === '') {
                                                                                setCommentValidation(true);
                                                                                setComment(e.target.value);
                                                                            } else {
                                                                                setComment(e.target.value);
                                                                            }
                                                                        }}
                                                                        className="form-control mb-2"
                                                                        placeholder="Your comments will be shared to client over email"
                                                                        disabled={disable}
                                                                        maxLength={250}
                                                                    />
                                                                    <div className="fs-12 float-right">
                                                                        {comment?.length} / 250
                                                                    </div>
                                                                </>
                                                            )}
                                                            {comment === '' && commentValidation ? (
                                                                <p className="text-danger fs-14">
                                                                    Comment is required.
                                                                </p>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="row">
                                                        <input type="checkbox" name="" id="" /> Check me
                                                    </div> */}
                                                </>
                                            }
                                        </>
                                    )}
                                </Modal.Body>
                                <Modal.Footer className="lt-modal-footer">
                                    {disable ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div className="d-flex m-0 mt-3">
                                                <button
                                                    type="submit"
                                                    disabled={disable}
                                                    className="btn btn-outline-danger me-3"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 4,
                                                            comments: comment || '',
                                                        });
                                                    }}
                                                >
                                                    Reject
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 5,
                                                            comments: comment || '',
                                                        });
                                                    }}
                                                    disabled={disable}
                                                    className="btn btn-outline-primary me-3"
                                                >
                                                    Need More Evidence
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => {
                                                        setStatusValue({
                                                            companyId: data?.id || '',
                                                            creatorId: data?.creatorId || '',
                                                            suspendStatus: 0,
                                                            comments: comment || '',
                                                        });
                                                    }}
                                                    disabled={disable}
                                                    className="btn btn-outline-success"
                                                >
                                                    Approve
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </Modal.Footer>
                            </form>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ActionModal;
