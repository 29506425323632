import React from 'react';

type Props = {
    value: number;
    size?: number;
    onChange?: (value: number) => void;
};

const RatingStar: React.FC<Props> = ({ value, size = 28, onChange }) => {
    const roundValue = Math.round(value * 2) / 2;
    const percentage = (roundValue * 100) / 5;
    return (
        <div className="rating" style={{ width: size * 5, height: size, backgroundSize: size }}>
            <div className="rating__progress" style={{ width: `${percentage}%`, backgroundSize: size }} />
            {onChange && (
                <div className="rating__buttons">
                    {[...Array(5)].map((item, i) => (
                        <button
                            key={i}
                            type="button"
                            onClick={() => {
                                onChange(i + 1);
                            }}
                            className="rating__btn"
                            style={{ width: size, height: size }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default RatingStar;
