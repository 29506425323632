import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageLoader from '../../components/loader';
import Table from '../../components/table';
import { invitationsService } from '../../lib/api/invitations';
import { CompanyLeadStatus, InviteStatusEnum } from '../../types/invitations';
import { getInviteStatusText } from '../../lib/utils/dictionary';
import CreateInvite from './modals/create-company';
import imgNoResult from '../../assets/icons/offer-no-result.svg';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { RootState } from '../../redux';
import { UserType } from '../../types/auth';
import { useDispatch, useSelector } from 'react-redux';
import CandidateExcelBulkUpload from './company-bulk-register';
import { CompanyListRequestType, CompanyProfileType } from '../../types/company';
import { SortTypeEnum } from '../../types';
import { companyService } from '../../lib/api/company';
import { ErrorIcon, Message, SuccessIcon, SweetAlertTitle } from '../../lib/utils/messages';

const CompanyRegistration = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const [data, setData] = useState<CompanyProfileType[]>([]);
    const hasUser = user as UserType;
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [loading, setLoading] = useState(false);
    const [isExitReviewEnabled, setIsExitReviewEnabled] = useState<boolean | null>(null);
    const [loadingResendStatus, setLoadingResendStatus] = useState<'loading' | 'success' | 'error' | null>(null);
    const [activeResendId, setActiveResendId] = useState('');
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(data.length);

    const [invitationHeaderText, setInvitationHeaderText] = useState('Company Registrations');

    const dispatch = useDispatch();

    // const onboardAndExitWorkFlowSettings = useSelector((state: RootState) => state.onboardingExitReviewsFlow);

    const [showBulkUploadPage, setShowBulkUploadPage] = useState(false);

    const getCompanyLeadStatusName = (statusNumber: CompanyLeadStatus) => {
        switch (statusNumber) {
            case CompanyLeadStatus.Uploaded:
                return 'Uploaded';

            case CompanyLeadStatus.InviteSent:
                return 'Invite sent';

            case CompanyLeadStatus.RequestToOnboard:
                return 'Requested to Onboard';

            case CompanyLeadStatus.Approved:
                return 'Approved';

            case CompanyLeadStatus.Rejected:
                return 'Rejected';

            case CompanyLeadStatus.Onboard:
                return 'Onboarded';

            default:
                return 'NA';
        }
    };

    const setSalesOwnerName = (id: string | undefined) => {
        switch (id) {
            case 'W15178':
                return 'Nikitha';

            case 'W15270':
                return 'Teja';

            case 'W15269':
                return 'Eshwar';

            case 'W15284':
                return 'Sai Kiran';

            case 'W15332':
                return 'Rajiv Atluri';

            case 'W15325':
                return 'Shirini';

            case 'W15293':
                return 'Rohith';

            case 'W0':
                return 'Others';

            default:
                return 'NA';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'name',
                title: 'Company Name',
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item.name}>
                        {item.name}
                    </div>
                ),
            },
            {
                dataIndex: 'registeredBy',
                title: 'Registered By',
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis" title={item.registeredBy}>
                        {item.registeredBy.trim() || 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'ownerEmail',
                title: 'Email',
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis" title={item?.ownerEmail}>
                        {item?.ownerEmail || 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'contactNumber',
                title: 'Contact Number',
                render: (item: any) => (
                    <span title={item.contactNumber}>
                        {item.companySTDCode && item.contactNumber
                            ? '+' + item?.companySTDCode + ' ' + item?.contactNumber
                            : 'NA'}
                    </span>
                ),
            },
            {
                dataIndex: 'salesOwnerOfferx',
                title: 'Sales Owner',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.salesOwnerName}>
                        {item?.salesOwnerName}
                    </div>
                ),
            },
            {
                dataIndex: 'onboardedDate',
                title: 'Onboarded Date',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) => {
                    return item.onboardedDate
                        ? new Date(item.onboardedDate).toLocaleString('en-in', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                          })
                        : 'NA';
                },
            },
            {
                dataIndex: 'dateInvited',
                title: 'Date Invited',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    item.dateInvited
                        ? new Date(item.dateInvited).toLocaleString('en-in', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                          })
                        : 'NA',
            },
            {
                dataIndex: 'leadStatus',
                title: 'Status',
                render: (item: any) => (
                    <div
                        className="text-nowrap text-ellipsis text-capitalize"
                        title={getCompanyLeadStatusName(item?.leadStatus)}
                    >
                        {item && getCompanyLeadStatusName(item?.leadStatus)}
                    </div>
                ),
            },
            {
                dataIndex: '',
                title: 'Actions',
                width: '120px',
                render: (item: any) =>
                    item.leadStatus >= CompanyLeadStatus.InviteSent && (
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                disabled={item.leadStatus > CompanyLeadStatus.RequestToOnboard}
                                onClick={() => {
                                    setActiveResendId(item.id);
                                }}
                                className="btn-link"
                            >
                                {loadingResendStatus === 'error' && activeResendId === item.id && (
                                    <span className="google-icon">
                                        <i className="bi bi-x-circle text-danger" />
                                    </span>
                                )}
                                {loadingResendStatus === 'loading' && activeResendId === item.id && (
                                    <div className="new-spinner p-fixed">
                                        <div>
                                            <span className="spinner-border spinner-border-sm custom-spinner-border" />
                                        </div>
                                        <p className="fs-14 custom-loading-text">Loading</p>
                                    </div>
                                )}
                                {item.sentTimes >= 1 ? 'Resend' : item.sentTimes == 0 && 'Invite'}
                            </button>
                        </div>
                    ),
            },
        ],
        [loadingResendStatus, activeResendId]
    );

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);
            const params: CompanyListRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };

            const {
                resultObject: { items },
            } = await companyService.getAllCompanyLeadsList(params);
            var mapItems = items.map((item) => ({
                ...item,
                salesOwnerOfferx: `${item?.salesOwnerName}`,
            }));
            setData(mapItems);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [filters, sort, loading]);

    useEffect(() => {
        if (!activeResendId) return;
        (async function () {
            try {
                setLoadingResendStatus('loading');
                const {
                    resultObject: { isEmailSent, errorMessage },
                } = await invitationsService.inviteOrResendCompanyInvite({ companyLeadId: activeResendId });
                if (isEmailSent) {
                    setLoadingResendStatus('success');
                    setActiveResendId('');
                    await getList();
                    Swal.fire({
                        title: SweetAlertTitle.Success,
                        icon: SuccessIcon,
                        text: Message.InvitationSent,
                    });
                } else {
                    Swal.fire({
                        title: SweetAlertTitle.Error,
                        icon: ErrorIcon,
                        text: errorMessage,
                    });
                }
                setLoadingResendStatus('success');
            } catch (err) {
                setLoadingResendStatus('error');
            }
        })();
    }, [activeResendId]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
    }, []);

    useEffect(() => {
        if (!showBulkUploadPage) {
            (async function () {
                await getList();
            })();
            setInvitationHeaderText('Company Registrations');
        }
    }, [showBulkUploadPage]);

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <>
                    <div className="card lt-invitation-table comp-reg-table">
                        <Table columns={columns} data={data} />
                    </div>
                </>
            )}
        </>
    );
};

export default CompanyRegistration;

const getStatusColor = (value: InviteStatusEnum) => {
    switch (value) {
        case InviteStatusEnum.invited:
            return '#AE8701';
        case InviteStatusEnum.joined:
            return '#107C10';
        case InviteStatusEnum.deleted:
            return '#D83B01';
        default:
            return '';
    }
};
