import mammoth from 'mammoth';

export async function convertDocxToHtml(url: string) {
    return new Promise((resolve) => {
        const jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', url, true);
        jsonFile.send();
        jsonFile.responseType = 'arraybuffer';
        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
                (async function () {
                    try {
                        const { value } = await mammoth.convertToHtml(
                            { arrayBuffer: jsonFile.response },
                            { includeDefaultStyleMap: true }
                        );
                        resolve(value);
                    } catch (err) {
                        console.log('error mammoth convert docx to pdf', err);
                        resolve('');
                    }
                })();
            }
        };
    });
}