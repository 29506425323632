import React, { useCallback, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
// import Table from '../../components/table/simple';
import { CompanyReportFromCandidateType } from '../../types/company';
import avatar from '../../assets/img/company-lg-icon.png';
import { companyService } from '../../lib/api/company';
import { getSuspensionDecisionText } from '../../lib/utils/dictionary';
import { SuspensionDecisionEnum } from '../../types/suspension';
import DatePickerComponent from '../../components/date-picker';
import { generateLink } from '../../lib/utils/generate-link';
import { routes } from '../routes/routes-names';
import ReportDetails from './modals/report-details';
import Suspend from '../../components/suspend-modal/suspend';
import { suspensionService } from '../../lib/api/suspension';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import StateCityCodeValue from '../../components/profile/stateCityCodeValue';
import Table from '../../components/table';

type Props = {
    loading: boolean;
    data: CompanyReportFromCandidateType[];
    setCurrentId: (id: string) => void;
    setRerenderKey: (key: number) => void;
    setData: (Data: CompanyReportFromCandidateType[]) => void;
};

const ReportedByCandidates: React.FC<Props> = ({ loading, data, setData, setCurrentId, setRerenderKey }) => {
    const [loadingFlag, setLoadingFlag] = useState('');
    const [range, setRange] = useState<[Date, Date] | null>(null);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [companyReport, setCompanyReport] = useState<CompanyReportFromCandidateType>();

    const submitFlag = useCallback(
        async (id: string, value: boolean) => {
            try {
                setLoadingFlag(id);
                await companyService.updateFlag({ companyReportFromCandidateId: id, flagValue: value });
                Swal.fire({
                    icon: SuccessIcon,
                    title: `${value ? 'Flag' : 'Unflag'} Your work has been saved`,
                    showConfirmButton: false,
                    timer: 1500,
                });
                setRerenderKey(Date.now());
            } catch (err: any) {
                console.log('companyService updateFlag error', err.response);
            } finally {
                setLoadingFlag('');
            }
        },
        [setRerenderKey]
    );

    const discardByCandidate = useCallback(
        async (id: any) => {
            try {
                Swal.fire({
                    title: Message.discardCandidate,
                    text: Message.discardCandidateRevert,
                    icon: WarningIcon,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    showConfirmButton: true,
                    showCloseButton: false,
                }).then(async function (result) {
                    if (result.isConfirmed || companyReport?.id) {
                        await suspensionService.rejectByReport({ companyReportFromCandidateId: id });

                        setRerenderKey(Date.now());
                        Swal.fire({
                            title: SweetAlertTitle.Success,
                            text: Message.discardSuccessfull,
                            icon: SuccessIcon,
                        });
                        setShowReportModal(false);
                    }
                });
            } catch (err: any) {
                console.log('suspensionService rejectByCandidateReport error', err.response);
            } finally {
            }
        },
        [setRerenderKey]
    );

    const getSuspensionDecisionText = (status: SuspensionDecisionEnum) => {
        switch (status) {
            case 0:
                return 'Active';

            case 1:
                return 'Rejected';

            case 2:
                return 'Approved';

            default:
                return 'unknown';
        }
    };

    const columns = useMemo(
        () => [
            // {
            //     dataIndex: 'avatarUrl',
            //     title: '',
            //     render: (item: any) => (
            //         <img src={item.companyAvatarUrl || avatar} alt="" className="avatar avatar--xs" />
            //     ),
            // },
            {
                dataIndex: 'companyName',
                title: 'Company Name',
                render: (item: any) => (
                    <div className="d-flex">
                        <div className="offer-table-reoffered me-2">
                            <img src={item.companyAvatarUrl || avatar} alt="" className="avatar avatar--xs" />
                        </div>
                        <div className="text-crop" style={{ top: '6px' }}>
                            {/* <Link to={generateLink(routes.CompanyView, { id: item.companyId, suspendStatus: item.suspendStatus })} className="offer-table-link" title={item.name}> */}
                            <div className="big-text">{item.companyName}</div>
                            {/* </Link> */}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'location',
                title: 'Location',
                render: (item: any) => (
                    <div className="text-ellipsis text-capitalize">
                        <StateCityCodeValue
                            id={String(Math.random()) + 1}
                            countryValue={'101'}
                            cityValue={item?.companyCityDistrict}
                            stateValue={item?.companyState}
                        />
                    </div>
                ),
            },
            {
                dataIndex: 'reportedDate',
                title: 'Reported Date',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.reportedDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'fullName',
                title: 'Reported By',
                render: (item: any) => (
                    <div className="text-ellipsis text-capitalize" title={item.fullName}>
                        <span className="offer-table-link">{item.fullName}</span>
                    </div>
                ),
            },
            {
                dataIndex: 'companySuspendReasonText',
                title: 'Reason',
                inputFilter: (value: any) => {
                    return value;
                },
                render: (item: any) => (
                    <div className="text-crop">
                        <div
                            className="big-text"
                            title={item.companySuspendReason.join(', ').replace(/, ([^,]*)$/, '  $1')}
                        >
                            {item.companySuspendReason.join(', ').replace(/, ([^,]*)$/, '  $1')}
                            {item.companySuspendReasonText}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'suspensionDecision',
                title: 'Status',
                // inputFilter: (value: any) => {
                //     return getSuspensionDecisionText(value);
                // },
                render: (item: any) => (
                    <span style={{ color: `${getStatusColor(item.suspensionDecision)}` }}>
                        {getSuspensionDecisionText(item.suspensionDecision)}
                    </span>
                ),
            },
            {
                dataIndex: '',
                title: 'Actions',
                render: (item: any) => (
                    <div className="hover-icons d-flex justify-content-center">
                        <button
                            type="button"
                            title="View report details"
                            className="lt-text-primary"
                            // data-bs-toggle="modal"
                            // data-bs-target="#CompanyDetails"
                            onClick={() => (setCurrentId(item.id), setCompanyReport(item), setShowReportModal(true))}
                        >
                            <i className="bi bi-eye" />
                        </button>
                        <button
                            type="button"
                            title={item.isFlagged ? 'Unflag' : 'Flag'}
                            className={`lt-text-primary lt-flag-icon ${loadingFlag ? 'table-btn-disabled' : ''}`}
                            onClick={() => submitFlag(item.id, !item.isFlagged)}
                        >
                            {loadingFlag === item.id ? (
                                <span className="spinner-border spinner-border-sm lt-text-primary-alt ms-1" />
                            ) : (
                                <i className={`bi flag-select ${item.isFlagged ? 'bi-flag-fill' : 'bi-flag'}`} />
                            )}
                        </button>
                        <button
                            type="button"
                            title="Discard"
                            disabled={
                                item.suspensionDecision === SuspensionDecisionEnum.approved ||
                                item.suspensionDecision === SuspensionDecisionEnum.rejected
                            }
                            onClick={() => discardByCandidate(item.id)}
                        >
                            <i className="bi bi-trash" />
                        </button>
                    </div>
                ),
            },
        ],
        [setCurrentId, loadingFlag, submitFlag]
    );

    useMemo(() => {
        if (range) {
            const dateFilter = data.filter((item) => {
                const reportedDate = new Date(item.reportedDate as Date).setHours(0, 0, 0, 0);
                return reportedDate >= range[0].setHours(0, 0, 0, 0) && reportedDate <= range[1].setHours(0, 0, 0, 0);
            });
            setData(dateFilter);
        } else {
            setData(data);
        }
    }, [range, loadingFlag, loading]);

    const reset = useCallback(() => {
        setRange(null);
        setData(data);
    }, [range]);

    return (
        <>
            <div className="d-flex align-items-center mb-4">
                <div className="label me-1">
                    <h1 className="fw-700 fs-14 m-0">Date Range:</h1>
                </div>
                <div className="ms-2">
                    <DatePickerComponent
                        key={range?.toString()}
                        startDate={range && range[0]}
                        endDate={range && range[1]}
                        placeholder="Select date range"
                        rangePicker
                        onChange={(date) => {
                            setRange(date as [Date, Date] | null);
                        }}
                    />
                </div>
                {range && (
                    <div className="ms-2">
                        <button type="button" onClick={reset} className="btn-link">
                            Reset
                        </button>
                    </div>
                )}
            </div>
            <Table columns={columns} data={data} />
            <ReportDetails
                setRerenderKey={setRerenderKey}
                showReportModal={showReportModal}
                setShowAdminModal={setShowAdminModal}
                setShowReportModal={setShowReportModal}
                data={data.find((item) => item.id === companyReport?.id)}
            />
            <Suspend
                reportBycandidate
                setShowAdminModal={setShowAdminModal}
                reportedId={companyReport?.id}
                showAdminModal={showAdminModal}
                id={companyReport?.companyId || ''}
                setRerenderKey={setRerenderKey}
            />
        </>
    );
};

export default ReportedByCandidates;

const getStatusColor = (value: SuspensionDecisionEnum) => {
    switch (value) {
        case SuspensionDecisionEnum.active:
            return '#107C10';
        case SuspensionDecisionEnum.approved:
            return '#107C10';
        case SuspensionDecisionEnum.rejected:
            return '#D83B01';
        default:
            return '';
    }
};
