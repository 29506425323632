import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import YearsDropdown from '../../../components/dropdown';
import PageLoader from '../../../components/loader';
import Nodata from '../../../components/no-data';
import { dashboardService } from '../../../lib/api/dashboard';
import { GetCompanyRatingsType } from '../../../types/dashboard';
import { Doughnut } from 'react-chartjs-2';
import { NewChartJoiningStatusOptions, NewChartOptions } from './utils/candidate-company-options';

type Props = {
    Options: any;
    selectedYear: number;
};

const CompanyRating: React.FC<Props> = ({ Options, selectedYear }) => {
    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<GetCompanyRatingsType | null>();
    const [dropdownYear, setDropdownYear] = useState<number>(selectedYear);
    const [seriesArray, setSeriesArray] = useState<number[]>([]);
    const [chartData, setChartData] = useState(NewChartJoiningStatusOptions);
    const [showChart, setShowChart] = useState(false);

    const prevSeriesArrayRef = useRef<number[]>(seriesArray);

    useMemo(() => {
        setDropdownYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        (async () => {
            try {
                setShowChart(false);
                setLoading(true);
                await dashboardService.getCompanyRatings(dropdownYear).then((res) => {
                    const value = res.resultObject;
                    setData(value);
                    const complianceCount = value.companyComplianceViolationCounts.complianceCount;
                    const violationCount = value.companyComplianceViolationCounts.violationCount;
                    if (complianceCount !== 0 || violationCount !== 0)
                        setSeriesArray([complianceCount, violationCount]);
                });

                setTimeout(() => {
                    setShowChart(true);
                }, 1000);
            } catch (error) {
                console.log('Company rating service', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dropdownYear]);

    const updateData = () => {
        setChartData((prevData) => ({
            ...prevData,
            datasets: [
                {
                    ...prevData.datasets[0],
                    data: seriesArray,
                    borderWidth: 2,
                },
            ],
        }));
    };

    useEffect(() => {
        if (
            seriesArray.length > 0 &&
            seriesArray !== prevSeriesArrayRef.current &&
            (seriesArray[0] !== 0 || seriesArray[1] !== 0)
        ) {
            prevSeriesArrayRef.current = seriesArray;
            updateData();
        }
    }, [seriesArray]);

    return (
        <div className="col-md-6">
            {loading ? (
                <PageLoader />
            ) : (
                <div className="p-4 label-alignment">
                    <div className="d-flex mb-3 justify-content-between">
                        <div className="card-title fs-16 fw-600">
                            Company ratings{' '}
                            <i className="bi bi-info-circle lt-tooltip">
                                <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                    Total number of ratings given by candidates to companies in a year
                                </span>
                            </i>
                        </div>
                        <YearsDropdown setDropdownYear={setDropdownYear} />
                    </div>
                    {seriesArray.length ? (
                        <div className="d-flex justify-content-center">
                            {/* <ReactApexChart
                            options={Options}
                            series={seriesArray}
                            type="donut"
                            height={250}
                        /> */}
                            {showChart ? (
                                <div className="chart-container">
                                    <Doughnut
                                        data={chartData}
                                        options={NewChartOptions}
                                        placeholder={undefined}
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    />
                                </div>
                            ) : (
                                <PageLoader />
                            )}
                        </div>
                    ) : (
                        <Nodata
                            title="No data"
                            text="Please check again in future"
                            icon="bi-graph-up"
                            style={{ height: 215 }}
                        />
                    )}
                    <div className="mb-3">
                        <div className="d-flex align-items-end my-1">
                            <div className="d-flex align-items-center">
                                <div className="fs-16 fw-700 me-1">
                                    {data?.companyStarRatingCounts.stars5P &&
                                        Math.round(data?.companyStarRatingCounts.stars5P)}
                                    %
                                </div>
                                <div className="fs-12 text-muted">
                                    ({data?.companyStarRatingCounts.stars5Count} Reviews)
                                </div>
                            </div>
                            <div className="ms-auto fw-700">
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex align-items-end my-1">
                            <div className="d-flex align-items-center">
                                <div className="fs-16 fw-700 me-1">
                                    {data?.companyStarRatingCounts.stars4P &&
                                        Math.round(data?.companyStarRatingCounts.stars4P)}
                                    %
                                </div>
                                <div className="fs-12 text-muted">
                                    ({data?.companyStarRatingCounts.stars4Count} Reviews)
                                </div>
                            </div>
                            <div className="ms-auto fw-700">
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex align-items-end my-1">
                            <div className="d-flex align-items-center">
                                <div className="fs-16 fw-700 me-1">
                                    {data?.companyStarRatingCounts.stars3P &&
                                        Math.round(data?.companyStarRatingCounts.stars3P)}
                                    %
                                </div>
                                <div className="fs-12 text-muted">
                                    ({data?.companyStarRatingCounts.stars3Count} Reviews)
                                </div>
                            </div>
                            <div className="ms-auto fw-700">
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="d-flex align-items-end my-1">
                            <div className="d-flex align-items-center">
                                <div className="fs-16 fw-700 me-1">
                                    {data?.companyStarRatingCounts.stars2P &&
                                        Math.round(data?.companyStarRatingCounts.stars2P)}
                                    %
                                </div>
                                <div className="fs-12 text-muted">
                                    ({data?.companyStarRatingCounts.stars2Count} Reviews)
                                </div>
                            </div>
                            <div className="ms-auto fw-700">
                                <i className="bi bi-star-fill lt-text-yellow" />
                                <i className="bi bi-star-fill lt-text-yellow" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-0">
                        <div className="d-flex align-items-end my-1">
                            <div className="d-flex align-items-center">
                                <div className="fs-16 fw-700 me-1">
                                    {data?.companyStarRatingCounts.stars1P &&
                                        Math.round(data?.companyStarRatingCounts.stars1P)}
                                    %
                                </div>
                                <div className="fs-12 text-muted">
                                    ({data?.companyStarRatingCounts.stars1Count} Reviews)
                                </div>
                            </div>
                            <div className="ms-auto fw-700">
                                <i className="bi bi-star-fill lt-text-yellow" />
                            </div>
                        </div>
                    </div>
                </div>
            )}{' '}
        </div>
    );
};

export default CompanyRating;
