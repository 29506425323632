import { ApiResponseType } from './api';

export enum SuspendStatusEnum {
    active,
    suspended,
    pending,
    uploaded,
    rejected,
    reupload,
    partially
}

export enum SuspendStatusEnumColor {
    active,
    suspended,
    pending,
    uploaded,
    rejected,
    moreEvidence,
    partially
}

export enum SuspensionDecisionEnum {
    active,
    rejected,
    approved,
}

export type SuspensionType = {
    suspendReasonId: string;
    suspendReasonName: string;
    suspendReasonOrder: number;
    suspendReasonCategoryId: string;
    suspendReasonCategoryName: string;
    suspendReasonCategoryOrder: number;
    suspendReasonText: string;
};

export type ReportRequestType = {
    companyId: string;
    suspendStatus: SuspendStatusEnum;
    suspensionReasonList: string[];
    otherText: string;
};

export type CandidateReportRequestType = {
    candidateId: string;
    suspensionReasonList: string[];
    otherText: string;
};

export type SuspensionResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        items: SuspensionType[];
        hasMore: boolean;
        count: number;
    };
};

export type candidateRejectResponseType = Omit<ApiResponseType, 'resultObject'> & {
    isSuccessful: boolean;
    error: string;
};

export type CandidateReportType = {
    candidateReportFromCompanyId: string;
    suspensionReasonList: string[];
    otherText: string;
};

export type CompanyReportType = {
    companyReportFromCandidateId: string;
    suspensionReasonList: string[];
    otherText: string;
};
