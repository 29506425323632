import React from 'react';
import * as XLSX from 'xlsx';

type Props = {
    data: any[];
    id?: string;
    text: string;
    FileName: string;
};

const DataAsExcel: React.FC<Props> = ({ data, text, id, FileName }) => {
    const downloadArrayExcel = () => {
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Processed Data');

        XLSX.writeFile(wb, FileName + '.xlsx');
    };

    const downloadObjectExcel = () => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Processed Data');
        XLSX.writeFile(wb, `${FileName}.xlsx`);
    };

    return (
        <div>
            {data && id ? (
                <button type="button" className="btn btn-primary" onClick={downloadObjectExcel}>
                    {text}
                </button>
            ) : (
                <button type="button" className="btn btn-primary" onClick={downloadArrayExcel}>
                    {text}
                </button>
            )}
        </div>
    );
};

export default DataAsExcel;
