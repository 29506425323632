import { useCallback, useEffect, useState } from 'react';
import CompanyRegistration from './companyRegister';
import CompanyRegistrationScreenX from './companyRegistrationScreenX';
import CandidateExcelBulkUpload from './company-bulk-register';
import CreateInvite from './modals/create-company';
import PageLoader from '../../components/loader';
import { invitationsService } from '../../lib/api/invitations';
import Swal from 'sweetalert2';
import { companyService } from '../../lib/api/company';
import { CompanyListRequestType, CompanyProfileType } from '../../types/company';
import { useDispatch, useSelector } from 'react-redux';
import { SortTypeEnum } from '../../types';
import { RootState } from '../../redux';
import { UserType } from '../../types/auth';

const CompanyRegistrationLanding = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const [data, setData] = useState<CompanyProfileType[]>([]);
    const hasUser = user as UserType;
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [loading, setLoading] = useState(false);
    const [isExitReviewEnabled, setIsExitReviewEnabled] = useState<boolean | null>(null);
    const [loadingResendStatus, setLoadingResendStatus] = useState<'loading' | 'success' | 'error' | null>(null);
    const [activeResendId, setActiveResendId] = useState('');
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(data.length);

    const [invitationHeaderText, setInvitationHeaderText] = useState('Company Registrations');

    const dispatch = useDispatch();

    // const onboardAndExitWorkFlowSettings = useSelector((state: RootState) => state.onboardingExitReviewsFlow);

    const [showBulkUploadPage, setShowBulkUploadPage] = useState(false);
    const [activeTab, setActiveTab] = useState<'offerX' | 'screenX'>('offerX');

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);
            const params: CompanyListRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };

            const {
                resultObject: { items },
            } = await companyService.getAllCompanyLeadsList(params);

            setData(items);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [filters, sort, loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
    }, []);

    useEffect(() => {
        if (!showBulkUploadPage) {
            (async function () {
                await getList();
            })();
            setInvitationHeaderText('Company Registrations');
        }
    }, [showBulkUploadPage]);

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    return (
        <>
            <div className="company-page-contener">
                {showBulkUploadPage && (
                    <div className="my-3">
                        <span className="pe-2 text-secondary">
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    setShowBulkUploadPage(false);
                                    setInvitationHeaderText('Company Registrations');
                                }}
                            >
                                <i className="bi bi-chevron-left lt-text-secondary-alt me-0"></i> Back
                            </span>
                        </span>
                    </div>
                )}
                <div className="d-flex my-4 align-items-center">
                    <div className="flex-grow-1 text-start">
                        <h1 className="fw-700 fs-20 page-header-title mb-0">{invitationHeaderText}</h1>
                        {/* <label className="fw-400 fs-14">Invitations expire after 30 days</label> */}
                    </div>
                    <div className="flex-shrink-1 text-sm-end">
                        <button
                            className={`btn btn-primary ${showBulkUploadPage && 'd-none'}`}
                            type="button"
                            data-bs-toggle={'modal'}
                            data-bs-target={'#AddInvitePage'}
                        >
                            Invite a company
                        </button>

                        <button
                            className={`btn btn-primary ms-3 ${showBulkUploadPage && 'd-none'}`}
                            type="button"
                            onClick={() => {
                                setInvitationHeaderText('Bulk Invitations');
                                setShowBulkUploadPage(true);
                            }}
                        >
                            Bulk Invitations
                        </button>
                    </div>
                </div>

                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <div className="card lt-invitation-table comp-reg-table">
                            {!showBulkUploadPage ? (
                                <>
                                    <div className="company-page-contener">
                                        <div className="card">
                                            <div className="employees-table">
                                                <nav>
                                                    <div className="d-flex bd-highlight lt-nav-bar mb-4">
                                                        <div className="w-100 bd-highlight">
                                                            <div className="nav nav-tabs nav-justified" id="nav-tab">
                                                                <button
                                                                    className={`nav-link col-2 fs-16 ${
                                                                        activeTab === 'offerX' ? 'active' : ''
                                                                    }`}
                                                                    type="button"
                                                                    onClick={() => setActiveTab('offerX')}
                                                                >
                                                                    OfferX
                                                                </button>
                                                                <button
                                                                    className={`nav-link col-2 fs-16 ${
                                                                        activeTab === 'screenX' ? 'active' : ''
                                                                    }`}
                                                                    type="button"
                                                                    onClick={() => setActiveTab('screenX')}
                                                                >
                                                                    ScreenX
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </nav>
                                                {activeTab === 'offerX' && <CompanyRegistration />}
                                                {activeTab === 'screenX' && <CompanyRegistrationScreenX />}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <CandidateExcelBulkUpload setShowCandidateUploadContent={setShowBulkUploadPage} />
                            )}
                        </div>
                    </>
                )}

                <CreateInvite id="AddInvitePage" getList={getList} />
            </div>
        </>
    );
};

export default CompanyRegistrationLanding;
