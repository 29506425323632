import React, { useCallback, useMemo, useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import getValue from 'lodash/get';
import { emailRegexp, siteRegexp } from '../../../lib/utils/validation';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { CreateInviteRequestType } from '../../../types/invitations';
import { invitationsService } from '../../../lib/api/invitations';
import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import PhoneCodeDropdown from '../../../components/dropdown/phoneCode';
import CountryDropdown from '../../../components/dropdown/country';
import StateDropdown from '../../../components/dropdown/states';
import CityDropdown from '../../../components/dropdown/city';
import Swal from 'sweetalert2';
import { excelUploadDataService } from '../../../lib/api/excelUpload';
import { ExcelApiFormDataType } from '../../../types/excelUpload';
import { toast } from 'react-toastify';
import { ErrorIcon, Message, SweetAlertTitle } from '../../../lib/utils/messages';

type Props = {
    id?: string;
    getList?: () => void;
};

const initialFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    userEmail: '',
    companyName: '',
    companyWebsite: '',
    companyEmail: '',
    companyContact: '',
    companyCountryCode: '',
    companyCityCode: '',
    companyStateCode: '',
    postalCode: '',
    companySTDCode: '',
    contactNumber: '',
    ownerSTDCode: '',
};

const CreateCandidateInvite: React.FC<Props> = ({ id = 'AddInvite', getList }) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stateDisabled, setStateDisabled] = useState(true);
    const [cityDisabled, setCityDisabled] = useState(true);
    const [iso2CountryString, setIso2CountryString] = useState('');
    const [iso2StateString, setIso2StateString] = useState('');
    const [ownerNumberError, setOwnerNumberError] = useState('');
    const [companyNumberError, setCompanyNumberError] = useState('');

    const [nameOfCountry, setNameOfCountry] = useState('');
    const [nameOfState, setNameOfState] = useState('');
    const [nameOfCity, setNameOfCity] = useState('');
    const [offerXSelected, setOfferXSelected] = useState(false);
    const [screenXSelected, setScreenXSelected] = useState(false);
    const [appError, setAppError] = useState('');


    const profileFormData = useMemo(() => { }, []);

    const initialErrors = useInitialErrors(profileFormData, getValidationSchema());

    const submitForm = useCallback(
        async (values: CreateInviteRequestType, { resetForm }: FormikHelpers<CreateInviteRequestType>) => {
            try {
                if (offerXSelected === true) {
                    
                    if (new Array(values).length !== 0 && nameOfCountry && nameOfState && nameOfCity) {
                        const requestData: ExcelApiFormDataType[] = [
                            {
                                ...values,
                                companyCountryName: nameOfCountry,
                                companyStateName: nameOfState,
                                companyCityName: nameOfCity,
                            },
                        ];
                        const {
                            isSuccessful,
                            resultObject: { companyLeadsExcelTotalOutputModel },
                        } = await excelUploadDataService.setCompanyExcelUploadData({
                            companyRequestModel: requestData,
                        });
                        if (getList && isSuccessful) {
                            await getList();
                        }
                        if (!companyLeadsExcelTotalOutputModel[0].output) {
                            setSuccess(true);
                            resetForm({ values: initialFormData });
                            setIso2CountryString('');
                            setIso2StateString('');
                            setStateDisabled(true);
                            setCityDisabled(true);
                        } else {
                            setSuccess(false);
                            // Swal.fire({
                            //     icon: 'error',
                            //     title: 'Error',
                            //     text: `${companyLeadsExcelTotalOutputModel[0].remarks}`,
                            //     allowOutsideClick: false,
                            // });
                            toast.error(`${companyLeadsExcelTotalOutputModel[0].remarks}`);
                        }
                    }
                }else if (screenXSelected === true) {
                    if (new Array(values).length !== 0 && nameOfCountry && nameOfState && nameOfCity) {
                        const requestData: ExcelApiFormDataType[] = [
                            {
                                ...values,
                                companyCountryName: nameOfCountry,
                                companyStateName: nameOfState,
                                companyCityName: nameOfCity
                            },
                        ];
                        const {
                            statusCode,
                            result: { companyLeadsExcelTotalOutputModel },
                        } = await excelUploadDataService.setScreenXCompanyExcelUploadData({
                            companyRequestModel: requestData,
                        });
                        if (getList && statusCode === 0) {
                            await getList();
                        }
                        if (!companyLeadsExcelTotalOutputModel[0].output) {
                            setSuccess(true);
                            resetForm({ values: initialFormData });
                            setIso2CountryString('');
                            setIso2StateString('');
                            setStateDisabled(true);
                            setCityDisabled(true);
                        } else {
                            setSuccess(false);
                            // Swal.fire({
                            //     icon: 'error',
                            //     title: 'Error',
                            //     text: `${companyLeadsExcelTotalOutputModel[0].remarks}`,
                            //     allowOutsideClick: false,
                            // });
                            toast.error(`${companyLeadsExcelTotalOutputModel[0].remarks}`);
                        }
                    }
                }
                else {
                    setAppError('Please select the application.');
                }

            } catch (err) {
                Swal.fire({
                    icon: ErrorIcon,
                    title: SweetAlertTitle.Error,
                    text: Message.SomethingWentWrong,
                    allowOutsideClick: false,
                });
            }
        },
        [getList, nameOfCountry, nameOfCity, nameOfState, offerXSelected, screenXSelected]
    );

    return (
        <>
            <div
                className="modal fade"
                id={id}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-hidden="true"
            >
                {success ? (
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content lt-modal-content">
                            <Success setSuccess={setSuccess} />
                        </div>
                    </div>
                ) : (
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content lt-modal-content">
                            <Formik
                                onSubmit={submitForm}
                                enableReinitialize
                                initialValues={initialFormData}
                                validationSchema={getValidationSchema()}
                                initialErrors={initialErrors}
                            >
                                {(formikProps: FormikProps<CreateInviteRequestType>) => {
                                    const {
                                        handleSubmit,
                                        values,
                                        isSubmitting,
                                        setFieldTouched,
                                        setFieldValue,
                                        resetForm,
                                    } = formikProps;

                                    return (
                                        <>
                                            {isSubmitting && (
                                                <div className="new-spinner p-absolute">
                                                    <div>
                                                        <span className="spinner-border spinner-border-sm custom-spinner-border" />
                                                    </div>
                                                    <p className="fs-14 custom-loading-text">Loading</p>
                                                </div>
                                            )}
                                            <div className="modal-header lt-modal-header">
                                                <h5
                                                    className="modal-title w-100 fs-20 fw-700 mb-4"
                                                    id="InviteaFriendLabel"
                                                >
                                                    Invite a company
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-close
                                                    data-bs-dismiss="modal"
                                                    title="Close"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        resetForm();
                                                        setIso2CountryString('');
                                                        setIso2StateString('');
                                                        setStateDisabled(true);
                                                        setCityDisabled(true);
                                                        setTimeout(() => {
                                                            success && setSuccess(false);
                                                        }, 500);
                                                    }}
                                                />
                                            </div>
                                            <div className="modal-body lt-modal-body">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="invite-modal">
                                                        <div className="fs-16 fw-600 mb-3">
                                                            Give us basic details about organization
                                                        </div>
                                                        <div className="form-section">
                                                            <div className="fs-16 fw-600 mb-3">User Details</div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="firstName">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);

                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            First Name{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                const regex =
                                                                                                    /^[a-zA-Z0-9 ]*$/;

                                                                                                if (
                                                                                                    regex.test(
                                                                                                        ev.target.value
                                                                                                    )
                                                                                                ) {
                                                                                                    setFieldTouched(
                                                                                                        field.name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        field.name,
                                                                                                        ev.target.value
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter First name"
                                                                                        />

                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="middleName">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field } = fieldProps;
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Middle Name
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                const regex =
                                                                                                    /^[a-zA-Z0-9 ]*$/;

                                                                                                if (
                                                                                                    regex.test(
                                                                                                        ev.target.value
                                                                                                    )
                                                                                                ) {
                                                                                                    setFieldTouched(
                                                                                                        field.name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        field.name,
                                                                                                        ev.target.value
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className="form-control"
                                                                                            placeholder="Enter Middle name"
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="lastName">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Last Name{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                const regex =
                                                                                                    /^[a-zA-Z0-9 ]*$/;

                                                                                                if (
                                                                                                    regex.test(
                                                                                                        ev.target.value
                                                                                                    )
                                                                                                ) {
                                                                                                    setFieldTouched(
                                                                                                        field.name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        field.name,
                                                                                                        ev.target.value
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter Last name"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="userEmail">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Email{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="email"
                                                                                            value={field.value}
                                                                                            maxLength={320}
                                                                                            onChange={(ev) => {
                                                                                                setFieldTouched(
                                                                                                    field.name
                                                                                                );
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    ev.target.value
                                                                                                );
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter Email"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label className="fw-700 mb-2 fs-14">
                                                                            Contact Number
                                                                            <span className="lt-text-error">*</span>
                                                                        </label>
                                                                        <div className="d-flex">
                                                                            <Field name="ownerSTDCode">
                                                                                {(fieldProps: FieldProps) => {
                                                                                    const { field, form } = fieldProps;
                                                                                    return (
                                                                                        <>
                                                                                            <div className="">
                                                                                                <PhoneCodeDropdown
                                                                                                    id="owner"
                                                                                                    candidateValue={
                                                                                                        field.value
                                                                                                    }
                                                                                                    setFieldValue={
                                                                                                        setFieldValue
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                            <Field name="contactNumber">
                                                                                {(fieldProps: FieldProps) => {
                                                                                    const { field, form } = fieldProps;
                                                                                    const error =
                                                                                        getValue(
                                                                                            form.touched,
                                                                                            field.name
                                                                                        ) &&
                                                                                        getValue(
                                                                                            form.errors,
                                                                                            field.name
                                                                                        );
                                                                                    if (error) {
                                                                                        setOwnerNumberError(
                                                                                            error.toString()
                                                                                        );
                                                                                    } else {
                                                                                        setOwnerNumberError('');
                                                                                    }
                                                                                    return (
                                                                                        <>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={field.value}
                                                                                                maxLength={10}
                                                                                                onChange={(ev) => {
                                                                                                    const regex =
                                                                                                        /^\d+$/;

                                                                                                    if (
                                                                                                        regex.test(
                                                                                                            ev.target
                                                                                                                .value
                                                                                                        )
                                                                                                    ) {
                                                                                                        setFieldTouched(
                                                                                                            field.name
                                                                                                        );
                                                                                                        setFieldValue(
                                                                                                            field.name,
                                                                                                            ev.target
                                                                                                                .value
                                                                                                        );
                                                                                                    } else if (
                                                                                                        ev.target
                                                                                                            .value ===
                                                                                                        ''
                                                                                                    ) {
                                                                                                        setFieldValue(
                                                                                                            field.name,
                                                                                                            ''
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                className={`form-control form-phone-border ${error
                                                                                                    ? 'phone-input is-invalid'
                                                                                                    : ''
                                                                                                    }`}
                                                                                                placeholder="Enter Contact Number"
                                                                                            />
                                                                                            {/* {typeof error ===
                                                                                                'string' && (
                                                                                                <div className="text-danger fs-12">
                                                                                                    {error}
                                                                                                </div>
                                                                                            )} */}
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                        {ownerNumberError && (
                                                                            <small className="text-danger fs-12">
                                                                                {ownerNumberError}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-section">
                                                            <div className="fs-16 fw-600 mb-3">Company Details</div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyName">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Company Name{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                const regex =
                                                                                                    /^[a-zA-Z0-9 ]*$/;

                                                                                                if (
                                                                                                    regex.test(
                                                                                                        ev.target.value
                                                                                                    )
                                                                                                ) {
                                                                                                    setFieldTouched(
                                                                                                        field.name
                                                                                                    );
                                                                                                    setFieldValue(
                                                                                                        field.name,
                                                                                                        ev.target.value
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter Company Name"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyWebsite">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Company Website
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                setFieldTouched(
                                                                                                    field.name
                                                                                                );
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    ev.target.value
                                                                                                );
                                                                                            }}
                                                                                            className="form-control"
                                                                                            placeholder="Enter Company Website"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyEmail">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Company Email{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="email"
                                                                                            value={field.value}
                                                                                            maxLength={100}
                                                                                            onChange={(ev) => {
                                                                                                setFieldTouched(
                                                                                                    field.name
                                                                                                );
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    ev.target.value
                                                                                                );
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter Company Email"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label className="fw-700 mb-2 fs-14">
                                                                            Company Contact Number{' '}
                                                                            <span className="lt-text-error">*</span>
                                                                        </label>
                                                                        <div className="d-flex">
                                                                            <Field name="companySTDCode">
                                                                                {(fieldProps: FieldProps) => {
                                                                                    const { field, form } = fieldProps;
                                                                                    const error =
                                                                                        getValue(
                                                                                            form.touched,
                                                                                            field.name
                                                                                        ) &&
                                                                                        getValue(
                                                                                            form.errors,
                                                                                            field.name
                                                                                        );

                                                                                    return (
                                                                                        <>
                                                                                            <div className="">
                                                                                                <PhoneCodeDropdown
                                                                                                    id="company"
                                                                                                    companyValue={
                                                                                                        field.value
                                                                                                    }
                                                                                                    setFieldValue={
                                                                                                        setFieldValue
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                            <Field name="companyContact">
                                                                                {(fieldProps: FieldProps) => {
                                                                                    const { field, form } = fieldProps;
                                                                                    const error =
                                                                                        getValue(
                                                                                            form.touched,
                                                                                            field.name
                                                                                        ) &&
                                                                                        getValue(
                                                                                            form.errors,
                                                                                            field.name
                                                                                        );
                                                                                    if (error) {
                                                                                        setCompanyNumberError(
                                                                                            error.toString()
                                                                                        );
                                                                                    } else {
                                                                                        setCompanyNumberError('');
                                                                                    }
                                                                                    return (
                                                                                        <>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={field.value}
                                                                                                onChange={(ev) => {
                                                                                                    const regex =
                                                                                                        /^\d+$/;

                                                                                                    if (
                                                                                                        regex.test(
                                                                                                            ev.target
                                                                                                                .value
                                                                                                        )
                                                                                                    ) {
                                                                                                        setFieldTouched(
                                                                                                            field.name
                                                                                                        );
                                                                                                        setFieldValue(
                                                                                                            field.name,
                                                                                                            ev.target
                                                                                                                .value
                                                                                                        );
                                                                                                    } else if (
                                                                                                        ev.target
                                                                                                            .value ===
                                                                                                        ''
                                                                                                    ) {
                                                                                                        setFieldValue(
                                                                                                            field.name,
                                                                                                            ''
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                                className={`form-control form-phone-border ${error
                                                                                                    ? 'phone-input is-invalid'
                                                                                                    : ''
                                                                                                    }`}
                                                                                                placeholder="Enter Company Contact Number"
                                                                                                maxLength={10}
                                                                                            />
                                                                                            {/* {typeof error ===
                                                                                                'string' && (
                                                                                                <small className="text-danger fs-12">
                                                                                                    {error}
                                                                                                </small>
                                                                                            )} */}
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                        {companyNumberError && (
                                                                            <small className="text-danger fs-12">
                                                                                {companyNumberError}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-section">
                                                            <div className="fs-16 fw-600 mb-3">Company Location</div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyCountryCode">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Country{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <CountryDropdown
                                                                                            id="country"
                                                                                            setFieldValue={
                                                                                                setFieldValue
                                                                                            }
                                                                                            setNameOfCountry={
                                                                                                setNameOfCountry
                                                                                            }
                                                                                            countryFieldValue={
                                                                                                field.value
                                                                                            }
                                                                                            setStateDisabled={
                                                                                                setStateDisabled
                                                                                            }
                                                                                            setCityDisabled={
                                                                                                setCityDisabled
                                                                                            }
                                                                                            setIso2CountryString={
                                                                                                setIso2CountryString
                                                                                            }
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyStateCode">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            State
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <StateDropdown
                                                                                            id="state"
                                                                                            setFieldValue={
                                                                                                setFieldValue
                                                                                            }
                                                                                            stateFieldValue={
                                                                                                field.value
                                                                                            }
                                                                                            setNameOfState={
                                                                                                setNameOfState
                                                                                            }
                                                                                            setStateDisabled={
                                                                                                setStateDisabled
                                                                                            }
                                                                                            stateDisabled={
                                                                                                stateDisabled
                                                                                            }
                                                                                            setCityDisabled={
                                                                                                setCityDisabled
                                                                                            }
                                                                                            Iso2CountryString={
                                                                                                iso2CountryString
                                                                                            }
                                                                                            setIso2StateString={
                                                                                                setIso2StateString
                                                                                            }
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="companyCityCode">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            City{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <CityDropdown
                                                                                            id="city"
                                                                                            setFieldValue={
                                                                                                setFieldValue
                                                                                            }
                                                                                            setNameOfCity={
                                                                                                setNameOfCity
                                                                                            }
                                                                                            cityFieldValue={field.value}
                                                                                            cityDisabled={cityDisabled}
                                                                                            iso2CountryString={
                                                                                                iso2CountryString
                                                                                            }
                                                                                            iso2StateString={
                                                                                                iso2StateString
                                                                                            }
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <Field name="postalCode">
                                                                            {(fieldProps: FieldProps) => {
                                                                                const { field, form } = fieldProps;
                                                                                const error =
                                                                                    getValue(
                                                                                        form.touched,
                                                                                        field.name
                                                                                    ) &&
                                                                                    getValue(form.errors, field.name);
                                                                                return (
                                                                                    <>
                                                                                        <label className="fw-700 mb-2 fs-14">
                                                                                            Postal Code{' '}
                                                                                            <span className="lt-text-error">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={field.value}
                                                                                            maxLength={10}
                                                                                            onChange={(ev) => {
                                                                                                setFieldTouched(
                                                                                                    field.name
                                                                                                );
                                                                                                setFieldValue(
                                                                                                    field.name,
                                                                                                    ev.target.value
                                                                                                );
                                                                                            }}
                                                                                            className={`form-control ${error
                                                                                                ? 'is-invalid'
                                                                                                : ''
                                                                                                }`}
                                                                                            placeholder="Enter Postal/Zip Code"
                                                                                        />
                                                                                        {typeof error === 'string' && (
                                                                                            <small className="text-danger fs-12">
                                                                                                {error}
                                                                                            </small>
                                                                                        )}
                                                                                    </>
                                                                                );
                                                                            }}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex">
                                                                        <div className="form-check me-3">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="flexRadioDefault1"
                                                                                onChange={(ev) => {
                                                                                    setOfferXSelected(true);
                                                                                    setScreenXSelected(false);
                                                                                    setAppError('')
                                                                                    
                                                                                }


                                                                                }
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault1"
                                                                            >
                                                                                OfferX
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="flexRadioDefault"
                                                                                id="flexRadioDefault2"
                                                                                onChange={(ev) => {
                                                                                    setScreenXSelected(true);
                                                                                    setOfferXSelected(false);
                                                                                    setAppError('');
                                                
                                                                                }


                                                                                }
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="flexRadioDefault2"
                                                                            >
                                                                                ScreenX
                                                                            </label>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <small className="text-danger fs-12">
                                                                            {appError}
                                                                        </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            type="button"
                                                            data-bs-dismiss="modal"
                                                            title="Close"
                                                            disabled={isSubmitting}
                                                            aria-label="Close"
                                                            className="btn btn-outline-primary mt-3 me-3"
                                                            onClick={() => {
                                                                resetForm();
                                                                setIso2CountryString('');
                                                                setIso2StateString('');
                                                                setStateDisabled(true);
                                                                setCityDisabled(true);
                                                                setTimeout(() => {
                                                                    success && setSuccess(false);
                                                                }, 500);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary mt-3"
                                                            disabled={isSubmitting}
                                                        >
                                                            Invite
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const Success: React.FC<{ setSuccess: (success: boolean) => void }> = ({ setSuccess }) => {
    return (
        <div className="text-center">
            <i className="bi bi-check-circle lt-text-success-alt fs-68" />
            <h1 className="fw-700 fs-20">Success</h1>
            <p className="mb-3">Invitation sent successfully.</p>
            <button
                type="button"
                onClick={() => {
                    setTimeout(() => {
                        setSuccess(false);
                    }, 500);
                }}
                className="btn btn-primary w-50"
                data-bs-dismiss="modal"
                title="Close"
            >
                OK
            </button>
        </div>
    );
};
export default CreateCandidateInvite;

const getValidationSchema = () =>
    objectYup().shape({
        firstName: stringYup().required('First name is required.'),
        lastName: stringYup().required('Last name is required.'),
        userEmail: stringYup().required('User email is required.').matches(emailRegexp, {
            message: 'Invalid email format.',
        }),
        companyName: stringYup().required('Company name is required.'),
        companyWebsite: stringYup().matches(siteRegexp, 'Invalid url.').required('Company website is required.'),
        companyEmail: stringYup().required('Company email is required.').matches(emailRegexp, {
            message: 'Invalid email format.',
        }),
        companyContact: stringYup().required('Company contact number is required.'),
        contactNumber: stringYup().required('Contact number is required.'),
        companyCountryCode: stringYup().required('Country is required.'),
        companyCityCode: stringYup().required('City is required.'),
        companyStateCode: stringYup().required('State is required.'),
        postalCode: stringYup().required('Postal code is required.'),
    });
