import React, { useState } from 'react';
import Suspend from '../../components/suspend-modal/suspend';
import AllCandidates from './all-candidates';
import ReportedByUser from './reported-by-user';
import Statistics from './statistics';

const CandidateList = () => {
    const [activeTab, setActiveTab] = useState<'all-candidates' | 'reported-by-users'>('all-candidates');
    const [currentId, setCurrentId] = useState('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page_title ox-dashboard-title">
                        <h1 className="">Reported Candidates </h1>
                    </div>
                </div>
            </div>
            <Statistics rerenderKey={rerenderKey} applicationName='OfferX' />
            <div className="card reported-candidates">
                <ReportedByUser />
            </div>

            <Suspend
                candidate={true}
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={currentId}
                setRerenderKey={setRerenderKey}
            />
        </>
    );
};

export default CandidateList;
