import { Country, CountryDetails, State, City } from './../../types/openApi';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const headers = {
    'X-CSCAPI-KEY': 'VFhERlVLdHBrUjBMR1ZEV0FIREh0QjlrMmtJOFZFMHRuRjdzcHRMUg==',
};

export const OpenAPIService = {
    async fetchCountriesWithPhoneCode(): Promise<Country[]> {
        try {
            // Fetch the list of countries
            const countriesResponse: AxiosResponse<Country[]> = await axios.get(
                'https://api.countrystatecity.in/v1/countries',
                {
                    headers,
                }
            );

            const countriesResult = countriesResponse.data;

            // Fetch country details and phone code for each country in parallel
            const mapItems = await Promise.all(
                countriesResult.map(async (item: Country) => {
                    const countryResponse: AxiosResponse<CountryDetails> = await axios.get(
                        `https://api.countrystatecity.in/v1/countries/${item.iso2}`,
                        {
                            headers,
                        }
                    );

                    const phoneCodeData = countryResponse.data;

                    return {
                        ...item,
                        phonecode: phoneCodeData?.phonecode?.replace('+', ''),
                    };
                })
            );

            sessionStorage.setItem('countries', JSON.stringify(mapItems));

            return mapItems;
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },

    async fetchAllStates(): Promise<State[]> {
        try {
            // Fetch the list of states

            const stateResponse: AxiosResponse<State[]> = await axios.get(`https://api.countrystatecity.in/v1/states`, {
                headers,
            });
            const stateResult = stateResponse.data;

            sessionStorage.setItem('states', JSON.stringify(stateResult));

            return stateResult;
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },

    async fetchStatesByCountry(Iso2CountryString?: string): Promise<State[]> {
        try {
            // Fetch the list of states
            const stateResponse: AxiosResponse<State[]> = await axios.get(
                `https://api.countrystatecity.in/v1/countries/${Iso2CountryString}/states`,
                {
                    headers,
                }
            );
            const stateResult = stateResponse.data;

            // sessionStorage.setItem('states', JSON.stringify(stateResult));

            return stateResult;
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },

    async fetchAllCities(iso2CountryString: string): Promise<City[]> {
        try {
            // Fetch the list of cities
            const citiesResponse: AxiosResponse<City[]> = await axios.get(
                `https://api.countrystatecity.in/v1/countries/${iso2CountryString}/cities`,
                {
                    headers,
                }
            );

            const cityResult = citiesResponse.data;

            const existingData = JSON.parse(sessionStorage.getItem('cities')!) || [];

            const uniqueNewRecords = cityResult.filter(
                (newRecord) => !existingData.some((existingRecord: any) => existingRecord.id === newRecord.id)
            );

            const updatedData = [...existingData, ...uniqueNewRecords];

            sessionStorage.setItem('cities', JSON.stringify(updatedData));

            return updatedData;
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },

    async fetchCitiesByStateAndCountry(iso2CountryString: string, iso2StateString?: string): Promise<City[]> {
        try {
            // Fetch the list of cities
            const citiesResponse: AxiosResponse<City[]> = await axios.get(
                `https://api.countrystatecity.in/v1/countries/${iso2CountryString}/states/${iso2StateString}/cities`,
                {
                    headers,
                }
            );

            const cityResult = citiesResponse.data;

            // sessionStorage.setItem('cities', JSON.stringify(cityResult));

            return cityResult;
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },

    async fetchCountriesCodeAndPhoneCode(countryName: string): Promise<Country | null> {
        try {
            const countriesResponse: AxiosResponse<Country[]> = await axios.get(
                'https://api.countrystatecity.in/v1/countries',
                {
                    headers,
                }
            );

            const countriesResult = countriesResponse.data;

            const countryNameToFind = String(countryName).trim().toLowerCase();

            const matchedCountry = countriesResult.find((country) => {
                const result = country.name.trim().toLowerCase() === countryNameToFind;

                return result;
            });

            if (!matchedCountry) {
                // Swal.fire({
                //     title: 'Sorry',
                //     icon: 'warning',
                //     text: `${countryNameToFind} country not found.`,
                // });

                toast.error(`${countryNameToFind} country not found.`);
            }

            if (matchedCountry) {
                const countryResponse: AxiosResponse<CountryDetails> = await axios.get(
                    `https://api.countrystatecity.in/v1/countries/${matchedCountry.iso2}`,
                    {
                        headers,
                    }
                );

                const phoneCodeData = countryResponse.data;

                return {
                    ...matchedCountry,
                    phonecode: phoneCodeData?.phonecode?.replace('+', ''),
                };
            } else {
                return null;
            }
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
            throw error;
        }
    },
};
