import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import history from '../../history';
import { routes } from '../routes/routes-names';
import PageLoader from '../../components/loader';
import AuthSocial from '../registration/social';
import { authService } from '../../lib/api/auth';

type Props = RouteComponentProps;

const Main: React.FC<Props> = ({ location }) => {
    const { ConfirmAdminInvitationId } = queryString.parse(location.search);
    const { loading, user } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (loading || !user) return;

        history.push(routes.companyList);
    }, [loading, user]);

    return loading ? (
        <PageLoader />
    ) : (
        <AuthSocial
            invitationId={typeof ConfirmAdminInvitationId === 'string' ? ConfirmAdminInvitationId : undefined}
        />
    );
};

export default Main;
