import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { starRegexp } from '../../lib/utils/validation';
import { SortTypeEnum } from '../../types';
import PageLoader from '../loader';
import imgNoResult from '../../assets/icons/offer-no-result.svg';
import { SuspensionDecisionEnum } from '../../types/suspension';
import { filterValueText } from '../../types/invitations';

export type TableFilterType = {
    dataIndex: string;
    value: string;
};

export type TableSortType = {
    dataIndex: string;
    value: SortTypeEnum;
};

export type TableObjectType = {
    loading?: boolean;
    columns: TableColumnType[];
    hideSort?: boolean;
    fixedHeader?: boolean;
    fixedCols: number;
    sort: TableSortType | null;
    setSort: (value: TableSortType) => void;
    paginationCurrent: number;
    paginationArray: TableDataType[][];
    border?: boolean;
    reverseHead?: boolean;
    overflow?: boolean;
    hideFilter?: boolean;
    // inputFilter?: { value: string; dataIndex: string } | null;
    // setInputFilter: (value: { value: string; dataIndex: string }) => void;
    inputFilter: TableFilterType[];
    setInputFilter: (value: TableFilterType) => void;
};

export type TableColumnType = {
    dataIndex?: string;
    title: string;
    hideSort?: boolean;
    hideFilter?: boolean;
    inputFilter?: (value: any) => string;
    render?: (item: TableDataType) => ReactNode | string | number;
    width?: string;
    children?: TableColumnType[];
    type?: string;
};

type TableHeadColumnType = TableColumnType & {
    colSpan?: number;
    rowSpan?: number;
    fixed?: boolean;
};

export type TableDataType = {
    [key: string]: any;
};

const TableObject: React.FC<TableObjectType> = ({
    loading,
    columns,
    fixedCols,
    sort,
    setSort,
    paginationCurrent,
    paginationArray,
    reverseHead,
    inputFilter,
    setInputFilter,
}) => {
    const handleSort = useCallback(
        (key: string) => {
            if (sort) {
                if (sort.dataIndex === key) {
                    if (sort.value === SortTypeEnum.asc) {
                        setSort({ dataIndex: key, value: SortTypeEnum.desc });
                    } else {
                        setSort({ dataIndex: key, value: SortTypeEnum.asc });
                    }
                } else {
                    setSort({ dataIndex: key, value: SortTypeEnum.asc });
                }
            } else {
                setSort({ dataIndex: key, value: SortTypeEnum.asc });
            }
        },
        [sort, setSort]
    );

    const childrenNumLevels = useCallback((cols: TableColumnType[], i: number) => {
        let count = i;
        cols.forEach((col) => {
            if (col.children) {
                const childCount = childrenNumLevels(col.children, i + 1);
                count = count < childCount ? childCount : count;
            }
        });
        return count;
    }, []);

    const headRowsCount = useMemo(() => childrenNumLevels(columns, 1), [columns, childrenNumLevels]);

    const fixedBodyCols = useMemo(() => {
        const fixedColumns = columns.slice(0, fixedCols);
        let count = 0;

        function calcCount(cols: TableColumnType[]) {
            cols.forEach((col) => {
                if (!col.children) {
                    count++;
                } else {
                    calcCount(col.children);
                }
            });
        }

        calcCount(fixedColumns);
        return count;
    }, [columns, fixedCols]);

    const statusSelectFilter = (col: any) => {
        return (
            <div className="d-flex">
                <select
                    className="form-select form-control-sm mt-1 lt-filter-box"
                    onChange={(ev) => {
                        col.dataIndex &&
                            setInputFilter({
                                dataIndex: col.dataIndex,
                                value: ev.target.value,
                            });
                        sessionStorage.setItem(col.dataIndex, JSON.stringify(ev.target.value));
                    }}
                >
                    <option value={''}>Select</option>
                    <option value={'0'}>Approved</option>
                    <option value={'1'}>Suspended</option>
                    <option value={'2'}>Document Not Uploaded</option>
                    <option value={'3'}>Document Uploaded</option>
                    <option value={'5'}>Need More Evidence</option>
                    <option value={'6'}>Partially Approved</option>
                    <option value={'4'}>Rejected</option>
                </select>
            </div>
        );
    };

    const reportCompStatusSelectFilter = (col: any) => {
        return (
            <div className="d-flex">
                <select
                    className="form-select form-control-sm mt-1 cursor-pointer"
                    // value={inputFilter ? (inputFilter.dataIndex === col.dataIndex ? inputFilter.value : '') : ''}
                    value={filterValueText(col)}
                    onChange={(ev) => {
                        col.dataIndex &&
                            setInputFilter({
                                dataIndex: col.dataIndex,
                                value: ev.target.value,
                            });
                        sessionStorage.setItem(col.dataIndex, JSON.stringify(ev.target.value));
                    }}
                >
                    <option value={''}>Select</option>
                    <option value={'0'}>Active</option>
                    <option value={'1'}>Rejected</option>
                    <option value={'2'}>Approved</option>
                </select>
            </div>
        );
    };

    const ratingSelectFilter = (col: any) => {
        return (
            <div className="d-flex">
                <select
                    className="form-select form-control-sm mt-1 cursor-pointer"
                    // value={inputFilter ? (inputFilter.dataIndex === col.dataIndex ? inputFilter.value : '') : ''}
                    value={filterValueText(col)}
                    onChange={(ev) => {
                        col.dataIndex &&
                            setInputFilter({
                                dataIndex: col.dataIndex,
                                value: ev.target.value,
                            });
                        sessionStorage.setItem(col.dataIndex, JSON.stringify(ev.target.value));
                    }}
                >
                    <option value={''}>Select</option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                    <option value={'3'}>3</option>
                    <option value={'4'}>4</option>
                    <option value={'5'}>5</option>
                </select>
            </div>
        );
    };

    const mapFixedHeadColumns = useMemo(() => {
        function setFixedChildren(children: TableColumnType[]): TableHeadColumnType[] {
            return children.map((col) => ({
                ...col,
                fixed: true,
                ...(col.children && { children: setFixedChildren(col.children) }),
            }));
        }

        return columns.map((col, i) => {
            if (i < fixedCols) {
                return {
                    ...col,
                    fixed: true,
                    ...(col.children && { children: setFixedChildren(col.children) }),
                };
            } else {
                return col;
            }
        });
    }, [columns, fixedCols]);

    const mapHeadColumns = useMemo(() => {
        let headCols: TableHeadColumnType[][] = [mapFixedHeadColumns];
        let reverseRows: TableHeadColumnType[][] = [];

        function createRow(headCols: TableHeadColumnType[][], i: number) {
            let refactorRow: TableHeadColumnType[] = [];
            headCols[reverseHead ? 0 : i].forEach((col) => {
                if (col.children) {
                    const colSpan = childrenNumLevels(col.children, col.children.length);
                    const refactorCol = {
                        title: col.title,
                        ...(col.fixed && { fixed: col.fixed }),
                        ...(col.width && { width: col.width }),
                        colSpan,
                    };
                    if (reverseHead) {
                        refactorRow = [...refactorRow, ...col.children];
                        if (reverseRows[i]) {
                            reverseRows[i] = [...reverseRows[i], refactorCol];
                        } else {
                            reverseRows.push([refactorCol]);
                        }
                    } else {
                        refactorRow = [...refactorRow, refactorCol];
                        if (headCols[i + 1]) {
                            headCols[i + 1] = [...headCols[i + 1], ...col.children];
                        } else {
                            headCols.push(col.children);
                        }
                    }
                } else {
                    const rowSpan = headRowsCount - i;
                    refactorRow.push({
                        ...col,
                        ...(rowSpan > 1 && !col.rowSpan && { rowSpan }),
                    });
                }
            });
            headCols[reverseHead ? 0 : i] = refactorRow;
        }

        for (let i = 0; i < headRowsCount; i++) {
            createRow(headCols, i);
        }

        return reverseHead ? [headCols[0], ...reverseRows.reverse()] : headCols; // Преобразован в headRows
    }, [headRowsCount, mapFixedHeadColumns, childrenNumLevels, reverseHead]);

    const mapBodyColumns = useMemo(() => {
        function map(cols: TableColumnType[]) {
            cols.forEach((item) => {
                if (item.children) {
                    map(item.children);
                } else {
                    mapBodyColumns.push(item);
                }
            });
        }

        let mapBodyColumns: TableColumnType[] = [];
        map(columns);
        return mapBodyColumns;
    }, [columns]);

    useEffect(() => {
        setTimeout(() => {
            const elementsTh = document.querySelectorAll('[data-fixed-th]');
            const elementsTd = document.querySelectorAll('[data-fixed-td]');
            if (!elementsTh.length || !elementsTd.length) return;

            function setStyles(elements: NodeListOf<Element>) {
                Array.prototype.forEach.call(elements, (item) => {
                    const width = item.getBoundingClientRect().width;
                    const left = item.offsetLeft;
                    item.style.position = 'sticky';
                    item.style.zIndex = '1';
                    item.style.width = `${width}px`;
                    item.style.left = `${left}px`;
                });
            }

            setStyles(elementsTh);
            setStyles(elementsTd);
        }, 100);
    }, [fixedCols]);

    return (
        <>
            <table className="table table-hover mb-0 text-nowrap">
                <thead>
                    {mapHeadColumns.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((col, colIndex) => (
                                <th
                                    rowSpan={col.rowSpan}
                                    colSpan={col.colSpan}
                                    key={col.dataIndex || colIndex}
                                    data-fixed-th={col.fixed}
                                    data-border-th=""
                                    className={'lt_' + col.dataIndex}
                                    style={{ width: col.width || '' }}
                                >
                                    <div style={{ width: col.width }} className="offer-table-th">
                                        {col.dataIndex ? (
                                            <>
                                                {col.title && (
                                                    <>
                                                        {col.title}
                                                        {!col.hideSort && (
                                                            <>
                                                                <span className="ms-1" />
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        col.dataIndex && handleSort(col.dataIndex)
                                                                    }
                                                                >
                                                                    {col.dataIndex === sort?.dataIndex &&
                                                                    sort?.value === SortTypeEnum.asc ? (
                                                                        <i className="bi bi-chevron-up text-primary" />
                                                                    ) : col.dataIndex === sort?.dataIndex &&
                                                                      sort?.value === SortTypeEnum.desc ? (
                                                                        <i className="bi bi-chevron-down text-primary" />
                                                                    ) : (
                                                                        <i className="bi bi-chevron-down" />
                                                                    )}
                                                                </button>
                                                            </>
                                                        )}
                                                        {!col.hideFilter && (
                                                            <>
                                                                {col.dataIndex === 'suspendStatus' ? (
                                                                    statusSelectFilter(col)
                                                                ) : col.dataIndex === 'rating' ? (
                                                                    ratingSelectFilter(col)
                                                                ) : col.dataIndex === 'suspensionDecision' ? (
                                                                    reportCompStatusSelectFilter(col)
                                                                ) : (
                                                                    <div className="d-flex position-relative">
                                                                        <input
                                                                            type="text"
                                                                            // value={
                                                                            //     inputFilter.find(
                                                                            //         (item) =>
                                                                            //             item.dataIndex === col.dataIndex
                                                                            //     )?.value || ''
                                                                            // }
                                                                            value={filterValueText(col)}
                                                                            onChange={(ev) => {
                                                                                if (col.dataIndex) {
                                                                                    setInputFilter({
                                                                                        dataIndex: col.dataIndex,
                                                                                        value:
                                                                                            col.type === 'numberRating'
                                                                                                ? ev.target.value.replace(
                                                                                                      starRegexp,
                                                                                                      ''
                                                                                                  )
                                                                                                : ev.target.value,
                                                                                    });
                                                                                    sessionStorage.setItem(
                                                                                        col.dataIndex,
                                                                                        JSON.stringify(ev.target.value)
                                                                                    );
                                                                                }

                                                                                if (ev.target.value == '') {
                                                                                    sessionStorage.removeItem(
                                                                                        col.dataIndex!
                                                                                    );
                                                                                }
                                                                            }}
                                                                            className="form-control form-control-sm mt-1 lt-filter-box"
                                                                        />
                                                                        <i className="bi bi-search lt-filter-icon" />
                                                                        {col.dataIndex &&
                                                                            sessionStorage.getItem(col.dataIndex) && (
                                                                                <>
                                                                                    {sessionStorage.getItem(
                                                                                        col.dataIndex
                                                                                    ) !== '' && (
                                                                                        <i
                                                                                            key={col.dataIndex}
                                                                                            className="bi bi-x-circle lt-clear-icon lt-text-error"
                                                                                            onClick={() => {
                                                                                                if (col.dataIndex) {
                                                                                                    setInputFilter({
                                                                                                        dataIndex:
                                                                                                            col.dataIndex,
                                                                                                        value: '',
                                                                                                    });
                                                                                                    sessionStorage.removeItem(
                                                                                                        col.dataIndex
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            col.title
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {!loading && (
                    <tbody>
                        {paginationArray[paginationCurrent]?.length > 0 ? (
                            paginationArray[paginationCurrent]?.map((row, rowIndex) => (
                                <tr key={rowIndex} style={{ position: 'relative' }}>
                                    {mapBodyColumns.map((col, colIndex) => {
                                        return (
                                            <td
                                                key={col.dataIndex || colIndex}
                                                data-fixed-td={colIndex < fixedBodyCols || undefined}
                                                valign="middle"
                                            >
                                                {col.render && col.render(row)}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr className="nodataRow">
                                <td valign="middle" colSpan={columns.length}>
                                    <div className="offer-container">
                                        <img src={imgNoResult} alt="" className="offer-no-result-img" />
                                        <div className="mt-2">No results</div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
            {loading && <PageLoader />}
        </>
    );
};

export default TableObject;
