import React, { useEffect, useState } from 'react';
import { candidateCountType } from '../../types/candidate';
import { candidateService } from '../../lib/api/candidate';
import PageLoader from '../../components/loader';
import { getColorStatus } from '../../lib/utils/cards-get-color-status';

type Props = {
    rerenderKey: number;
    applicationName: string;
};

const Statistics: React.FC<Props> = ({ rerenderKey, applicationName }) => {
    const [countsData, setCountsData] = useState<candidateCountType | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                if (applicationName === "OfferX") {
                    setLoading(true);
                    const { resultObject: data } = await candidateService.getCount();
                    setCountsData(data);
                }
                else {
                    const {
                        result: { items, count },
                    } = await candidateService.getScreenXCandidateList();
                    const activeCandidatesCount = items.filter((value) => value.isActive === true).length;
                    const val: candidateCountType = {
                        totalCandidatesCount: items.length,
                        totalCandidatesGrowth: 0,
                        activeCandidatesCount: activeCandidatesCount,
                        activeCandidatesGrowth: 0,
                        suspendedCandidatesCount: 0,
                        suspendedCandidatesGrowth: 0,
                        reportedByCompaniesCandidatesCount: 0,
                        reportedByCompaniesCandidatesGrowth: 0
                    }
                    setCountsData(val);
                }
            } catch (error) {
                console.log('Company count error', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [rerenderKey]);

    return (
        <>
            {countsData ? (
                <div className="row mt-4">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <span className="lt-dashboard-text">Total candidates</span>
                                <i className="bi bi-info-circle lt-tooltip ms-1">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Total candidates in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                    </span>
                                </i>
                                <span className="float-end fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-people" />
                                </span>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{countsData.totalCandidatesCount}</div>
                            {/* <div>
                                <span className={getColorStatus(countsData.totalCandidatesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.totalCandidatesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.totalCandidatesGrowth}%
                                </span>
                                <span className='ms-1'> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <span className="lt-dashboard-text">Active candidates</span>
                                <i className="bi bi-info-circle lt-tooltip ms-1">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Active candidates in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                    </span>
                                </i>
                                <span className="float-end fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-people" />
                                </span>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{countsData.activeCandidatesCount}</div>
                            {/* <div>
                                <span className={getColorStatus(countsData.activeCandidatesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.activeCandidatesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.activeCandidatesGrowth}%
                                </span> <span className='ms-1'> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    {applicationName === "OfferX" &&
                        <>
                            <div className="col-lg-3 col-xl-3">
                                <div className="card lt-shape-card p-3 mb-4">
                                    <div className="fw-600 fs-14">
                                        <span className="lt-dashboard-text">Suspended candidates</span>
                                        <i className="bi bi-info-circle lt-tooltip ms-1">
                                            <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                                Suspended candidates in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                            </span>
                                        </i>
                                        <span className="float-end fs-28 lt-dashboard-icon">
                                            <i className="lt-dashboard-text bi bi-people" />
                                        </span>
                                    </div>
                                    <div className="fw-400 fs-32 lt-text-link-primary">
                                        {' '}
                                        {countsData?.suspendedCandidatesCount}
                                    </div>
                                    {/* <div>
                                <span className={getColorStatus(countsData.suspendedCandidatesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.suspendedCandidatesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.suspendedCandidatesGrowth}%
                                </span><span className='ms-1'> than last month</span>
                            </div> */}
                                </div>
                            </div>
                        </>
                    }
                    {applicationName === "OfferX" &&
                        <>
                            <div className="col-lg-3 col-xl-3">
                                <div className="card lt-shape-card p-3 mb-4">
                                    <div className="fw-600 fs-14">
                                        <span className="lt-dashboard-text">Reported by companies</span>
                                        <i className="bi bi-info-circle lt-tooltip ms-1">
                                            <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">
                                                Reported by companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                            </span>
                                        </i>
                                        <span className="float-end fs-28 lt-dashboard-icon">
                                            <i className="lt-dashboard-text bi bi-building" />
                                        </span>
                                    </div>
                                    <div className="fw-400 fs-32 lt-text-link-primary">
                                        {' '}
                                        {countsData?.reportedByCompaniesCandidatesCount}
                                    </div>
                                    {/* <div>
                                <span className={getColorStatus(countsData.reportedByCompaniesCandidatesGrowth) ? 'lt-text-success fw-600' : 'lt-text-error fw-600'}>
                                    <i className={getColorStatus(countsData.reportedByCompaniesCandidatesGrowth) ? 'bi bi-caret-up-fill' : 'bi bi-caret-down-fill'} /> {countsData.reportedByCompaniesCandidatesGrowth}%
                                </span> <span className='ms-1'> than last month</span>
                            </div> */}
                                </div>
                            </div>
                        </>
                    }
                </div>
            ) : null}
        </>
    );
};
export default Statistics;
