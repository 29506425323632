import { CompanyCandidateRatingColor } from './colors';

export const NewChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            align: 'center',
            labels: {
                usePointStyle: true,
                pointStyle: 'circle',
                boxWidth: 10,
                boxHeight: 10,
            },
        },
        title: {
            display: false,
            text: 'Chart',
        },
    },
};

export const NewChartJoiningStatusOptions = {
    labels: ['Contract compliance', 'Contract violation'],
    datasets: [
        {
            data: [0, 0],
            backgroundColor: CompanyCandidateRatingColor,
            borderWidth: 0.5,
        },
    ],
};
