import { axiosInstance } from './index';
import { EmployeeCreateResponseType, EmployeeFormType, EmployeeListResponseType } from '../../types/employee';
import { candidateRejectResponseType } from '../../types/suspension';

export const employeeService = {
    async getList() {
        const { data } = await axiosInstance.get('AdminUserManagement/GetAdminUsersPaginatedList');
        return data as EmployeeListResponseType;
    },

    async create(data: EmployeeFormType) {
        const { data: responseData } = await axiosInstance.post('AdminUserManagement', data);
        return responseData as EmployeeCreateResponseType;
    },

    async update(data: EmployeeFormType & { id: string }) {
        await axiosInstance.put('AdminUserManagement', data);
    },

    async resend(id: string) {
        await axiosInstance.put(`AdminUserManagement/${id}/Resend`);
    },

    async delete(id: string) {
       const {data: responseData} = await axiosInstance.delete(`AdminUserManagement/${id}`);
       return responseData as candidateRejectResponseType
    },
};
