import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { dashboardService } from '../../../../lib/api/dashboard';
import { GetOffersCountType, seriesType } from '../../../../types/dashboard';
import PageLoader from '../../../../components/loader';
import { months, totalMonths } from '../utils/month';
import { OffersChartColor } from '../utils/colors';
import YearsDropdown from '../../../../components/dropdown';
import LineChart from '../../LineChart';

type Props = {
    selectedYear: number;
};

type ChartDataType = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor?: string;
        borderColor?: string;
        borderWidth?: number;
    }[];
    tension: number;
};

const OffersChart: React.FC<Props> = ({ selectedYear }) => {
    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<GetOffersCountType>();
    const [seriesArray, setSeriesArray] = useState<seriesType[]>([]);
    const [dropdownYear, setDropdownYear] = useState<number>(selectedYear);
    const [chartData, setChartData] = useState<any>();

    useMemo(() => {
        setDropdownYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                await dashboardService.getOffersCount(dropdownYear).then((res) => {
                    const value = res.resultObject;
                    setData(res.resultObject);

                    setSeriesArray([
                        {
                            name: 'Offers released',
                            data: value.offersReleased,
                            color: '#0096ff',
                        },
                        {
                            name: 'Offers accepted',
                            data: value.offersAccepted,
                            color: '#2BC04C',
                        },
                        {
                            name: 'Offers declined',
                            data: value.offersRejected,
                            color: '#f0c916',
                        },
                        {
                            name: 'Offers expired',
                            data: value.offersExpired,
                            color: '#D83B01',
                        },
                        {
                            name: 'Offers retracted',
                            data: value.offersRetracted,
                            color: '#0017FF',
                        },
                    ]);
                });
            } catch (error) {
                console.log('getOffersCount services', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dropdownYear]);

    const options: ApexOptions = {
        chart: {
            id: 'area-datetime',
            type: 'area',
            height: 350,
            zoom: {
                autoScaleYaxis: true,
            },
        },
        colors: OffersChartColor,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: months,
            max: totalMonths(dropdownYear),
        },
        legend: {
            position: 'bottom',
        },
    };

    function createChartData(seriesArray: seriesType[]) {
        const labels: string[] = [];
        const tension = 0.1;
        const datasets: {
            label: string;
            data: number[];
            backgroundColor?: string;
            borderColor?: string;
            borderWidth?: number;
        }[] = [];

        const sliceData = totalMonths(dropdownYear);
        months.slice(0, sliceData).map((value) => labels.push(value));

        seriesArray.forEach((series) => {
            datasets.push({
                label: series.name,
                data: series.data,
                backgroundColor: series.color,
                borderColor: series.color,
                borderWidth: 1,
            });
        });

        return { labels, datasets, tension };
    }

    useEffect(() => {
        if (seriesArray) {
            const result = createChartData(seriesArray);

            setChartData(result);
        }
    }, [seriesArray]);

    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <div>
                    <div>
                        <div className="d-flex mb-3">
                            <div className="card-title fs-16 fw-600 mb-0">
                                Total offers{' '}
                                <i className="bi bi-info-circle lt-tooltip">
                                    <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                        Total number of offers in a year and month
                                    </span>
                                </i>
                            </div>
                            <YearsDropdown setDropdownYear={setDropdownYear} />
                        </div>
                        <div>
                            <div className="row no-gutters">
                                <div className="col-lg-8">
                                    {/* <ReactApexChart options={options} series={seriesArray} type="line" height={350} /> */}
                                    <div className="custom-line-graph">
                                        <LineChart data={chartData} />
                                    </div>
                                </div>
                                <div className="col-lg-4 border-start px-4">
                                    <div className="fw-600">Offers year to date ({dropdownYear})</div>
                                    <div className="d-flex py-3 border-bottom">
                                        <div>
                                            <h6 className="fs-14 mb-1">Offers released</h6>
                                            <div className="fs-18 fw-700">{data?.totalOffersReleased}</div>
                                        </div>
                                        <div className="ms-auto">
                                            <i className="bi bi-envelope-open lt-icon-circle lt-bg-primary" />
                                        </div>
                                    </div>
                                    <div className="d-flex py-3 border-bottom">
                                        <div>
                                            <h6 className="fs-14 mb-1">Offers accepted</h6>
                                            <div className="fs-18 fw-700">{data?.totalOffersAccepted}</div>
                                        </div>
                                        <div className="ms-auto">
                                            <i className="bi bi-envelope-open lt-icon-circle lt-alert-bg-green" />
                                        </div>
                                    </div>
                                    <div className="d-flex py-3 border-bottom">
                                        <div>
                                            <h6 className="fs-14 mb-1">Offers declined</h6>
                                            <div className="fs-18 fw-700">{data?.totalOffersRejected}</div>
                                        </div>
                                        <div className="ms-auto">
                                            <i className="bi bi-envelope-open lt-icon-circle lt-alert-bg-warning-fill" />
                                        </div>
                                    </div>
                                    <div className="d-flex py-3 border-bottom">
                                        <div>
                                            <h6 className="fs-14 mb-1">Offers expired</h6>
                                            <div className="fs-18 fw-700">{data?.totalOffersExpired}</div>
                                        </div>
                                        <div className="ms-auto">
                                            <i className="bi bi-envelope-open lt-icon-circle lt-alert-bg-error" />
                                        </div>
                                    </div>
                                    <div className="d-flex pt-3">
                                        <div>
                                            <h6 className="fs-14 mb-1">Offers retracted</h6>
                                            <div className="fs-18 fw-700">{data?.totalOffersRetracted}</div>
                                        </div>
                                        <div className="ms-auto">
                                            <i className="bi bi-envelope-open lt-icon-circle lt-bg-alert-darker" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OffersChart;
