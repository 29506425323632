import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Swal from 'sweetalert2';
import siteLogo from '../../assets/img/offerx-logo.png';
import { signOut } from '../../redux/user';
import { routes } from '../routes/routes-names';
import Notify from './notify';
import { authService } from '../../lib/api/auth';
import { UserResponseType } from '../../types/auth';
import history from '../../history';
import { RootState } from '../../redux';
import { isConsentFeatureEnabled } from '../../lib/utils/dictionary';
import { getConsentWorkflowSettings } from '../../redux/consentWorkFlow';
import { getCompanyRegistrationSettings } from '../../redux/companyRegistration';
import affiliateImg from "../../assets/img/Layer_1.svg"

const Sidebar = () => {
    const dispatch = useDispatch();
    const consentWorkFlowSettings = useSelector((state: RootState) => state.consentWorkFlow);

    const timeout = 900000; // 15 Minutes session timeout
    const [isIdle, setIsIdle] = useState(false);
    const handleOnActive = () => setIsIdle(false);
    const handleOnIdle = () => setIsIdle(true);
    const [email, setEmail] = useState('');
    const [showSidebarTab, setShowSidebarTab] = useState(true);
    const { user } = useSelector((state: RootState) => state.user);
    const [isCompanyRegistrationTabEnabled, setIsCompanyRegistrationTabEnabled] = useState<boolean | null>(null);

    const companyRegistrationFlowSettings = useSelector((state: RootState) => state.companyRegistrationFlow);

    useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
    });

    const emailArray = [
        'teja.emani@wallero.com',
        'nikitha.muthuswamy@wallero.com',
        'eswar.pinnelli@wallero.com',
        'saikiran.potlapalli@wallero.com',
        'rohith.karlapalem@wallero.com',
        'shirini.takkallapally@wallero.com',
        'Shirini.Takkallapally@offerx.net',
        'saikiran.potlapalli@offerx.net',
        'Rohith.Karlapalem@offerx.net',
        'rajiv.atluri@wallero.com',
        'rajiv.atluri@offerx.net',
    ];

    function isEmailInArray(email: string | undefined, array: string[]) {
        return array.indexOf(email?.toLowerCase() || '') !== -1;
    }

    useEffect(() => {
        if (isEmailInArray(user?.email, emailArray)) {
            setShowSidebarTab(false);
            history.push(routes.companyList);
        } else {
            setShowSidebarTab(true);
            history.push(routes.dashboard);
        }
    }, [email]);

    useEffect(() => {
        if (!isIdle) return;
        if (isIdle) {
            dispatch(signOut());
            Swal.close();
        }
    }, [isIdle, dispatch]);

    useEffect(() => {
        if (!consentWorkFlowSettings?.settings) dispatch(getConsentWorkflowSettings());

        const getList = async () => {
            try {
                // const data: UserResponseType = await authService.getUser();
                const { resultObject } = await authService.getUser();
                setEmail(resultObject.email);
            } catch (err) {}
        };

        getList();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (companyRegistrationFlowSettings.settings == null) {
                    dispatch(getCompanyRegistrationSettings());
                } else {
                    setIsCompanyRegistrationTabEnabled(companyRegistrationFlowSettings.settings);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        // }, [dispatch, user?.isOffboardingReviewsEnabled, companyRegistrationFlowSettings.settings]);
    }, [dispatch, companyRegistrationFlowSettings.settings]);

    return (
        <aside
            aria-hidden="true"
            aria-labelledby="offcanvasNavbarLabel"
            className="offerx_left_nav d-flex flex-column flex-shrink-0 offcanvas offcanvas-start"
            id="offcanvasNavbar"
            tabIndex={-1}
        >
            {/* <button className='text-end'><i className='bi bi-x-lg'></i></button> */}
            <div className="text-end">
                <button
                    type="button"
                    className="btn-close fw-600 desktop-hide"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="fs-20 fw-700 offerx-logo-link px-0">
                <img src={siteLogo} alt="OfferX Logo" className="site-logo" />
                <span className="fs-12 fw-400"> | Admin Panel</span>
            </div>
            <nav className="nav flex-column offerx_nav_links mb-auto">
                {!showSidebarTab && (
                    <>
                        <NavLink
                            to={routes.companyRegistration}
                            className="nav-link hover-link"
                            activeClassName="active"
                        >
                            <span className="sidebar-ms-4">
                                <i className="bi bi-chat-left-text"></i> Company Registrations
                            </span>
                        </NavLink>
                        <NavLink
                            to={routes.companyVerification}
                            className="nav-link hover-link"
                            activeClassName="active"
                        >
                            <span className="sidebar-ms-4">
                                <i className="bi bi-patch-check"></i> Company Verification
                            </span>
                        </NavLink>
                        {/* <NavLink to={routes.companyList} className="nav-link hover-link" activeClassName="active">
                            <span className="sidebar-ms-4">
                                <i className="bi bi-buildings" /> Company List
                            </span>
                        </NavLink> */}
                    </>
                )}
                {showSidebarTab && (
                    <>
                        <NavLink to={routes.dashboard} className="nav-link hover-link" activeClassName="active">
                            <i className="bi bi-speedometer2" />
                            <span>Dashboard</span>
                        </NavLink>
                        <a
                            className="nav-link hover-link side-dropdown"
                            data-bs-toggle="collapse"
                            href="#companyCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="companyCollapse"
                            onClick={() => {
                                if (isCompanyRegistrationTabEnabled) {
                                    history.push(routes.companyRegistration);
                                } else {
                                    history.push(routes.companyVerification);
                                }
                            }}
                        >
                            <i className="bi bi-building" /> Companies
                            <span>
                                <i className="bi bi-chevron-down ms-2" />
                            </span>
                        </a>
                        <div className="collapse" id="companyCollapse">
                            {isCompanyRegistrationTabEnabled && (
                                <NavLink
                                    to={routes.companyRegistration}
                                    className="nav-link hover-link"
                                    activeClassName="active"
                                >
                                    <span className="sidebar-ms-4">
                                        <i className="bi bi-chat-left-text"></i> Company Registrations
                                    </span>
                                </NavLink>
                            )}
                            <NavLink
                                to={routes.companyVerification}
                                className="nav-link hover-link"
                                activeClassName="active"
                            >
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-patch-check"></i> Company Verification
                                </span>
                            </NavLink>
                            <NavLink to={routes.companyList} className="nav-link hover-link" activeClassName="active">
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-buildings" /> Company List
                                </span>
                            </NavLink>
                            <NavLink
                                to={routes.hrmsCompanyList}
                                className="nav-link hover-link"
                                activeClassName="active"
                            >
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-list-check" /> HRMS Company List
                                </span>
                            </NavLink>
                            <NavLink
                                to={routes.reportedCompanyList}
                                className="nav-link hover-link"
                                activeClassName="active"
                            >
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-flag"></i> Reported Companies
                                </span>
                            </NavLink>
                        </div>
                    </>
                )}
                {showSidebarTab && (
                    <>
                        <a
                            className="nav-link hover-link side-dropdown"
                            data-bs-toggle="collapse"
                            href="#candidateCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="candidateCollapse"
                            onClick={() => history.push(routes.candidateList)}
                        >
                            <i className="bi bi-people" /> Candidates
                            <span>
                                <i className="bi bi-chevron-down ms-2" />
                            </span>
                        </a>
                        <div className="collapse" id="candidateCollapse">
                            <NavLink to={routes.candidateList} className="nav-link hover-link" activeClassName="active">
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-person-lines-fill"></i> Candidate List
                                </span>
                            </NavLink>
                            <NavLink
                                to={routes.reportedCandidateList}
                                className="nav-link hover-link"
                                activeClassName="active"
                            >
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-flag"></i> Reported Candidates
                                </span>
                            </NavLink>
                        </div>
                    </>
                )}

                {showSidebarTab && (
                    <>
                        <a
                            className="nav-link hover-link side-dropdown"
                            data-bs-toggle="collapse"
                            href="#affiliateCollapse"
                            role="button"
                            aria-expanded="false"
                            aria-controls="affiliateCollapse"
                            onClick={() => {
                                history.push(routes.affiliateUser);
                            }}
                        >
                           <img src={affiliateImg} alt='affiliate_img' width={14} className='me-2' /> Affiliate Marketing
                            <span>
                                <i className="bi bi-chevron-down ms-2" />
                            </span>
                        </a>
                        <div className="collapse" id="affiliateCollapse">
                            <NavLink to={routes.affiliateUser} className="nav-link hover-link" activeClassName="active">
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-patch-check"></i> Users
                                </span>
                            </NavLink>
                            <NavLink
                                to={routes.affiliateCompanies}
                                className="nav-link hover-link"
                                activeClassName="active"
                            >
                                <span className="sidebar-ms-4">
                                    <i className="bi bi-buildings" /> Companies
                                </span>
                            </NavLink>
                        </div>

                        {isConsentFeatureEnabled(consentWorkFlowSettings) && (
                            <NavLink to={routes.policy} className="nav-link hover-link" activeClassName="active">
                                <i className="bi bi-file-earmark-text" />
                                <span>Legal Documents</span>
                            </NavLink>
                        )}
                        {/*<div className="nav-link disabled">
                    <i className="bi bi-chat-left-text" />
                    <span>Reviews & Replies</span>
                </div>*/}

                        <div className="nav-link hover-link dropend">
                            <Notify reRenderKey={Date.now()} />
                        </div>
                    </>
                )}
            </nav>

            <nav className="nav offerx_nav_links">
                {showSidebarTab && (
                    <NavLink to={routes.userManagement} className="nav-link" activeClassName="active">
                        <i className="bi bi-people" />
                        <span>Manage Employees</span>
                    </NavLink>
                )}
                {/*<div className="nav-link disabled">
                    <i className="bi bi-gear" />
                    <span>Settings</span>
            </div>*/}
                <button onClick={() => dispatch(signOut())} className="nav-link offerx-nav-bottom-link text-start">
                    <i className="bi bi-box-arrow-right" />
                    <span>Log out</span>
                </button>
            </nav>
        </aside>
    );
};

export default Sidebar;
