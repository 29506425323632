import React, { useEffect, useState } from 'react';

import CityDropdown from '../dropdown/city';

import StateDropdown from '../dropdown/states';

import CountryDropdown from '../dropdown/country';
import PageLoader from '../loader';

type Props = {
    id: string;

    countryValue?: string;

    cityValue: string;

    stateValue: string;
};

const StateCityCodeValue: React.FC<Props> = ({ id, countryValue, cityValue, stateValue }) => {
    const [showSkeleton, setShowSkeleton] = useState(true);

    const [iso2CountryString, setIso2CountryString] = useState('');

    const [iso2StateString, setIso2StateString] = useState('');

    useEffect(() => {
        const alphabetPattern = /^[A-Za-z]+$/;

        if (!countryValue) {
            setTimeout(() => {
                setShowSkeleton(false);
            }, 2000);
        } else if (alphabetPattern.test(countryValue)) {
            setTimeout(() => {
                setShowSkeleton(false);
            }, 2000);
        }

        if (!countryValue) {
            setShowSkeleton(false);
        } else if (stateValue === 'null' || cityValue === 'null') {
            setShowSkeleton(false);
        } else if (stateValue === '' || cityValue === '') {
            setShowSkeleton(false);
        }
    }, [cityValue, countryValue, stateValue]);

    return (
        <>
            <div>
                <div style={{ display: `${showSkeleton ? 'block' : 'none'}` }} className="skeleton-loader"></div>
                {countryValue && stateValue && cityValue && typeof +countryValue === 'number' ? (
                    <div style={{ display: `${showSkeleton ? 'none' : 'flex'}` }}>
                        <>
                            {stateValue && iso2CountryString ? (
                                <>
                                    <StateDropdown
                                        id="stateResult"
                                        Iso2CountryString={iso2CountryString}
                                        stateFieldValue={stateValue}
                                        setIso2StateString={setIso2StateString}
                                        setShowSkeleton={setShowSkeleton}
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                ''
                            )}
                            {cityValue && iso2CountryString && iso2StateString ? (
                                <>
                                    <CityDropdown
                                        id={id + Math.random() + 1}
                                        cityFieldValue={cityValue}
                                        iso2CountryString={iso2CountryString}
                                        iso2StateString={iso2StateString}
                                        setShowSkeleton={setShowSkeleton}
                                    />
                                    &nbsp;
                                </>
                            ) : (
                                ''
                            )}
                            &nbsp;
                            {countryValue && (
                                <>
                                    <CountryDropdown
                                        id="countryResult"
                                        hideName={true}
                                        countryFieldValue={countryValue}
                                        setIso2CountryString={setIso2CountryString}
                                        setShowSkeleton={setShowSkeleton}
                                    />
                                </>
                            )}
                            &nbsp;
                        </>
                    </div>
                ) : (
                    'NA'
                )}
            </div>
        </>
    );
};

export default StateCityCodeValue;
