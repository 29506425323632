import { ApiResponseType } from './api';

export enum SocialEnum {
    microsoft,
    google,
    linkedIn,
    amazon,
}
export type SocialEnumKeyType = keyof typeof SocialEnum;

export enum HighestEducationEnum {
    NohighSchoolDiplomaOrGed,
    HighSchoolDiplomaOrGed,
    Vocational_TradeDegree_MilitaryTraining,
    SomeCollege_NotEnrolledNow,
    CurrentlyEnrolledInCollege,
    BachelorsOrAssociateDegree,
    GraduateWorkOrGraduateDegree,
}

export enum UserRoleEnum {
    manager,
    candidate,
}

export enum GenderEnum {
    male,
    female,
    preferNotToDisclose,
}

export enum ManagerPermissionEnum {
    superAdmin,
    business,
    management,
    developer,
}

export type socialSignInType = {
    Email: string;
    AuthType: SocialEnum;
    Token: string;
};

export type JoinByInvitationType = {
    email: string;
    invitationId: string;
};

export type AuthResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        accessToken: string;
    };
};

export type UserType = {
    id: string;
    active: boolean;
    email: string;
    role: UserRoleEnum;
    firstName: string;
    lastName: string;
    middleName: string;
    website: string;
    jobTitle: string | null;
    rating: {
        value: number;
    };
    companyId: string | null;
    companyName: string | null;
    avatarUrl: string;
    permissionTypes: ManagerPermissionEnum[] | null;
    isConsentFeatureEnabled: boolean | null;
};

export type UserResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: UserType;
};
