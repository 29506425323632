import { ApiResponseType } from './api';

export enum ContractComplianceStateEnum {
    ContractCompliance,
    ContractViolation,
};

export enum DiscardEnum {
    Active,
    Rejected,
    Approved,
};

export type ReviewRequestType = {
    reviewText: string;
    stars: number;
    contractComplianceState: ContractComplianceStateEnum;
};

export type CreateReviewRequestType = ReviewRequestType & {
    offerId: string;
};

export type EditReviewRequestType = ReviewRequestType & {
    companyReviewId?: string;
    candidateReviewId?: string;
};

export type ExistingReviewType = {
    id: string;
    reviewText: string;
    stars: {
        value: number;
    };
    contractComplianceState: ContractComplianceStateEnum;
};

export type CompanyReviewType = {
    id: string;
    companyAvatarUrl: string;
    managerId: string;
    companyId: string;
    offerId: string;
    companyName: string;
    date: Date;
    reviewText: string;
    stars: {
        value: number;
    };
    contractComplianceState: ContractComplianceStateEnum;
    comments: [];
};

export type CandidateReviewType = {
    id: string;
    candidateAvatarUrl: string;
    candidateFirstName: string;
    candidateLastName: string;
    candidateId: string;
    companyId: string;
    date: Date;
    offerId: string;
    reviewText: string;
    stars: {
        value: number;
    };
    contractComplianceState: ContractComplianceStateEnum;
    comments: [];
};

export type ReviewResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: ExistingReviewType;
};
