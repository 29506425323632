import React, { useState } from 'react';
import Suspend from '../../components/suspend-modal/suspend';
import AllCandidates from './all-candidates';
import ReportedByUser from './reported-by-user';
import Statistics from '../candidate-list-old/statistics';

const CandidateList = () => {
    const [activeTab, setActiveTab] = useState<'all-candidates' | 'reported-by-users'>('all-candidates');
    const [currentId, setCurrentId] = useState('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);

    return (
        <>

            <Statistics rerenderKey={rerenderKey} applicationName='OfferX' />
            {/* <Statistics rerenderKey={rerenderKey}/> */}
            <div className="card">
                <AllCandidates
                    rerenderKey={rerenderKey}
                    setRerenderKey={setRerenderKey}
                    setCurrentId={setCurrentId}
                    setShowAdminModal={setShowAdminModal}
                />
            </div>

            <Suspend
                candidate={true}
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={currentId}
                setRerenderKey={setRerenderKey}
            />
        </>
    );
};

export default CandidateList;
