import { useState } from 'react';
import PageLoader from '../../components/loader';
import CompanyListScreenX from './companyListScreenX';
import EmployerVerification from '.';

const CompanyListLanding = () => {
    const [activeTab, setActiveTab] = useState<'offerX' | 'screenX'>('offerX');
    const [loading, setLoading] = useState(false);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="">Company List</h1>
                </div>
            </div>
            {loading ? (
                <PageLoader />
            ) : (
                <>
                    <div className="card">
                        <div className="employees-table">
                            <nav>
                                <div className="d-flex bd-highlight lt-nav-bar">
                                    <div className="w-100 bd-highlight">
                                        <div className="nav nav-tabs nav-justified" id="nav-tab">
                                            <button
                                                className={`nav-link col-2 fs-16 ${
                                                    activeTab === 'offerX' ? 'active' : ''
                                                }`}
                                                type="button"
                                                onClick={() => setActiveTab('offerX')}
                                            >
                                                OfferX
                                            </button>
                                            <button
                                                className={`nav-link col-2 fs-16 ${
                                                    activeTab === 'screenX' ? 'active' : ''
                                                }`}
                                                type="button"
                                                onClick={() => setActiveTab('screenX')}
                                            >
                                                ScreenX
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                            {activeTab === 'offerX' && <EmployerVerification />}
                            {activeTab === 'screenX' && <CompanyListScreenX />}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default CompanyListLanding;
