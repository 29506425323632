import React, { useState } from 'react'
import OffersChart from './tabs/offers-chart'
import TotalRegistrationsChart from './tabs/total-registrations-chart'
import TotalRevenueChart from './tabs/total-revenue-chart'

type Props = {
    selectedYear: number
}

const TotalOffersWidget: React.FC<Props> = ({ selectedYear }) => {
    const [activeTab, setActiveTab] = useState<'Offers' | 'registrations' | 'subscriptions'>('Offers');

    return (
        <div className="col-lg-12 col-xl-8">
            <div className="card border mb-4">
                <div className="card-body px-0 pb-0 lt-card-body">
                    <nav>
                        <div className="d-flex bd-highlight lt-nav-bar">
                            <div className="w-100 bd-highlight">
                                <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                                    <div className={`nav-link col-2 fs-16 ${activeTab === 'Offers' ? 'active' : ''
                                        }`} onClick={() => setActiveTab('Offers')}>Offers</div>
                                    <div className={`nav-link col-2 fs-16 ${activeTab === 'registrations' ? 'active' : ''
                                        }`} onClick={() => setActiveTab('registrations')}>Registrations</div>
                                    {/*<div className={`nav-link col-2 fs-16 ${activeTab === 'subscriptions' ? 'active' : ''
                                        }`} onClick={() => setActiveTab('subscriptions')}>Subscriptions & Revenue</div>*/}
                                    <div className="dropdown nav-link col-2 fs-16 d-none">
                                        <div className="dropdown-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-three-dots" />
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <li className="dropdown-item" data-bs-target="#pills-additional" data-bs-toggle="pill" id="pills-additional-tab" role="tab">Action</li>
                                            <li className="dropdown-item">Another action</li>
                                            <li className="dropdown-item">Something else here</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="tab-content p-4" id="pills-tabContent">
                        <div aria-labelledby="pills-additional-tab" className="tab-pane fade" id="pills-additional" role="tabpanel">
                            Additional tab
                        </div>
                        {activeTab === 'Offers' && (<OffersChart selectedYear={selectedYear} />)}
                        {activeTab === 'registrations' && (<TotalRegistrationsChart selectedYear={selectedYear} />)}
                        {/*{activeTab === 'subscriptions' && (<TotalRevenueChart />)}*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TotalOffersWidget