import React, { useState } from 'react';
import Suspend from '../../components/suspend-modal/suspend';
import AllCandidates from './all-candidates';
import ReportedByUser from './reported-by-user';
import Statistics from '../candidate-list-old/statistics';
import AllCandidatesScreenX from './allCandidateScreenX';

const CandidateScreenX = () => {
    const [activeTab, setActiveTab] = useState<'all-candidates' | 'reported-by-users'>('all-candidates');
    const [currentId, setCurrentId] = useState('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);

    return (
        <>
            <div className="card">
                <AllCandidatesScreenX
                    rerenderKey={rerenderKey}
                    setRerenderKey={setRerenderKey}
                    setCurrentId={setCurrentId}
                    setShowAdminModal={setShowAdminModal}
                />
            </div>
        </>
    );
};

export default CandidateScreenX;
