import React from 'react';
import { Link } from 'react-router-dom';

import RatingStar from '../rating-star';
import { ContractComplianceStateEnum } from '../../types/review';
import DefultCompAvthar from '../../assets/img/company-lg-icon.png';
import DefultCandiAvthar from '../../assets/img/avatars-lg.png';
import { routes } from '../../containers/routes/routes-names';
import Avatar from '../../assets/img/candidate-avatars-lg.png';
import PdfViewer from '../pdf-viewer';
import DocxViewer from '../docx-viewer';
import { getFileExtension } from '../../lib/utils/file-extension';
import { SuspendStatusEnum } from '../../types/suspension';

type Props = {
    id?: string;
    avatar: string | null;
    banner: string | null;
    name: string;
    activity?: string;
    location: string | null;
    address?: string;
    releaseOfferId?: string;
    aboutMe?: string | null;
    aboutUs?: string | null;
    reviewsCount: number;
    rating: number;
    contractCompliance: number;
    contractViolation: number;
    phone: string | null;
    userCountryCode: string;
    email: string;
    website: string | null;
    education?: {
        highestEducation: string;
        institutionName: string;
        graduationYear: number;
    };
    editCandidate?: boolean;
    editCompany?: boolean;
    resume?: string;
    foundedYear?: number;
    reviewList: {
        id: string;
        avatar: string;
        name: string;
        text: string;
        date: Date;
        contractComplianceState: ContractComplianceStateEnum;
        rating: number;
        creatorId: string;
        offerId: string;
    }[];
    allCompaniesAcceptedOffersCount?: number;
    currentCompanyLastEventDetailedText?: string | null;
    reOfferId?: string | null;
    candidate?: string;
    suspendProfile: (id: string) => void;
    suspendCandidate: (id: string) => void;
    suspendStatus?: SuspendStatusEnum;
    jobTitle?: string;
    industry?: string;
    suspendReasonOtherText?: string;
    suspendReasons?: string[];
    aadhar?: string | null;
};

const Profile: React.FC<Props> = ({
    id,
    avatar,
    name,
    activity,
    location,
    address,
    aboutMe,
    aboutUs,
    rating,
    contractCompliance,
    contractViolation,
    phone,
    userCountryCode,
    email,
    website,
    foundedYear,
    reviewList,
    education,
    resume,
    candidate,
    suspendProfile,
    suspendCandidate,
    suspendStatus,
    jobTitle,
    industry,
    suspendReasonOtherText,
    suspendReasons,
    aadhar,
}) => {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="page_title">
                        <div className="mt-1">
                            <h1>
                                <Link
                                    to={candidate ? routes.candidateList : routes.companyList}
                                    className="lt-text-primary fs-18 fw-600"
                                >
                                    <i className="bi bi-chevron-left" /> {candidate ? 'Candidate' : 'Company'} List
                                </Link>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="profile-banner-box rounded-0">
                    <div className="mt-3 mb-4 profile-details">
                        <div>
                            <div className="d-sm-flex align-items-center my-4">
                                <img
                                    src={avatar ? avatar : candidate ? DefultCandiAvthar : DefultCompAvthar}
                                    alt={candidate ? 'Candidate Profile Image' : 'Company Logo'}
                                    className="avatar avatar-lg"
                                />
                                <div className="ms-sm-3 mt-sm-0 mt-2">
                                    <h5 className="fs-18 fw-700 text-capitalize">{name}</h5>
                                    <p className="text-capitalize fs-14 fw-400">{location?.toLowerCase()}</p>
                                    {industry}
                                    {jobTitle && <p className="fs-14 fw-400">{jobTitle}</p>}
                                </div>
                                <div className="d-flex justify-content-start ms-auto">
                                    <div className="dropdown lt-dropdown-menu">
                                        <button
                                            className="btn btn-secondary dropdown-toggle lt-border"
                                            id="MoreOption"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {suspendStatus === SuspendStatusEnum.active && (
                                                <span className="lt-text-success">
                                                    Active <i className="bi bi-chevron-down fs-14" />
                                                </span>
                                            )}
                                            {suspendStatus === SuspendStatusEnum.suspended && (
                                                <span className="lt-text-error">
                                                    Suspended <i className="bi bi-chevron-down fs-14" />
                                                </span>
                                            )}
                                        </button>
                                        <ul className="dropdown-menu lt-shadow-sm py-0" aria-labelledby="MoreOption">
                                            <li>
                                                <button
                                                    type="button"
                                                    className="dropdown-item lt-text-success"
                                                    onClick={() => suspendProfile(Date())}
                                                    disabled={suspendStatus === SuspendStatusEnum.active}
                                                >
                                                    {suspendStatus === SuspendStatusEnum.active
                                                        ? 'Active'
                                                        : 'Unsuspend'}
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    type="button"
                                                    disabled={suspendStatus === SuspendStatusEnum.suspended}
                                                    className="dropdown-item lt-text-error"
                                                    onClick={() => suspendCandidate(Date())}
                                                >
                                                    {suspendStatus === SuspendStatusEnum.suspended
                                                        ? 'Suspended'
                                                        : 'Suspend'}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-9 col-md-7 ps-lg-0 ps-md-0 border-right">
                    {suspendStatus === SuspendStatusEnum.suspended && (
                        <div className="border-bottom profile-card py-0 mb-3 rounded-0">
                            {suspendReasons?.length ? (
                                <div className="text-danger">
                                    <b className="pb-1">Reasons for suspension:</b>
                                    <ol className="pt-2">
                                        {/* <li>{suspendReasons?.join(', ').replace(/, ([^,]*)$/, '  $1')}</li> */}
                                        {/* {suspendReasons.map(item => <li>item</li>)} */}

                                        {suspendReasons.map((number) => (
                                            <li>{number}</li>
                                        ))}
                                    </ol>
                                </div>
                            ) : null}
                            {suspendReasonOtherText ? (
                                <div className="text-danger">
                                    <b className="pb-1">Other reason:</b>
                                    <p className="py-2">{suspendReasonOtherText} </p>
                                </div>
                            ) : null}
                        </div>
                    )}
                    {aboutMe && (
                        <div className="card profile-card py-0 border-bottom rounded-0">
                            <div>
                                <h4 className="fs-18 fw-700">About me</h4>
                                <p className="fs-14 fw-400 mb-3">{aboutMe}</p>
                            </div>
                        </div>
                    )}

                    {aboutUs && (
                        <div className="card profile-card border-bottom rounded-0">
                            <div>
                                <h4 className="fs-18 fw-700">About Us</h4>
                                <p className="mb-3 fs-14 fw-400">
                                    <span className="fs-16 fw-600">Founded:</span> {foundedYear}
                                </p>
                                <p className="mb-3 fs-14 fw-400">{aboutUs}</p>
                            </div>
                        </div>
                    )}

                    <div className="card profile-card">
                        <div>
                            <div className="accordion lt_Offerx_accordion" id="accordion1">
                                <div className="accordion-item border-0">
                                    <div className="accordion-header" id="ReviewOverview">
                                        <button
                                            className="accordion-button px-0 pt-0 show"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#ReviewOverviewOne"
                                            aria-expanded="false"
                                            aria-controls="ReviewOverviewOne"
                                        >
                                            <h2 className="fw-700 fs-18 m-0">
                                                Reviews from {candidate ? 'employers' : 'candidates'}
                                            </h2>
                                        </button>
                                        <div className="d-lg-flex px-0 mb-3">
                                            <div className="col">
                                                {reviewList.length ? (
                                                    <p className="fs-14 fw-400">
                                                        Reviews from {reviewList.length}{' '}
                                                        {!candidate
                                                            ? reviewList.length > 1
                                                                ? ' candidates'
                                                                : ' candidate'
                                                            : reviewList.length > 1
                                                            ? ' employers'
                                                            : 'employer'}
                                                    </p>
                                                ) : (
                                                    <p className="fs-14 fw-400">No reviews</p>
                                                )}
                                                <div className="fs-32 fw-600 star-icon align-items-center d-flex">
                                                    {rating ? <span className="pe-2">{rating}</span> : null}
                                                    <RatingStar value={rating} />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-xl-4 col-12">
                                                <ul className="list-group">
                                                    <li className="list-group-item d-flex justify-content-between align-items-center lt-text-success fw-600 border-bottom-0">
                                                        Contract compliance
                                                        <span className="badge lt-snack-bg-success rounded-pill fs-14 lt-text-success p-2">
                                                            {contractCompliance}
                                                        </span>
                                                    </li>
                                                    <li className="list-group-item d-flex justify-content-between align-items-center lt-text-error fw-600">
                                                        Contract violation
                                                        <span className="badge lt-snack-bg-error rounded-pill fs-14 lt-text-error p-2">
                                                            {contractViolation}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="ReviewOverviewOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="ReviewOverview"
                                    >
                                        <div className="">
                                            {reviewList.map((item) => {
                                                return (
                                                    <div className="lt-review-li" key={item.id}>
                                                        <div className="d-flex align-items-center bd-highlight">
                                                            <div className="flex-shrink-1 bd-highlight">
                                                                <img
                                                                    src={item.avatar ? item.avatar : Avatar}
                                                                    className="avatar avatar-md"
                                                                    alt={item.avatar}
                                                                />
                                                            </div>
                                                            <div className="p-2 w-100 bd-highlight">
                                                                <h2 className="fw-700 fs-16">{item.name}</h2>
                                                                <span className="fs-14 fw-400">
                                                                    {new Date(item.date).toLocaleString('en-in', {
                                                                        day: 'numeric',
                                                                        month: 'numeric',
                                                                        year: 'numeric',
                                                                    })}{' '}
                                                                    •{' '}
                                                                    {new Date(item.date).toLocaleString('en-in', {
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="fs-24 fw-600 d-flex align-items-center my-2">
                                                            <RatingStar value={item.rating} />
                                                            <span className="fw-600 fs-14 ms-2">
                                                                {item.rating} out of 5 stars
                                                            </span>
                                                        </div>
                                                        {item.contractComplianceState ===
                                                            ContractComplianceStateEnum.ContractCompliance && (
                                                            <div className="lt-status lt-status-success rounded-pill fw-600 fw-12 mb-3">
                                                                Contract compliance
                                                            </div>
                                                        )}
                                                        {item.contractComplianceState ===
                                                            ContractComplianceStateEnum.ContractViolation && (
                                                            <div className="lt-status lt-status-error rounded-pill fw-600 fw-12 mb-3">
                                                                Contract violation
                                                            </div>
                                                        )}
                                                        <p className="fs-14 fw-400">{item.text}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {reviewList.length ? (
                        <div className="card d-none">
                            <div className="">
                                <div className="accordion lt_offerx_accordion" id="accordion2">
                                    <div className="accordion-item border-0">
                                        <div className="accordion-header" id="ReviewHeader">
                                            <button
                                                className="accordion-button lt-text-primary"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#ReviewHeaderOne"
                                                aria-expanded="false"
                                                aria-controls="ReviewHeaderOne"
                                            >
                                                <h2 className="fw-700 fs-18 m-0">
                                                    Reviews {name} has given to other{' '}
                                                    {candidate ? 'companies' : 'candidates'}{' '}
                                                    <span className="lt-status lt-status-default rounded-pill fs-12 fw-400 px-2 ms-1">
                                                        {reviewList.length
                                                            ? `${reviewList.length} ${
                                                                  reviewList.length > 1 ? 'Reviews' : 'Review'
                                                              }`
                                                            : null}{' '}
                                                    </span>
                                                </h2>
                                            </button>
                                        </div>
                                        <div
                                            id="ReviewHeaderOne"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="ReviewHeader"
                                        >
                                            <div className="px-4"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="col-lg-3 col-12 mt-4 mt-lg-0 pe-lg-0 pe-md-0">
                    <div className="card profile-card py-0">
                        <div className="text-wrap">
                            <h4 className="fs-18 fw-700">Contact info</h4>
                            <p className="mb-2">
                                <span className="fw-700 fs-12">Address</span>
                            </p>
                            <p className="mb-2 text-capitalize fs-12 fw-400">{address?.toLowerCase()}</p>
                            <p className="mb-1 d-flex align-items-center">
                                <i className="bi bi-telephone fs-16 me-2 lt-text-primary-alt" />{' '}
                                <a href={`tel:${phone}`} className="fs-12 profile-email text-break lt-text-primary">
                                    {candidate ? userCountryCode && '+' + userCountryCode.replace('+', '') : ''} {phone}
                                </a>
                            </p>
                            <p className="mb-1 d-flex align-items-center">
                                <i className="bi bi-envelope fs-16 me-2 lt-text-primary-alt" />{' '}
                                <a href={`mailto:${email}`} className="fs-12 profile-email text-break lt-text-primary">
                                    {email}
                                </a>
                            </p>
                            {aadhar && (
                                <p className="mb-1 d-flex align-items-center">
                                    <div className="d-flex mb-2 align-items-center justify-content-start">
                                        <i className="bi bi-person-vcard fs-16 me-2 lt-text-primary-alt" />
                                        <span className="fs-12">**** **** {aadhar}</span>
                                    </div>
                                </p>
                            )}
                            <p className="mb-1 d-flex align-items-center">
                                <i
                                    className={
                                        candidate
                                            ? 'bi bi-link-45deg fs-18 me-1 lt-text-primary-alt'
                                            : 'bi bi-globe fs-16 me-2 lt-text-primary-alt'
                                    }
                                />{' '}
                                <a
                                    href={`${website}`}
                                    className="fs-12 profile-email text-break lt-text-primary"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    {website}
                                </a>
                            </p>
                        </div>
                        <hr></hr>
                    </div>
                    {education &&
                        (education.highestEducation || education.institutionName || !!education.graduationYear) && (
                            <div className="card profile-card py-0">
                                <h1 className="fw-700 fs-18 mb-3 mt-0">Education</h1>
                                {education.highestEducation && (
                                    <div className="mb-3">
                                        <div className="fs-12 fw-600">Highest Education</div>
                                        <div className="fs-12 fw-400 text-capitalize">{education.highestEducation}</div>
                                    </div>
                                )}
                                {education.institutionName && (
                                    <div className="mb-3">
                                        <div className="fs-12 fw-600">Institution name</div>
                                        <div className="fs-12 text-capitalize">{education.institutionName}</div>
                                    </div>
                                )}
                                {!!education.graduationYear && (
                                    <div>
                                        <div className="fs-12 fw-600">Graduation year</div>
                                        <div className="fs-12 fw-400">{education.graduationYear}</div>
                                    </div>
                                )}
                                <hr></hr>
                            </div>
                        )}
                    {resume && (
                        <>
                            <div className="card profile-card box-out-pad mt-0 pt-0">
                                <div className="d-flex align-items-center">
                                    <h1 className="fw-700 fs-18 w-100 my-0">Resume</h1>
                                    <div className="text-end">
                                        <a
                                            href={resume}
                                            download={`${name}.${getFileExtension(resume)}`}
                                            title="Download Resume"
                                        >
                                            <i className="bi bi-download" />
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-3">
                                    <div className="doc-full-size" style={{ height: '160px', overflow: 'hidden' }}>
                                        {getFileExtension(resume) === 'pdf' && <PdfViewer url={resume} />}
                                        {getFileExtension(resume) === 'docx' && <DocxViewer url={resume} />}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary w-100 border-default"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight"
                                        aria-controls="offcanvasRight"
                                    >
                                        See full resume
                                    </button>
                                </div>
                            </div>
                            <div
                                className="offcanvas offcanvas-end lt-offcanvas-end"
                                tabIndex={-1}
                                id="offcanvasRight"
                                aria-labelledby="offcanvasRightLabel"
                            >
                                <div className="offcanvas-header">
                                    <h5 id="offcanvasRightLabel">Resume preview - {name}</h5>
                                    <button
                                        type="button"
                                        className="btn-close text-reset"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="offcanvas-body">
                                    <div className="doc-full-size">
                                        {getFileExtension(resume) === 'pdf' && <PdfViewer url={resume} />}
                                        {getFileExtension(resume) === 'docx' && <DocxViewer url={resume} />}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile;
