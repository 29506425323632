import React, { useEffect, useState } from 'react';

interface RoundTimerProps {
    duration: number;
}

const RoundTimer: React.FC<RoundTimerProps> = ({ duration }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    const timerValue = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    const circumference = 2 * Math.PI * 48;
    const progress = (duration - timeLeft) / duration;
    const offset = circumference * (2 - progress);

    return (
        <div className='timer-loader'>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="round-timer">
                    <svg className="circle" width="100" height="100">
                        <circle
                            r="48"
                            cx="50"
                            cy="50"
                            strokeDasharray={`${circumference} ${circumference}`}
                            strokeDashoffset={offset}
                        />
                    </svg>
                    <div className="timer-value">{timerValue}</div>
                </div>
                <p className="fs-14 mt-2">Please wait for a moment...</p>
            </div>
        </div>
    );
};

export default RoundTimer;
