import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Swal from 'sweetalert2';

import { companyService } from '../../lib/api/company';
import { CompanyProfileType } from '../../types/company';
import PageLoader from '../../components/loader';
import Error404 from '../../components/errors/404';
import Profile from '../../components/profile/index';
import { suspensionService } from '../../lib/api/suspension';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import Suspend from '../../components/suspend-modal/suspend';

type Props = RouteComponentProps<{ id: string }>;

const CompanyView: React.FC<Props> = ({ match }) => {
    const { id } = match.params;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<CompanyProfileType | null>(null);
    const [currentId, setCurrentId] = useState<string>('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);

    const getCompanyProfile = useCallback(async () => {
        if (!id) return;

        try {
            const { resultObject } = await companyService.getId(id);
            setData(resultObject);
        } catch (error: any) {
            console.log('company services getId errors', error.response);
        } finally {
            setLoading(false);
        }
    }, [rerenderKey]);

    useEffect(() => {
        getCompanyProfile();
    }, [rerenderKey, getCompanyProfile]);

    const suspendCompany = (item: any) => {
        setShowAdminModal(true);
        setCurrentId(item);
    };

    const unsuspend = useCallback(async () => {
        try {
            setLoading(true);
            await suspensionService.unSuspendByAdmin(id);
            setRerenderKey(Date.now());
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [setRerenderKey]);

    const suspendProfile = () => {
        Swal.fire({
            title: Message.unsuspendCompany,
            icon: WarningIcon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Unsuspend',
            showConfirmButton: true,
            showCloseButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                unsuspend();
                Swal.fire({
                    icon: SuccessIcon,
                    title: SweetAlertTitle.Success,
                    text: Message.unsuspendSuccess,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
            }
        });
    };

    return loading ? (
        <PageLoader />
    ) : data ? (
        <>
            <Profile
                id={data.id}
                avatar={data.avatarUrl}
                banner={data.bannerUrl}
                name={data.name}
                industry={data.industry}
                location={`${data.cityDistrict}, ${data.state}`}
                address={data.companyAddress}
                aboutUs={data.aboutUs}
                reviewsCount={data.reviewsCount}
                rating={data.rating.value}
                contractCompliance={data.contractComplianceQuantity}
                contractViolation={data.contractViolationQuantity}
                phone={data.phone}
                email={data.email}
                reviewList={data.companyReviews.map((item) => ({
                    id: item.id,
                    avatar: item.candidateAvatarUrl,
                    name: `${item.candidateFirstName} ${item.candidateLastName}`,
                    text: item.reviewText,
                    date: item.date,
                    contractComplianceState: item.contractComplianceState,
                    rating: item.stars.value,
                    creatorId: item.candidateId,
                    offerId: item.offerId,
                }))}
                foundedYear={data.foundedYear}
                suspendProfile={suspendProfile}
                suspendCandidate={suspendCompany}
                suspendStatus={data.suspendStatus}
                suspendReasonOtherText={data.suspendReasonOtherText}
                suspendReasons={data.suspendReasons}
                website={data.website}
                userCountryCode={data?.userCountryCode!}
            />

            <Suspend
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={id}
                setRerenderKey={setRerenderKey}
            />
        </>
    ) : (
        <Error404 />
    );
};

export default CompanyView;
