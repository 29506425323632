import React, { ReactChild } from 'react';
import Header from './header';
import Sidebar from './sidebar';

type Props = {
    children: ReactChild;
};

const Layout: React.FC<Props> = ({ children }) => {
    return (
        <div>
            <Header />
            <Sidebar />

            <section className="page-content">
                <div className="container-fluid"><div className="company-page-container">{children}</div></div>
            </section>
        </div>
    );
};

export default Layout;
