import React, { useCallback, useMemo } from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { object as objectYup, string as stringYup, array as arrayYup } from 'yup';
import getValue from 'lodash/get';
import Swal from 'sweetalert2'
import { ManagerPermissionEnum } from '../../../types/auth';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import { emailRegexp } from '../../../lib/utils/validation';
import { EmployeeFormType, EmployeeType } from '../../../types/employee';
import { getPermissionText } from '../../../lib/utils/dictionary';
import { employeeService } from '../../../lib/api/employee';
import { arrayFromEnum } from '../../../lib/utils/emun';
import { closeModal } from '../../../lib/utils/close-modal';
import { Message, SuccessIcon, SweetAlertTitle } from '../../../lib/utils/messages';

type Props = {
    activeEmployee: EmployeeType | null;
    getList: () => Promise<void>;
};

const Create: React.FC<Props> = ({ activeEmployee, getList }) => {
    const editMode = useMemo(() => !!activeEmployee, [activeEmployee]);

    const initialFormData = useMemo(
        () => ({
            firstName: activeEmployee?.firstName || '',
            middleName: activeEmployee?.middleName || '',
            lastName: activeEmployee?.lastName || '',
            email: activeEmployee?.email || '',
            permissionTypeList: activeEmployee?.permissionTypeList || [],
        }),
        [activeEmployee]
    );

    const initialErrors = useInitialErrors(initialFormData, getValidationSchema());

    const submitForm = useCallback(
        async (values: EmployeeFormType) => {
            try {
                if (activeEmployee) {
                    await employeeService.update({ ...values, id: activeEmployee.id })
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.employeeUpdate,
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    await employeeService.create(values);
                    Swal.fire({
                        icon: SuccessIcon,
                        title: SweetAlertTitle.Success,
                        text: Message.addEmployee,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                await getList();
                closeModal('AddEmployee');
            } catch (err) {
                console.log('error employeeService create', err);
            }
        },
        [getList, activeEmployee]
    );

    return (
        <Formik
            onSubmit={submitForm}
            enableReinitialize
            initialValues={initialFormData}
            validationSchema={getValidationSchema()}
            initialErrors={initialErrors}
        >
            {(formikProps: FormikProps<EmployeeFormType>) => {
                const { handleSubmit, isSubmitting, setFieldTouched, setFieldValue, resetForm } = formikProps;

                return (
                    <form onSubmit={handleSubmit}>
                        <div
                            className="modal fade"
                            id="AddEmployee"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="AddEmployeeLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content lt-modal-content">
                                    <div className="modal-header lt-modal-header">
                                        <h5 className="modal-title w-100 fs-20 fw-700 mb-4" id="InviteaFriendLabel">
                                            {editMode ? 'Edit an employee' : 'Add employee'}
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => resetForm()}
                                            title="Close"
                                        />
                                    </div>
                                    <div className="modal-body lt-modal-body">
                                        <div className="mb-3">
                                            <Field name="firstName">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        (getValue(form.errors, field.name) as string);
                                                    return (
                                                        <>
                                                            <label className="fw-700 mb-2 fs-14">
                                                                First name <span className="lt-text-error">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                            />
                                                            <small className="text-danger">{error}</small>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="mb-3">
                                            <Field name="middleName">
                                                {(fieldProps: FieldProps) => {
                                                    const { field } = fieldProps;
                                                    return (
                                                        <>
                                                            <label className="fw-700 mb-2 fs-14">Middle name</label>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className="form-control"
                                                                placeholder=""
                                                            />
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="mb-3">
                                            <Field name="lastName">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        (getValue(form.errors, field.name) as string);
                                                    return (
                                                        <>
                                                            <label className="fw-700 mb-2 fs-14">
                                                                Last name <span className="lt-text-error">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                            />
                                                            <small className="text-danger">{error}</small>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="mb-3">
                                            <Field name="email">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        (getValue(form.errors, field.name) as string);
                                                    return (
                                                        <>
                                                            <label className="fw-700 mb-2 fs-14">
                                                                Email <span className="lt-text-error">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                value={field.value}
                                                                onChange={(ev) => {
                                                                    setFieldTouched(field.name);
                                                                    setFieldValue(field.name, ev.target.value);
                                                                }}
                                                                className={`form-control ${error ? 'is-invalid' : ''}`}
                                                                placeholder=""
                                                                disabled={editMode}
                                                            />
                                                            <small className="text-danger">{error}</small>
                                                        </>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <div className="mb-3">
                                            <label className="fw-700 mb-2 fs-14">
                                                Roles <span className="lt-text-error">*</span>
                                            </label>
                                            <Field name="permissionTypeList">
                                                {(fieldProps: FieldProps) => {
                                                    const { field, form } = fieldProps;
                                                    const error =
                                                        getValue(form.touched, field.name) &&
                                                        getValue(form.errors, field.name);
                                                    return (
                                                        <div className="d-lg-flex">
                                                            {arrayFromEnum(ManagerPermissionEnum)
                                                                .filter(
                                                                    (valueEnum) =>
                                                                        valueEnum !== ManagerPermissionEnum.superAdmin
                                                                )
                                                                .map((valueEnum) => (
                                                                    <div key={valueEnum} className="me-4 d-flex align-items-center">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={field.value.includes(valueEnum)}
                                                                            onChange={() => {
                                                                                setFieldTouched(field.name);
                                                                                const hasValue =
                                                                                    field.value.includes(valueEnum);
                                                                                const newValue = hasValue
                                                                                    ? field.value.filter(
                                                                                          (
                                                                                              item: ManagerPermissionEnum
                                                                                          ) => item !== valueEnum
                                                                                      )
                                                                                    : [...field.value, valueEnum];
                                                                                setFieldValue(field.name, newValue);
                                                                            }}
                                                                            id={getPermissionText(valueEnum)}
                                                                            className="me-2"
                                                                        />
                                                                        <label
                                                                            htmlFor={getPermissionText(valueEnum)}
                                                                            className={error ? 'text-danger' : ''}
                                                                        >
                                                                            {getPermissionText(valueEnum)}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        {editMode && (
                                            <div className="alert alert-warning p-2 mb-0 fs-12">
                                                Note: Any modification in assigned role(s) will be reflected only after
                                                employee re-login
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-12 col-sm">
                                                <button
                                                    type="button"
                                                    data-bs-dismiss="modal"
                                                    onClick={() => resetForm()}
                                                    className="btn btn-outline-primary w-100 mt-3"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className="col-12 col-sm">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary w-100 mt-3"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting && (
                                                        <span className="spinner-border spinner-border-sm" />
                                                    )}{' '}
                                                    {editMode ? 'Save changes' : 'Add'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{' '}
                    </form>
                );
            }}
        </Formik>
    );
};

export default Create;

const getValidationSchema = () =>
    objectYup().shape({
        firstName: stringYup()
            .required('First name is required and must be at least 3 characters.')
            .min(3, 'First name must be at least 3 characters.'),
        lastName: stringYup()
            .required('Last name is required and must be at least 3 characters.')
            .min(3, 'Last name must be at least 3 characters.'),
        email: stringYup().required('Email is required.').matches(emailRegexp, {
            message: 'Invalid email format.',
        }),
        permissionTypeList: arrayYup().min(1, 'Required field.'),
    });
