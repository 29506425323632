import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getJoinStatusText } from '../../lib/utils/dictionary';
import Table from '../../components/table';
import avatar from '../../assets/img/company-lg-icon.png';
import PageLoader from '../../components/loader';
import { CompanyListRequestType, CompanyProfileType } from '../../types/company';
import { SortTypeEnum } from '../../types';
import { companyService } from '../../lib/api/company';
import { SuspendStatusEnumColor } from '../../types/suspension';
import ActionModal from './modal/actionModal';
import Statistics from './statistics';

import countriesData from '../countries states cities/countries.json';

interface Country {
    CountryId: string;
    CountryName: string;
    CountryPhoneCode: string;
}

const EmployerVerification = () => {
    const [data, setData] = useState<CompanyProfileType[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingResendStatus, setLoadingResendStatus] = useState<'loading' | 'success' | 'error' | null>(null);

    // NEW
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(data.length);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [show, setShow] = useState(false);
    const [rerenderKey, setRerenderKey] = useState(0);
    const [modalData, setModalData] = useState<CompanyProfileType>();

    const setStatusMessage = (status: number) => {
        switch (status) {
            case 0:
                return 'Approved';

            case 1:
                return 'Suspended';

            case 2:
                return 'Document Not Uploaded';

            case 3:
                return 'Document Uploaded';

            case 4:
                return 'Rejected';

            case 5:
                return 'Need More Evidence';

            case 6:
                return 'Partially Approved';

            default:
                return 'Waiting For Upload';
        }
    };

    const setSalesOwnerName = (id: string | undefined) => {
        switch (id) {
            case 'W15178':
                return 'Nikitha';

            case 'W15270':
                return 'Teja';

            case 'W15269':
                return 'Eshwar';

            case 'W15284':
                return 'Sai Kiran';

            case 'W15332':
                return 'Rajiv Atluri';

            case 'W15325':
                return 'Shirini';

            case 'W15293':
                return 'Rohith';

            case 'W0':
                return 'Others';

            default:
                return 'NA';
        }
    };

    const setCompanyInviteTypeName = (type: number) => {
        switch (type) {
            case 0:
                return 'Old Onboarding Process';

            case 1:
                return 'New Onboarding Process';

            default:
                return 'NA';
        }
    };

    const columns = useMemo(
        () => [
            // {
            //     dataIndex: 'avatarUrl',
            //     title: '',
            //     hideSort: true,
            //     render: (item: any) => <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />,
            // },
            {
                dataIndex: 'name',
                title: 'Company Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="offer-table-reoffered me-2">
                                <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                            </div>
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.name}>
                                    {item.name}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },

            {
                dataIndex: 'registeredBy',
                title: 'Registered By',
                render: (item: any) => (
                    <div className="text-crop" style={{ top: '6px' }}>
                        <div className="big-text text-capitalize" title={item.registeredBy}>
                            {item?.registeredBy === ' ' ? 'NA' : item?.registeredBy}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'createdDate',
                title: 'Registered Date',
                width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.createdDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'ownerPhoneNumber',
                title: 'Contact Number',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.ownerPhoneNumber}>
                        {item?.ownerPhoneNumber ? (
                            <>
                                {/* +{item?.country!} {item?.ownerPhoneNumber} */}
                                {item?.compCountryCode! && '+' + item?.compCountryCode!.replace('+', '')}{' '}
                                {item?.ownerPhoneNumber}
                            </>
                        ) : (
                            'NA'
                        )}
                    </div>
                ),
            },
            {
                dataIndex: 'website',
                title: 'Company Website',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-ellipsis" title={item?.website}>
                        {item?.website ? (
                            <>{item?.website.length > 13 ? `${item?.website.slice(0, 25)}...` : item?.website}</>
                        ) : (
                            'NA'
                        )}
                    </div>
                ),
            },

            {
                dataIndex: 'onboardedDate',
                title: 'Onboarded Date',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) => {
                    return item.onboardedDate
                        ? new Date(item.onboardedDate).toLocaleString('en-in', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                          })
                        : 'NA';
                },
            },
            {
                dataIndex: 'companyInviteType',
                title: 'Invite Type',
                // hideSort: true,
                render: (item: any) => (
                    <div
                        className="text-nowrap text-ellipsis text-capitalize"
                        title={setCompanyInviteTypeName(item?.companyInviteType)}
                    >
                        {setCompanyInviteTypeName(item?.companyInviteType)}
                    </div>
                ),
            },
            {
                dataIndex: 'salesOwner',
                title: 'Sales Owner',
                // hideSort: true,
                render: (item: any) => (
                    <div
                        className="text-nowrap text-ellipsis text-capitalize"
                        title={setSalesOwnerName(item?.salesOwner)}
                    >
                        {setSalesOwnerName(item?.salesOwner)}
                    </div>
                ),
            },
            {
                dataIndex: 'suspendStatus',
                title: 'Status',
                // hideSort: true,
                width: '128px',
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis text-capitalize" title={setStatusMessage(item.suspendStatus)}>
                            <span style={{ color: `${getStatusColor(item.suspendStatus)}` }}>
                                {setStatusMessage(item.suspendStatus)}
                            </span>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'uploadedDate',
                title: 'Uploaded Date',
                width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) => {
                    return item?.uploadedDate !== null
                        ? new Date(item.uploadedDate).toLocaleString('en-in', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                          })
                        : 'NA';
                },
            },
            {
                dataIndex: '',
                title: 'Actions',
                width: '136px',
                render: (item: any) => {
                    // return item?.suspendStatus === 2 ||
                    return item?.suspendStatus === 4 || item?.suspendStatus === 0 || item?.suspendStatus === 1 ? (
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setShow(true);
                                    setModalData(item);
                                }}
                                className="btn btn-outline-secondary action-btn"
                            >
                                <i className="bi bi-eye"></i> View
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setShow(true);
                                    setModalData(item);
                                }}
                                className="btn btn-outline-primary action-btn"
                            >
                                <i className="bi bi-check2-square"></i> Review
                            </button>
                        </div>
                    );
                },
            },
        ],
        [loadingResendStatus]
    );

    // const getCountryPhoneCode = (item: CompanyProfileType) => {
    //     if (isNaN(parseFloat(item?.country!))) {
    //         if (item?.country! === 'India') {
    //             return '91';
    //         } else {
    //             const country = countriesData.find(
    //                 (country: Country) => country.CountryName.toLowerCase() === item?.country?.toLowerCase()
    //             );
    //             return country?.CountryPhoneCode!;
    //         }
    //     } else {
    //         const country = countriesData.find((country: Country) => country.CountryId === item?.country!);
    //         return country?.CountryPhoneCode!;
    //     }
    // };

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);
            const params: CompanyListRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };

            const {
                resultObject: { items, count },
            } = await companyService.getAllList(params);

            var mapItems = items.map((item) => ({
                ...item,
                // country: getCountryPhoneCode(item),
            }));

            setData(mapItems);
            // setData(items)
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Statistics applicationName="OfferX" />

            <div className="company-page-contener employer-table">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <ActionModal
                            key={+show}
                            getList={getList}
                            show={show}
                            setShow={setShow}
                            data={modalData}
                            applicationName="OfferX"
                        />
                        <div className="company-verification">
                            <Table columns={columns} data={data} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default EmployerVerification;

const getStatusColor = (value: SuspendStatusEnumColor) => {
    switch (value) {
        case SuspendStatusEnumColor.active:
            return '#107C10';
        case SuspendStatusEnumColor.suspended:
            return '#D83B01';
        case SuspendStatusEnumColor.partially:
            return '#C5B000';
        case SuspendStatusEnumColor.pending:
            return 'orange';
        case SuspendStatusEnumColor.uploaded:
            return '#0d6efd';
        case SuspendStatusEnumColor.rejected:
            return '#D83B01';
        case SuspendStatusEnumColor.moreEvidence:
            return '#0d6efd';
        default:
            return '';
    }
};
