import React, { useEffect, useRef, useState } from 'react';
import { Country, CountryDetails, FormDataType, defaultFormData } from '../../types/openApi';
import { OpenAPIService } from '../../lib/api/openApi';

interface DropdownProps {
    companyValue?: string;
    candidateValue?: string;
    companyUserValue?: string;
    enquiryValue?: string;
    id: string;
    disabled?: boolean;
    setFieldValue?: (fieldName: string, fieldvalue: string) => void;
    setFormData?: (formData: FormDataType) => void;
    advanceCandidateValue?: string;
    onPhoneCodeSelectionChange?: (value: any) => void;
}

const PhoneCodeDropdown: React.FC<DropdownProps> = ({
    candidateValue,
    companyUserValue,
    companyValue,
    enquiryValue,
    advanceCandidateValue,
    setFormData,
    disabled,
    id,
    setFieldValue,
    onPhoneCodeSelectionChange,
}) => {
    const [selectedCountry, setSelectedCountry] = useState<Country | string | number>('');

    const [countries, setCountries] = useState<Country[] | null>();
    const [countriesPhoneCode, setCountriesPhoneCode] = useState<CountryDetails[]>();
    const [isCandiateId, setIsCandiateId] = useState<string | null>('');
    const [phoneCode, setPhoneCode] = useState('91');
    const [filterText, setFilterText] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [dropdownPlacement, setDropdownPlacement] = useState<'down' | 'up'>('down');

    const filterInputRef = useRef<HTMLInputElement>(null);
    const countryPhoneCodeRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const filteredCountries =
        countries &&
        countries!.filter(
            (country) =>
                country.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
                country.phonecode!.startsWith(filterText)
        );

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryPhoneCodeRef.current &&
                !countryPhoneCodeRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        switch (id) {
            case 'owner':
                setIsCandiateId('0');
                setPhoneCode(candidateValue! || '91');
                setFieldValue?.('ownerSTDCode', candidateValue! || '91');
                break;

            case 'candidate':
                setIsCandiateId('1');
                setPhoneCode(companyUserValue! || '91');
                setFieldValue?.('ownerCode', companyUserValue! || '91');
                break;

            case 'company':
                setIsCandiateId('2');
                setPhoneCode(companyValue! || '91');
                setFieldValue?.('companySTDCode', companyValue! || '91');
                break;

            case 'enquiry':
                setIsCandiateId('3');

                setPhoneCode(enquiryValue! || '91');
                setFieldValue?.('EnquiryCountryCode', enquiryValue! || '91');
                break;

            case 'advance-candidate-search':
                setIsCandiateId('4');
                setPhoneCode(advanceCandidateValue! || '91');
                setFormData?.({
                    ...defaultFormData,
                    userCountryCode: advanceCandidateValue!,
                });
                break;
            default:
                setPhoneCode('91');
                break;
        }
    }, [
        candidateValue,
        companyValue,
        companyUserValue,
        enquiryValue,
        advanceCandidateValue,
        id,
        setFieldValue,
        setFormData,
    ]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryPhoneCodeRef.current &&
                !countryPhoneCodeRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        const handleDropdownPosition = () => {
            if (openDropdown && countryPhoneCodeRef.current && dropdownRef.current) {
                const dropdownHeight = dropdownRef.current.offsetHeight;
                const triggerRect = countryPhoneCodeRef.current.getBoundingClientRect();
                const taskbarHeight = window.innerHeight - window.screen.height;

                if (triggerRect.bottom + dropdownHeight > window.innerHeight - taskbarHeight) {
                    setDropdownPlacement('up');
                } else {
                    setDropdownPlacement('down');
                }
            }
        };

        handleDropdownPosition();

        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('scroll', handleDropdownPosition);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('scroll', handleDropdownPosition);
        };
    }, [openDropdown]);

    useEffect(() => {
        const getPhoneCode = async () => {
            const data = await OpenAPIService.fetchCountriesWithPhoneCode();

            return data;
        };

        const countries = JSON.parse(sessionStorage.getItem('countries')!);

        if (countries) {
            setCountries(countries);
        } else {
            (async () => {
                const result = await getPhoneCode();
                setCountries(result);
            })();
        }
    }, []);

    return (
        <div>
            <div className="d-flex">
                <div>
                    <button
                        type="button"
                        ref={countryPhoneCodeRef}
                        key={phoneCode}
                        // id="countryPhoneCode"
                        disabled={disabled}
                        className="form-select number-select cursor-pointer w-90 text-start"
                        style={{ paddingBottom: '0.375rem' }}
                        onClick={() => {
                            setOpenDropdown(!openDropdown);
                            setFilterText('');
                            setTimeout(() => {
                                filterInputRef.current?.focus();
                            }, 50);
                        }}
                    >
                        {'+' + phoneCode?.replace('+', '')}
                    </button>
                    {openDropdown && (
                        <div ref={dropdownRef} className={`country-dropdown ${dropdownPlacement}`}>
                            <div className="sticky-search">
                                <input
                                    type="search"
                                    ref={filterInputRef}
                                    className="form-control cursor-pointer country-search"
                                    placeholder="Search Country"
                                    value={filterText}
                                    onChange={(e) => {
                                        setFilterText(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                {filteredCountries &&
                                    filteredCountries.map((country) => (
                                        <li
                                            onBlur={() => setOpenDropdown(false)}
                                            className="dropdown-item cursor-pointer fs-14"
                                            onClick={() => {
                                                if (isCandiateId === '0') {
                                                    setFieldValue?.('ownerSTDCode', country.phonecode || '91');
                                                } else if (isCandiateId === '1') {
                                                    setFieldValue?.('ownerCode', country.phonecode || '91');
                                                } else if (isCandiateId === '2') {
                                                    setFieldValue?.('companySTDCode', country.phonecode || '91');
                                                } else if (isCandiateId === '3') {
                                                    setFieldValue?.('EnquiryCountryCode', country.phonecode || '91');
                                                } else if (isCandiateId === '4') {
                                                    setFormData?.({
                                                        ...defaultFormData,
                                                        userCountryCode: country.phonecode! || '91',
                                                    });
                                                } else {
                                                    setPhoneCode('91');
                                                }
                                                onPhoneCodeSelectionChange?.(country.phonecode);
                                                setOpenDropdown(false);
                                            }}
                                            key={country.id}
                                            value={country.id}
                                        >
                                            {country.name} (+{country.phonecode})
                                        </li>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PhoneCodeDropdown;
