import { useState } from 'react';
import PageLoader from '../../components/loader';
import HRMSEmployerVerification from '.';

const HRMSCompanyListLanding = () => {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="">HRMS Company List</h1>
                </div>
            </div>
            {loading ? (
                <PageLoader />
            ) : (
                <>
                    <div className="card">
                        <div className="hrms-table">
                            <HRMSEmployerVerification />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
export default HRMSCompanyListLanding;
