import React, { useEffect, useState } from 'react';
import OffersWidget from './components/offers-widget';
import TotalOffersWidget from './components/total-offers-widget';
import PlatformFeedback from './components/platform-feedback';
import CompanyCandidateRatings from './components/company-candidate-ratings';
import TodayReviewScore from './components/today-review-score';
import LoginStatsAuthentication from './components/login-stats-authentication';
import TicketingSystem from './components/ticketing-system';
import SocialFollowing from './components/social-following';
import WebsiteTraffic from './components/website-traffic';
import RecentActivity from './components/recent-activity';
import DateRange from './components/date-range';
import moment from 'moment';

const Dashboard = () => {
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [time, setTime] = useState<string | null>(moment().format('LT'));

    useEffect(() => {
        const subscribe = setInterval(() => {
            setTime(moment().format('LT'));
        }, 1000);
        return () => clearInterval(subscribe);
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="page_title ox-dashboard-title d-sm-flex">
                        <div>
                            <h1 className="">Dashboard</h1>
                            <DateRange setSelectedYear={setSelectedYear} />
                        </div>
                        <div className="ms-auto py-3 align-items-center">
                            <div className="lt-today-date d-flex align-items-center">
                                <i className="bi bi-calendar3 fs-32 lt-text-primary-alt me-3" />
                                <div>
                                    <div className="fs-20 fw-700">{time && time}</div>
                                    <div className="fs-12 border-sm-start mt-3 mt-sm-0">
                                        {moment().format('Do MMMM YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <OffersWidget selectedYear={selectedYear} />
            <div className="row row-deck">
                <TotalOffersWidget selectedYear={selectedYear} />
                <PlatformFeedback selectedYear={selectedYear} />
            </div>
            <div className="row row-deck">
                <CompanyCandidateRatings selectedYear={selectedYear} />
                {/* <TodayReviewScore /> */}
                <LoginStatsAuthentication selectedYear={selectedYear} />
            </div>
            {/* <div className="row row-deck">
                <TicketingSystem />
                <SocialFollowing />
            </div> */}
            {/* <div className="row row-deck">
                <WebsiteTraffic />
                <RecentActivity />
            </div> */}
        </div>
    );
};

export default Dashboard;
