import companyRegistrationFlow from './companyRegistration';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import user from './user';
import consentWorkFlow from './consentWorkFlow';

export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    user,
    consentWorkFlow,
    companyRegistrationFlow,
});

export const store = configureStore({
    reducer: rootReducer,
});
