import {  GetAllAuthenticationTypeResponseType, GetCandidateRatingsResponseType, GetCompanyRatingsResponseType, GetFeedbackCountResponseType, GetOffersCountResponseType, GetRegistrationCountResponseType, GetTotalUserOfferCountResponseType } from '../../types/dashboard';
import { axiosInstance } from './index';

export const dashboardService = {
    async getOffersCount(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetOffersCount/${params}`);
        return data as GetOffersCountResponseType;
    },

    async getRegistrationCount(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetRegistrationCount/${params}`);
        return data as GetRegistrationCountResponseType;
    },

    async getCandidateRatings(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetCandidateRatings/${params}`);
        return data as GetCandidateRatingsResponseType;
    },

    async getCompanyRatings(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetCompanyRatings/${params}`);
        return data as GetCompanyRatingsResponseType;
    },

    async getFeedbackCount(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetFeedbackCount/${params}`);
        return data as GetFeedbackCountResponseType;
    },

    async getTotalUserOfferCount(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetTotalUserOfferCount/${params}`);
        return data as GetTotalUserOfferCountResponseType;
    },

    async getAllAuthenticationTypeCount(params: number) {
        const { data } = await axiosInstance.get(`Dashboard/GetAllAuthenticationTypeCount/${params}`);
        return data as GetAllAuthenticationTypeResponseType;
    },
}