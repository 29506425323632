import { CompanyLeadResendParamType, CompanyLeadResendType, ScreenXCompanyLeadResendType } from './../../types/invitations';
import { axiosInstance } from './index';
import { CreateInviteRequestType, InviteListResponseType } from '../../types/invitations';

export const invitationsService = {
    async getList() {
        const { data } = await axiosInstance.get('Invitation/GetAllInvitationsList');
        return data as InviteListResponseType;
    },

    async create(data: CreateInviteRequestType) {
        await axiosInstance.post('Invitation', data);
    },

    async resend(id: string) {
        await axiosInstance.put(`Invitation/${id}/Resend`);
    },

    async inviteOrResendCompanyInvite(id: CompanyLeadResendParamType) {
      const {data} =  await axiosInstance.post(`CompanyLeads/SendInviteEmailsForCompanyLeads`, id);
      return  data as CompanyLeadResendType
    },

    async inviteOrResendScreenXCompanyInvite(id: CompanyLeadResendParamType) {
        const {data} =  await axiosInstance.post(`Company/ResendInviteCompanyScreenX`, id);
        return  data as ScreenXCompanyLeadResendType
      },
};
