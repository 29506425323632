// import { Field, FieldProps, Formik, FormikProps } from 'formik';
import Files from 'react-files';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { ReactFilesFile, ReactFilesError } from '../../types/files';
import PageLoader from '../../components/loader';
import { EditCandidateFormType } from '../../types/candidate';
import useInitialErrors from '../../hooks/formik-initial-errors';
import { date as dateYup, object as objectYup, string as stringYup, number as numYup, array as arrayYup } from 'yup';
import { Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { values } from 'lodash';
import { toast } from 'react-toastify';
import PdfViewer from '../../components/pdf-viewer';
import getValue from 'lodash/get';
import { Modal, Table } from 'react-bootstrap';
import { CompanyProfileType } from '../../types/company';
import MultiSelectDropdown from '../../components/dropdown/multiSelectDropdown';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
    CandidateListDataType,
    CompanyListDataType,
    CountryListDataType,
    PolicyDocFileType,
    PolicyDocumentFormType,
    PolicyDocumentList,
    PolicyDocumentRequestType,
    PolicyDocumentResponseType,
} from '../../types/policyDocument';
import { isLessThanTheMB } from '../../lib/utils/lessthan-max-filesize';
import ViewPolicyModal from './viewPolicyModal';
import { policyDocumentService } from '../../lib/api/policyDocument';
import history from '../../history';
import CountryListModal from './modals/countryListModal';
import CompanyListModal from './modals/companyListModal';
import CandidateListModal from './modals/candidateListModal';
import Tables from '../../components/table';
import Base64DownloadPdf from './Base64/base64DownloadPdf';
import { convertToIST } from '../../lib/utils/convertToIST';
import { ErrorIcon, Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';

let initialFormData = {
    docType: '',
    countryList: [
        {
            countryId: '',
            countryName: '',
            countryCode: '',
        },
    ],
    file: null,
};

const Policy = () => {
    const [companyDoc, setcompanyDoc] = useState<{ file?: File; error?: string } | null>(null);
    const [data, setData] = useState<PolicyDocumentList[]>([]);
    const [policyDocViewModal, setPolicyDocViewModal] = useState(false);
    const [resetMultiSelectDropdown, setResetMultiSelectDropdown] = useState(false);
    const [showPolicyUploadDocModal, setShowPolicyUploadDocModal] = useState(false);
    const [showCandidateListModal, setShowCandidateListModal] = useState(false);
    const [showCompanyListModal, setShowCompanyListModal] = useState(false);
    const [showCountryListModal, setShowCountryListModal] = useState(false);
    const [fileUrlData, setFileUrlData] = useState('');
    const [loading, setLoading] = useState(false);

    const [countryListData, setCountryListData] = useState<CountryListDataType[]>([]);
    const [candidateListData, setCandidateListData] = useState<CandidateListDataType[]>([]);
    const [companyListData, setCompanyListData] = useState<CompanyListDataType[]>([]);
    const [policyDocID, setPolicyDocID] = useState('');

    const [singlePolicyData, setSinglePolicyData] = useState<PolicyDocumentList>();

    const submitHandler = useCallback(
        async (values: PolicyDocumentFormType, formikProps: FormikHelpers<any>) => {
            const requestData: PolicyDocFileType = {
                ...values,
                file: undefined,
            };

            let formData = new FormData();
            formData.append('request', JSON.stringify(requestData));
            companyDoc?.file && formData.append('cinFile', companyDoc?.file);

            try {
                setLoading(true);
                await policyDocumentService.uploadPolicyDocument(formData);

                setcompanyDoc(null);

                Swal.fire({
                    title: SweetAlertTitle.Success,
                    text: Message.DocumentUploaded,
                    icon: SuccessIcon,
                    confirmButtonText: 'Ok',
                });

                formikProps.resetForm({
                    values: initialFormData,
                    errors: {},
                });

                setShowPolicyUploadDocModal(false);
                await getLegalDocuments();
                setLoading(false);
            } catch (err) {
                Swal.fire({
                    title: SweetAlertTitle.Error,
                    text: Message.SomethingWentWrongTryAgain,
                    icon: ErrorIcon,
                    confirmButtonText: 'Ok',
                    iconHtml: '<i class="fas fa-bug"></i>',
                });
                setLoading(false);
                setShowPolicyUploadDocModal(false);
                formikProps.resetForm({
                    values: initialFormData,
                    errors: {},
                });
            }
        },
        [companyDoc]
    );

    const profileFormData = useMemo(() => initialFormData, []);

    const initialErrors = useInitialErrors(profileFormData, getValidationSchema());

    useEffect(() => {
        const getData = async () => {
            try {
                const {
                    resultObject: { items },
                } = await policyDocumentService.getPolicyDocuments();
                setData(items);
            } catch (err) {
                console.log(err, 'Error');
            }
        };
        getData();
    }, []);

    const getLegalDocuments = async () => {
        try {
            setLoading(true);
            const {
                resultObject: { items },
            } = await policyDocumentService.getPolicyDocuments();
            setData(items);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err, 'Error');
        }
    };

    const policyDocumentStatus = (status: string) => {
        switch (status) {
            case '0':
                return 'Latest';

            case '1':
                return 'Old';

            default:
                break;
        }
    };

    const documentType = (status: string) => {
        switch (status) {
            case '0':
                return 'Public';

            case '1':
                return 'Private';

            default:
                break;
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'documentType',
                title: 'Type',
                render: (item: any) => <div>{documentType(item?.documentType?.toString())}</div>,
            },
            {
                dataIndex: 'name',
                title: 'Document Name',
                width: '200px',
                render: (item: any) => <>{item?.name || 'NA'}</>,
            },
            {
                dataIndex: 'countryNames',
                title: 'Countries',
                width: '250px',
                render: (item: any) => {
                    if (item?.countryList.length > 5) {
                        return (
                            <div>
                                {item?.countryNames}
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowCountryListModal(true);
                                        setSinglePolicyData(item);
                                        setCountryListData(item?.countryList);
                                        setPolicyDocID(item?.policyDocumentId);
                                    }}
                                >
                                    &nbsp; More
                                </a>
                            </div>
                        );
                    } else {
                        return <div>{item?.countryNames}</div>;
                    }
                },
            },
            {
                dataIndex: 'uploadedBy',
                title: 'Uploaded By',
                render: (item: any) => (
                    <div className="text-crop" style={{ top: '6px' }}>
                        <div className="big-text text-capitalize" title={item.uploadedBy}>
                            {item?.uploadedBy === ' ' ? 'NA' : item?.uploadedBy}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'uploadedDate',
                title: 'Uploaded Date',
                width: '128px',
                render: (item: any) => {
                    // new Date(item.uploadedDate).toLocaleString('en-in', {
                    //     day: 'numeric',
                    //     month: 'short',
                    //     year: 'numeric',
                    // });
                    return convertToIST(item.uploadedDate);
                },
            },
            {
                dataIndex: '',
                title: 'Pending',
                render: (item: any) => (
                    <>
                        {documentType(item?.documentType?.toString()) == 'Private' ? (
                            <div>
                                Companies: [{item?.companyCountPending}]
                                <br />
                                Candidates: [{item?.candidateCountPending}]
                            </div>
                        ) : (
                            <div>-</div>
                        )}
                    </>
                ),
            },
            {
                dataIndex: '',
                title: 'Accepted',
                render: (item: any) => (
                    <>
                        {documentType(item?.documentType?.toString()) == 'Private' ? (
                            <div>
                                Companies: [{item?.companyCountAccepted}]
                                <br />
                                Candidates: [{item?.candidateCountAccepted}]
                            </div>
                        ) : (
                            <div>-</div>
                        )}
                    </>
                ),
            },

            // {
            //     dataIndex: '',
            //     title: 'Status',
            //     hideSort: true,
            //     render: (item: any) => <div>{policyDocumentStatus(item?.status?.toString() || '')}</div>,
            // },
            {
                dataIndex: '',
                title: 'Actions',
                // hideSort: true,
                render: (item: any) => (
                    <>
                        <div className="dropdown">
                            <i
                                className="bi bi-three-dots-vertical cursor-pointer"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                title="Options"
                            ></i>
                            <ul className="dropdown-menu py-0">
                                <li
                                    onClick={() => {
                                        setPolicyDocViewModal(true);
                                        setFileUrlData(item?.fileUrl);
                                        setSinglePolicyData(item);
                                    }}
                                    className="dropdown-item fs-14 cursor-pointer"
                                >
                                    View
                                </li>
                                <hr className="m-0" />
                                <li className="dropdown-item fs-14 cursor-pointer">
                                    {/* <a
                                        className="dropdown-item fs-14 cursor-pointer"
                                        href={item?.fileUrl}
                                        target="_top"
                                        download="Policy Document"
                                        rel="noopener noreferrer"
                                    >
                                        <Base64DownloadPdf id={item?.policyDocumentId} />
                                        <button>Download</button>
                                    </a> */}

                                    <Base64DownloadPdf id={item?.policyDocumentId} type="link" />
                                </li>
                                <hr className="m-0" />
                                <li
                                    onClick={() => {
                                        setShowCountryListModal(true);
                                        setSinglePolicyData(item);
                                        setCountryListData(item?.countryList);
                                        setPolicyDocID(item?.policyDocumentId);
                                    }}
                                    className="dropdown-item fs-14 cursor-pointer"
                                >
                                    Country list
                                </li>
                                <hr className="m-0" />
                                {documentType(item?.documentType?.toString()) == 'Private' && (
                                    <>
                                        {' '}
                                        <li
                                            onClick={() => {
                                                setShowCompanyListModal(true);
                                                setSinglePolicyData(item);
                                                setCompanyListData(item?.countryList);
                                                setPolicyDocID(item?.policyDocumentId);
                                            }}
                                            className="dropdown-item fs-14 cursor-pointer"
                                        >
                                            Company list
                                        </li>
                                        <hr className="m-0" />
                                        <li
                                            onClick={() => {
                                                setShowCandidateListModal(true);
                                                setSinglePolicyData(item);
                                                setCandidateListData(item?.countryList);
                                                setPolicyDocID(item?.policyDocumentId);
                                            }}
                                            className="dropdown-item fs-14 cursor-pointer"
                                        >
                                            Candidate list
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <Formik
                    onSubmit={submitHandler}
                    enableReinitialize
                    initialValues={initialFormData}
                    validationSchema={getValidationSchema()}
                    initialErrors={initialErrors}
                >
                    {(formikProps: FormikProps<PolicyDocFileType>) => {
                        const { handleSubmit, values, isSubmitting, setFieldTouched, setFieldValue, resetForm } =
                            formikProps;

                        return (
                            <>
                                <Modal
                                    id="policyUpload"
                                    show={showPolicyUploadDocModal}
                                    size="lg"
                                    tabIndex={-1}
                                    aria-labelledby="contained-modal-title-vcenter policyUploadLabel"
                                    centered
                                >
                                    <div className="modal-content">
                                        <Modal.Header className="modal-header align-items-start border-0">
                                            <div className="flex-column">
                                                <h1 className="modal-title w-100 fs-16" id="policyUploadLabel">
                                                    Upload Document
                                                </h1>
                                                <div className="fs-12 lt-text-secondary">
                                                    Upload a copy of your legal document
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                title="Close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={() => {
                                                    resetForm();
                                                    setResetMultiSelectDropdown(true);
                                                    setShowPolicyUploadDocModal(false);
                                                }}
                                            ></button>
                                        </Modal.Header>
                                        {loading ? (
                                            <PageLoader />
                                        ) : (
                                            <Modal.Body className="modal-body">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <Field name="docType">
                                                                {(fieldProps: FieldProps) => {
                                                                    const { field, form } = fieldProps;
                                                                    const error =
                                                                        getValue(form.touched, field.name) &&
                                                                        getValue(form.errors, field.name);
                                                                    return (
                                                                        <div className="mb-3">
                                                                            <label
                                                                                htmlFor="exampleFormControlInput1"
                                                                                className="form-label fs-14 mb-1"
                                                                            >
                                                                                Document Type{' '}
                                                                                <span className="lt-text-error">*</span>
                                                                            </label>
                                                                            <select
                                                                                value={field.value}
                                                                                onChange={(ev) => {
                                                                                    setFieldTouched(field.name);
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        ev.target.value
                                                                                    );
                                                                                }}
                                                                                className="form-select cursor-pointer"
                                                                            >
                                                                                <option value={''}>
                                                                                    Select Document Type
                                                                                </option>
                                                                                <option value={'0'}>Public</option>
                                                                                <option value={'1'}>Private</option>
                                                                            </select>
                                                                            <small className="text-danger fs-12">
                                                                                {error?.toString()}
                                                                            </small>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Field>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Field name="countryList">
                                                                {(fieldProps: FieldProps) => {
                                                                    const { field, form } = fieldProps;
                                                                    const error =
                                                                        getValue(form.touched, field.name) &&
                                                                        getValue(form.errors, field.name);
                                                                    return (
                                                                        <div className="mb-3">
                                                                            <label
                                                                                htmlFor="exampleFormControlInput1"
                                                                                className="form-label fs-14 mb-1"
                                                                            >
                                                                                Country{' '}
                                                                                <span className="lt-text-error">*</span>
                                                                            </label>
                                                                            <MultiSelectDropdown
                                                                                resetMultiSelectDropdown={
                                                                                    resetMultiSelectDropdown
                                                                                }
                                                                                key={Number(resetMultiSelectDropdown)}
                                                                                setFieldTouched={setFieldTouched}
                                                                                setFieldValue={setFieldValue}
                                                                            />
                                                                            <small className="text-danger fs-12">
                                                                                {error?.toString()}
                                                                            </small>
                                                                        </div>
                                                                    );
                                                                }}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <Field name="file">
                                                            {(fieldProps: FieldProps) => {
                                                                const { field, form } = fieldProps;
                                                                const error =
                                                                    getValue(form.touched, field.name) &&
                                                                    getValue(form.errors, field.name);
                                                                return values.file ? (
                                                                    <>
                                                                        <div className="verify-doc my-3">
                                                                            <div className="uploaded-div">
                                                                                <div className="fs-14 mb-1">
                                                                                    Uploaded file
                                                                                </div>
                                                                                <div className="d-flex align-items-center uploaded-border">
                                                                                    <i className="bi bi-file-earmark-check-fill lt-text-secondary"></i>
                                                                                    <div className="fs-12 me-5">
                                                                                        {values.file.name}
                                                                                    </div>
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            setPolicyDocViewModal(true);
                                                                                            setFileUrlData(
                                                                                                values.file.preview.url
                                                                                            );
                                                                                        }}
                                                                                        className="btn btn-outline-primary me-2 btn-sm fs-12 outline-p-hover"
                                                                                    >
                                                                                        <i className="bi bi-eye"></i>{' '}
                                                                                        View
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        data-bs-toggle=""
                                                                                        data-bs-target=""
                                                                                        title="Delete"
                                                                                        className="ms-2 lh-1"
                                                                                        onClick={() =>
                                                                                            setFieldValue('file', '')
                                                                                        }
                                                                                    >
                                                                                        <i
                                                                                            className="bi bi-trash lt-text-error"
                                                                                            title="Replace document"
                                                                                        ></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <small className="text-danger fs-12">
                                                                            {error?.toString()}
                                                                        </small>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Files
                                                                            className="files-dropzone"
                                                                            multiple={false}
                                                                            onChange={(files: ReactFilesFile[]) => {
                                                                                if (files[0]) {
                                                                                    const file = files[0];
                                                                                    setcompanyDoc({
                                                                                        file,
                                                                                    });
                                                                                    const fileSize = isLessThanTheMB(
                                                                                        file.size,
                                                                                        5
                                                                                    );
                                                                                    if (fileSize) {
                                                                                        if (!file.preview.url) {
                                                                                            file.preview.url =
                                                                                                URL.createObjectURL(
                                                                                                    file
                                                                                                );
                                                                                        }

                                                                                        setFieldTouched(field.name);
                                                                                        setFieldValue(field.name, file);
                                                                                    } else {
                                                                                        Swal.fire({
                                                                                            title: SweetAlertTitle.Alert,
                                                                                            text: Message.AttachmentSize5mb,
                                                                                            icon: WarningIcon,
                                                                                            confirmButtonText: 'Ok',
                                                                                        });
                                                                                    }
                                                                                }
                                                                            }}
                                                                            onError={(error: ReactFilesError) => {
                                                                                if (error.code === 1) {
                                                                                }
                                                                            }}
                                                                            accepts={['.pdf']}
                                                                            clickable
                                                                        >
                                                                            <div className="drag-drop-box my-3">
                                                                                <div className="text-center">
                                                                                    <i className="bi bi-upload fs-28" />
                                                                                    <p>
                                                                                        Drop your file to upload or{' '}
                                                                                        <a className="ox-browse">
                                                                                            browse
                                                                                        </a>
                                                                                    </p>
                                                                                    <div className="fs-12 mt-2">
                                                                                        Supports only PDF - Max file
                                                                                        size 5MB
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Files>
                                                                        <small className="text-danger fs-12">
                                                                            {error?.toString()}
                                                                        </small>
                                                                    </>
                                                                );
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className="modal-footer border-0">
                                                        <button type="submit" className="btn btn-primary">
                                                            Upload
                                                        </button>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                        )}
                                    </div>
                                    {/* </div> */}
                                </Modal>
                                {policyDocViewModal && (
                                    <ViewPolicyModal
                                        policyDocViewModal={policyDocViewModal}
                                        setPolicyDocViewModal={setPolicyDocViewModal}
                                        url={fileUrlData}
                                    />
                                )}
                                <div className="col-md-12">
                                    <div className="page_title ox-dashboard-title mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary float-end"
                                            onClick={() => {
                                                setResetMultiSelectDropdown(false);
                                                setShowPolicyUploadDocModal(true);
                                            }}
                                        >
                                            <i className="bi bi-upload"></i> Upload Document
                                        </button>
                                        <h1 className="">Legal Documents</h1>
                                    </div>
                                </div>
                                <div className="legal-doc-table mt-4">
                                    <Tables columns={columns} data={data} />
                                </div>
                                {/* ALL MODALS  */}
                                {showCountryListModal && (
                                    <CountryListModal
                                        countryList={countryListData}
                                        policyDocId={policyDocID}
                                        showCountryListModal={showCountryListModal}
                                        setShowCountryListModal={setShowCountryListModal}
                                        showCompanyListModal={showCompanyListModal}
                                        setShowCompanyListModal={setShowCompanyListModal}
                                        showCandidateListModal={showCandidateListModal}
                                        setShowCandidateListModal={setShowCandidateListModal}
                                    />
                                )}
                                {showCompanyListModal && (
                                    <CompanyListModal
                                        policyDocId={policyDocID}
                                        companyList={companyListData}
                                        showCompanyListModal={showCompanyListModal}
                                        setShowCompanyListModal={setShowCompanyListModal}
                                    />
                                )}
                                {showCandidateListModal && (
                                    <CandidateListModal
                                        policyDocId={policyDocID}
                                        candidateList={candidateListData}
                                        showCandidateListModal={showCandidateListModal}
                                        setShowCandidateListModal={setShowCandidateListModal}
                                    />
                                )}
                            </>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};

export default Policy;

const getValidationSchema = () =>
    objectYup().shape({
        file: stringYup().nullable().required('File is required.'),
        docType: stringYup().required('Document Type is required.'),
        countryList: arrayYup()
            .of(
                objectYup().shape({
                    countryId: stringYup().required('Country ID is required'),
                    countryName: stringYup().required('Country name is required'),
                })
            )
            .min(1, 'Please select at least one country.'),
    });
