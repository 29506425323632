import React, { useMemo, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CompanyListDataType } from '../../../types/policyDocument';
import Table from '../../../components/table';
import { policyDocumentService } from '../../../lib/api/policyDocument';
import PageLoader from '../../../components/loader';
import Base64DownloadPdf from '../Base64/base64DownloadPdf';

type Props = {
    companyList: CompanyListDataType[];
    showCompanyListModal: boolean;
    setShowCompanyListModal: (key: boolean) => void;
    policyDocId: string;
};

const CompanyListModal: React.FC<Props> = ({
    companyList,
    policyDocId,
    showCompanyListModal,
    setShowCompanyListModal,
}) => {
    const [data, setData] = useState<CompanyListDataType[]>();

    useEffect(() => {
        if (policyDocId) {
            const getData = async (policyDocID: string, countryID?: string) => {
                try {
                    const {
                        resultObject: { companyList },
                    } = await policyDocumentService.getCompanyPolicyDocumentCount({
                        policyDocumentId: policyDocID,
                        countryId: '',
                    });

                    setData(companyList);
                } catch (err) {
                    console.log(err, 'Error');
                }
            };
            getData(policyDocId);
        } else {
            const getData = async (policyDocID: string, countryID: string) => {
                try {
                    const {
                        resultObject: { companyList },
                    } = await policyDocumentService.getCompanyPolicyDocumentCount({
                        policyDocumentId: policyDocID,
                        countryId: countryID,
                    });

                    setData(companyList);
                } catch (err) {
                    console.log(err, 'Error');
                }
            };
            getData(companyList[0].policyDocumentId, companyList[0].countryId.toString());
        }
    }, [companyList, policyDocId]);

    const policyDocumentStatus = (status: string) => {
        switch (status) {
            case '0':
                return 'Pending';

            case '1':
                return 'Accepted';

            default:
                return 'NA';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'countryName',
                title: 'Country',
                render: (item: any) => <div>{item?.countryName || 'NA'}</div>,
            },
            {
                dataIndex: 'companyName',
                title: 'Company Name',
                render: (item: any) => (
                    <div className='text-crop' title={item?.companyName}>
                        <div className='big-text'>{item?.companyName}</div>
                    </div>
                ),
            },
            {
                dataIndex: 'employeeName',
                title: 'User Name',
                render: (item: any) => (
                    <div>
                        <div className="">{item?.employeeName}</div>
                        <div className="text-crop mb-3" title={item?.employeeEmail}><div className='big-text'>({item?.employeeEmail})</div></div>
                    </div>
                ),
            },
            {
                dataIndex: 'status',
                title: 'Status',
                render: (item: any) => <div>{policyDocumentStatus(item?.status?.toString())}</div>,
            },
            {
                dataIndex: '',
                title: 'Action',
                render: (item: any) => (
                    <>
                        {item?.policyDocumentId && <Base64DownloadPdf id={item?.policyDocumentId} />}
                    </>
                ),
            },
        ],
        []
    );

    return (
        <div>
            {' '}
            <Modal
                show={showCompanyListModal}
                className="modal fade admin-company-list-modal"
                id="CompanyList"
                backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="CompanyListLabel"
                aria-hidden="true"
                size="lg"
                centered
            >
                <div className="modal-content">
                    <Modal.Header className="modal-header align-items-start border-0">
                        <div className="flex-column">
                            <h1 className="modal-title w-100 fs-16" id="CompanyListLabel">
                                Company List
                            </h1>
                        </div>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShowCompanyListModal(false)}
                        ></button>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {data ? (
                            <Table columns={columns} data={data} />
                        ) : companyList ? (
                            '<Table columns={columns} data={companyList} />'
                        ) : (
                            <PageLoader />
                        )} */}
                        {data ? <div className='complist-table'><Table columns={columns} data={data} /></div> : <PageLoader />}
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};

export default CompanyListModal;
