import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import avatar from '../../assets/img/avatars.png';
import RatingStar from '../../components/rating-star';
import AsyncTable from '../../components/table/async';
import { candidateService } from '../../lib/api/candidate';
import { suspensionService } from '../../lib/api/suspension';
import { generateLink } from '../../lib/utils/generate-link';
import { Message } from '../../lib/utils/messages';
import { SortTypeEnum } from '../../types';
import { CandidateAdvancedRequestType, CandidateListType, ScreenXCandidateListType } from '../../types/candidate';
import { SuspendStatusEnum } from '../../types/suspension';
import { routes } from '../routes/routes-names';
import { mapFilters, mapSort } from './utils';
import statesData from '../countries states cities/states.json';
import citiesData from '../countries states cities/cities.json';
import StateCityCodeValue from '../../components/profile/stateCityCodeValue';
import Table from '../../components/table';
import PageLoader from '../../components/loader';

type Props = {
    rerenderKey: number;
    setCurrentId: (id: string) => void;
    setShowAdminModal: (value: boolean) => void;
    setRerenderKey: (key: number) => void;
};

interface State {
    StateId: string;
    StateName: string;
    CountryId: string;
}

interface City {
    CitiesId: string;
    CitiesName: string;
    CitiesStateId: string;
}

const AllCandidatesScreenX: React.FC<Props> = ({ rerenderKey, setCurrentId, setShowAdminModal, setRerenderKey }) => {
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState<ScreenXCandidateListType[]>([]);
    const [loadingResendStatus, setLoadingResendStatus] = useState<'loading' | 'success' | 'error' | null>(null);



    const columns = useMemo(
        () => [
            // {
            //   dataIndex: 'avatarUrl',
            //   title: '',
            //   hideSort: true,
            //   render: (item: any) => <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />,
            // },
            {
                dataIndex: 'name',
                title: 'Candidate Name',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.fullName}>
                        <div className="offer-table-reoffered me-2">
                            <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                        </div>
                            {item.fullName?.toLowerCase()}
                    </div>
                ),
            },
            {
                dataIndex: 'email',
                title: 'Email',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.email}>
                        {item.email || 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'jobTitle',
                title: 'Job Title',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.jobTitle}>
                        {item.jobTitle || 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'location',
                title: 'Location',
                render: (item: any) => (
                    <div
                        className="text-ellipsis text-capitalize" title={item.location}
                        // title={item.cityDistrict?.toLowerCase() + ', ' + item.state?.toLowerCase()}
                    >
                        {/* {item.cityDistrict?.toLowerCase() + ', ' + item.state?.toLowerCase()} */}
                        {item.location || 'NA'}
                    </div>
                ),
            },
        ],
        [loadingResendStatus]
    );

    const getCandidateList = useCallback(async () => {
        try {
            setLoading(true);
            const {
                result: { items, count },
            } = await candidateService.getScreenXCandidateList();

            var mapItems = items.map((item) => ({
                ...item,
                name: `${item.firstName} ${item.lastName}`,
                location: `${item.city ? item.city + ' , ' : ''}${item.state || ''}`,
                // state: getStateName(item),
                // cityDistrict: getCityName(item),
            }));

            setData(mapItems);

            setTotalCount(count);
        } catch (err) {
            console.log('error companyService getList', err);
        } finally {
            setLoading(false);
        }
    }, [start, limit, filters, sort]);

    useEffect(() => {
        (async function () {
            await getCandidateList();
        })();
    }, [start, limit, filters, sort, getCandidateList, rerenderKey]);

    return (
        <div>
            {loading ? (
                    <PageLoader />
                ) : (
                    <>
                    <div className='screenX-table'>
             <Table columns={columns} data={data} />
             </div>
             </>
                )}
        </div>
    );
};

export default AllCandidatesScreenX;
