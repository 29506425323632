import { TableFilterType, TableSortType } from '../../../components/table/table-object';
import { SortTypeEnum } from '../../../types';

export function mapFilters(filters: TableFilterType[]) {
    let mappedFilters: { [key: string]: string } = {};
    filters.forEach((item) => {
        if (item.dataIndex === 'name') {
            mappedFilters['FullName.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'jobTitle') {
            mappedFilters['JobTitle.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'location') {
            mappedFilters['Location.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'rating') {
            mappedFilters['Rating.FilterValue'] = item.value;
        }
        if (item.dataIndex === 'suspendStatus') {
            mappedFilters['Status.FilterValue'] = item.value;
        }
    });
    return mappedFilters;
}

export function mapSort(sort: TableSortType) {
    let mappedSort: { [key: string]: SortTypeEnum } = {};
    if (sort.dataIndex === 'name') {
        mappedSort['FullName.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'jobTitle') {
        mappedSort['JobTitle.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'location') {
        mappedSort['Location.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'rating') {
        mappedSort['Rating.SortType'] = sort.value;
    }
    if (sort.dataIndex === 'suspendStatus') {
        mappedSort['Status.SortType'] = sort.value;
    }
    return mappedSort;
}
