import React, { useState, useMemo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { months, totalMonths } from '../utils/month';
import { GetRegistrationCountColor } from '../utils/colors';
import { dashboardService } from '../../../../lib/api/dashboard';
import { GetRegistrationCountType, seriesType } from '../../../../types/dashboard';
import PageLoader from '../../../../components/loader';
import YearsDropdown from '../../../../components/dropdown';

type Props = {
    selectedYear: number;
};

const TotalRegistrationsChart: React.FC<Props> = ({ selectedYear }) => {
    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<GetRegistrationCountType>();
    const [dropdownYear, setDropdownYear] = useState<number>(selectedYear);
    const [seriesArray, setSeriesArray] = useState<seriesType[]>([]);

    useMemo(() => {
        setDropdownYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                await dashboardService.getRegistrationCount(dropdownYear).then((res) => {
                    const value = res.resultObject;
                    setData(res.resultObject);

                    setSeriesArray([
                        {
                            name: 'Candidate registration',
                            data: value.candidateRegistrations,
                        },
                        {
                            name: 'Employer registration',
                            data: value.employerRegistrations,
                        },
                    ]);
                });
            } catch (error) {
                console.log('getRegistrationCount', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dropdownYear]);

    const options: ApexOptions = {
        colors: GetRegistrationCountColor,
        legend: { position: 'bottom' },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff'],
            curve: 'smooth',
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        xaxis: {
            categories: months,
            max: totalMonths(dropdownYear),
        },
    };

    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <div>
                    <div className="d-flex mb-4">
                        <div className="card-title fs-16 fw-600">
                            Total registrations{' '}
                            <i className="bi bi-info-circle lt-tooltip">
                                <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                    Total number of users signed up in a year and month
                                </span>
                            </i>
                        </div>
                        <YearsDropdown setDropdownYear={setDropdownYear} />
                    </div>
                    <ReactApexChart options={options} series={seriesArray} type="bar" height={350} />
                    <div className="pt-4 border-top">
                        <div className="row">
                            <div className="col text-center">
                                <h6 className="fs-14 mb-1">Total registrations ({dropdownYear})</h6>
                                <div className="fs-20 fw-700">{data?.totalRegistrations}</div>
                            </div>
                            <div className="col text-center">
                                <h6 className="fs-14 mb-1">Candidate registrations ({dropdownYear})</h6>
                                <div className="fs-20 fw-700">{data?.totalCandidateRegistrations}</div>
                            </div>
                            <div className="col text-center">
                                <h6 className="fs-14 mb-1">Employer registrations ({dropdownYear})</h6>
                                <div className="fs-20 fw-700">{data?.totalEmployerRegistrations}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TotalRegistrationsChart;
