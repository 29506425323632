import React, { useEffect, useRef, useState } from 'react';

import history from '../../history';

import { UserRoleEnum, UserType } from '../../types/auth';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux';
import { State } from '../../types/openApi';
import { OpenAPIService } from '../../lib/api/openApi';

type Props = {
    id?: string;

    setFieldValue?: (fieldName: string, fieldvalue: string) => void;

    setNameOfState?: (fieldName: string) => void;

    setCityDisabled?: (key: boolean) => void;

    stateDisabled?: boolean;

    setIsStateValueAvailable?: (key: boolean) => void;

    setIsCityValueAvailable?: (key: boolean) => void;

    setStateDisabled?: (key: boolean) => void;

    stateFieldValue?: string;

    Iso2CountryString?: string;

    setIso2StateString?: (key: string) => void;

    setShowSkeleton?: (key: boolean) => void;
    error?: any;
    onStateSelectionChange?: (value: any) => void;
};

const StateDropdown: React.FC<Props> = ({
    id,

    stateFieldValue,

    setNameOfState,

    setFieldValue,

    stateDisabled,

    Iso2CountryString,

    setCityDisabled,

    setIso2StateString,

    setIsStateValueAvailable,

    setIsCityValueAvailable,

    setStateDisabled,

    setShowSkeleton,
    error,
    onStateSelectionChange,
}) => {
    const [stateName, setStateName] = useState('Select State');

    const [filterText, setFilterText] = useState('');

    const [openDropdown, setOpenDropdown] = useState(false);

    const [statesData, setStatesData] = useState<State[]>();

    const [dropdownPlacement, setDropdownPlacement] = useState<'down' | 'up'>('down');

    const filterInputRef = useRef<HTMLInputElement>(null);

    const stateButtonRef = useRef<HTMLButtonElement>(null);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const { user } = useSelector((state: RootState) => state.user);

    const isEditPage = history.location.pathname === '/edit-my-profile' || history.location.pathname === '/signup';

    const hasUser = user as UserType;

    let disableState = false;

    let hasCandidate = false;

    if (history.location.pathname === '/edit-my-profile') {
        hasCandidate = hasUser.role === UserRoleEnum.candidate;

        disableState = true;
    }

    const filteredStates =
        statesData &&
        statesData

            .sort((a, b) => (a.name > b.name ? 1 : -1))

            .filter(
                (states) =>
                    states.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
                    states.id.toString().startsWith(filterText)
            );

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                stateButtonRef.current &&
                !stateButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const getAllStates = async () => {
            const data = await OpenAPIService.fetchAllStates();

            return data;
        };

        const getStates = async () => {
            const data = await OpenAPIService.fetchStatesByCountry(Iso2CountryString);

            return data;
        };

        const states = JSON.parse(sessionStorage.getItem('states')!);

        if (id === 'state' && Iso2CountryString) {
            (async () => {
                const result = await getStates();

                if (result.length <= 0) {
                    setIsStateValueAvailable?.(false);
                    setIsCityValueAvailable?.(false);
                    setStateDisabled?.(true);
                    setTimeout(() => {
                        setCityDisabled?.(true);
                    }, 300);
                    setStatesData([]);
                } else {
                    setStatesData(result);
                    setIsStateValueAvailable?.(true);
                    setIsCityValueAvailable?.(true);
                }
            })();
        } else {
            if (states) {
                setStatesData(states);
            } else {
                (async () => {
                    const result = await getAllStates();
                    setStatesData(result);
                })();
            }
        }
    }, [Iso2CountryString, id, setIsStateValueAvailable]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                stateButtonRef.current &&
                !stateButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        const handleDropdownPosition = () => {
            if (openDropdown && stateButtonRef.current && dropdownRef.current) {
                const dropdownHeight = dropdownRef.current.offsetHeight;

                const triggerRect = stateButtonRef.current.getBoundingClientRect();

                const taskbarHeight = window.innerHeight - window.screen.height;

                if (triggerRect.bottom + dropdownHeight > window.innerHeight - taskbarHeight) {
                    setDropdownPlacement('up');
                } else {
                    setDropdownPlacement('down');
                }
            }
        };

        handleDropdownPosition();

        window.addEventListener('click', handleOutsideClick);

        window.addEventListener('scroll', handleDropdownPosition);

        return () => {
            window.removeEventListener('click', handleOutsideClick);

            window.removeEventListener('scroll', handleDropdownPosition);
        };
    }, [openDropdown]);

    useEffect(() => {
        if (stateFieldValue) {
            const filteredParticularCountriesById =
                statesData && statesData.filter((states: State) => states.id.toString() === stateFieldValue);

            if (filteredParticularCountriesById) {
                filteredParticularCountriesById?.map((state) => {
                    setStateName(state.name);

                    setIso2StateString?.(state.iso2);
                });

                setCityDisabled?.(false);
            } else {
                setStateName('');
            }
        } else {
            setStateName('Select State');
        }
    }, [statesData, stateFieldValue, setCityDisabled, setIso2StateString, isEditPage]);

    useEffect(() => {
        if (isNaN(parseFloat(stateFieldValue!)) && stateName === 'Select State') {
            setFieldValue?.('companyStateCode', '');
        }
    }, [stateName, setFieldValue, stateFieldValue]);

    return (
        <div className="position-relative">
            {id === 'state' ? (
                <>
                    <button
                        type="button"
                        ref={stateButtonRef}
                        id="state"
                        disabled={stateFieldValue && hasCandidate ? false : stateDisabled}
                        className={'form-select cursor-pointer text-start ' + (error ? 'is-invalid' : '')}
                        onClick={() => {
                            setOpenDropdown(!openDropdown);

                            setFilterText('');

                            setTimeout(() => {
                                filterInputRef.current?.focus();
                            }, 50);
                        }}
                    >
                        {stateName}
                    </button>

                    {openDropdown && (
                        <div ref={dropdownRef} className={`country-dropdown ${dropdownPlacement} w-100`}>
                            <div className="sticky-search">
                                <input
                                    type="search"
                                    ref={filterInputRef}
                                    className="form-control cursor-pointer country-search"
                                    placeholder="Search State"
                                    value={filterText}
                                    onChange={(e) => {
                                        setFilterText(e.target.value);
                                    }}
                                />
                            </div>

                            <div>
                                {filteredStates &&
                                    filteredStates.map((states) => (
                                        <>
                                            <li
                                                onBlur={() => setOpenDropdown(false)}
                                                className="dropdown-item cursor-pointer fs-14"
                                                onClick={() => {
                                                    setOpenDropdown(false);

                                                    // setCityDisabled?.(false);

                                                    setStateName(states.name);

                                                    setNameOfState?.(states.name);

                                                    setFieldValue?.('companyStateCode', states.id.toString());

                                                    setFieldValue?.('companyCityCode', '');

                                                    setIso2StateString?.(states.iso2);
                                                    onStateSelectionChange?.(states);
                                                }}
                                                key={states.id}
                                                value={states.id}
                                            >
                                                {states.name}
                                            </li>
                                        </>
                                    ))}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {/* {returnStateIsoString(id!)} */}

                    {stateName ? stateName + ', ' : ''}
                </>
            )}
        </div>
    );
};

export default StateDropdown;
