import React, { useEffect, useRef, useState } from 'react';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';
import { notificationService } from '../../lib/api/notification';
import { NotificationStateEnum, NotificationType, NotificationTypeEnum } from '../../types/notification';
import candidateAvatar from '../../assets/img/avatars.svg';
import companyLogo from '../../assets/img/logo.svg';
import { tokenManager } from '../../lib/token-manager';
import { serverUrl } from '../../config/constants';
import Nodata from '../../components/no-data';

type Props = {
    reRenderKey?: number;
};

const Notify: React.FC<Props> = ({ reRenderKey }) => {
    const [signalrConnection, setSignalrConnection] = useState<HubConnection | null>(null);
    const [data, setData] = useState<NotificationType[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [itemLoading, setItemLoading] = useState('');
    const [page, setPage] = useState<number>(1);
    const [limit, setlimitPage] = useState<number>(50);
    const [unread, setUnread] = useState<number>();
    const [count, setCount] = useState<number>(0);

    const notificationsRef = useRef<NotificationType[]>([]);
    notificationsRef.current = data;

    const markRead = async (id: string) => {
        try {
            setItemLoading(id);
            await notificationService.markRead(id);
            const readNotify = data?.map((item) =>
                item.notificationId === id ? { ...item, state: NotificationStateEnum.read } : item
            );
            setData(readNotify);
        } catch (error) {
            console.log('notification read services', error);
        } finally {
            setItemLoading('');
        }
    };

    const onScrollChange = (event: React.UIEvent<HTMLElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        const height = scrollTop + clientHeight + 2;

        if (height >= scrollHeight - 2 && !loading && data.length <= count) {
            setPage((prevPage) => prevPage + 1);
            setlimitPage((prevLimit) => prevLimit + 50);
        }
    };

    useEffect(() => {
        (async () => {
            const token = (await tokenManager.getToken()) as string;
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${serverUrl}NotifyUser`, { accessTokenFactory: () => token })
                .withAutomaticReconnect()
                .build();
            setSignalrConnection(newConnection);
        })();
    }, []);

    useEffect(() => {
        if (!signalrConnection) return;
        (async function () {
            try {
                await signalrConnection.start();
                signalrConnection.on('SendNotification', (message: NotificationType) => {
                    const updatedNotifications = [...notificationsRef.current];
                    updatedNotifications.unshift(message);
                    const uniqueNotify = Array.from(new Set(updatedNotifications));
                    setData(uniqueNotify);
                });
            } catch (err) {
                console.log('Connection failed: ', err);
            }
        })();
    }, [signalrConnection, reRenderKey]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const {
                    resultObject: { items, count },
                } = await notificationService.getNotificationsList(page, limit);

                setData((prevData) => {
                    const uniqueItemIds = new Set(prevData.map((item) => item.notificationId));
                    const newItems = items.filter((item) => !uniqueItemIds.has(item.notificationId));

                    return [...prevData, ...newItems];
                });
                setCount(count);
            } catch (error) {
                console.log('notification services', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [page, limit, unread]);

    useEffect(() => {
        (async () => {
            try {
                const { resultObject } = await notificationService.getUnreadCount();
                setUnread(resultObject);
            } catch (error) {
                console.log('notification UnreadCount', error);
            }
        })();
    }, [data, reRenderKey]);

    return (
        <>
            <button
                type="button"
                className="dropdown-toggle lt-dropdown-toggle text-start w-100 d-block"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <i className="bi bi-bell me-2" /> Notifications{' '}
                {unread !== 0 && (
                    <div className="badge rounded-pill bg-danger fs-12 float-end mt-1 small-mt-0">{unread}</div>
                )}
            </button>
            <div className="dropdown-menu lt-shadow-sm lt-notify-dropdown p-2 pe-0 mobile-dropdown">
                <h1 className="fs-18 fw-700 my-0 ps-2">Notifications</h1>
                <div>
                    {data.length ? (
                        <ul className="list-group list-group-flush notifiy-li" onScroll={onScrollChange}>
                            {data &&
                                data
                                    ?.sort((a, b) => (a.date < b.date ? 1 : -1))
                                    .map((item, index) => {
                                        const read = item.state === NotificationStateEnum.read;
                                        return (
                                            <>
                                                {' '}
                                                <li
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        if (read) return;
                                                        markRead(item.notificationId);
                                                    }}
                                                    key={index}
                                                    className={`${
                                                        read ? 'read' : 'cursor-pointer'
                                                    } list-group-item px-0 `}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="p-2 flex-shrink-1">
                                                            <img
                                                                width={40}
                                                                height={40}
                                                                src={
                                                                    item.avatarUrl
                                                                        ? item.avatarUrl
                                                                        : getUserIcon(item.notificationType)
                                                                }
                                                                className="rounded-circle"
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="p-1 w-100">
                                                            <p className="fs-12">{item.message}</p>
                                                            <p className="fs-12">
                                                                <span className="fw-600">
                                                                    {moment(item.date).fromNow()}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div style={{ width: 20, height: 20, marginRight: 4 }}>
                                                            {itemLoading === item.notificationId ? (
                                                                <span className="spinner-border spinner-border-sm lt-text-primary-alt" />
                                                            ) : (
                                                                <i
                                                                    className={`bi bi-circle-fill fs-10 me-2 ${
                                                                        read
                                                                            ? 'lt-text-disabled'
                                                                            : 'lt-text-primary-alt'
                                                                    }`}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        );
                                    })}
                        </ul>
                    ) : (
                        <Nodata text="No notifications received yet." icon="bi-bell" style={{ height: 450 }} />
                    )}
                    {/* {loading && (
                        <div className="spinner-grow text-info" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )} */}
                </div>
            </div>
        </>
    );
};

export default Notify;

const getUserIcon = (value: NotificationTypeEnum) => {
    switch (value) {
        case NotificationTypeEnum.offerAccepted:
        case NotificationTypeEnum.offerRejected:
        case NotificationTypeEnum.companyReviewAdded:
        case NotificationTypeEnum.candidateSignedUpByInvitation:
        case NotificationTypeEnum.reportEmployerForCandidate:
        case NotificationTypeEnum.reportEmployerForAdmin:
        case NotificationTypeEnum.reportCandidateForEmployer:
        case NotificationTypeEnum.suspendCandidateForAdmin:
        case NotificationTypeEnum.suspendCandidateForCandidate:
        case NotificationTypeEnum.suspendCandidateForEmployer:
        case NotificationTypeEnum.unsuspendCandidateForAdmin:
        case NotificationTypeEnum.unsuspendCandidateForCandidate:
        case NotificationTypeEnum.adminSignedUpBySuperAdminUserManagement:
        case NotificationTypeEnum.unsuspendCandidateForCompany:
            return candidateAvatar;
        case NotificationTypeEnum.offerCreated:
        case NotificationTypeEnum.offerRetracted:
        case NotificationTypeEnum.managerSignedUpByEmployeeManagement:
        case NotificationTypeEnum.candidateReviewAdded:
        case NotificationTypeEnum.suspendEmployerForAdmin:
        case NotificationTypeEnum.suspendEmployerForCompany:
        case NotificationTypeEnum.suspendEmployerForCandidate:
        case NotificationTypeEnum.unsuspendEmployerForAdmin:
        case NotificationTypeEnum.unsuspendEmployerForCandidate:
        case NotificationTypeEnum.unsuspendEmployerForCompany:
        case NotificationTypeEnum.reportCandidateForAdmin:
            return companyLogo;
    }
};
