import React, { ReactNode } from 'react';
import websitelogo from '../../assets/img/offerx-logo.png';
import LoginPic from '../../assets/img/login-pic.png';

type Props = {
    children: ReactNode[];
};

const MediaLayout: React.FC<Props> = ({ children }) => {
    return (
        <div className="lt-login text-center">
            <div className="container d-flex">
                <div className="row align-items-center">
                    <div className="col-md-7 col-12">
                        <img className="img-fluid mb-4" alt="" src={LoginPic} />
                    </div>
                    <div className="col-md-5 col-12">
                        <div className="text-center">
                            <img className="mb-2" width="150px" alt="" src={websitelogo} />
                            <div className="w-100">{children}</div>
                            <p className="px-5">
                                All copyrights reserved by <a href="/#"> OfferX.in</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MediaLayout;
