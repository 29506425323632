import React, { useCallback, useEffect, useState } from 'react';
import AllCompanies from './all-companies';
import Statistics from './statistics';
import ReportedByCandidates from './reported-by-candidates';
import { CompanyReportFromCandidateRequestType, CompanyReportFromCandidateType } from '../../types/company';
import { companyService } from '../../lib/api/company';
import DiscardReport from './modals/discard-report';
import SuspendReport from './modals/suspend-report';
import Suspend from '../../components/suspend-modal/suspend';
import ReportDetails from '../candidate-list/modals/report-details';
import UserInvitations from '../company-verification';

const CompanyList = () => {
    const [loadingReportFromCandidate, setLoadingReportFromCandidate] = useState(false);
    const [reportFromCandidateData, setReportFromCandidateData] = useState<CompanyReportFromCandidateType[]>([]);
    const [activeTab, setActiveTab] = useState<'all-companies' | 'reported-by-candidates'>('all-companies');
    const [currentId, setCurrentId] = useState('');
    const [rerenderKey, setRerenderKey] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);

    const getReportFromCandidate = useCallback(async () => {
        try {
            setLoadingReportFromCandidate(true);
            const { resultObject: data } = await companyService.getCount();
            const params: CompanyReportFromCandidateRequestType = {
                Start: 1,
                Limit: data.reportedByCandidatesCompaniesCount,
            };
            const {
                resultObject: { items },
            } = await companyService.getReportFromCandidateList(params);
            const mapItems = items.map((item) => ({
                ...item,
                location: `${item.companyCityDistrict}, ${item.companyState}`,
                fullName: `${item.reportedByFirstName} ${item.reportedByLastName}`,
                companySuspendReason: [...item.companySuspendReason, item.suspendReasonOtherText],
            }));
            setReportFromCandidateData(mapItems);
        } catch (err) {
            console.log('error companyService CompanyReportFromCandidateType', err);
        } finally {
            setLoadingReportFromCandidate(false);
        }
    }, []);

    useEffect(() => {
        (async function () {
            await getReportFromCandidate();
        })();
    }, [rerenderKey]);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page_title ox-dashboard-title">
                        <h1 className="">Reported Companies</h1>
                    </div>
                </div>
            </div>

            <Statistics rerenderKey={rerenderKey} applicationName='OfferX' />

            {/* <div className="card">
                <div>
                    <nav>
                        <div className="d-flex bd-highlight lt-nav-bar">
                            <div className="w-100 bd-highlight">
                                <div className="nav nav-tabs nav-justified" id="nav-tab">
                                    <button
                                        className={`nav-link col-2 fs-16 ${
                                            activeTab === 'all-companies' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => setActiveTab('all-companies')}
                                    >
                                        All companies
                                    </button>
                                    <button
                                        className={`nav-link col-2 fs-16 ${
                                            activeTab === 'reported-by-candidates' ? 'active' : ''
                                        }`}
                                        type="button"
                                        onClick={() => setActiveTab('reported-by-candidates')}
                                    >
                                        Reported by candidates{' '}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {activeTab === 'all-companies' && (
                        <>
                            <AllCompanies
                                rerenderKey={rerenderKey}
                                setRerenderKey={setRerenderKey}
                                setCurrentId={setCurrentId}
                                setShowAdminModal={setShowAdminModal}
                            />
                        </>
                    )}
                    {activeTab === 'reported-by-candidates' && (
                        <ReportedByCandidates
                            setData={setReportFromCandidateData}
                            loading={loadingReportFromCandidate}
                            data={reportFromCandidateData}
                            setCurrentId={setCurrentId}
                            setRerenderKey={setRerenderKey}
                        />
                    )}
                </div>
            </div> */}
            <div className="card reported-companies">
                <ReportedByCandidates
                    setData={setReportFromCandidateData}
                    loading={loadingReportFromCandidate}
                    data={reportFromCandidateData}
                    setCurrentId={setCurrentId}
                    setRerenderKey={setRerenderKey}
                />
            </div>
            <Suspend
                setShowAdminModal={setShowAdminModal}
                showAdminModal={showAdminModal}
                id={currentId}
                setRerenderKey={setRerenderKey}
            />
            {/* <ReportDetails data={reportFromCandidateData.find((item) => item.id === currentId)} /> */}
            {/* <DiscardReport id={currentId} setRerenderKey={setRerenderKey} />
            <SuspendReport id={currentId} setRerenderKey={setRerenderKey} /> */}
        </>
    );
};

export default CompanyList;
