import React, { useEffect, useRef, useState, useMemo } from 'react';
import history from '../../history';
import { UserRoleEnum, UserType } from '../../types/auth';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { Country } from '../../types/openApi';
import { OpenAPIService } from '../../lib/api/openApi';

type Props = {
    id?: string;
    setFieldValue?: (fieldName: string, fieldvalue: string) => void;
    setNameOfCountry?: (fieldName: string) => void;
    countryDisabled?: boolean;
    setStateDisabled?: (key: boolean) => void;
    countryFieldValue?: string;
    setCityDisabled?: (key: boolean) => void;
    setIso2CountryString?: (key: string) => void;
    setShowSkeleton?: (key: boolean) => void;
    onCountrySelectionChange?: (value: any) => void;
    error?: any;
    hideName?: boolean;
};

const CountryDropdown: React.FC<Props> = ({
    id,
    setFieldValue,
    setNameOfCountry,
    countryDisabled,
    countryFieldValue,
    setCityDisabled,
    setStateDisabled,
    setIso2CountryString,
    setShowSkeleton,
    onCountrySelectionChange,
    error,
    hideName,
}) => {
    const [countryName, setCountryName] = useState('Select Country');
    const [filterText, setFilterText] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const [countriesData, setCountriesData] = useState<Country[]>();
    const [dropdownPlacement, setDropdownPlacement] = useState<'down' | 'up'>('down');

    const filterInputRef = useRef<HTMLInputElement>(null);
    const countryButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { user } = useSelector((state: RootState) => state.user);

    const hasUser = user as UserType;

    let hasCandidate = false;
    if (history.location.pathname === '/edit-my-profile') {
        hasCandidate = hasUser.role === UserRoleEnum.candidate;
    }

    const filteredCountries =
        countriesData &&
        countriesData
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(
                (countries) =>
                    countries.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
                    countries.id.toString().startsWith(filterText)
            );

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryButtonRef.current &&
                !countryButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const getCountries = async () => {
            const data = await OpenAPIService.fetchCountriesWithPhoneCode();

            return data;
        };

        const countries = JSON.parse(sessionStorage.getItem('countries')!);

        if (countries) {
            setCountriesData(countries);
        } else {
            (async () => {
                const result = await getCountries();
                setCountriesData(result);
            })();
        }
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                countryButtonRef.current &&
                !countryButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        const handleDropdownPosition = () => {
            if (openDropdown && countryButtonRef.current && dropdownRef.current) {
                const dropdownHeight = dropdownRef.current.offsetHeight;
                const triggerRect = countryButtonRef.current.getBoundingClientRect();
                const taskbarHeight = window.innerHeight - window.screen.height;

                if (triggerRect.bottom + dropdownHeight > window.innerHeight - taskbarHeight) {
                    setDropdownPlacement('up');
                } else {
                    setDropdownPlacement('down');
                }
            }
        };

        handleDropdownPosition();

        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('scroll', handleDropdownPosition);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('scroll', handleDropdownPosition);
        };
    }, [openDropdown]);

    useEffect(() => {
        if (countryFieldValue) {
            const filteredParticularCountriesById =
                countriesData &&
                countriesData.filter((countries: Country) => countries.id.toString() === countryFieldValue);

            if (filteredParticularCountriesById) {
                filteredParticularCountriesById?.map((country) => {
                    setCountryName(country.name);
                    setIso2CountryString?.(country.iso2);
                });
            } else {
                setCountryName('');
            }

            setStateDisabled?.(false);
        } else {
            setCountryName('Select Country');
        }
    }, [countriesData, countryFieldValue, setIso2CountryString, setStateDisabled]);

    useEffect(() => {
        if (isNaN(parseFloat(countryFieldValue!)) && countryName === 'Select Country') {
            setFieldValue?.('companyCountryCode', '');
        }
    }, [countryName, setFieldValue, countryFieldValue]);

    useMemo(() => {
        switch (id) {
            case 'countryResult':
                countriesData &&
                    countriesData.map((country) => {
                        if (country.id.toString() === countryFieldValue) {
                            setIso2CountryString?.(country.iso2);
                        } else {
                            // setShowSkeleton(false)
                        }
                    });
                break;

            default:
                break;
        }
    }, [countriesData, countryFieldValue, id, setIso2CountryString]);

    return (
        <div className="position-relative">
            {id !== 'countryResult' ? (
                <>
                    <button
                        type="button"
                        ref={countryButtonRef}
                        id="country"
                        disabled={!countryFieldValue && hasCandidate ? false : countryDisabled}
                        className={'form-select cursor-pointer text-start ' + (error ? 'is-invalid' : '')}
                        onClick={() => {
                            setOpenDropdown(!openDropdown);
                            setFilterText('');
                            setTimeout(() => {
                                filterInputRef.current?.focus();
                            }, 50);
                        }}
                    >
                        {countryName}
                    </button>
                    {openDropdown && (
                        <div ref={dropdownRef} className={`country-dropdown ${dropdownPlacement} w-100`}>
                            <div className="sticky-search">
                                <input
                                    type="search"
                                    ref={filterInputRef}
                                    className="form-control cursor-pointer country-search"
                                    placeholder="Search Country"
                                    value={filterText}
                                    onChange={(e) => {
                                        setFilterText(e.target.value);
                                    }}
                                />
                            </div>
                            <div>
                                {filteredCountries &&
                                    filteredCountries.map((country) => {
                                        return (
                                            <>
                                                <li
                                                    onBlur={() => setOpenDropdown(false)}
                                                    className="dropdown-item cursor-pointer fs-14"
                                                    onClick={() => {
                                                        setOpenDropdown(false);
                                                        setStateDisabled?.(false);
                                                        setCityDisabled?.(true);
                                                        setCountryName(country.name);
                                                        setNameOfCountry?.(country.name);
                                                        setIso2CountryString?.(country.iso2);
                                                        setFieldValue?.('companyCountryCode', country.id.toString());
                                                        setFieldValue?.('companyStateCode', '');
                                                        setFieldValue?.('companyCityCode', '');

                                                        // if (countryName && countryName != country.name)
                                                        //     setFieldValue?.('IsCompanyTermsChecked', '');

                                                        onCountrySelectionChange?.(country);
                                                    }}
                                                    key={country.id}
                                                    value={country.id}
                                                >
                                                    {country.name}
                                                </li>
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                !hideName && countryName
            )}
        </div>
    );
};

export default CountryDropdown;
