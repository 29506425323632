import React, { useEffect, useRef, useState } from 'react';

import history from '../../history';

import { UserRoleEnum, UserType } from '../../types/auth';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux';
import { City } from '../../types/openApi';
import { OpenAPIService } from '../../lib/api/openApi';

type Props = {
    id?: string;

    setFieldValue?: (fieldName: string, fieldvalue: string) => void;

    setNameOfCity?: (fieldName: string) => void;

    cityDisabled?: boolean;

    cityFieldValue?: string;

    iso2CountryString?: string;

    setIsCityValueAvailable?: (key: boolean) => void;

    setCityDisabled?: (key: boolean) => void;

    iso2StateString?: string;

    setShowSkeleton?: (key: boolean) => void;
    error?: any;
    onCitySelectionChange?: (value: any) => void;
};

const CityDropdown: React.FC<Props> = ({
    id,

    cityFieldValue,

    setNameOfCity,

    setFieldValue,

    iso2CountryString,

    iso2StateString,

    cityDisabled,

    setIsCityValueAvailable,

    setCityDisabled,

    setShowSkeleton,
    error,
    onCitySelectionChange,
}) => {
    const [cityName, setCityName] = useState('Select City');

    const [filterText, setFilterText] = useState('');

    const [openDropdown, setOpenDropdown] = useState(false);

    const [citiesData, setCitiesData] = useState<City[]>();

    const [dropdownPlacement, setDropdownPlacement] = useState<'down' | 'up'>('down');

    const filterInputRef = useRef<HTMLInputElement>(null);

    const cityButtonRef = useRef<HTMLButtonElement>(null);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const { user } = useSelector((state: RootState) => state.user);

    const isEditPage = history.location.pathname === '/edit-my-profile' || history.location.pathname === '/signup';

    const hasUser = user as UserType;

    // let disableCity = false; // Initialize with default value

    let hasCandidate = false; // Initialize with default value

    if (history.location.pathname === '/edit-my-profile') {
        hasCandidate = hasUser.role === UserRoleEnum.candidate;

        // disableCity = true;
    }

    const filteredCities =
        citiesData &&
        citiesData

            ?.sort((a, b) => a.name.localeCompare(b.name))

            .filter(
                (cities) =>
                    cities.name.toLowerCase().startsWith(filterText.toLowerCase()) ||
                    cities.id.toString().startsWith(filterText)
            );

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                cityButtonRef.current &&
                !cityButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const getAllCities = async () => {
            if (iso2CountryString) {
                const data = await OpenAPIService.fetchAllCities(iso2CountryString);

                return data;
            }
        };

        const getCities = async () => {
            try {
                if (iso2CountryString && iso2StateString) {
                    const data = await OpenAPIService.fetchCitiesByStateAndCountry(iso2CountryString, iso2StateString);
                    return data;
                }
            } catch (err) {
                setCitiesData([]);
                setFieldValue?.('companyCityCode', '');
                setTimeout(() => {
                    setIsCityValueAvailable?.(false);
                    setCityDisabled?.(true);
                }, 400);
            }
        };
        const cities = JSON.parse(sessionStorage.getItem('cities')!);

        if (id === 'city' && iso2CountryString) {
            if (iso2StateString) {
                (async () => {
                    const result = await getCities();

                    if (result?.length! <= 0) {
                        setIsCityValueAvailable?.(false);
                        setCitiesData([]);
                        setTimeout(() => {
                            setCityDisabled?.(true);
                        }, 400);
                    } else {
                        setIsCityValueAvailable?.(true);
                        setCitiesData(result);
                    }
                })();
            }
        } else {
            if (cities) {
                const result = cities.some((item: City) => item.id === Number(cityFieldValue));

                if (!result) {
                    (async () => {
                        const result = await getAllCities();
                        setCitiesData(result);
                    })();
                } else {
                    setCitiesData(cities);
                }
            } else {
                (async () => {
                    const result = await getAllCities();
                    setCitiesData(result);
                })();
            }
        }
    }, [cityFieldValue, id, iso2CountryString, iso2StateString, setIsCityValueAvailable]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                cityButtonRef.current &&
                !cityButtonRef.current.contains(event.target as Node) &&
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setOpenDropdown(false);
            }
        };

        const handleDropdownPosition = () => {
            if (openDropdown && cityButtonRef.current && dropdownRef.current) {
                const dropdownHeight = dropdownRef.current.offsetHeight;

                const triggerRect = cityButtonRef.current.getBoundingClientRect();

                const taskbarHeight = window.innerHeight - window.screen.height;

                if (triggerRect.bottom + dropdownHeight > window.innerHeight - taskbarHeight) {
                    setDropdownPlacement('up');
                } else {
                    setDropdownPlacement('down');
                }
            }
        };

        handleDropdownPosition();

        window.addEventListener('click', handleOutsideClick);

        window.addEventListener('scroll', handleDropdownPosition);

        return () => {
            window.removeEventListener('click', handleOutsideClick);

            window.removeEventListener('scroll', handleDropdownPosition);
        };
    }, [openDropdown]);

    useEffect(() => {
        if (cityFieldValue) {
            const filteredParticularCountriesById =
                citiesData && citiesData.filter((cities: City) => cities.id.toString() === cityFieldValue);

            if (filteredParticularCountriesById) {
                const result = filteredParticularCountriesById?.map((state) => setCityName(state.name));

                if (result) {
                    // setTimeout(() => {
                    // setShowSkeleton?.(false);
                    // }, 2000);
                }
            } else {
                setCityName('');
            }
        } else {
            setCityName('Select City');
        }
    }, [citiesData, cityFieldValue, isEditPage, setFieldValue, setShowSkeleton]);

    useEffect(() => {
        if (isNaN(parseFloat(cityFieldValue!)) && cityName === 'Select City') {
            setFieldValue?.('companyCityCode', '');
        }
    }, [cityFieldValue, cityName, setFieldValue]);

    useEffect(() => {
        if (cityName) {
            setTimeout(() => {
                setShowSkeleton?.(false);
            }, 1000);
        } else {
            setTimeout(() => {
                setShowSkeleton?.(false);
            }, 1000);
        }
    }, [cityName, setShowSkeleton]);

    return (
        <div className="position-relative">
            {id === 'city' ? (
                <>
                    <button
                        type="button"
                        ref={cityButtonRef}
                        id="city"
                        disabled={cityFieldValue && hasCandidate ? false : cityDisabled}
                        className={'form-select cursor-pointer text-start ' + (error ? 'is-invalid' : '')}
                        onClick={() => {
                            setOpenDropdown(!openDropdown);

                            setFilterText('');

                            setTimeout(() => {
                                filterInputRef.current?.focus();
                            }, 50);
                        }}
                    >
                        {cityName}
                    </button>
                    {openDropdown && (
                        <div ref={dropdownRef} className={`country-dropdown ${dropdownPlacement} w-100`}>
                            <div className="sticky-search">
                                <input
                                    type="search"
                                    ref={filterInputRef}
                                    className="form-control cursor-pointer country-search"
                                    placeholder="Search City"
                                    value={filterText}
                                    onChange={(e) => {
                                        setFilterText(e.target.value);
                                    }}
                                />
                            </div>

                            <div>
                                {filteredCities &&
                                    filteredCities.map((cities) => (
                                        <>
                                            <li
                                                onBlur={() => setOpenDropdown(false)}
                                                className="dropdown-item cursor-pointer fs-14"
                                                onClick={() => {
                                                    setOpenDropdown(false);

                                                    setCityName(cities.name);

                                                    setNameOfCity?.(cities.name);

                                                    setFieldValue?.('companyCityCode', cities.id.toString());
                                                    onCitySelectionChange?.(cities);
                                                }}
                                                key={cities.id}
                                                value={cities.id}
                                            >
                                                {cities.name}
                                            </li>
                                        </>
                                    ))}
                            </div>
                        </div>
                    )}{' '}
                </>
            ) : (
                <>
                    {/* <span>{returnCityIsoString(id!)}</span> */}

                    {cityName ? cityName : ''}
                </>
            )}
        </div>
    );
};

export default CityDropdown;
