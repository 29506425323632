import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getJoinStatusText } from '../../../lib/utils/dictionary';
import Table from '../../../components/table';
import avatar from '../../../assets/img/company-lg-icon.png';
import PageLoader from '../../../components/loader';
import { CompanyListRequestType, CompanyProfileType } from '../../../types/company';
import { SortTypeEnum } from '../../../types';
import { companyService } from '../../../lib/api/company';
import { SuspendStatusEnumColor } from '../../../types/suspension';
import Statistics from '../../company-list-old/statistics';
import { afiiliateMarketingService } from '../../../lib/api/affiliate';

interface Country {
    CountryId: string;
    CountryName: string;
    CountryPhoneCode: string;
}

const AffiliatedCompanies = () => {
    const [data, setData] = useState<CompanyProfileType[]>([]);
    const [afilliatedata, setAfilliateData] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    // NEW
    const [start] = useState(1);
    const [limit] = useState(data.length);
    const [filters] = useState<{ [key: string]: string } | null>(null);
    const [sort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [companyCountryPhoneCode] = useState('91');

    const setStatusMessage = (status: number) => {
        switch (status) {
            case 0:
                return 'Approved';

            case 1:
                return 'Suspended';

            case 2:
                return 'Document Not Uploaded';

            case 3:
                return 'Document Uploaded';

            case 4:
                return 'Rejected';

            case 5:
                return 'Need More Evidence';

            case 6:
                return 'Partially Approved';

            default:
                return 'Waiting For Upload';
        }
    };

    const columns = useMemo(
        () => [
            {
                dataIndex: 'companyName',
                title: 'Company Name',
                render: (item: any) => (
                    <>
                        <div className="d-flex">
                            <div className="offer-table-reoffered me-2">
                                <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                            </div>
                            <div className="text-crop" style={{ top: '6px' }}>
                                <div className="big-text text-capitalize" title={item.companyName}>
                                    {item.companyName}
                                </div>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                dataIndex: 'registrationDate',
                title: 'Registered Date',
                width: '128px',
                inputFilter: (value: any) => {
                    return new Date(value).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                },
                render: (item: any) =>
                    new Date(item.registrationDate).toLocaleString('en-in', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    }),
            },
            {
                dataIndex: 'affiliatedBy',
                title: 'Affiliated By',
                render: (item: any) => (
                    <div className="text-crop" style={{ top: '6px' }}>
                        <div className="big-text text-capitalize" title={item.affiliatedBy}>
                            {item?.affiliatedBy === ' ' ? 'NA' : item?.affiliatedBy}
                        </div>
                    </div>
                ),
            },
            {
                dataIndex: 'companyPhone',
                title: 'Contact Number',
                // hideSort: true,
                render: (item: any) => (
                    <div className="text-nowrap text-ellipsis text-capitalize" title={item?.companyPhone}>
                        {item?.companyPhone ? <>{item?.companyPhone}</> : 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'suspendStatus',
                title: 'Status',
                width: '128px',
                render: (item: any) => (
                    <>
                        <div className="text-ellipsis text-capitalize" title={setStatusMessage(item.suspendStatus)}>
                            <span style={{ color: `${getStatusColor(item.suspendStatus)}` }}>
                                {setStatusMessage(item.suspendStatus)}
                            </span>
                        </div>
                    </>
                ),
            },
        ],
        [companyCountryPhoneCode]
    );

    const getList = useCallback(async () => {
        try {
            sessionStorage.clear();
            !loading && setLoading(true);

            const { resultObject } = await afiiliateMarketingService.getAfilliateMarketingList();

            setData(resultObject);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();
    }, []);

    return (
        <>
            <h1 className="mb-5 mt-4">Affiliate Companies</h1>
            <div className="company-page-contener employer-table">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <Table columns={columns} data={data} />
                    </>
                )}
            </div>
        </>
    );
};

export default AffiliatedCompanies;

const getStatusColor = (value: SuspendStatusEnumColor) => {
    switch (value) {
        case SuspendStatusEnumColor.active:
            return '#107C10';
        case SuspendStatusEnumColor.suspended:
            return '#D83B01';
        case SuspendStatusEnumColor.pending:
            return 'orange';
        case SuspendStatusEnumColor.partially:
            return '#C5B000';
        case SuspendStatusEnumColor.uploaded:
            return '#0d6efd';
        case SuspendStatusEnumColor.rejected:
            return '#D83B01';
        case SuspendStatusEnumColor.moreEvidence:
            return '#0d6efd';
        default:
            return '';
    }
};
