import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DataAsExcel from '../../download/dataAsExcel';
import { ExcelResponseType } from '../../../types/excelUpload';
import * as XLSX from 'xlsx';

type Props = {
    resetState?: () => void;
    openProcessedDataModal: boolean;
    setOpenProcessedDataModal: (key: boolean) => void;
    setIsLead?: (key: boolean) => void;
    totalOutPutData?: ExcelResponseType[];
    duplicateRecords?: number;
    failedRecords?: number;
    passRecords?: number;
    setFieldValue?: (key: string, value: string | number) => void;
    setShowCandidateUploadContent: (key: boolean) => void;
};

const DownloadExcelResponse: React.FC<Props> = ({
    openProcessedDataModal,
    setOpenProcessedDataModal,
    duplicateRecords,
    resetState,
    failedRecords,
    passRecords,
    totalOutPutData,
    setIsLead,
    setFieldValue,
    setShowCandidateUploadContent,
}) => {
    const [data, setData] = useState(totalOutPutData || []);
    const ExcelDataHeadersLabel = ['Email', 'Status', 'Reason'];
    const [dataToSendForDownload, setDatasToSendForDownload] = useState<any>();

    const getStatusColumnColor = (value: number) => {
        switch (value) {
            case 0:
                return <span style={{ color: 'green' }}>Pass</span>;

            case 1:
                return <span style={{ color: 'red' }}>Fail</span>;

            default:
                return '';
        }
    };

    const getStatusName = (value: number) => {
        switch (value) {
            case 0:
                return 'Pass';

            case 1:
                return 'Fail';

            default:
                return '';
        }
    };

    const getReasonColumnColor = (value: string) => {
        const data = (value && value.replaceAll(' ', '').toLowerCase()) || '';

        switch (data) {
            case 'useralreadyexists' || 'useralreadyexists.':
                return <span style={{ color: 'orange' }}>User already exists</span>;

            case 'invitationsent' || 'invitationsent.':
                return <span style={{ color: 'green' }}>Invitation Sent</span>;

            case 'datauploaded.':
                return <span style={{ color: 'green' }}>Data uploaded</span>;

            case 'invitationalreadyexists' || 'invitationalreadyexists.':
                return <span style={{ color: 'red' }}>Invitation already exists</span>;

            case 'candidateleadexists' || 'candidateleadexists.':
                return <span style={{ color: 'red' }}>Candidate Lead Exists</span>;

            case 'emailformatnotcorrect' || 'emailformatnotcorrect.':
                return <span style={{ color: 'red' }}>Email format not correct</span>;

            case 'emailnotprovided' || 'emailnotprovided.':
                return <span style={{ color: 'red' }}>Email not provided</span>;

            case 'firstnameorlastnameismandatoryforcreatingusers' || 'firstnameorlastnameismandatoryforcreatingusers.':
                return <span style={{ color: 'red' }}>FirstName or Last Name is mandatory for creating Users</span>;

            case 'apersonofrolemanageralreadyexistsinthesystem.':
                return <span style={{ color: 'red' }}>A person of role Manager already exists in the system</span>;

            case 'invalidphonenumber.thenumbermustbe10digitslong' || 'invalidphonenumber.thenumbermustbe10digitslong.':
                return <span style={{ color: 'red' }}>Invalid Phone Number. The number must be 10 digits long.</span>;

            default:
                return <span style={{ color: 'red' }}>{value}</span>;
        }
    };

    useEffect(() => {
        if (totalOutPutData) {
            setData(totalOutPutData || []);
            const dataToDownloadAsExcel = [
                ExcelDataHeadersLabel,
                ...totalOutPutData.map((row) => [row.ownerEmail, getStatusName(row.output), row.remarks]),
            ];
            setDatasToSendForDownload(dataToDownloadAsExcel);
        }
    }, [totalOutPutData]);

    return (
        <div>
            <Modal show={openProcessedDataModal} centered size="xl" backdrop="static">
                <div className="lt-modal-content">
                    <Modal.Header className="lt-modal-header mb-3">
                        <Modal.Title className="fw-700 fs-20">Processed data</Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            title="Close"
                            onClick={() => {
                                setOpenProcessedDataModal(false);
                                resetState?.();
                                setFieldValue?.('companyId', '');
                                setIsLead?.(false);
                                setShowCandidateUploadContent(false);
                            }}
                        ></button>
                    </Modal.Header>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="green-card lt-shadow-sm">
                                <div className="p-2">
                                    <div className="d-flex">
                                        <div className="me-2 mt-1 green-card-icon">
                                            <i className="bi bi-file-earmark-check me-0 lt-text-success fs-32"></i>
                                        </div>
                                        <div className="lt-text-success">
                                            <div className="fs-14">Total records passed</div>
                                            <div className="fs-20 fw-600">{passRecords}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="red-card lt-shadow-sm">
                                <div className="p-2">
                                    <div className="d-flex">
                                        <div className="me-2 mt-1 red-card-icon">
                                            <i className="bi bi-file-earmark-check me-0 lt-text-error fs-32"></i>
                                        </div>
                                        <div className="lt-text-error">
                                            <div className="fs-14">Total records failed</div>
                                            <div className="fs-20 fw-600">{failedRecords}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="yellow-card lt-shadow-sm">
                                <div className="p-2">
                                    <div className="d-flex">
                                        <div className="me-2 mt-1 yellow-card-icon">
                                            <i className="bi bi-file-earmark-text me-0 lt-text-warning fs-32"></i>
                                        </div>
                                        <div className="lt-text-warning">
                                            <div className="fs-14">Duplicate records</div>
                                            <div className="fs-20 fw-600">{duplicateRecords}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive excel-table-height">
                                    {/* <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Email</th>
                                                <th>Status</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row, index) => (
                                                <tr key={index}>
                                                    <td>{row.email}</td>
                                                    <td>{getStatusColumnColor(row.output)}</td>
                                                    <td>{getReasonColumnColor(row.remarks)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> */}

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                {ExcelDataHeadersLabel.map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>{row.ownerEmail}</td>
                                                    <td>{getStatusColumnColor(row.output)}</td>
                                                    <td>{getReasonColumnColor(row.remarks)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <div>
                            <button
                                type="button"
                                className="btn btn-outline-primary outline-p-hover me-3"
                                onClick={() => {
                                    setOpenProcessedDataModal(false);
                                    resetState?.();
                                    setFieldValue?.('companyId', '');
                                    setIsLead?.(false);
                                    setShowCandidateUploadContent(false);
                                }}
                            >
                                Close
                            </button>
                        </div>
                        <div>
                            <DataAsExcel
                                data={dataToSendForDownload}
                                text="Download Full Report"
                                FileName="Processed_Data"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DownloadExcelResponse;
