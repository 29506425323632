import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { dashboardService } from '../../../lib/api/dashboard';
import { GetAllAuthenticationType } from '../../../types/dashboard';
import { LoginStatusColors } from './utils/colors';
import PageLoader from '../../../components/loader';
import YearsDropdown from '../../../components/dropdown';
import { percentage } from './utils/percentage';
import CustomRadialBarChart from '../RadialBarChart';

type Props = {
    selectedYear: number;
};

const LoginStatsAuthentication: React.FC<Props> = ({ selectedYear }) => {
    const [loading, setLoading] = useState<boolean>();
    const [data, setData] = useState<GetAllAuthenticationType>();
    const [dropdownYear, setDropdownYear] = useState<number>(selectedYear);
    const [seriesArray, setSeriesArray] = useState<number[]>([]);
    const [showChart, setShowChart] = useState(false);
    const labels = ['Microsoft', 'Google', 'LinkedIn', 'Custom'];
    const colors = LoginStatusColors;

    useMemo(() => {
        setDropdownYear(selectedYear);
    }, [selectedYear]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setShowChart(false);
                await dashboardService.getAllAuthenticationTypeCount(dropdownYear).then((res) => {
                    const value = res.resultObject;
                    setData(value);
                    const totalCount = value.microsoft + value.google + value.customAuth;
                    setSeriesArray([
                        percentage(value.microsoft, totalCount),
                        percentage(value.google, totalCount),
                        percentage(value.linkedIn, totalCount),
                        percentage(value.customAuth, totalCount),
                    ]);
                });
                setTimeout(() => {
                    setShowChart(true);
                }, 1000);
            } catch (error) {
                console.log('getAllAuthenticationTypeCount service', error);
            } finally {
                setLoading(false);
            }
        })();
    }, [dropdownYear]);

    const options: ApexOptions = {
        chart: {
            height: 360,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: -120,
                endAngle: 120,
                hollow: {
                    margin: 10,
                    size: '50%',
                    background: 'transparent',
                    image: undefined,
                },
                track: {
                    show: true,
                    startAngle: undefined,
                    endAngle: undefined,
                    background: '#d2d2d2',
                    strokeWidth: '50%',
                    opacity: 1,
                    margin: 5,
                },
                dataLabels: {},
            },
        },
        stroke: {
            lineCap: 'round',
        },
        colors: LoginStatusColors,
        labels: ['Microsoft', 'Google', 'Linked in', `Custom`],
        legend: {
            floating: true,
            fontSize: '16px',
            position: 'left',
            offsetX: 10,
            offsetY: 15,
            labels: {
                useSeriesColors: true,
            },
            itemMargin: {
                vertical: 3,
            },
        },
        responsive: [
            {
                breakpoint: 1272,
                options: {
                    chart: {
                        height: 200,
                        width: 180,
                        type: 'radialBar',
                    },
                    legend: {
                        show: false,
                    },
                },
            },
            {
                breakpoint: 1318,
                options: {
                    chart: {
                        height: 180,
                        width: 180,
                        type: 'radialBar',
                    },
                    legend: {
                        show: false,
                    },
                },
            },
            {
                breakpoint: 1442,
                options: {
                    chart: {
                        height: 270,
                        type: 'radialBar',
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };
    return (
        <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <div className="card border p-4 mb-4">
                {loading ? (
                    <PageLoader />
                ) : (
                    <>
                        <div className="d-flex mb-3">
                            <div className="card-title fs-16 fw-600">Login stats of authentication</div>
                            <YearsDropdown setDropdownYear={setDropdownYear} />
                        </div>
                        <div className="d-flex justify-content-center">
                            {/* <ReactApexChart options={options} series={seriesArray} type="radialBar" height={320} /> */}
                            {seriesArray && showChart ? (
                                <div>
                                    <CustomRadialBarChart
                                        data={seriesArray}
                                        labels={labels}
                                        colors={colors}
                                        size={250}
                                        thickness={8}
                                        animationDuration={1000}
                                    />
                                </div>
                            ) : (
                                <PageLoader />
                            )}
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <div className="d-flex align-items-center">
                                <span className="lt-review-dot lt-alert-bg-green" />
                                <div className="fs-14 ms-2">Microsoft</div>
                            </div>
                            <div className="ms-auto fw-700 fs-16">{data?.microsoft ? data.microsoft : 0}</div>
                        </div>
                        <div className="d-flex align-items-center my-2">
                            <div className="d-flex align-items-center">
                                <span className="lt-review-dot lt-bg-primary" />
                                <div className="fs-14 ms-2">Google</div>
                            </div>
                            <div className="ms-auto fw-700 fs-16">{data?.google ? data.google : 0}</div>
                        </div>
                        {/* {data && data?.linkedIn > 0 && (
                            <div className="d-flex align-items-center my-2">
                                <div className="d-flex align-items-center">
                                    <span className="lt-review-dot lt-alert-bg-warning-fill" />
                                    <div className="fs-14 ms-2">Linked in</div>
                                </div>
                                <div className="ms-auto fw-700 fs-16">{data?.linkedIn ? data.linkedIn : 0}</div>
                            </div>
                        )} */}
                        {data && data?.customAuth > 0 && (
                            <div className="d-flex align-items-center my-2">
                                <div className="d-flex align-items-center">
                                    <span className="lt-review-dot lt-alert-bg-secondary-fill" />
                                    <div className="fs-14 ms-2">Custom </div>
                                </div>
                                <div className="ms-auto fw-700 fs-16">{data?.customAuth ? data.customAuth : 0}</div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default LoginStatsAuthentication;
