import { axiosInstance } from './index';
import { GetUnreadCountRespType, NotificationListResponseType } from '../../types/notification';

export const notificationService = {
    async getNotificationsList(count: number,limit:number) {
        const { data } = await axiosInstance.get(`Notification/GetLastNotifications?Start=${count}&Limit=${limit}`);
        return data as NotificationListResponseType;
    },

    async markRead(id: string) {
        await axiosInstance.put(`Notification/${id}/MarkAsRead`);
    },    

     async getUnreadCount() {
        const { data } = await axiosInstance.get('Notification/GetUnreadCount');
        return data as GetUnreadCountRespType;
    },
};
