import React, { useEffect, useState } from 'react';

interface CustomRadialBarChartProps {
    data: number[];
    labels: string[];
    colors: string[];
    size?: number;
    thickness?: number;
    animationDuration?: number;
}

const CustomRadialBarChart: React.FC<CustomRadialBarChartProps> = ({
    data,
    labels,
    colors,
    size = 300,
    thickness = 20,
    animationDuration = 1000,
}) => {
    const [animatedData, setAnimatedData] = useState(data.map(() => 0));
    const [tooltip, setTooltip] = useState<{ x: number; y: number; color: string; content: string } | null>(null);

    useEffect(() => {
        const start = performance.now();

        const animate = (time: number) => {
            const elapsed = time - start;
            const progress = Math.min(elapsed / animationDuration, 1);
            setAnimatedData(data.map((value) => value * progress));
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [data, animationDuration]);

    const getCoordinatesForAngle = (angle: number, radius: number) => {
        const radians = angle * (Math.PI / 180); // Convert angle to radians
        return {
            x: size / 2 + radius * Math.cos(radians),
            y: size / 2 + radius * Math.sin(radians),
        };
    };

    const handleMouseOver = (e: React.MouseEvent, label: string, color: string, value: number) => {
        const { clientX, clientY } = e;
        setTooltip({ x: clientX, y: clientY, color: color, content: `${label}: ${value.toFixed(2)}` });
    };

    const handleMouseOut = () => {
        setTooltip(null);
    };

    const renderSegments = () => {
        const total = data.reduce((acc, value) => acc + value, 0);
        const baseRadius = size / 2;
        const gap = 10;

        return data.map((value, i) => {
            const radius = baseRadius - i * (thickness + gap) - thickness / 2;
            const angle = (animatedData[i] / total) * 270;
            const startCoords = getCoordinatesForAngle(-90, radius); // Starting angle at -90 for top
            const endCoords = getCoordinatesForAngle(-90 + angle, radius);
            const largeArcFlag = angle > 180 ? 1 : 0;
            const pathData = `M ${startCoords.x} ${startCoords.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endCoords.x} ${endCoords.y}`;
            const dashArray = Math.round((angle / 360) * Math.PI * 2 * radius);
            const fullCircleCoords = getCoordinatesForAngle(180, radius); // Coordinates for the end of the full 360 degrees
            const fullPathData = `M ${startCoords.x} ${startCoords.y} A ${radius} ${radius} 0 1 1 ${fullCircleCoords.x} ${fullCircleCoords.y}`;

            return (
                <g key={i}>
                    {/* Full border path (background circle) */}
                    <path
                        d={fullPathData}
                        fill="none"
                        stroke="#d2d2d2"
                        strokeWidth={thickness}
                        strokeLinecap="round"
                        className="radial-bar-border"
                    />
                    {/* Animated segment path */}
                    <path
                        d={pathData}
                        fill="none"
                        stroke={colors[i]}
                        strokeWidth={thickness}
                        strokeLinecap="round"
                        className="radial-bar"
                        style={{
                            strokeDasharray: `0 ${dashArray}`,
                            animation: `draw ${animationDuration}ms linear forwards`,
                        }}
                        onMouseOver={(e) => {
                            handleMouseOver(e, labels[i], colors[i], value);
                        }}
                        onMouseOut={handleMouseOut}
                    />
                </g>
            );
        });
    };

    return (
        <>
            <div className="custom-radial-bar-chart">
                {tooltip && <div className="chart-tooltip">{tooltip.content.replace('.00', '')}%</div>}
                <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className="radial-chart-svg">
                    {renderSegments()}
                </svg>
            </div>
        </>
    );
};

export default CustomRadialBarChart;
