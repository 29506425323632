import React from 'react'; 
import { ApexOptions } from 'apexcharts';
import CandidateRating from './candidate-rating';
import CompanyRating from './company-rating';
import { CompanyCandidateRatingColor } from './utils/colors';

type Props = {
    selectedYear: number
};

const CompanyCandidateRatings: React.FC<Props> = ({ selectedYear }) => { 

    const Options: ApexOptions = {
        labels: ['Contract compliance', 'Contract violation'],
        chart: {
            height: 250,
        },
        plotOptions: {
            pie: {
                startAngle: 10,
            },
        },
        legend: {
            position: 'bottom',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 4,
            colors: ['#fff'],
            lineCap: 'round',
        },
        colors: CompanyCandidateRatingColor,
        responsive: [
            {
                breakpoint: 1370,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return (
        <div className="col-xl-8 col-xxl-8">
            <div className="card mb-4 lt-star-review-card border">
                <div className="row no-gutters">
                   <CompanyRating Options={Options} selectedYear={selectedYear}/>
                   <CandidateRating Options={Options} selectedYear={selectedYear}/>
                </div>
            </div>
        </div>
    );
};

export default CompanyCandidateRatings;
