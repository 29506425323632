import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import history from '../../history';
import { routes } from './routes-names';
import Main from '../main';
import { getUser } from '../../redux/user';
import ProtectedRoute from './protected';
import Room from '../room';
import ScrollToTop from './scroll-to-top';

const Routes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        (async function () {
            await dispatch(getUser());
        })();
    }, [dispatch]);

    return (
        <Router history={history}>
            <ScrollToTop />
            <Switch>
                <Route exact path={routes.main} component={Main} />
                <ProtectedRoute path={routes.main} component={Room} />
            </Switch>
            <ToastContainer autoClose={5000} transition={Zoom} theme="colored" hideProgressBar />
        </Router>
    );
};

export default Routes;
