import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import avatar from '../../assets/img/avatars.png';
import RatingStar from '../../components/rating-star';
import AsyncTable from '../../components/table/async';
import { candidateService } from '../../lib/api/candidate';
import { suspensionService } from '../../lib/api/suspension';
import { generateLink } from '../../lib/utils/generate-link';
import { Message, SuccessIcon, SweetAlertTitle, WarningIcon } from '../../lib/utils/messages';
import { SortTypeEnum } from '../../types';
import { CandidateAdvancedRequestType, CandidateListType } from '../../types/candidate';
import { SuspendStatusEnum } from '../../types/suspension';
import { routes } from '../routes/routes-names';
import { mapFilters, mapSort } from './utils';
import statesData from '../countries states cities/states.json';
import citiesData from '../countries states cities/cities.json';
import StateCityCodeValue from '../../components/profile/stateCityCodeValue';

type Props = {
    rerenderKey: number;
    setCurrentId: (id: string) => void;
    setShowAdminModal: (value: boolean) => void;
    setRerenderKey: (key: number) => void;
};

interface State {
    StateId: string;
    StateName: string;
    CountryId: string;
}

interface City {
    CitiesId: string;
    CitiesName: string;
    CitiesStateId: string;
}

const AllCandidates: React.FC<Props> = ({ rerenderKey, setCurrentId, setShowAdminModal, setRerenderKey }) => {
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);
    const [totalCount, setTotalCount] = useState(0);
    const [data, setData] = useState<CandidateListType[]>([]);

    // const getStateName = (item: CandidateListType) => {
    //     if (isNaN(parseFloat(item?.state!))) {
    //         const stateIdByName = statesData.find(
    //             (state: State) => state?.StateName?.toLowerCase() === item?.state?.toLowerCase()
    //         );
    //         const stateNameById = statesData.find((state: State) => state.StateId === stateIdByName?.StateId);

    //         return stateNameById?.StateName!;
    //     } else if (item?.state! === 'Other') {
    //         return 'Others';
    //     } else {
    //         const stateNameById = statesData.find((state: State) => state.StateId === item?.state!);
    //         return stateNameById?.StateName!;
    //     }
    // };

    // const getCityName = (item: CandidateListType) => {
    //     if (isNaN(parseFloat(item?.cityDistrict!))) {
    //         const cityIdByName = citiesData.find(
    //             (city: City) => city.CitiesName?.toLowerCase() === item?.cityDistrict?.toLowerCase()
    //         );
    //         const cityNameById = citiesData.find((city: City) => city.CitiesId === cityIdByName?.CitiesId);

    //         return cityNameById?.CitiesName!;
    //     } else if (item?.cityDistrict! === 'Other') {
    //         return 'Others';
    //     } else {
    //         const cityNameById = citiesData.find((city: City) => city.CitiesId === item?.cityDistrict!);
    //         return cityNameById?.CitiesName!;
    //     }
    // };

    const suspendCandidate = (item: any) => {
        setShowAdminModal(true);
        setCurrentId(item);
    };

    const unsuspend = useCallback(
        async (id: string) => {
            try {
                setLoading(true);
                await suspensionService.unSuspendByCandidate(id);
                setRerenderKey(Date.now());
            } catch (error) {
            } finally {
                setLoading(false);
            }
        },
        [setRerenderKey]
    );

    const suspendswal = (id: string) => {
        Swal.fire({
            title: Message.unsuspendCandidate,
            text: Message.unsuspendCandText,
            icon: WarningIcon,
            showCancelButton: true,
            confirmButtonColor: '#0096ff',
            cancelButtonColor: '#d83b01',
            confirmButtonText: 'Unsuspend',
            showConfirmButton: true,
            showCloseButton: false,
        }).then((result: any) => {
            if (result.isConfirmed) {
                unsuspend(id);
                Swal.fire({
                    icon: SuccessIcon,
                    title: SweetAlertTitle.Success,
                    text: Message.unsuspendCandSuccess,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
            }
        });
    };

    const columns = useMemo(
        () => [
            // {
            //   dataIndex: 'avatarUrl',
            //   title: '',
            //   hideSort: true,
            //   render: (item: any) => <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />,
            // },
            {
                dataIndex: 'name',
                title: 'Candidate Name',
                render: (item: any) => (
                    <div className="text-ellipsis" title={item.fullName}>
                        <div className="offer-table-reoffered me-2">
                            <img src={item.avatarUrl || avatar} alt="" className="avatar avatar--xs" />
                        </div>
                        <Link
                            to={generateLink(routes.candidateView, { id: item.id, suspendStatus: item.suspendStatus })}
                            className="offer-table-link text-capitalize"
                        >
                            {item.fullName?.toLowerCase()}
                        </Link>
                    </div>
                ),
            },
            {
                dataIndex: 'jobTitle',
                title: 'Job Title',
                render: (item: any) => (
                    <div className="text-ellipsis text-capitalize" title={item.jobTitle}>
                        {item.jobTitle || 'NA'}
                    </div>
                ),
            },
            {
                dataIndex: 'location',
                title: 'Location',
                render: (item: any) => (
                    <div
                        className="text-ellipsis text-capitalize"
                        // title={item.cityDistrict?.toLowerCase() + ', ' + item.state?.toLowerCase()}
                    >
                        {/* {item.cityDistrict?.toLowerCase() + ', ' + item.state?.toLowerCase()} */}
                        <StateCityCodeValue
                            id={String(Math.random()) + 1}
                            countryValue={item?.country}
                            cityValue={item?.cityDistrict}
                            stateValue={item?.state}
                        />
                    </div>
                ),
            },
            {
                dataIndex: 'rating',
                title: 'Rating',
                render: (item: any) => <RatingStar value={item.rating} />,
                type: 'numberRating',
            },
            {
                dataIndex: 'suspendStatus',
                title: 'Actions',
                hideFilter: true,
                render: (item: any) => (
                    <div className="d-flex justify-content-center">
                        <div className="dropdown lt-dropdown-menu">
                            <button
                                className="btn btn-secondary dropdown-toggle ps-0"
                                id="MoreOption"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {item.suspendStatus === SuspendStatusEnum.active && (
                                    <span className="lt-text-success">
                                        Active <i className="bi bi-chevron-down fs-14" />
                                    </span>
                                )}
                                {item.suspendStatus === SuspendStatusEnum.suspended && (
                                    <span className="lt-text-error">
                                        Suspended <i className="bi bi-chevron-down fs-14" />
                                    </span>
                                )}
                            </button>
                            <ul className="dropdown-menu lt-shadow-sm py-0" aria-labelledby="MoreOption">
                                <li>
                                    <button
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#UnsuspendModal"
                                        className="dropdown-item lt-text-success"
                                        onClick={() => suspendswal(item.id)}
                                        disabled={item.suspendStatus === SuspendStatusEnum.active}
                                    >
                                        {loading && <span className="spinner-border spinner-border-sm" />}
                                        {item.suspendStatus === SuspendStatusEnum.active ? 'Active' : 'Unsuspend'}
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        disabled={item.suspendStatus === SuspendStatusEnum.suspended}
                                        className="dropdown-item lt-text-error"
                                        onClick={() => (suspendCandidate(item.id), setShowAdminModal(true))}
                                    >
                                        {item.suspendStatus === SuspendStatusEnum.suspended ? 'Suspended' : 'Suspend'}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                ),
            },
        ],
        []
    );

    const getCandidateList = useCallback(async () => {
        try {
            setLoading(true);
            const params: CandidateAdvancedRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };
            const {
                resultObject: { items, count },
            } = await candidateService.getList(params);

            var mapItems = items.map((item) => ({
                ...item,
                // state: getStateName(item),
                // cityDistrict: getCityName(item),
            }));

            setData(mapItems);

            setTotalCount(count);
        } catch (err) {
            console.log('error companyService getList', err);
        } finally {
            setLoading(false);
        }
    }, [start, limit, filters, sort]);

    useEffect(() => {
        (async function () {
            await getCandidateList();
        })();
    }, [start, limit, filters, sort, getCandidateList, rerenderKey]);

    return (
        <div>
            <AsyncTable
                loading={loading}
                columns={columns}
                data={data}
                start={start}
                setStart={setStart}
                pageSize={limit}
                setPageSize={setLimit}
                totalCount={totalCount}
                makeSearchRequest={(filters) => setFilters(mapFilters(filters))}
                makeSortRequest={(value) => setSort(mapSort(value))}
            />
        </div>
    );
};

export default AllCandidates;
