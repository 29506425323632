import React, { useEffect, useState } from 'react';
import PdfViewer from '../../components/pdf-viewer';
import { Modal } from 'react-bootstrap';
import PageLoader from '../../components/loader';

type Props = {
    id?: string;
    url: string;
    policyDocViewModal: boolean;
    setPolicyDocViewModal: (key: boolean) => void;
};

const ViewPolicyModal: React.FC<Props> = ({ policyDocViewModal, setPolicyDocViewModal, id, url }) => {
    return (
        <div>
            <Modal
                className="modal fade"
                backdrop="static"
                show={policyDocViewModal}
                size="lg"
                // aria-labelledby="contained-modal-title-vcenter policyViewLabel"
                aria-labelledby="policyViewLabel"
                centered
            >
                <Modal.Header onHide={() => setPolicyDocViewModal(false)}>
                    <div className="flex-column">
                        <h1 className="modal-title w-100 fs-16" id="policyViewLabel">
                            Uploaded Document
                        </h1>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        title='Close'
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setPolicyDocViewModal(false)}
                    ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${url ? 'view-doc-height' : 'view-doc-height'}`}>
                        {url && <PdfViewer url={url as string} />}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ViewPolicyModal;
