import { Dispatch } from '@reduxjs/toolkit';
import { tokenManager } from '../../../lib/token-manager';
import { getUser } from '../../../redux/user';
import history from '../../../history';
import { routes } from '../../routes/routes-names';
import { AuthResponseType } from '../../../types/auth';
import { closeModal } from '../../../lib/utils/close-modal';

export async function goToRoom(data: AuthResponseType, dispatch: Dispatch<any>) {
    closeModal('SignInSignUp');
    tokenManager.setToken(data.resultObject.accessToken);
    await dispatch(getUser());
    history.push(routes.dashboard);
}
