import React, { useState, useEffect, useCallback } from 'react';
import { companyService } from '../../lib/api/company';
import { CompanyCountType, CompanyListRequestType, CompanyProfileType, ScreenXCompanyProfileType } from '../../types/company';
import PageLoader from '../../components/loader';
import { getColorStatus } from '../../lib/utils/cards-get-color-status';
import { SortTypeEnum } from '../../types';
import { SuspendStatusEnum } from '../../types/suspension';

// type Props = {
//     rerenderKey: number;
// };

type Props = {
    applicationName: string;
}

const Statistics: React.FC<Props> = ({ applicationName }) => {
    const [countsData, setCountsData] = useState<CompanyCountType | null>(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<CompanyProfileType[] | ScreenXCompanyProfileType[]>([]);
    const [start, setStart] = useState(1);
    const [limit, setLimit] = useState(data.length);
    const [filters, setFilters] = useState<{ [key: string]: string } | null>(null);
    const [sort, setSort] = useState<{ [key: string]: SortTypeEnum } | null>(null);

    const [approved, setApproved] = useState(0);
    const [documentNotUploaded, setDocumentNotUploaded] = useState(0);
    const [partiallyApproved, setPartiallyApproved] = useState(0);
    const [rejected, setRejected] = useState(0);

    const getList = useCallback(async () => {
        try {
            !loading && setLoading(true);
            const params: CompanyListRequestType = {
                Start: start,
                Limit: limit,
                ...filters,
                ...sort,
            };
            if (applicationName === "OfferX") {
                const {
                    resultObject: { items, count },
                } = await companyService.getAllList(params);

                var mapItems = items.map((item) => ({
                    ...item,
                }));

                setData(mapItems);
                setLimit(count);
            }
            else {
                const {
                    result: { companies, count },
                } = await companyService.getVerificationCompanyList();

                var mapItemsScreenX = companies.map((item) => ({
                    ...item,
                }));

                setData(mapItemsScreenX);
                setLimit(count);
            }



        } catch (err) {
        } finally {
            setLoading(false);
        }
    }, [loading]);

    useEffect(() => {
        (async function () {
            await getList();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        data.map((value, index) => {
            if (value.suspendStatus === SuspendStatusEnum.active) {
                setApproved((preApproved) => preApproved + 1);
            } else if (value.suspendStatus === SuspendStatusEnum.partially) {
                setPartiallyApproved((prePartiallyApproved) => prePartiallyApproved + 1);
            } else if (value.suspendStatus === SuspendStatusEnum.rejected) {
                setRejected((preRejected) => preRejected + 1);
            } else if (value.suspendStatus === SuspendStatusEnum.pending) {
                setDocumentNotUploaded((preDocumentNotUploaded) => preDocumentNotUploaded + 1);
            }
        });
    }, [data]);


    return (
        <>
            {data ? (
                <div className="row mt-4">
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Approved
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Total approved companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building-check me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary"> {approved}</div>
                            {/* <div>
                                <span
                                    className={
                                        getColorStatus(countsData.totalCompaniesGrowth)
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(countsData.totalCompaniesGrowth)
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />{' '}
                                    {countsData.totalCompaniesGrowth}%
                                </span>
                                <span className="ms-1"> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Document Not Uploaded
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Document not uploaded companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-file-earmark-excel me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{documentNotUploaded}</div>
                            {/* <div>
                                <span
                                    className={
                                        getColorStatus(countsData.activeCompaniesGrowth)
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(countsData.activeCompaniesGrowth)
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />
                                    {countsData.activeCompaniesGrowth}%
                                </span>
                                <span className="ms-1"> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Partially Approved
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14 bottom-tip">
                                            Partially approved companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-file-earmark-ppt me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{partiallyApproved}</div>
                            {/* <div>
                                <span
                                    className={
                                        getColorStatus(countsData.suspendedCompaniesGrowth)
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(countsData.suspendedCompaniesGrowth)
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />{' '}
                                    {countsData.suspendedCompaniesGrowth}%
                                </span>
                                <span className="ms-1"> than last month</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-xl-3">
                        <div className="card lt-shape-card p-3 mb-4">
                            <div className="fw-600 fs-14">
                                <div className="lt-dashboard-text">
                                    Rejected
                                    <i className="bi bi-info-circle lt-tooltip ms-1">
                                        <span className="lt-tooltiptext lt-shadow-md fw-400 fs-14">
                                            Total rejected companies in {applicationName === "OfferX" ? 'offerx' : 'screenx'} as on today
                                        </span>
                                    </i>
                                </div>
                                <div className="fs-28 lt-dashboard-icon">
                                    <i className="lt-dashboard-text bi bi-building-x me-0" />
                                </div>
                            </div>
                            <div className="fw-400 fs-32 lt-text-link-primary">{rejected}</div>
                            {/* <div>
                                <span
                                    className={
                                        getColorStatus(countsData.reportedByCandidatesCompaniesGrowth)
                                            ? 'lt-text-success fw-600'
                                            : 'lt-text-error fw-600'
                                    }
                                >
                                    <i
                                        className={
                                            getColorStatus(countsData.reportedByCandidatesCompaniesGrowth)
                                                ? 'bi bi-caret-up-fill'
                                                : 'bi bi-caret-down-fill'
                                        }
                                    />{' '}
                                    {countsData.reportedByCandidatesCompaniesGrowth}%
                                </span>
                                <span className="ms-1"> than last month</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Statistics;
