import { ApiResponseType } from './api';

export enum InviteStatusEnum {
    invited,
    joined,
    deleted,
}

export enum CompanyLeadStatus {
    Uploaded,
    InviteSent,
    RequestToOnboard,
    Approved,
    Rejected,
    Onboard,
}

export type CompanyLeadResendParamType = {
    companyLeadId: string;
};

// export type CreateInviteRequestType = {
//     firstName: string;
//     middleName: string;
//     lastName: string;
//     email: string;
//     emailSubject: string;
//     emailMessage: string;
// };

export type CreateInviteRequestType = {
    firstName: string;
    middleName: string;
    lastName: string;
    userEmail: string;
    companyName: string;
    companyWebsite: string;
    companyEmail: string;
    companyContact: string;
    companyCountryCode: string;
    companyCityCode: string;
    companyStateCode: string;
    postalCode: string;
    companySTDCode: string;
    ownerSTDCode: string;
    contactNumber: string;
    companyCountryName?: string;
    companyStateName?: string;
    companyCityName?: string;
};

export type InviteType = {
    id: string;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    inviteStatus: InviteStatusEnum;
    invitedDate: Date;
};

export type InviteListResponseType = Omit<ApiResponseType, 'resultObject'> & {
    resultObject: {
        items: InviteType[];
        hasMore: boolean;
        count: number;
    };
};

export type CompanyLeadResendType = Omit<ApiResponseType, 'resultObject'> & {
    isSuccessful: boolean;
    resultObject: {
        isEmailSent: boolean;
        errorMessage: string;
    };
};

export type ScreenXCompanyLeadResendType = Omit<ApiResponseType, 'result'> & {
    statusCode: number;
    result: {
        isEmailSent: boolean;
        errorMessage: string;
    };
};

export const filterValueText = (col: any) => {
    const resultData = JSON.parse(sessionStorage.getItem(col.dataIndex)!);

    if (resultData) {
        switch (col.dataIndex) {
            case 'name':
                return resultData;
            case 'fullName':
                return resultData;
            case 'registeredBy':
                return resultData;
            case 'jobTitle':
                return resultData;
            case 'documentType':
                return resultData;
            case 'createdDate':
                return resultData;
            case 'onboardedDate':
                return resultData;
            case 'dateInvited':
                return resultData;
            case 'salesOwner':
                return resultData;
            case 'salesOwnerName':
                return resultData;
            case 'contactNumber':
                return resultData;
            case 'inviteStatus':
                return resultData;
            case 'inviteType':
                return resultData;
            case 'ownerPhoneNumber':
                return resultData;
            case 'suspendStatus':
                return resultData;
            case 'uploadedDate':
                return resultData;
            case 'companyName':
                return resultData;
            case 'location':
                return resultData;
            case 'leadStatus':
                return resultData;
            case 'companyInviteType':
                return resultData;
            case 'website':
                return resultData;
            case 'companySuspendReasonText':
                return resultData;
            case 'suspensionDecision':
                return resultData;
            case 'rating':
                return resultData;
            case 'candidateState':
                return resultData;
            case 'reportedByCompanyName':
                return resultData;
            case 'candidateSuspendReasonText':
                return resultData;
            case 'candidateFullName':
                return resultData;
            case 'reportedDate':
                return resultData;
            case 'email':
                return resultData;
            case 'role':
                return resultData;
            case 'dateAdded':
                return resultData;
            case 'joinStatus':
                return resultData;
            case 'phone':
                return resultData;
            case 'documentUploadedDate':
                return resultData;
            case 'salesOwnerOfferx':
                return resultData;
            case 'companyWebsite':
                return resultData;
            case 'adminPhone':
                return resultData;
            case 'companyPhone':
                return resultData;
            case 'adminName':
                return resultData;
            case 'registrationDate':
                return resultData;
            case 'adminEmail':
                return resultData;
            case 'companyEmail':
                return resultData;
            case 'hrmsJoinStatus':
                return resultData;
            case 'firstName':
                return resultData;
            case 'lastName':
                return resultData;
            case 'phoneNumber':
                return resultData;
            case 'referralCode':
                return resultData;
            case 'referralURL':
                return resultData;
            case 'affiliatedBy':
                return resultData;
            case 'companiesRegistered':
                return resultData;
            case 'ownerEmail':
                return resultData;
            default:
                return '';
        }
    } else {
        return '';
    }
};
