import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import PageLoader from '../loader';

type Props = {
    url: string;
};

const PdfViewer: React.FC<Props> = ({ url }) => {
    const [numPages, setNumPages] = useState(0);

    return (
        <Document
            file={url}
            onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
            }}
            loading={() => <PageLoader text="Loading..." />}
        >
            {Array.from(Array(numPages), (e, i) => i + 1).map((item) => (
                <Page key={item} pageNumber={item} />
            ))}
        </Document>
    );
};

export default PdfViewer;
