import React, { useEffect, useState } from 'react';
import history from '../../../history';
import { offerService } from '../../../lib/api/offer';
import { policyDocumentService } from '../../../lib/api/policyDocument';
import { Spinner } from 'react-bootstrap';

type Props = {
    id: string;
    type?: "button" | "link";
};

const Base64DownloadPdf: React.FC<Props> = ({ id, type = "button" }) => {
    const [base64StringData1, setBase64StringData1] = useState('');
    const [loading, setLoading] = useState(false);

    const base64ToBlob = (base64String: string, contentType: string = ''): Blob => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    const downloadPDF = (base64String: string, fileName: string) => {
        const blob = base64ToBlob(base64String);
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
    };

    const fileName = `${id}.pdf`;

    const handleDownload = (base64StringToDownload: string) => {
        setLoading(true);
        downloadPDF(base64StringToDownload, fileName);
    };

    useEffect(() => {
        (async function () {
            try {
                if (id) {
                    const {
                        resultObject: { documentId },
                    } = await policyDocumentService.getBase64PolicyDocumentString({
                        documentId: id,
                    });
                    setBase64StringData1(documentId);
                } else {
                    setBase64StringData1('');
                }
            } catch (err: any) {
                setBase64StringData1('');
            } finally {
                // setLoading(false);
            }
        })();
    }, [id]);

    return (
        <>
            {type == "button" ?
                <button
                    onClick={() => {
                        setLoading(true);
                        handleDownload(base64StringData1);
                        setTimeout(() => {
                            setLoading(false);
                        }, 800);
                    }}
                    className="btn btn-primary text-center"
                    title="Download policy document"
                    disabled={base64StringData1 ? false : true}
                >
                    {loading ? <Spinner size="sm" /> : <i className="bi bi-download" />} Download
                </button> :
                <button
                    onClick={() => {
                        setLoading(true);
                        handleDownload(base64StringData1);
                        setTimeout(() => {
                            setLoading(false);
                        }, 800);
                    }}
                    className="link-button"
                    title="Download policy document"
                    disabled={base64StringData1 ? false : true}
                >
                    {loading ? <Spinner size="sm" /> : ""} Download
                </button>
            }
        </>
    );
};

export default Base64DownloadPdf;
