import { axiosInstance } from './index';
import {
    Base64PolicyDocCountParamType,
    Base64ResponseType,
    CandidateAndCountryPolicyDocCountParamType,
    CandidateDocCountResponseType,
    CompanyDocCountResponseType,
    PolicyDocumentResponseType,
} from '../../types/policyDocument';

export const policyDocumentService = {
    async uploadPolicyDocument(data: FormData) {
        await axiosInstance.post(`User/UploadPolicyDocuments`, data);
    },

    async getPolicyDocuments() {
        const { data } = await axiosInstance.get('User/GetTotalPolicyDocumentCount');
        return data as PolicyDocumentResponseType;
    },

    async getCandidateAndCountryPolicyDocumentCount(params: CandidateAndCountryPolicyDocCountParamType) {
        const { data } = await axiosInstance.get('User/GetCandidateandCountryPolicyDocumentCount', { params });
        return data;
    },

    async getCandidateanDocumentCount(params: CandidateAndCountryPolicyDocCountParamType) {
        const { data } = await axiosInstance.get('User/GetCandidateanDocumentCount', { params });
        return data as CandidateDocCountResponseType;
    },

    async getCompanyPolicyDocumentCount(params: CandidateAndCountryPolicyDocCountParamType) {
        const { data } = await axiosInstance.get('User/GeCompanyPolicyDocumentCount', { params });
        return data as CompanyDocCountResponseType;
    },

    async getBase64PolicyDocumentString(params: Base64PolicyDocCountParamType) {
        const { data } = await axiosInstance.get('User/GetFileBase64String', { params });
        return data as Base64ResponseType;
    },
};
