import { axiosInstance } from './index';
import { AuthResponseType, JoinByInvitationType, socialSignInType, UserResponseType } from '../../types/auth';

export const authService = {
    async socialSignIn(data: socialSignInType) {
        const { data: responseData } = await axiosInstance.post('user/SignIn', data);
        return responseData as AuthResponseType;
    },

    async joinByInvitation(data: JoinByInvitationType) {
        await axiosInstance.post('user/AdminJoinByInvitation', data);
    },

    async getUser() {
        const { data } = await axiosInstance.get('user');
        return data as UserResponseType;
    },
};
