import { axiosInstance } from './index';

export const afiiliateMarketingService = {
    async getAfilliateMarketingList() {
        const { data } = await axiosInstance.get('AfilliateMarketing');
        return data as any;
    },

    async getAllAfilliateMarketersList() {
        const { data } = await axiosInstance.get('AfilliateMarketing/GetAffiliateMarketers');
        return data as any;
    },
};
