export type Country = {
    id: number;
    name: string;
    iso2: string;
    phonecode: string;
};

export interface CountryDetails {
    id: number;
    name: string;
    iso3: string;
    iso2: string;
    phonecode: string;
    capital: string;
    currency: string;
    native: string;
    emoji: string;
    emojiU: string;
}

export type State = {
    id: number;
    name: string;
    iso2: string;
};

export type City = {
    id: number;
    name: string;
    country_id: number;
};

export type FormDataType = {
    firstName: string;
    lastName: string;
    jobTitle: string;
    cityDistrict: string;
    state: string;
    country: string;
    institutionName: string;
    userCountryCode: string;
    phone: string;
    countryPhoneCode?: string;
    email: string;
    start: number;
    limit: number;
};

export const defaultFormData = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    country: '',
    state: '',
    cityDistrict: '',
    institutionName: '',
    userCountryCode: '',
    phone: '',
    countryPhoneCode: '',
    email: '',
    start: 1,
    limit: 10,
};
